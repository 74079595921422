import React, {useState, useEffect} from 'react';
import {
    CircularProgress,
    Grid,
    Button,
    TextField,
    Radio,
    FormGroup,
    FormControlLabel,
    Switch,
    Drawer,
    Toolbar,
    IconButton,
    AppBar,
    Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import moment from 'moment';
import MuiStyleFunction from './PhotoShoot.style';
import axios from 'axios';
import PersonIcon from '@material-ui/icons/Person';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import CalendarTodayIcon from '@material-ui/icons/DateRange';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import LocationOnRoundedIcon from '@material-ui/icons/LocationOnRounded';
import FolderIcon from '@material-ui/icons/Folder';
const useStyles = makeStyles(MuiStyleFunction);

var mobile = (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1)

const PhotoShoot = (props) => {

    const classes = useStyles()

    return (
        <div style={{color: '#6C65FF', width: '100%', marginTop: 8, fontSize: 15}}>
            <Grid container spacing={2} dir="ltr" justify="center">
                <Grid item xs={12} sm={12} md={6} xl={6}>
                <div style={{fontFamily: "'Montserrat', sans-serif", padding: '32px 16px', display: 'flex', marginBottom: 12, alignItems: 'center', width: '100%', height: mobile ? 80 : 92, padding: '32px 16px', backgroundColor: 'rgb(255,255,255)', boxShadow: '0px 4px 8px 0px rgba(99,52,255,0.05)', borderRadius: 20}}>
                    <div style={{display: 'inline-block', height: mobile ? 52 : 62, width: mobile ? 52 : 62, minWidth: mobile ? 52 : 62, borderRadius: mobile ? 26 : 30, backgroundColor: '#4BB8FF30', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center'}}>
                    <FolderIcon size="25" style={{color: '#4BB8FF'}}/>
                    </div>
                    <div style={{justifyContent: 'center',  marginLeft: 12, flexFlow: 'column', display: 'flex', justifyContent: 'center', marginLeft: 12, fontSize: 15}}>
                    <div style={{marginTop: 4, color: '#7F7E90', fontWeight: 400}}>Project Name</div>
                    <div style={{marginTop: 8, color: '#1F1F55', fontWeight: 600}}>
                        {props.projectName}
                    </div>
                    </div>
                </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6} xl={6}>
                <div style={{fontFamily: "'Montserrat', sans-serif", padding: '32px 16px', display: 'flex', marginBottom: 12, alignItems: 'center', width: '100%', height: mobile ? 80 : 92, padding: '32px 16px', backgroundColor: 'rgb(255,255,255)', boxShadow: '0px 4px 8px 0px rgba(99,52,255,0.05)', borderRadius: 20}}>
                    <div style={{display: 'inline-block', height: mobile ? 52 : 62, width: mobile ? 52 : 62, minWidth: mobile ? 52 : 62, borderRadius: mobile ? 26 : 30, backgroundColor: '#FE8B5930', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center'}}>
                    <MonetizationOnIcon size="25" style={{color: '#FE8B59'}}/>
                    </div>
                    <div style={{justifyContent: 'center',  marginLeft: 12, flexFlow: 'column', display: 'flex', justifyContent: 'center', marginLeft: 12, fontSize: 15}}>
                    <div style={{marginTop: 4, color: '#7F7E90', fontWeight: 400}}>Product</div>
                    <div style={{marginTop: 8, color: '#1F1F55', fontWeight: 600}}>
                        {props.product}
                    </div>
                    </div>
                </div>
                </Grid>
                {/* <Grid item xs={12} sm={12} md={6} xl={6}>
                <div style={{fontFamily: "'Montserrat', sans-serif", padding: '32px 16px', display: 'flex', marginBottom: 12, alignItems: 'center', width: '100%', height: mobile ? 80 : 92, padding: '32px 16px', backgroundColor: 'rgb(255,255,255)', boxShadow: '0px 4px 8px 0px rgba(99,52,255,0.05)', borderRadius: 20}}>
                    <div style={{display: 'inline-block', height: mobile ? 52 : 62, width: mobile ? 52 : 62, minWidth: mobile ? 52 : 62, borderRadius: mobile ? 26 : 30, backgroundColor: '#FD528030', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center'}}>
                    <LocationOnRoundedIcon size="25" style={{color: '#FD5280'}}/>
                    </div>
                    <div style={{justifyContent: 'center',  marginLeft: 12, flexFlow: 'column', display: 'flex', justifyContent: 'center', marginLeft: 12, fontSize: 15}}>
                    <div style={{marginTop: 4, color: '#7F7E90', fontWeight: 400}}>Location</div>
                    <div style={{marginTop: 8, color: '#1F1F55', fontWeight: 600}}>
                        {props.location}
                    </div>
                    </div>
                </div>
                </Grid> */}
                {/* <Grid item xs={12} sm={12} md={6} xl={6}>
                <div style={{fontFamily: "'Montserrat', sans-serif", padding: '32px 16px', display: 'flex', marginBottom: 12, alignItems: 'center', width: '100%', height: mobile ? 80 : 92, padding: '32px 16px', backgroundColor: 'rgb(255,255,255)', boxShadow: '0px 4px 8px 0px rgba(99,52,255,0.05)', borderRadius: 20}}>
                    <div style={{display: 'inline-block', height: mobile ? 52 : 62, width: mobile ? 52 : 62, minWidth: mobile ? 52 : 62, borderRadius: mobile ? 26 : 30, backgroundColor: '#33D7A030', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center'}}>
                    <CalendarTodayIcon size="25" style={{color: '#33D7A0'}}/>
                    </div>
                    <div style={{justifyContent: 'center',  marginLeft: 12, flexFlow: 'column', display: 'flex', justifyContent: 'center', marginLeft: 12, fontSize: 15}}>
                    <div style={{marginTop: 4, color: '#7F7E90', fontWeight: 400}}>Date & Time</div>
                    <div style={{marginTop: 8, color: '#1F1F55', fontWeight: 600}}>
                        {props.dateTime}
                    </div>
                    </div>
                </div>
                </Grid> */}
            </Grid>
            </div>
    );
}
 
export default PhotoShoot;