import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import SwipeableViews from 'react-swipeable-views';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from "@material-ui/core/Button";
import Checkbox from '@material-ui/core/Checkbox';
// import Theme from '../../App.js';

const styles = theme => ({
  root: {
    backgroundColor: '#F9F8FD',
    width: '100%',
    // width: 300,
    marginTop: 12,
    zIndex: 0
  },
  tab: {
    width: '33.3333%'
  },
  checkbox: {
    color: 'rgb(120,195,17)',
    '&$checked': {
      color: 'rgb(120,195,17)',
    },
  },
  checked: {},
});

class FullWidthTabs extends React.Component {
  state = {
    value: 0,
    checked: false,
  };

  handleChange = (event, value) => {
    this.props.main.setState({eqType: value})
    this.setState({ value });
  };

  returnTabStyle = (index) => {
    if (this.state.value != index) {
      return {
        fontWeight: 'bold', height: '100%', width: '33.3333%', textTransform: 'none', borderRadius: 25
      }
    } else {
      return {
        fontWeight: 'bold', height: '100%', width: '33.3333%', textTransform: 'none', borderRadius: 25, color: 'white', background: "linear-gradient(to right , #6334FF, #6334FF)", marginLeft: 2
      }
    }
  }

  render() {
    const { classes, theme } = this.props;
    return (
      <div className={classes.root} style={this.props.settingsPage && {backgroundColor: 'white'}}>
        <Paper className={classes.root} elevation={0} style={this.props.settingsPage && {backgroundColor: 'white'}}>
          <Tabs
            value={this.state.value}
            onChange={this.handleChange}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            <Tab fullWidth label="Cameras" className={{root: classes.tab}} />
            <Tab fullWidth label="Drones" className={{root: classes.tab}} />
            <Tab fullWidth label="3D" className={{root: classes.tab}} />
          </Tabs>
        </Paper>
      </div>
    );
  }
}

FullWidthTabs.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(FullWidthTabs);
