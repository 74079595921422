import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import RequestsTable from './RequestsTable'

const useStyles = makeStyles(theme => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
}));

export default function MaxWidthDialog(props) {
  const classes = useStyles();
  const [pr, setPR] = useState(null);

  const handleClose = () => {
    props.main.setState({openPaymentRequestDetails: false})
  };

  useEffect(() => {
    let prID = props.main.state.prID
    let paymentRequests = props.main.state.paymentRequests
    console.log('prID')
    console.log(prID)
    paymentRequests.forEach(pr => {
      if (pr.Id === prID) {
        setPR(pr)
      }
    });
  }, [])

  const getBankDetails = () => {
    
  }

  return (
    <React.Fragment>
      <Dialog
        fullWidth={true}
        maxWidth={'lg'}
        open={props.main.state.openPaymentRequestDetails}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">Payment Request Details</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {pr !== null && (
              <div>
                <div>Bank Details:</div>
                <div>Bank name: {pr.Bank_Name__c} Account number: {pr.Bank_Account_Number__c} Branch: {pr.Bank_Branch__c}</div>
                {(pr.Photographer_Comments__c !== null && pr.Photographer_Comments__c !== '') && (
                  <div>Comment: {pr.Photographer_Comments__c}</div>
                )}
                <br/>
              </div>
            )}
            Requests paid of:
          </DialogContentText>
          <RequestsTable tableData={props.main.state.detailsTableData} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
