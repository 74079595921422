import React from 'react';
import {Button, FormControlLabel, Switch, Tooltip, CircularProgress} from '@material-ui/core'
import UploadedBox from './UploadedBox'

const zipIcon = require("../../../../Assets/zipIcon.png");

const UploadedStation = ({
    imagesRawExposures,
    images1Exposures,
    images3Exposures,
    images4Exposures,
    images5Exposures,
    images6Exposures,
    images7Exposures,
    images8Exposures,
    images9Exposures,
    downloadZipFileByType,
    uploadStation,
    displayGallery,
    requestName,
    handleChangeDisplayGallery,
    requestId

}) => {
    return ( 
        <div style={{width: '100%'}}>
            {(images1Exposures.compressed.length > 0 || images3Exposures.compressed.length > 0 || images4Exposures.compressed.length > 0 || images5Exposures.compressed.length > 0 || images6Exposures.compressed.length > 0 || images7Exposures.compressed.length > 0 || images8Exposures.compressed.length > 0 || images9Exposures.compressed.length > 0) ? (
                <div style={{display: 'flex', alignItems: 'top'}}>
                    <div style={{fontWeight: 'bold', width: '50%',fontFamily: "'Montserrat', sans-serif", fontSize: 20, textAlign: 'left', marginBottom: 5, color: '#818BA6', flexGrow: 1}}>{uploadStation}</div>
                    <div style={{fontWeight: 'bold', width: '50%', fontFamily: "'Montserrat', sans-serif", fontSize: 20, textAlign: 'right', marginBottom: 5, color: '#818BA6'}}>
                        <Tooltip title={"Download Zip"} placement="top">
                        <FormControlLabel
                            control={
                                <div style={{display: 'inline-block', fontSize: 16, float: 'right', marginRight: 9}} className="buttonIcon">
                                    <img src={zipIcon} style={{objectFit: 'scale-down', height: 28}} alt="..." />
                                </div>
                            }
                            onClick={() => downloadZipFileByType(uploadStation, 'WeSnapp_' + requestName + '_' + uploadStation + '.zip')}
                            label="Download Zip"
                        />
                        </Tooltip>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={displayGallery}
                                    onChange={handleChangeDisplayGallery(!displayGallery)}
                                    value="displayGallery"
                                    color="primary"
                                    style={{marginTop: 40}}
                                />
                            }
                            label="Gallery View"
                        />
                    </div>
                </div>
            ): (
                <div style={{marginTop: 10}}>
                    <center>
                    <CircularProgress style={{marginTop: 20}}/>
                    <div style={{marginTop: 20, fontSize: 20, fontFamily: "'Montserrat', sans-serif", color: 'black'}}>Loading Images</div>
                    </center>
                </div>
            )}
            <div style={(uploadStation == '1 Exposures' && images1Exposures.compressed.length > 0) ? {} : { display: 'none' }}>
                <UploadedBox displayGallery={displayGallery} exposures={1} images={images1Exposures} requestId={requestId} galleryOrLinesUpload={displayGallery}/>
            </div>
            <div style={(uploadStation == '3 Exposures' && images3Exposures.compressed.length > 0) ? {} : { display: 'none' }}>
                <UploadedBox displayGallery={displayGallery} exposures={3} images={images3Exposures} requestId={requestId} galleryOrLinesUpload={displayGallery}/>
            </div>
            <div style={(uploadStation == '4 Exposures' && images4Exposures.compressed.length > 0) ? {} : { display: 'none' }}>
                <UploadedBox displayGallery={displayGallery} exposures={4} images={images4Exposures} requestId={requestId} galleryOrLinesUpload={displayGallery}/>
            </div>
            <div style={(uploadStation == '5 Exposures' && images5Exposures.compressed.length > 0) ? {} : { display: 'none' }}>
                <UploadedBox displayGallery={displayGallery} exposures={5} images={images5Exposures} requestId={requestId} galleryOrLinesUpload={displayGallery}/>
            </div>
            <div style={(uploadStation == '6 Exposures' && images6Exposures.compressed.length > 0) ? {} : { display: 'none' }}>
                <UploadedBox displayGallery={displayGallery} exposures={6} images={images6Exposures} requestId={requestId} galleryOrLinesUpload={displayGallery}/>
            </div>
            <div style={(uploadStation == '7 Exposures' && images7Exposures.compressed.length > 0) ? {} : { display: 'none' }}>
                <UploadedBox displayGallery={displayGallery} exposures={7} images={images7Exposures} requestId={requestId} galleryOrLinesUpload={displayGallery}/>
            </div>
            <div style={(uploadStation == '8 Exposures' && images8Exposures.compressed.length > 0) ? {} : { display: 'none' }}>
                <UploadedBox displayGallery={displayGallery} exposures={8} images={images8Exposures} requestId={requestId} galleryOrLinesUpload={displayGallery}/>
            </div>
            <div style={(uploadStation == '9 Exposures' && images9Exposures.compressed.length > 0) ? {} : { display: 'none' }}>
                <UploadedBox displayGallery={displayGallery} exposures={9} images={images9Exposures} requestId={requestId} galleryOrLinesUpload={displayGallery}/>
            </div>
            <div style={(uploadStation == 'Raws' && imagesRawExposures.compressed.length > 0) ? {} : { display: 'none' }}>
                <UploadedBox displayGallery={displayGallery} exposures={'Raw'} images={imagesRawExposures} requestId={requestId} galleryOrLinesUpload={displayGallery}/>
            </div>
        </div>
    );
}
 
export default UploadedStation;