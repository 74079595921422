import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import CompanyIcon from '@material-ui/icons/Business';
import AccountIcon from '@material-ui/icons/Person';
import NewOrder from '@material-ui/icons/FiberNew';
import ExitToApp from '@material-ui/icons/ExitToApp';
import Settings from '@material-ui/icons/SettingsApplications';
import Notifications from '@material-ui/icons/Notifications';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withRouter } from "react-router-dom";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import history from '../../../utils/history';

const cameraIcon = require("../../../Assets/cameraTableIcon.png");

const whiteIcon = require("../../../Assets/WhiteIcon.png");

var mobile = (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1)

const styles = theme => ({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  root: {
    height: '100%',
    width: '200px',
    backgroundColor: 'white'
  }
});

class Template extends React.Component {

  state = {
    page: ''
  }

  componentWillMount() {
    if (history.location.pathname.includes('/c/dashboard/settings')) {
      this.setState({page: 'settings'})
    } else if (history.location.pathname.includes('/c/dashboard/photo-shoots')) {
      this.setState({page: 'photo-shoots'})
    } else if (history.location.pathname.includes('/c/dashboard/order')) {
      this.setState({page: 'order'})
    } else if (history.location.pathname.includes('/c/dashboard/account')) {
      this.setState({page: 'account'})
    } else if (history.location.pathname.includes('/c/dashboard/company')) {
      this.setState({page: 'company'})
    }

  }

  logout = () => {
    localStorage.removeItem('wesnapp-token');
    this.props.history.push("/login")
    window.location.reload();
  }

  render() {
    const { classes, theme } = this.props;
    return (
      <div style={{width: '120px', minHeight: '100vh', backgroundColor: 'clear', display: 'inline-block', verticalAlign:'top'}}>
          {/* <img style={{width: 50, height: 'auto', position: 'absolute', top: 45, left: 32.5}} src={whiteIcon} alt="..." /> */}
          <div className="centerbuttons">
            <Link to="/c/dashboard/photo-shoots" style={{color:'white', textDecoration: 'none'}} onClick={()=> {this.setState({page: 'photo-shoots'}); this.props.setShootPage(false)}}>
              <div style={{cursor: 'pointer', width: '120px', paddingTop: 25, paddingBottom: 25, backgroundColor: this.state.page === 'photo-shoots' ? ("rgba(255,255,255,0.05") : ("rgba(255,255,255,0")}}>
                <div>
                  <PhotoCamera size="25" color="white" style={{color: 'white'}}/>
                </div>
                <div style={{marginTop: 4, color: 'white'}}>
                  Photo Shoots
                </div>
              </div>
            </Link>
            <Link to="/c/dashboard/account" style={{color:'white', textDecoration: 'none'}} onClick={()=> {this.setState({page: 'settings'}); this.props.setShootPage(false)}}>
              <div style={{cursor: 'pointer', width: '120px',  marginTop: '20%', paddingTop: 25, paddingBottom: 25, backgroundColor: this.state.page === 'settings' ? ("rgba(255,255,255,0.05") : ("rgba(255,255,255,0")}}>
                <div>
                  <AccountIcon size="25" color="white" style={{color: 'white'}}/>
                </div>
                <div style={{marginTop: 4, color: 'white'}}>
                  My Account
                </div>
              </div>
            </Link>
            {this.props.isBusinessAccount && (
              <Link to="/c/dashboard/company" style={{color:'white', textDecoration: 'none'}} onClick={()=> {this.setState({page: 'company'}); this.props.setShootPage(false)}}>
                <div style={{cursor: 'pointer', width: '120px',  marginTop: '20%', paddingTop: 25, paddingBottom: 25, backgroundColor: this.state.page === 'company' ? ("rgba(255,255,255,0.05") : ("rgba(255,255,255,0")}}>
                  <div>
                    <CompanyIcon size="25" color="white" style={{color: 'white'}}/>
                  </div>
                  <div style={{marginTop: 4, color: 'white'}}>
                    My Company
                  </div>
                </div>
              </Link>
            )}
            <Link to="/c/dashboard/billing" style={{color:'white', textDecoration: 'none'}} onClick={()=> {this.setState({page: 'blling'}); this.props.setShootPage(false)}}>
              <div style={{cursor: 'pointer', width: '120px',  marginTop: '20%', paddingTop: 25, paddingBottom: 25, backgroundColor: this.state.page === 'billing' ? ("rgba(255,255,255,0.05") : ("rgba(255,255,255,0")}}>
                <div>
                  <NewOrder size="25" color="white" style={{color: 'white'}}/>
                </div>
                <div style={{marginTop: 4, color: 'white'}}>
                  Billing
                </div>
              </div>
            </Link>
            <div style={{cursor: 'pointer', marginTop: '20%', width: '120px', paddingTop: 25, paddingBottom: 25, backgroundColor: this.props.page == 'ss' ? ("rgba(255,255,255,0.05") : ("rgba(255,255,255,0")}} onClick={() => this.logout()}>
              <div>
                <ExitToApp size="25" color="white" style={{color: 'white'}}/>
              </div>
              <div style={{marginTop: 4, color: 'white'}}>
                Logout
              </div>
            </div>
          </div>
      </div>
    );
  }
}

Template.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default (withStyles(styles, { withTheme: true }))(Template);
