import React, {useContext} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import { withRouter } from 'react-router-dom';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function AlertDialogSlide({open, setOpen, history}) {

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        fullWidth={true}
        maxWidth={'xs'}
      >
        <div style={{display: 'flex', flexDirection: 'column', padding: 48, justifyContent: 'center', alignItems: 'center'}}>
            <Button onClick={()=> history.push('/signup/photographer')} style={{background: 'linear-gradient(256.79deg, #6852ED 40.49%, #BA43FC 100%)', boxShadow: 'rgba(114, 82, 240, 0.6) 0px 8px 20px 0px', height: 50, borderRadius: 25, color: 'white', textTransform: 'none', width: '100%'}}>I'm a Photographer</Button>
            <Button onClick={()=> history.push('/signup/client')} style={{background: 'linear-gradient(256.79deg, #6852ED 40.49%, #BA43FC 100%)', boxShadow: 'rgba(114, 82, 240, 0.6) 0px 8px 20px 0px', height: 50, borderRadius: 25, color: 'white', textTransform: 'none', width: '100%', marginTop: 32}}>I Need Photographer</Button>
        </div>
      </Dialog>
    </div>
  );
}

export default withRouter(AlertDialogSlide)