import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import '../../../../App.css';
import Slide from '@material-ui/core/Slide';
import CloudDownload from '@material-ui/icons/CloudDownload';
import Delete from '@material-ui/icons/Delete';
import Lightbox from 'react-image-lightbox';
import LazyLoad from 'react-lazyload'
import Tooltip from '@material-ui/core/Tooltip';
import 'react-image-lightbox/style.css';

const GET_BRACKETS_IMAGES = 'https://py.wesnapp.co.il/server/get-images-orgenized/'

const vIcon = require("../../../../Assets/vIcon.png");
const menuIcon = require("../../../../Assets/menuIcon.png");
const xIcon = require("../../../../Assets/xIcon.png");
const zoomIn = require("../../../../Assets/zoomIn.png");

const trashIcon = require("../../../../Assets/trashTableIcon.png");
const zoomInIcon = require("../../../../Assets/zoomInTableIcon.png");
const downloadTIcon = require("../../../../Assets/downloadTableIcon.png");
const cameraIcon = require("../../../../Assets/cameraTableIcon.png");

const dateIcon = require("../../../../Assets/dateIcon.png");
const locationIcon = require("../../../../Assets/locationIcon.png");
const userIcon = require("../../../../Assets/userIcon.png");
const imageIcon = require("../../../../Assets/imageIcon.png");

const alertIcon = require("../../../../Assets/alertIcon.png");
const successIcon = require("../../../../Assets/successIcon.png");
const zipIcon = require("../../../../Assets/zipIcon.png");

const zoomInIconBig = require("../../../../Assets/zoomin_icon.png");

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing.unit * 2,
    color: theme.palette.text.secondary,
  },
  margin: {
    margin: 10
  }
});

class UploadedBox extends React.Component {

  state = {
    exposure: this.props.exposures,
    openGallery: false,
    index: 0
  };

  getName = (url) => {
    var arr = url.split('/')
    var last = arr[arr.length - 1]
    return last.replace('_reduced_thumbnail.', '')
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.checked });
  };

  render() {
    const { classes, theme } = this.props;
    return (
      <div className={classes.root}>
        {this.state.openGallery && (
          <Lightbox
            mainSrc={this.props.images.compressed[this.state.index]}
            nextSrc={this.props.images.compressed[(this.state.index + 1) % this.props.images.compressed.length]}
            prevSrc={this.props.images.compressed[(this.state.index + this.props.images.compressed.length - 1) % this.props.images.compressed.length]}
            onCloseRequest={() => this.setState({ openGallery: false })}
            onMovePrevRequest={() =>
              this.setState({
                index: (this.state.index + this.props.images.compressed.length - 1) % this.props.images.compressed.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                index: (this.state.index + 1) % this.props.images.compressed.length,
              })
            }
          />
        )}
        <Grid container spacing={24} dir="ltr">
          <Grid item xs={12} sm={12} md={12}>
            <div>
              <div style={{marginTop: 0, width: '100%'}}>
                {this.props.images.loading && (
                  <center>
                    <CircularProgress style={{marginTop: 20}}/>
                    <div style={{marginTop: 20, fontSize: 16, fontFamily: "'Montserrat', sans-serif", color: '#818BA6'}}>Loading Images</div>
                  </center>
                )}
                {(this.props.images.loading == false && this.props.images.thumbnail.length == 0) && (
                  <center>
                    <div style={{marginTop: 20, fontSize: 16, fontFamily: "'Montserrat', sans-serif", color: '#818BA6'}}>0 Images</div>
                  </center>
                )}
                {(this.props.images.loading == false && this.props.images.thumbnail.length > 0) && (
                  <div style={{fontWeight: 'bold', fontFamily: "'Montserrat', sans-serif", fontSize: 16, marginBottom: 20, textAlign: 'left', color: '#818BA6'}}>{this.props.images.thumbnail.length} Images</div>
                )}
                {this.props.displayGallery == false ? (
                  <div>
                      <div>
                      {this.props.images.thumbnail.map((item, i) => (
                        <div>
                          <div style={{width: '100%', height: 50, marginBottom: 10, padding: 5,  alignItems: 'center', boxShadow: '0px 0px 6px 0px rgba(0,0,0,0.09)'}} className="imageCell">
                            <div style={{display: 'inline-block', fontSize: 16, float: 'left', marginRight: 9, marginLeft: 9}}>
                              <img style={{width: 18, height: 18, borderRadius: 9, backgroundColor: 'rgb(120,203,117)', marginTop: 10}} src={vIcon} alt="..." />
                            </div>
                            <div style={{display: 'inline-block', fontSize: 16, float: 'left', marginRight: 5, marginTop: 9}}>{this.getName(item)}</div>
                            {item != "" && (
                              <div style={{display: 'inline-block', fontSize: 16, float: 'right', marginRight: 5}}>
                                <LazyLoad offset={100}>
                                  <img src={item} style={{objectFit: 'scale-down', height: 40}} alt="..." />
                                </LazyLoad>
                              </div>
                            )}
                            {/* <Tooltip title={"Delete"} placement="top">
                              <div onClick={() => this.props.main.deleteImage('3Exposures', i)} style={{display: 'inline-block', fontSize: 16, float: 'right', right: 0, marginRight: 9, marginTop: 8}} className="buttonIcon">
                                <img src={trashIcon} style={{objectFit: 'scale-down', height: 22, fill: 'red'}} alt="..." />
                              </div>
                            </Tooltip>
                            <Tooltip title={"Download"} placement="top">
                              <div onClick={() => this.props.main.downloadOneImage(this.props.images.originals[i], this.props.images.originals[i].split('/').slice(-1)[0])} style={{display: 'inline-block', fontSize: 16, float: 'right', marginRight: 9, marginTop: 8}} className="buttonIcon">
                                <img src={downloadTIcon} style={{objectFit: 'scale-down', height: 22}} alt="..." />
                              </div>
                            </Tooltip> */}
                            <Tooltip title={"Zoom In"} placement="top">
                              <div onClick={() => {this.setState({openGallery: true, index: i})}} style={{display: 'inline-block', fontSize: 16, float: 'right', marginRight: 9, marginTop: 8, filter: 'opacity(1.0) drop-shadow(150 150 150 red)', cursor: 'pointer'}} className="buttonIcon">
                                <img src={zoomInIcon} style={{objectFit: 'scale-down', height: 22}} alt="..." />
                              </div>
                            </Tooltip>
                          </div>
                          {((i+1) != 1) && ((i+1) % this.state.exposure == 0) && (<div style={{width: '100%', height: 20}}></div>)}
                          </div>
                        ))}
                      </div>
                  </div>
                ) : (
                  <div>
                    {this.props.images.imagesForGallery.length > 0 && (
                      <div>
                      <div style={{display: 'inline-block', width: 'calc(100% - 0px)', verticalAlign:'top'}}>
                        {this.props.images.imagesForGallery.map((item, i) => (
                          <div style={{display: 'inline-block', width: '32%',marginBottom: 10, marginLeft: 10, cursor: 'pointer'}} onClick={() => this.setState({openGallery: true, index: i})}>
                            <div className="imgcontainer">
                              <img src={item.src} alt="Avatar" className="image" style={{width: '100%'}} />
                              <div className="boxinimage">
                                <div style={{marginTop: 47}}>
                                  <div style={{display: 'inline-block', fontSize: '19px', marginTop: -8, textAlign: 'left', float: 'left', marginLeft: 8}}>Photo #{i+1}</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    )}
  }

  UploadedBox.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(UploadedBox);
