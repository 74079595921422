import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
// import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
// import List from '@material-ui/core/List';
// import Divider from '@material-ui/core/Divider';
// import ListItem from '@material-ui/core/ListItem';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PhoneIcon from '@material-ui/icons/Phone';

import BillingIcon from '@material-ui/icons/CreditCard';
import MembersIcon from '@material-ui/icons/AccountTree';
import Grid from '@material-ui/core/Grid';
import { SnackbarProvider, useSnackbar } from 'notistack';
import Snackbar from '@material-ui/core/Snackbar';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios';
import { withRouter } from "react-router-dom";
import {
  BrowserRouter as Router,
  Route,
  Link
} from "react-router-dom";
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import Location from './Location';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { TextField } from '@material-ui/core';

const saleBanner = require("../../../../Assets/sale-banner.png");
var mobile = (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1)

const cameraIcon = require("../../../../Assets/cameraTableIcon.png");

const noImage = require("../../../../Assets/no_image.png");

const dateIcon = require("../../../../Assets/dateIcon.png");
const locationIcon = require("../../../../Assets/locationIcon.png");
const userIcon = require("../../../../Assets/userIcon.png");
const imageIcon = require("../../../../Assets/imageIcon.png");


const styles = theme => ({
  list: {
    width: 250
  },
  fullList: {
    width: 'auto',
  },
  root: {
    width: '100vw',
    height: '100vh',
    backgroundColor: '#6C65FF',
    backgroundColor: 'white'
  },
  // button: {
  //   height: 50,
  //   marginTop: 15,
  //   borderRadius: 25,
  //   paddingLeft: 32,
  //   paddingRight: 32,
  //   boxShadow: 'none',
  //   hover: {
  //     backgroundColor: '#33D7A0'
  //   }
  // },
  button: {
    color: 'white',
    height: 50,
    backgroundColor: theme.palette.primary.main,
    textTransform: 'none',
    fontWeight: 'bold',
    paddingLeft: 32,
    paddingRight: 32,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
    boxShadow: `0 4px 10px ${theme.palette.primary.main}40`,
    borderRadius: 25
  },
});

class PhotoShoots extends React.Component {

  state = {
    isLoading: false,
    bankName: '',
    bankCity: '',
    bankBranch: '',
    bankAccountNumber: '',
    companyEmail: '',
    companyName: '',
    companyNumber: '',
    chargingVAT: false
  }

  componentWillMount() {
      
  }

  handleChangeVAT = (event) => {
    this.setState({chargingVAT: event.target.checked})
  };


  componentDidMount = () => {
    var contactId = localStorage.getItem("contactId")
    var userData = this.props.userData
    console.log(userData)
    this.setState({
        bankName: userData.Bank_Name__c,
        bankCity: userData.Bank_City__c,
        bankBranch: userData.Bank_Branch__c,
        bankAccountNumber: userData.Bank_Account_Number__c,
        chargingVAT: userData.VAT_Inclusive__c
        // companyEmail: userData.Company_Email_for_Invoices__c,
        // companyName: userData.Company_Name_for_Invoices__c,
        // companyNumber: userData.Company_Number_VAT__c
    })
  }

  handleChangeText = name => event => {
    // console.log("@@");
    // console.log( event.target.value);
    this.setState({ [name]: event.target.value });
  };

  handleChangeLocation = (value) => {
    // console.log(value.description);
    this.setState({ "address": value.description });
  };

  save = async () => {
    var allowToContinue = true
    this.setState({
      error_bankName: "",
      error_bankCity: "",
      error_bankBranch: "",
      error_bankAccountNumber: "",
      // error_companyEmail: "",
      // error_companyNumber: "",
      // error_companyName: ""
    })

    if (this.state.bankName == "") {
      this.setState({error_bankName: "Bank name is empty"})
      allowToContinue = false
    }
    if (this.state.bankCity == "") {
      this.setState({error_bankCity: "Bank city is empty"})
      allowToContinue = false
    }
    if (this.state.bankBranch == "") {
      this.setState({error_bankBranch: "Bank branch is empty"})
      allowToContinue = false
    }
    if (this.state.bankAccountNumber == "") {
      this.setState({error_bankAccountNumber: "Bank account number is empty"})
      allowToContinue = false
    }
    // if (this.state.companyEmail == "") {
    //   this.setState({error_companyEmail: "Company email is empty"})
    //   allowToContinue = false
    // }
    // if (this.state.companyNumber == "") {
    //   this.setState({error_companyNumber: "Company number is empty"})
    //   allowToContinue = false
    // }
    // if (this.state.companyName == "") {
    //   this.setState({error_companyName: "Company name is empty"})
    //   allowToContinue = false
    // }

    if (allowToContinue) {
      this.saveNewDataInDB()
    }
  }

  saveNewDataInDB = () => {
    this.setState({updating: true})
    var data = {
      id: localStorage.getItem("accountId"),
      data: {
        Bank_Name__c: this.state.bankName,
        Bank_City__c: this.state.bankCity,
        Bank_Branch__c: this.state.bankBranch,
        Bank_Account_Number__c: this.state.bankAccountNumber,
        VAT_Inclusive__c: this.state.chargingVAT,
        // Company_Email_for_Invoices__c: this.state.companyEmail,
        // Company_Name_for_Invoices__c: this.state.companyName,
        // Company_Number_VAT__c: this.state.companyNumber
      }
     };
     axios.post('https://wesnapp.co.il/api/photographer/update-profile',data, {headers: {'Content-Type': 'application/json'}}).then((response) => {
      this.setState({updating: false})

      if (this.props.popup) {
        this.props.openSuccess()
        this.props.nextStep()
      } else {
        this.props.main.setState({openSuccess: true})
      }
      var text = "⚠️ The photographer <" + "https://wesnapp.lightning.force.com/lightning/r/Account/" + localStorage.getItem("accountId") + "/view" + "|" + this.props.userData.Name + "> changed his details in billing settings"
      axios.post('https://wesnapp.co.il/api/slack/photographer-notification',{text: text}, {headers: {'Content-Type': 'application/json'}}).then((response) => {
      })
      .catch( (error) => {
      });
      // Set last update date and time
      axios.post('https://us-central1-wesnapp-d136a.cloudfunctions.net/updateContact',{id: localStorage.getItem("accountId"), data: {Last_Confirmed_Settings__c: new Date().toISOString()}}, {headers: {'Content-Type': 'application/json'}}).then((response) => {
      })
      .catch( (error) => {
      });

      this.props.getUserDetails()
      
     })
     .catch( (error) => {
     });
  }

  render() {
    const { classes, theme } = this.props;

    return (
      <div style={{backgroundColor: 'white', borderRadius: '23px', boxShadow: '0px 4px 10px #6334FF10', marginTop: 0, width: '100%', padding: 24, marginBottom: 24}}>
        <Grid container spacing={2} style={{padding: 0, paddingTop: 0}} justifyContent="center" justify="center">
                <Grid item xs={12} sm={10} md={8} xl={7}>
                    <div style={{fontSize: 20, color: 'rgb(40,40,53)', textAlign: 'left', marginLeft: 0, fontFamily: "'Montserrat', sans-serif"}}>
                        <b>Billing Details</b>
                    </div>
                </Grid>
                <Grid item xs={12} sm={10} md={8} xl={7}>
                    <Grid container spacing={2} dir="ltr">
                        <Grid item xs={6} sm={6} md={6}>
                            <TextField fullWidth label='Bank Account Number' id={"bankAccountNumber"} type={"text"} onChange={this.handleChangeText("bankAccountNumber")} placeholder="Bank account number*" value={this.state.bankAccountNumber} style={{float: 'right'}} />
                            <div style={{marginLeft: 12, float: 'left', color: 'red', marginTop: 3, textAlign: 'left'}}>{this.state.error_bankAccountNumber}</div>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6}>
                            <TextField fullWidth label='Bank Name' id={"bankName"} type={"text"} onChange={this.handleChangeText("bankName")} placeholder="Bank name*" value={this.state.bankName} style={{float: 'right'}} />
                            <div style={{marginLeft: 12, float: 'left', color: 'red', marginTop: 3, textAlign: 'left'}}>{this.state.error_bankName}</div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <TextField fullWidth label='Bank Branch' id={"bankBranch"} type={"text"} onChange={this.handleChangeText("bankBranch")} placeholder="Bank branch*" value={this.state.bankBranch} style={{float: 'right'}} />
                            <div style={{marginLeft: 12, float: 'left', color: 'red', marginTop: 3, textAlign: 'left'}}>{this.state.error_bankBranch}</div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <TextField fullWidth label='Bank City' id={"bankCity"} type={"text"} onChange={this.handleChangeText("bankCity")} placeholder="Bank city*" value={this.state.bankCity} style={{float: 'right'}} />
                            <div style={{marginLeft: 12, float: 'left', color: 'red', marginTop: 3, textAlign: 'left'}}>{this.state.error_bankCity}</div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} style={{textAlign: 'left'}}>
                            <div style={{width: '100%', textAlign: 'left', marginBottom: 8}}></div>
                            <FormControlLabel style={{textAlign: 'left'}}
                              control={<Switch checked={this.state.chargingVAT} onChange={this.handleChangeVAT} name="chargingVAT" />}
                              label="Charging VAT"
                            />
                        </Grid>
                        {/* <Grid item xs={12} sm={6} md={6}>
                            <div style={{width: '100%', textAlign: 'left', marginBottom: 8}}>Company Name For Invoice</div>
                            <input id={"companyName"} type={"text"} onChange={this.handleChangeText("companyName")} placeholder="Company Name*" value={this.state.companyName} style={{float: 'right'}} />
                            <div style={{marginLeft: 12, float: 'left', color: 'red', marginTop: 3, textAlign: 'left'}}>{this.state.error_companyName}</div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <div style={{width: '100%', textAlign: 'left', marginBottom: 8}}>Company Number</div>
                            <input id={"companyNumber"} type={"text"} onChange={this.handleChangeText("companyNumber")} placeholder="Company Number*" value={this.state.companyNumber} style={{float: 'right'}} />
                            <div style={{marginLeft: 12, float: 'left', color: 'red', marginTop: 3, textAlign: 'left'}}>{this.state.error_companyNumber}</div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <div style={{width: '100%', textAlign: 'left', marginBottom: 8}}>Company Email For Invoice</div>
                            <input id={"companyEmail"} type={"text"} onChange={this.handleChangeText("companyEmail")} placeholder="Company Email*" value={this.state.companyEmail} style={{float: 'right'}} />
                            <div style={{marginLeft: 12, float: 'left', color: 'red', marginTop: 3, textAlign: 'left'}}>{this.state.error_companyEmail}</div>
                        </Grid> */}

                      
                        <Grid item xs={12} sm={12} md={12}>
                        {this.state.updating ? (
                          <div style={{width: '100%', marginTop: 5}}>
                            <center>
                              <CircularProgress size={40} />
                            </center>
                          </div>
                        ) : (
                          <div style={{width: '100%', textAlign: 'center'}}>
                            <center>
                              <Button id={"register_ph"} onClick={() => this.save()} elevation={0}  className={classes.button}>
                                <b>{this.props.popup ? 'Continue' : 'Save'}</b>
                              </Button>
                            </center>
                          </div>
                        )}
                      </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
  }
}

PhotoShoots.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(withRouter(PhotoShoots));
