import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function FormDialog(props) {
  const [email, setEmail] = React.useState(props.contactData.Email);

  const handleChangeText = event => {
    setEmail(event.target.value)
  };

  const handleClose = () => {
    props.setOpenEmailDialog(false)
  };

  const handleSendTo = () => {
    if (props.emailType === 'regular') {
    props.sendImagesViaEmail(props.selectedInThePast, 'WeSnapp_' + props.requestData.Name + '_edited.zip', email)
    } else {
      props.sendImagesWithLogoViaEmail(props.selectedInThePast, 'WeSnapp_' + props.requestData.Name + '_edited.zip', email)
    }
    props.setOpenEmailDialog(false)
  };

  return (
    <div>
      <Dialog open={props.open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Get the images on mail!</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please insert the email address that you would like to send the images to
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            value={email}
            onChange={handleChangeText}
            label="Email Address"
            type="email"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSendTo} color="primary">
            Send
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
