import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const mobile = (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: 0,
    background: 'transparent',
    marginTop: mobile ? 24 : 32
  },
  stepperRoot: {
    padding: 0,
    background: 'transparent'
  },
  button: {
    marginRight: theme.spacing(1),
  },
  completed: {
    display: 'inline-block',
    color: '#61CE70'
  },
}));

function getSteps() {
  return ['', '', '', ''];
}

export default function HorizontalNonLinearStepper({step}) {
  const classes = useStyles();
  const steps = getSteps();

  return (
    <div className={classes.root}>
      {/* <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: mobile ? 24 : 32, alignItems: 'center', flexGrow: 1}}>
        <img src={require('../../../Assets/bmbyLogo.png')} style={{height: 32, marginRight: 8}} alt={''} />
        <img src={require('../../../Assets/logo.png')} style={{height: 40, marginLeft: 14}} alt={''} />
      </div> */}
      <div style={{width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 24, paddingLeft: 12, paddingRight: 12}}>
        <img src={require('../../../Assets/bmbyLogo.png')} style={{height: 24}} alt={''} />
        <img src={require('../../../Assets/logo.png')} style={{height: 30}} alt={''} />
      </div>
      <Stepper nonLinear activeStep={step - 1} classes={{root: classes.stepperRoot}}>
        {steps.map((label, index) => (
          <Step key={label} disabled>
            <StepButton completed={step - 1 > index}>
              {label}
            </StepButton>
          </Step>
        ))}
      </Stepper>
    </div>
  );
}
