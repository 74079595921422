import axios from 'axios';
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ClientForm from './ClientForm'

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: 'white',
  },
  grow: {
    flexGrow: 1,
    fontSize: '20px',
    textAlign: 'left'
  },
  margin: {
    margin: 4
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 2,
  },
  loginComponent: {
    // marginTop: '50%',
    // transform: 'translate(0%, -50%)',
    marginTop: 30,
    width: '100%'
  },
  button: {
    height: 50,
    marginTop: 60,
    float: 'left',
    borderRadius: 25,
    paddingLeft: 32,
    paddingRight: 32,
    boxShadow: 'none',
    hover: {
      backgroundColor: '#33D7A0'
    }
  }
});

const mobile = (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1)

class MainLogin extends React.Component {

  state = {
    phone: ''
  }

  componentDidMount() {
  }

  handleChangeText = name => event => {
    if (name === 'phone') {
      this.setState({ [name]: event.target.value.replace('-', '') });
    } else {
      this.setState({ [name]: event.target.value });
    }
  };

  render() {
    const { classes, theme } = this.props;
      return (
        <div className={classes.root}>
            <Grid container spacing={0}>
            <Grid item xs={12} sm={12} md={6} xl={6}>
                  <div style={{width: '100%', overflowY: 'scroll', height: '100vh'}}>
                    <Grid container spacing={0}  justify="center" style={{padding: 20}}>
                      <Grid item xs={12} sm={12} md={12} xl={12}>
                        <img src={require('../../Assets/logo.png')} width='auto' height='30px' alt='' style={{float: 'left'}}/>
                      </Grid>
                      <Grid item xs={12} sm={6} md={8} xl={8} className={classes.loginComponent}>
                        <div style={{fontSize: mobile ? '2.5em': '3em', fontWeight: 'bold', textAlign: 'left'}}>Register</div>
                        <div style={{marginTop: 30}}>
                         <ClientForm main={this} history={this.props.history} />
                        </div>
                        {/* <div>
                          <Link to="/login"><div
                            color="primary"
                            style={{marginTop: 30, marginBottom: 70, textDecoration: 'none', cursor: 'pointer', fontSize: 16, width: '100%', textAlign: 'left'}}
                          >
                            <u>Already have an account? Sign in now!</u>
                          </div></Link>
                        </div> */}
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
                <Grid item xs={0} sm={0} md={6} xl={6}>
                  <div style={{width: '100%', minHeight: '100vh', backgroundColor: '#ECF1FF'}}>
                    
                  </div>
                </Grid>
            </Grid>
        </div>
    )
  }
}

MainLogin.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(MainLogin);
