import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import MenuIcon from '@material-ui/icons/Menu';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

const mobile = (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1)

export default function ButtonAppBar() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          {/* <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
            <MenuIcon />
          </IconButton> */}
          <Typography variant="h6" className={classes.title}>
            <img alt='' src={require('../../Assets/white_logo.png')} style={{height: mobile ? 30 : 40, float: 'left'}}/>
          </Typography>
          {/* <Button onClick={window.location.replace('https://my')} color="inherit" style={{height: mobile ? 38 : 38, borderRadius: 8, backgroundColor: 'white', paddingLeft: 10, paddingRight: 10, fontWeight: 'bold', textTransform: 'none', color: '#6C65FF'}}>App</Button> */}
        </Toolbar>
      </AppBar>
    </div>
  );
}
