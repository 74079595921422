import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/CircularProgress';

const mobile = (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);

class LoadingComponent extends React.Component {

  render() {
    const { classes } = this.props;
    return (
      <div className="loading" style={{
        height: '100vh', 
        width: '100vw',
        zIndex: 9999,
        top: 0, left: 0,
        backgroundColor: 'rgba(255,255,255,0.8)',
        position: 'fixed', display: 'flex',
        justifyContent: 'center', alignItems: 'center'
      }}>
          <CircularProgress style={{justifyContent: 'center', alignItems: 'center'}}/>
      </div>
    );
  }
}

export default LoadingComponent;

