import axios from 'axios';
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import PhotographerForm from './Photographer/PhotographerForm'
import { withRouter } from "react-router-dom";
import {
  BrowserRouter as Router,
  Link
} from "react-router-dom";
import { Container, Hidden } from '@material-ui/core';

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: '#F9F8FD',
  },
  grow: {
    flexGrow: 1,
    fontSize: '20px',
    textAlign: 'left'
  },
  margin: {
    margin: 4
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 2,
  },
  loginComponent: {
    // marginTop: '50%',
    // transform: 'translate(0%, -50%)',
    marginTop: 30,
    width: '100%'
  },
  button: {
    height: 50,
    marginTop: 60,
    float: 'left',
    borderRadius: 25,
    paddingLeft: 32,
    paddingRight: 32,
    boxShadow: 'none',
    hover: {
      backgroundColor: '#33D7A0'
    }
  }
});

const mobile = (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1)

class MainLogin extends React.Component {

  state = {
    phone: ''
  }

  componentDidMount() {
  }

  handleChangeText = name => event => {
    if (name === 'phone') {
      this.setState({ [name]: event.target.value.replace('-', '') });
    } else {
      this.setState({ [name]: event.target.value });
    }
  };

  render() {
    const { classes, theme } = this.props;
      return (
        <div className={classes.root}>
          <Grid container spacing={0}>
            <Hidden smDown>
              <Grid item xs={0} sm={0} md={4} xl={4} style={{ background: '#F9F8FD'}}>
                <div style={{width: '100%', backgroundColor: '#ECF1FF'}}>
                  <img alt='' src={require('../../Assets/photographerImage.jpg')} style={{width: '100%', height: '100%', objectFit: 'cover', height: '100vh'}} />
                </div>
              </Grid>
            </Hidden>
            <Grid item xs={12} sm={12} md={8} xl={8} className={classes.loginComponent}>
              <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column', padding: mobile ? 16 : 32}}>
                <Container maxWidth={'sm'} disableGutters={true}>
                  <img alt='' src={require('../../Assets/logo.png')} style={{height: mobile ? '32px': '42px', marginBottom: 12}} />
                  <PhotographerForm main={this} history={this.props.history} />
                </Container>
              </div>
            </Grid>
          </Grid>
        </div>
    )
  }
}

MainLogin.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(withRouter(MainLogin));

