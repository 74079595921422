import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function FormDialog(props) {
  const [open, setOpen] = React.useState(true);

  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  const handleChange = name => event => {
    props.main.setState({[name]: event.target.value})
  };

  return (
    <div>
      <Dialog fullWidth={'sm'} maxWidth={'sm'} open={props.main.state.commentDialogOpen} onClose={() => props.main.setState({commentDialogOpen: false})} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Comment</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please add your comment
          </DialogContentText>
          <TextField
            onChange={handleChange('commentText')}
            id="outlined-multiline-static"
            label="Comment"
            multiline
            value={props.main.state.commentText}
            autoFocus
            fullWidth
            rows="4"
            defaultValue=""
            margin="normal"
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => props.main.setState({commentDialogOpen: false})} color="primary">
            Cancel
          </Button>
          {props.main.state.addingCommentInProgress ? (
            <CircularProgress size={25} style={{marginRight: 15}}/>
          ) : (
            <Button onClick={() => props.main.addComment()} disabled={props.main.state.commentText === "" || props.main.state.commentText === null} color="primary">
              Send
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
