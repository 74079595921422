import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide(props) {
  // const [open, setOpen] = React.useState(false);
  //
  // const handleClickOpen = () => {
  //   setOpen(true);
  // };
  //
  // const handleClose = () => {
  //   setOpen(false);
  // };

  return (
    <div>
      <Dialog
        open={props.open}
        TransitionComponent={Transition}
        keepMounted
        onClose={()=> props.setOpemEmailDialog(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <center>
        <div><img src={require('../../../../../Assets/successIcon.png')} height="60px" width="60px" style={{marginTop: 20}}/></div>
        </center>
        <DialogTitle id="alert-dialog-slide-title">{"Your images are on the way!"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            We will email you the images in few seconds.<br/><br/>
            <div style={{color: 'orange'}}>
            The images we sent you are not in the full resolution.<br/>
            For full resolution, you need to download the images to your computer from the system, with the download button.
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={()=> props.setOpenEmailDialog(false)} color="primary">
            close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
