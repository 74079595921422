import {Map, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react';

import React from 'react';

const vector = require('../../../Assets/marker.png')

const styles = require('../../GoogleMapStyle.json')

const google = window.google

// initialCenter={{
//     lat: this.props.lat,
//     lng: this.props.lng
//   }}

export class MapContainer extends React.Component {
  render() {
    return (
      <div style={{width: '100%', height: '100%'}}>
        <Map google={this.props.google} zoom={16}  style={{width: '100%', height: '100%', borderRadius: 20}} styles={styles}
        center={{
            lat: this.props.lat,
            lng: this.props.lng
          }}>
          {this.props.lat != 32 && (
            <Marker
              name={'Dolores park'}
              position={{lat: this.props.lat, lng: this.props.lng}}
              icon={{
                url: vector,
                anchor:  new google.maps.Point(32,32),
                scaledSize: new google.maps.Size(64,64)
            }}/>
          )}
        </Map>
      </div>
    );
  }
}

export default MapContainer
