import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const classes = {
  root: {
    '& .MuiDialog-paper': {
      position: 'sticky',
      top: 20, borderRadius: 50
    },
    '	.MuiDialog-root': {
      position: 'sticky',
      top: 20, borderRadius: 50

    }
  }
}
export default function FormDialog(props) {

  const handleClickOpen = () => {
    props.main.setState({smsDialog: true})
  };

  const handleClose = () => {
    props.main.setState({smsDialog: false})
  };

  const handleChangeText = name => event => {
    props.main.setState({ [name]: event.target.value });
  };

  return (
    <div>
      <Dialog className={classes.root} fullWidth open={props.main.state.smsDialog} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Verification</DialogTitle>
        <DialogContent>
          <DialogContentText>
          We sent you a varification code via SMS. Please enter the code you got from the messages app.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Code"
            type="number"
            fullWidth
            onChange={handleChangeText('inputCode')}
          />
          {props.main.state.errorCode && (
            <div style={{color: 'red', fontSize: 15, fontFamily: "'Montserrat', sans-serif", float: 'left', marginTop: 8}}>Error verification code</div>
          )}
        </DialogContent>
        <DialogActions style={{width: '100%'}}>
          <Button onClick={handleClose} color="primary" style={{float: 'left', textTransform: 'none'}}>
            Cancel
          </Button>
          <Button onClick={handleClose} color="primary" style={{float: 'right', display: 'none'}}>
            Resend Code
          </Button>
          <Button onClick={() => props.main.verifyCode()} color="primary" style={{float: 'right', background: 'linear-gradient(256.79deg, #6852ED 40.49%, #BA43FC 100%)', borderRadius: 12, paddingLeft: 24, paddingRight: 24, color: 'white', textTransform: 'none'}}>
            Enter
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
