import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  dialogRoot: {
    textAlign: 'right'
  }
}))

export default function FormDialog(props) {

  const classes = useStyles()

  const handleClose = () => {
    props.setClose()
  };

  const handleChangeText = name => event => {
    props.setUserCode(event.target.value)
  };

  return (
    <div style={{textAlign: 'right'}}>
      <Dialog fullWidth open={props.open} onClose={handleClose} aria-labelledby="form-dialog-title" classes={{root: classes.dialogRoot, paper: classes.dialogRoot}}>
        {/* <DialogTitle id="form-dialog-title" classes={{root: classes.dialogRoot}}>אימות טלפוני</DialogTitle> */}
        <DialogContent classes={{root: classes.dialogRoot}}>
          {/* <DialogContentText classes={{root: classes.dialogRoot}}> */}
            <div style={{marginTop: 0, textAlign: 'right', fontSize: 20, fontWeight: 600}}>
              אימות טלפוני  
            </div>
            <div style={{marginTop: 12, marginBottom: 12, textAlign: 'right'}}>
              שלחנו לך הרגע הודעת טקסטס עם קוד האימות. אנא הזן אותו בשדה הבא  
            </div>
          {/* </DialogContentText> */}
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="קוד אימות"
            type="number"
            fullWidth
            onChange={handleChangeText('inputCode')}
          />
          {/* {console.log('props', props)} */}
          {props.errorCode && (
            <div style={{color: 'red', fontSize: 14, fontFamily: "arial", float: 'right', marginTop: 8}}>קוד אימות שגוי</div>
          )}
        </DialogContent>
        <DialogActions style={{width: '100%'}}>
          {/* <Button onClick={handleClose} color="primary" style={{float: 'left'}}>
            Cancel
          </Button> */}
          {/* <Button onClick={handleClose} color="primary" style={{float: 'right', display: 'none'}}>
            Resend Code
          </Button> */}
          <Button onClick={() => props.verifyCode()} color="primary" style={{float: 'right'}}>
            אישור
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
