const mobile = (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);

export default (theme) => ({
    root: {
        direction: 'rtl',
        textAlign: 'right',
        fontFamily: "'Rubik', sans-serif",
        background: '#F9F8FD'
    },
    productBox: {
        position: 'relative',
        flexFlow: 'column',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: mobile ? '100%' : 240,
        height: mobile ? 150 : 260,
        borderRadius: 12,
        margin: 0,
        marginTop: mobile ? 12 : 0,
        background: 'white',
        boxShadow: 'rgba(114, 82, 240, 0.1) 0px 8px 20px 0px'
    }
}); 
  