import React, {useContext, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import {AuthContext} from '../../../../contexts/Auth';
import NumberFormat from 'react-number-format';
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog({open, setOpen}) {
  const classes = useStyles();

  const {accountData} = useContext(AuthContext)

  useEffect(() => {

  }, [accountData])

  const getSkills = async () => {
    let skills = await axios.post('https://wesnapp.co.il/api/skill/get-by-contact', {id: localStorage.getItem('contactId')}, {headers: {'Content-Type': 'application/json'}})
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog fullWidth maxWidth={'xl'} open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <Typography style={{fontSize: 18, fontWeight: 'bold', flexGrow: 1}}>Shooting test guidlines</Typography>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        {accountData && (
          <iframe src={accountData.Country_Code__c === "+972" ? "https://wesnapp.io/he/photographers-guidelines-israel" : "https://wesnapp.io/photographers-guidelines-usa"} width="100%" height="100%" style={{minHeight: '84vh'}} frameBorder="0">Browser not compatible.</iframe>
        )}
      </Dialog>
    </div>
  );
}
