import React, {useEffect} from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios';
import CreditCardInput from 'react-credit-card-input';
import CreditCardRoundedIcon from '@material-ui/icons/CreditCardRounded';
import MoreVertRoundedIcon from '@material-ui/icons/MoreVertRounded';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    background: '#F9F8FD'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  }
});

const useStyles = makeStyles((theme) => ({
  roundedCorners: {
    borderRadius: 24,
  },
  confirmSelection: {
    paddingLeft: 18,
    paddingRight: 18,
    height: 42,
    borderRadius: 21,
    backgroundColor: '#33D7A0',
    color: 'white',
    textTransform: 'none'
  },
  dialogContent: {
    borderTop: '1px solid rgba(0,0,0,0.2)',
    background: '#F9F8FD'
  },
  button: {
    color: 'white',
    height: 50,
    backgroundColor: theme.palette.primary.main,
    textTransform: 'none',
    fontWeight: 'bold',
    paddingLeft: 32,
    paddingRight: 32,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
    boxShadow: `0 4px 10px ${theme.palette.primary.main}40`,
    borderRadius: 25,
    marginTop: 12
  },
  defaultTag: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 4,
    paddingBottom: 4,
    fontSize: 12,
    fontWeight: 600,
    borderRadius: 4,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  }
}))

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
}))(MuiDialogActions);

export default function CustomizedDialogs(props) {

  const classes = useStyles()

  const [cardNumber, setCardNumber] = React.useState('')
  const [cvc, setCvc] = React.useState('')
  const [expiry, setExpiry] = React.useState('')
  const [errorCard, setErrorCard] = React.useState(false)
  const [newCardOpen, setNewCardOpen] = React.useState(false)
  const [cards, setCards] = React.useState([])
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [cardIndex, setCardIndex] = React.useState(null)
  const [addingCard, setAddingCard] = React.useState(false)
  const [loadingCards, setLoadingCards] = React.useState(false)
  const [saveCardForLater, setSaveCardForLater] = React.useState(false)
  const [resposeData, setResposeData] = React.useState('')

  useEffect(() => {
    getExistingCards()
    let body = {
      TranzilaTK: '',
      remarks: '',
      expmonth: '',
      year: '',
      sum: ''
    }
  }, [])

  const getExistingCards = async () => {
    setLoadingCards(true)
    let cards = await axios.post('https://us-central1-wesnapp-d136a.cloudfunctions.net/getAccountTokens', {accountId: localStorage.getItem('accountId')}, {headers: {'Content-Type': 'application/json'}})
    setCards(cards.data)
    setNewCardOpen(false)
    setAddingCard(false)
    setLoadingCards(false)
  }

  const handleCardNumberChange = (e) => {
    setErrorCard(false)
    setCardNumber(e.target.value)
  }

  const handleCardExpiryChange = (e) => {
    setErrorCard(false)
    setExpiry(e.target.value)
  }

  const handleCardCVCChange = (e) => {
    setErrorCard(false)
    setCvc(e.target.value)
  }

  const saveCard = () => {
    setAddingCard(true)
    let data = {
      accountId: localStorage.getItem("accountId"),
      cardNumber: cardNumber,
      expirationDate: expiry,
      cvc: cvc,
    }
    axios.post('https://us-central1-wesnapp-d136a.cloudfunctions.net/createNewToken',data, {headers: {'Content-Type': 'application/json'}}).then((response) => {
    // axios.post('https://us-central1-wesnapp-d136a.cloudfunctions.net/createNewToken',data, {headers: {'Content-Type': 'application/json'}}).then((response) => {
      console.log('res', response.data)
      setCardNumber('')
      setExpiry('')
      setCvc('')
      getExistingCards()
    })
    .catch( (error) => {
      //console.log(error);
    });
  }

  const makeDefaultCard = async () => {
    setAnchorEl(null)
    await axios.post('https://us-central1-wesnapp-d136a.cloudfunctions.net/makeDefault', {cardId: cards[cardIndex].Id, accountId: localStorage.getItem('accountId')}, {headers: {'Content-Type': 'application/json'}})
    getExistingCards()
  }

  const removeCard = async () => {
    setAnchorEl(null)
    await axios.post('https://us-central1-wesnapp-d136a.cloudfunctions.net/removeCard', {cardId: cards[cardIndex].Id}, {headers: {'Content-Type': 'application/json'}})
    getExistingCards()
  }

  const handleCloseCardMenu = () => {
    setAnchorEl(null)
  }

  const handleOpenCardMenu = (event, index) => {
    setAnchorEl(event.currentTarget)
    setCardIndex(index)
  };

  const chargeCard = async (card) => {
    setAddingCard(true)
    let res = await axios.post('https://us-central1-wesnapp-d136a.cloudfunctions.net/makePayment', {paymentData: props.paymentData, sum: props.paymentData.totalAmountToPay, TranzilaTK: card.Token__c, expmonth: card.Expiration_Date__c.split('/')[0], expyear: card.Expiration_Date__c.split('/')[1]}, {headers: {'Content-Type': 'application/json'}})
    let resData = res.data
    if (resData.includes('rror')) {
      setResposeData(resData.split('<a')[0])
    } else {
      setResposeData(res.data)
    }
    console.log('res', res)
    setAddingCard(false)
  }

  return (
    <div>
      <Dialog fullWidth maxWidth={'sm'} onClose={()=> props.setDialogStatus(false)} aria-labelledby="customized-dialog-title" open={props.open} classes={{root: classes.roundedCorners, container: classes.roundedCorners, paper: classes.roundedCorners }}>
        <DialogTitle id="customized-dialog-title" onClose={()=> props.setDialogStatus(false)}>
          Payment
        </DialogTitle>
        <DialogContent classes={{root: classes.dialogContent}}>
          <div style={{paddingTop: 24, paddingBottom: 24, paddingLeft: 12, paddingRight: 12, textAlign: 'center'}}>
          <Typography gutterBottom>
            <div style={{fontSize: 11}} dangerouslySetInnerHTML={{__html: resposeData}} />
            {!resposeData.includes('succe') && (
              <>
                {props.paymentWithProduct ? (
                    "Photo shoot not paid. Additionally you've just selected additional media. You will get charged for those. If would you like to proceed with the download, please complete the payment proccess."
                  ) : (
                    "You've just selected additional media. You will get charged for those. If would you like to proceed with the download, please complete the payment proccess."
                  )}
                <div style={{marginTop: 20, width: '100%', height: 5}}></div>
                <div style={{textAlign: 'left', fontWeight: 'bold', fontSize: 20}}>Use saved card</div>
                <div style={{marginTop: 20, width: '100%', height: 5}}></div>
                <div style={{width: '100%', display: 'flex', flexFlow: 'column', gap: 12}}>
                  {loadingCards && (
                      <>
                        <Skeleton variant="rect" width={'100%'} height={70} />
                        <Skeleton variant="rect" width={'100%'} height={70} />
                        <Skeleton variant="rect" width={'100%'} height={70} />
                      </>
                    )}
                    {!loadingCards && (
                      <>
                        {cards.map((item, index) => (
                          <div style={{width: '100%', background: 'white', gap: 12, textAlign: 'left', padding: '14px 16px', display: 'flex', alignItems: 'center', borderRadius: '12px', boxShadow: '0px 4px 10px #6334FF10'}} key={index}>
                            <CreditCardRoundedIcon />
                            <div style={{flexGrow: 1, textAlign: 'left'}}>**** **** **** {item.Last_4_Digits__c}. Expires: {item.Expiration_Date__c.replace(/ /g, '')}</div>
                            {item.Default_Card__c && (
                              <div className={classes.defaultTag}>Default</div>
                            )}
                            {addingCard ? <CircularProgress /> : 
                              <Button className={classes.defaultTag} onClick={() => chargeCard(item)}>Charge Card</Button>
                            }
                          </div>
                        ))}
                      </>
                    )}
                  </div>
                  <div style={{marginTop: 20, width: '100%', height: 5}}></div>
                  <div style={{textAlign: 'left', fontWeight: 'bold', fontSize: 20}}>Use new card</div>
                  <div style={{marginTop: 20, width: '100%', height: 5}}></div>
                  <FormGroup row>
                    <FormControlLabel
                      control={<Checkbox checked={saveCardForLater} onChange={(e) => setSaveCardForLater(e.target.checked)} name="checkedA" />}
                      label="Save card for next payments"
                    />
                  </FormGroup>
                  <iframe style={{width: '100%', height: 490, marginTop: 24, frameBorder: 0, borderRadius: 0, border: 0}} src={props.paymentLink} />
                  <div style={{marginTop: 12, marginBottom: 12, width: '100%', textAlign: 'center', fontSize: 16}}>The process might take few seconds. Please wait.</div>
              </>
            )}            
          </Typography>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
