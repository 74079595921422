import React, {useEffect, useState} from 'react';
import UploadBox from './UploadBox';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import Lottie from 'react-lottie';
import * as animationData from './animationData.json'
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Demo = () => {

    const [images, setImages] = useState({
        originals: [],
        thumbnail: [],
        compressed: []
    })
    const [datetime, setDatetime] = useState('')

    const [editStatus, setEditStatus] = useState('waiting')
    const [successMessage, setSuccessMessage] = useState('')
    const [editedImage, setEditedImage] = useState(null)

    const [open, setOpen] = React.useState(false);

    const [openGallery, setOpenGallery] = React.useState(false);
    const [galleryIndex, setGalleryIndex] = React.useState(0);
    const [editedImages, setEditedImages] = React.useState([]);
    const [originalImages, setOriginalImages] = React.useState([]);
    const [galleryImages, setGalleryImages] = React.useState([]);

    useEffect(() => {
        console.log('galleryImages', galleryImages)
    }, [galleryImages])

    useEffect(() => {
        console.log('originalImages', originalImages)
    }, [originalImages])

    const setEditedImageGalleryArray = (obj) => {
        let array = [{
            src: obj.compressedImageUrl,
            thumbnail: obj.thumbnailImageUrl,
            thumbnailWidth: 320,
            thumbnailHeight: 174,
            caption: "Edited Image"
        }]
        setEditedImages(array)
    }

    const setOriginalImagesGalleryArray = (arr) => {
        console.log('in setOriginalImagesGalleryArray', arr)
        let array = []
        for (let index = 0; index < arr.length; index++) {
            const obj = arr[index];
            array.push({
                src: obj.compressedImageUrl,
                thumbnail: obj.thumbnailImageUrl,
                thumbnailWidth: 320,
                thumbnailHeight: 174,
                caption: "Original Image - " + toString(index)
            })
        }
        console.log('setOriginalImages', array);
        setOriginalImages(array)
    }

    const handleOpenGallery = (type) => {
        console.log('type', type)
        setGalleryIndex(0)
        if (type === 'edited') {
            console.log('edited', editedImages)
            setGalleryImages(editedImages)
        } else {
            console.log('originals', originalImages)
            setGalleryImages(originalImages)
        }
        setOpenGallery(true);
    };

    const handleClick = (message) => {
        setOpen(true);
        setSuccessMessage(message)
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
        return;
        }

        setOpen(false);
    };

    useEffect(() => {
        let datetime = moment(new Date()).format()
        let splited = datetime.split('+')
        let formatted = splited[0].replace(/-/g, '~')
        setDatetime(formatted)
    }, [])

    return (
        <div style={{padding: 12, textAlign: 'left', overflow: 'scroll', height: '100vh'}}>
            {(openGallery && galleryImages.length > 0) && (
                <Lightbox
                    mainSrc={galleryImages[galleryIndex].src}
                    nextSrc={galleryImages[(galleryIndex + 1) % galleryImages.length].src}
                    prevSrc={galleryImages[(galleryIndex + galleryImages.length - 1) % galleryImages.length].src}
                    onCloseRequest={() => setOpenGallery(false)}
                    onMovePrevRequest={() =>
                        {
                            console.log('galleryImages', galleryImages);
                            console.log('change index', galleryIndex);
                            console.log('new index', (galleryIndex + galleryImages.length - 1) % galleryImages.length);
                            setGalleryIndex((galleryIndex + galleryImages.length - 1) % galleryImages.length);
                        }
                    }
                    onMoveNextRequest={() =>
                        {
                            console.log('galleryImages', galleryImages);
                            console.log('change index', galleryIndex);
                            console.log('new index', (galleryIndex + 1) % galleryImages.length);
                            setGalleryIndex((galleryIndex + 1) % galleryImages.length)
                        }
                    }
                />
            )}
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success">
                    {successMessage}
                </Alert>
            </Snackbar>
            <Grid container spacing={24} dir="ltr" justify="center">
                <Grid item xs={12} sm={10} md={8} xl={6}>
                    <div style={{paddingTop: 24, paddingBottom: 12, textAlign: 'left'}}>
                        <img alt='' src={require('../../Assets/black_logo.png')}  height="50px" width="auto" />
                    </div>
                    <div style={{paddingBottom: 12, textAlign: 'left'}}>
                        API version 1.16.2
                    </div>
                    <UploadBox handleOpenGallery={handleOpenGallery} setOriginalImagesGalleryArray={setOriginalImagesGalleryArray} setEditedImageGalleryArray={setEditedImageGalleryArray} images={images} editStatus={editStatus} datetime={datetime} setEditedImage={setEditedImage} setEditStatus={setEditStatus} handleClick={handleClick} />
                    {editStatus === 'started' && (
                        <Lottie
                            options={{
                                loop: true,
                                autoplay: true, 
                                animationData: require('./animationData.json'),
                                rendererSettings: {
                                    preserveAspectRatio: 'xMidYMid slice'
                                }
                            }}
                            height={180}
                            width={180}
                            isStopped={false}
                            isPaused={false}
                        />
                    )}
                    {editStatus === 'finished' && (
                        <div>
                            <div style={{fontWeight: 'bold', fontFamily: "'Montserrat', sans-serif", fontSize: 16, marginBottom: 20, marginTop: 24, marginBottom: 24, textAlign: 'left', color: '#303030'}}>Edited Image <span style={{cursor: 'pointer', color: '#6B4CF8'}} onClick={()=> handleOpenGallery('edited')}><u>Gallery</u></span></div>
                            <img alt='' src={editedImage}  height="auto" width="100%" />
                        </div>
                    )}
                </Grid>
            </Grid>
        </div>
    );
}
 
export default Demo;