import React from 'react';
import {Button, FormControlLabel, Switch, Tooltip, CircularProgress} from '@material-ui/core'
import UploadBox from './UploadBox'
import UploadBoxRaw from './UploadBoxRaw'

const zipIcon = require("../../../../Assets/zipIcon.png");

const UploadedStation = ({
    imagesRawExposures,
    images1Exposures,
    images3Exposures,
    images4Exposures,
    images5Exposures,
    images6Exposures,
    images7Exposures,
    images8Exposures,
    images9Exposures,
    downloadZipFileByType,
    uploadStation,
    displayGallery,
    requestName,
    handleChangeDisplayGallery,
    requestId,
    setCommentDialogOpen,
    setUploadedExposures,
    uploadedExposures,
    editImages,
    getImages,
    photographerName,
    shootingLocation
}) => {
    return ( 
        <div style={{width: '100%'}}>
            <div>
                <div style={{display: 'inline-block', fontWeight: 'bold', width: '50%',fontFamily: "'Montserrat', sans-serif", fontSize: 20, textAlign: 'left', marginBottom: 5, color: '#818BA6', marginTop: -16}}>{uploadStation}</div>
                <div style={{display: 'inline-block', fontWeight: 'bold', width: '50%', fontFamily: "'Montserrat', sans-serif", fontSize: 20, textAlign: 'right', marginBottom: 5, color: '#818BA6', marginTop: -16}}>
                    <Button variant="contained" type="submit" elevation={0} style={{color: 'white', marginBottom: 20, height: 40, borderRadius: 20, background: "linear-gradient(to right , rgb(94,44,237), rgb(94,44,237))", boxShadow: "0px 10px 15px -10px rgba(0,0,0,0.0)"}} onClick={ () => setCommentDialogOpen(true)}>
                        Add comments
                    </Button>
                </div>
            </div>
            {uploadStation === 'Raws' && (
                <UploadBoxRaw shootingLocation={shootingLocation} photographerName={photographerName} requestName={requestName} exposure={'1'} editImages={editImages} getImages={getImages} images={imagesRawExposures} requestId={requestId} galleryOrLinesUpload={displayGallery} uploadedExposures={uploadedExposures} setUploadedExposures={setUploadedExposures}/>
            )}
            {uploadStation === '1 Exposures' && (
                <UploadBox shootingLocation={shootingLocation} photographerName={photographerName} requestName={requestName} exposure={'1'} editImages={editImages} getImages={getImages} images={images1Exposures} requestId={requestId} galleryOrLinesUpload={displayGallery} uploadedExposures={uploadedExposures} setUploadedExposures={setUploadedExposures}/>
            )}
            {uploadStation === '3 Exposures' && (
                <UploadBox shootingLocation={shootingLocation} photographerName={photographerName} requestName={requestName} exposure={'3'} editImages={editImages} getImages={getImages} images={images3Exposures} requestId={requestId} galleryOrLinesUpload={displayGallery} uploadedExposures={uploadedExposures} setUploadedExposures={setUploadedExposures}/>
            )}
            {uploadStation === '4 Exposures' && (
                <UploadBox shootingLocation={shootingLocation} photographerName={photographerName} requestName={requestName} exposure={'4'} editImages={editImages} getImages={getImages} images={images4Exposures} requestId={requestId} galleryOrLinesUpload={displayGallery} uploadedExposures={uploadedExposures} setUploadedExposures={setUploadedExposures}/>
            )}
            {uploadStation === '5 Exposures' && (
                <UploadBox shootingLocation={shootingLocation} photographerName={photographerName} requestName={requestName} exposure={'5'} editImages={editImages} getImages={getImages} images={images5Exposures} requestId={requestId} galleryOrLinesUpload={displayGallery} uploadedExposures={uploadedExposures} setUploadedExposures={setUploadedExposures}/>
            )}
            {uploadStation === '6 Exposures' && (
                <UploadBox shootingLocation={shootingLocation} photographerName={photographerName} requestName={requestName} exposure={'6'} editImages={editImages} getImages={getImages} images={images6Exposures} requestId={requestId} galleryOrLinesUpload={displayGallery} uploadedExposures={uploadedExposures} setUploadedExposures={setUploadedExposures}/>
            )}
            {uploadStation === '7 Exposures' && (
                <UploadBox shootingLocation={shootingLocation} photographerName={photographerName} requestName={requestName} exposure={'7'} editImages={editImages} getImages={getImages} images={images7Exposures} requestId={requestId} galleryOrLinesUpload={displayGallery} uploadedExposures={uploadedExposures} setUploadedExposures={setUploadedExposures}/>
            )}
            {uploadStation === '8 Exposures' && (
                <UploadBox shootingLocation={shootingLocation} photographerName={photographerName} requestName={requestName} exposure={'8'} editImages={editImages} getImages={getImages} images={images8Exposures} requestId={requestId} galleryOrLinesUpload={displayGallery} uploadedExposures={uploadedExposures} setUploadedExposures={setUploadedExposures}/>
            )}
            {uploadStation === '9 Exposures' && (
                <UploadBox shootingLocation={shootingLocation} photographerName={photographerName} requestName={requestName} exposure={'9'} editImages={editImages} getImages={getImages} images={images9Exposures} requestId={requestId} galleryOrLinesUpload={displayGallery} uploadedExposures={uploadedExposures} setUploadedExposures={setUploadedExposures}/>
            )}
        </div>
    );
}
 
export default UploadedStation;