import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from "@material-ui/core/Button";
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios';
import '../../../../App.css';
import Slide from '@material-ui/core/Slide';
import Dropzone from "./Dropzone/Dropzone";
import LazyLoad from 'react-lazyload'
import 'react-image-lightbox/style.css';
import { withSnackbar } from 'notistack';

const BASE_URL = 'https://py.wesnapp.co.il/server/upload/';
// const BASE_URL = 'http://localhost:8080/server/upload/';

const vIcon = require("../../../../Assets/vIcon.png");

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing.unit * 2,
    color: theme.palette.text.secondary,
  },
  margin: {
    margin: 10
  }
});

class Upload3Exposures extends React.Component {

  state = {
    urls: [],
    files: [],
    finishLoading: false,
    finishUploading: false,
    exposure: parseInt(this.props.exposure)
  };

  handleChange = name => event => {
    this.setState({ [name]: event.target.checked });
  };

  componentDidMount() {
    console.log(this.getDateAndTime())
  }

  handleChangeFiles(files){
    console.log(files);
    if (files.length > 0) {
      this.setState({finishLoading: false})
      var selectedFiles = this.state.files;
      var counter = 0
      for (var i = 0; i < files.length; i++) {
        var reader = new FileReader();
        var file = files[i]
        reader.onload = e => {
          selectedFiles.push({data: e.target.result, file: files[counter], size: files[counter].size, percentCompleted: 0, url: ''})
          counter += 1
          if (counter == files.length) {
            if (counter % this.state.exposure != 0) {
              // this.handleOpen('X')
            }
            var sortedFiles = selectedFiles.sort(this.naturalCompare);
            this.setState({
              files: sortedFiles,
              finishUploading: false,
            }, () => {
              console.log("DONE");
              this.uploaderImages()
              this.setState({finishLoading: false})
            })
          }
        };
        if (!this.checkIfFileExisting(file, selectedFiles)) {
          reader.readAsDataURL(file);
        }
      }
    }
  }

  naturalCompare(a, b) {
    var ax = [], bx = [];
    a.file.name.replace(/(\d+)|(\D+)/g, function(_, $1, $2) { ax.push([$1 || Infinity, $2 || ""]) });
    b.file.name.replace(/(\d+)|(\D+)/g, function(_, $1, $2) { bx.push([$1 || Infinity, $2 || ""]) });
    while(ax.length && bx.length) {
        var an = ax.shift();
        var bn = bx.shift();
        var nn = (an[0] - bn[0]) || an[1].localeCompare(bn[1]);
        if(nn) return nn;
    }
    return ax.length - bx.length;
  }

  naturalCompareByUrl(a, b) {
    var ax = [], bx = [];
    a.replace(/(\d+)|(\D+)/g, function(_, $1, $2) { ax.push([$1 || Infinity, $2 || ""]) });
    b.replace(/(\d+)|(\D+)/g, function(_, $1, $2) { bx.push([$1 || Infinity, $2 || ""]) });
    while(ax.length && bx.length) {
        var an = ax.shift();
        var bn = bx.shift();
        var nn = (an[0] - bn[0]) || an[1].localeCompare(bn[1]);
        if(nn) return nn;
    }
    return ax.length - bx.length;
  }

  checkIfFileExisting = (file, files) => {
    for (var i = 0; i < files.length; i++) {
      if (files[i].file.name == file.name) {
        return true
      }
    }
    return false
  }

  getDateAndTime = () => {
    var newdate = ""
    var event = new Date();
    var e = event.toLocaleString("IST", {timeZone: "Asia/Jerusalem"})
    var newdateForSalesforce = e
    var date = e.split(',')[0]
    var date_ = date.split('/')
    var month = date_[0]
    var day = date_[1]
    var year = date_[2]
    if (String(month).length < 2) {
      month = "0" + String(month)
    }
    if (String(day).length < 2) {
      day = "0" + String(day)
    }
    newdate = year + "-" + month + "-" + day;
    console.log(newdate)
    var time = e.split(',')[1]
    time = time.replace(" ", "")
    var times = time.split(":")
    var hour = times[0]
    console.log(times[2].includes('PM'))
    if (times[2].includes('PM')) {
      if (hour === "1") {
          hour = "13";
        }
        if (hour === "2") {
          hour = "14";
        }
        if (hour === "3") {
          hour = "15";
        }
        if (hour === "4") {
          hour = "16";
        }
        if (hour === "5") {
          hour = "17";
        }
        if (hour === "6") {
          hour = "18";
        }
        if (hour === "7") {
          hour = "19";
        }
        if (hour === "8") {
          hour = "20";
        }
        if (hour === "9") {
          hour = "21";
        }
        if (hour === "10") {
          hour = "22";
        }
        if (hour === "11") {
          hour = "23";
        }
        if (hour === "12") {
          hour = "12";
        }
    }
    var minutes = times[1]
    var seconds = times[2].replace("AM", "").replace("PM", "").replace(" ", "")
    var time_full = hour + ':' + minutes + ':' + seconds
    console.log(time_full)

    var newdateForSalesforce = newdate + "T" + time_full + "+0200"
    return newdateForSalesforce
  }

  uploaderImages = () => {
    var addExposure = this.props.uploadedExposures
    if (this.props.uploadedExposures == "") {
      addExposure = this.props.uploadedExposures + this.props.exposure
      this.props.setUploadedExposures(addExposure)
    } else {
      addExposure = this.props.uploadedExposures + ';' + this.props.exposure
      this.props.setUploadedExposures(addExposure)
    }
    console.log(addExposure);
    axios.post('https://wesnapp.co.il/api/request/update-request',{requestId: this.props.requestId, data: {Status__c: 'Images Uploaded', Original_Image_Upload_Date_Time__c: this.getDateAndTime(), Type_Of_Upload__c: addExposure}}, {headers: {'Content-Type': 'application/json'}}).then((response) => {
      console.log(response.data);
    })
    .catch( (error) => {
      console.log(error);
    });

    var counter = 0
    var files = this.state.files
    var images3Exposures = this.props.images
    if (files.length % this.state.exposure != 0) {
      this.handleAlert()
    }
    if (files.length > 0) {
      console.log("UPLOADING IMAGES IN " + this.props.exposure + " EXPOSURES");
      if (files.length === 1) {
        this.uploadImageFunction(counter)
      } else if (files.length === 2) {
        this.uploadImageFunction(counter)
        this.uploadImageFunction(counter + 1)
      } else if (files.length === 3) {
        this.uploadImageFunction(counter)
        this.uploadImageFunction(counter + 1)
        this.uploadImageFunction(counter + 2)
      } else if (files.length === 4) {
        this.uploadImageFunction(counter)
        this.uploadImageFunction(counter + 1)
        this.uploadImageFunction(counter + 2)
        this.uploadImageFunction(counter + 3)
      } else if (files.length === 5) {
        this.uploadImageFunction(counter)
        this.uploadImageFunction(counter + 1)
        this.uploadImageFunction(counter + 2)
        this.uploadImageFunction(counter + 3)
        this.uploadImageFunction(counter + 4)
      }
      else if (files.length >= 6) {
        this.uploadImageFunction(counter)
        this.uploadImageFunction(counter + 1)
        this.uploadImageFunction(counter + 2)
        this.uploadImageFunction(counter + 3)
        this.uploadImageFunction(counter + 4)
        this.uploadImageFunction(counter + 5)
      }
    } else {
        alert('All images are uploaded successfully.')
    }
  }

  getSignedUrl = async (fileName, contentType) => {
    let response = await axios.post("https://wesnapp.co.il/api/storage/get-signed-url", {fileName: fileName, contentType: contentType} ,{headers: {'Content-Type': "application/json", 'Access-Control-Allow-Origin': '*'}})
    return response.data
  }

  uploadImageFunction = async (counter) => {
    var files = this.state.files
    const file = files[counter]

    let directory = `shootings/${this.props.requestId}/originals/bracketing/${this.props.exposure}Exposures/`

    let signedUrl = await this.getSignedUrl(directory + file.file.name, file.file.type)

    console.log('file', file)

    const xhr = new XMLHttpRequest();
    xhr.open("PUT", signedUrl, true);
    xhr.onload = () => {
      const status = xhr.status;
      if (status === 200) {
        console.log('counter', counter)
        console.log('length', files.length)
        if (counter === this.state.files.length - 1) {
          // finished
          this.handleSuccess()
          this.props.editImages(this.props.exposure)
        } else {
          if (counter + 6 < files.length) {
            this.uploadImageFunction(counter + 6)
          }
        }
      } else {
        console.log(xhr)
      }
    };

    xhr.onerror = (e) => {
      console.log("Something went wrong", e);
    };
    xhr.setRequestHeader('Content-Type', file.file.type);
    xhr.upload.addEventListener("progress", this.uploadProgress(counter), false)
    xhr.send(file.file);
  }

  // uploadImageFunction = (counter) => {
  //   var files = this.state.files
  //   var images3Exposures = this.props.images
  //   var config = {
  //     onUploadProgress: this.uploadProgress(counter)
  //   };
  //   var image = this.state.files[counter]
  //   if (image.percentCompleted != 100) {
  //     var config = {
  //       onUploadProgress: this.uploadProgress(counter)
  //     };
  //     var data = new FormData();
  //     var name = this.getImageName(image)
  //     data.append("image", image.file, name.toLowerCase());

  //     axios.post(BASE_URL + this.props.requestId + "-originals-bracketing-" + this.props.exposure + "Exposures", data, config).then(response => {
  //       console.log(response.data);
  //       for (var i = 0; i < files.length; i++) {
  //         var x = response.data.thumbnailImageUrl.split('/')
  //         var splited = files[i].file.name.split('.')
  //         // var fileName = splited[0] + "(" + this.state.exposure + ")." + splited[splited.length - 1]
  //         var fileName = files[i].file.name
  //         fileName.replace('_reduced_thumbnail', '')
  //         if (x[x.length - 1].replace('_reduced_thumbnail', '') == fileName) {
  //           files[i].url = response.data.thumbnailImageUrl
  //           images3Exposures.originals.push(response.data.imageUrl)
  //           images3Exposures.thumbnail.push(response.data.thumbnailImageUrl)
  //           images3Exposures.compressed.push(response.data.reducedImageUrl)
  //           break
  //         }
  //       }
  //       this.setState({files: files})

  //       var foundEmpty = false
  //       for (var i = 0; i < files.length; i++) {
  //         if (files[i].url == "") {
  //           foundEmpty = true
  //         }
  //       }

  //       if (!foundEmpty) {
  //         this.handleSuccess()
  //         this.props.editImages(this.props.exposure)
  //       } else {
  //         if (counter + 6 < files.length) {
  //           this.uploadImageFunction(counter + 6)
  //         }
  //       }

  //       var sortedFiles = images3Exposures.originals.sort(this.naturalCompareByUrl);

  //       this.props.getImages(String(this.props.exposure))

  //     })
  //   }
  // }

  getImageName = (image) => {
    // var splited = image.file.name.split('.')
    // var name = splited[0]+ "(" + this.state.exposure + ")." + splited[1]
    return image.file.name
  }

  handleSuccess = () => {
    var text = "Images in " + this.props.exposure + " Exposures where uploaded by the photographer -> " + this.props.photographerName + " to shoot number -> " + this.props.requestName + " in address -> " + this.props.shootingLocation
    axios.post('https://wesnapp.co.il/api/slack/photographer-notification',{text: text}, {headers: {'Content-Type': 'application/json'}}).then((response) => {
      console.log(response.data);
    })
    .catch( (error) => {
      console.log(error);
    });
    window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;
    this.props.enqueueSnackbar("Images in " + this.props.exposure + " exposures were successfully uploaded",
      { variant: "success", autoHideDuration: 9999999999999999, resumeHideDuration: 99999999999999,
      action: key => (
                    <Button color="secondary" style={{color: 'white'}} size="small" onClick={() => this.props.closeSnackbar(key)}>
                        Dismiss
                    </Button>
            )}
      )
  }

  handleAlert = () => {
    window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;
    this.props.enqueueSnackbar("Wrong number of images in " + this.props.exposure + " exposures", { variant: "warning", autoHideDuration: 9999999999999999, resumeHideDuration: 99999999999999,
    action: key => (
                  <Button color="secondary" style={{color: 'white'}} size="small" onClick={() => this.props.closeSnackbar(key)}>
                      Dismiss
                  </Button>
          )}
  )
  }

  uploadProgress = (id) => {
    return (progress) => {
      var arrayOfFiles = this.state.files
      var percentCompleted = Math.round((progress.loaded * 100) / progress.total);
      arrayOfFiles[id].percentCompleted = percentCompleted
      this.setState({files: arrayOfFiles}, () => {
      })
    }
  }

  getNameFromUrl = (item) => {
    var splitedUrl = item.url.split('/')
    return splitedUrl[splitedUrl.length - 1]
  }

  render() {
    const { classes, theme } = this.props;
    return (
      <div className={classes.root}>
        <Grid container spacing={24} dir="ltr">
          <Grid item xs={12} sm={12} md={12}>
            <div>
              <Dropzone onFilesAdded={this.handleChangeFiles.bind(this)} />
              <div style={{marginTop: 0, width: '100%', marginLeft: -2.5}}>
                {this.state.files.length > 0 && (
                  <div>
                    <div style={{fontWeight: 'bold', fontFamily: "'Montserrat', sans-serif", fontSize: 20, marginBottom: 20, marginTop: 15, textAlign: 'left', color: '#818BA6'}}>{this.state.files.length} New images</div>
                    {this.state.files.map((item, i) => (
                      <div>
                        <div style={{width: '100%', height: 50, marginBottom: 10, padding: 5,  alignItems: 'center', boxShadow: '0px 0px 6px 0px rgba(0,0,0,0.09)'}} className="imageCell">
                          {item.percentCompleted == 100 && (
                            <div style={{display: 'inline-block', fontSize: 16, float: 'left', marginRight: 9, marginLeft: 9}}>
                              <img style={{width: 18, height: 18, borderRadius: 9, backgroundColor: 'rgb(120,203,117)', marginTop: 10}} src={vIcon} alt="..." />
                            </div>
                          )}
                          {item.percentCompleted < 100 && (
                            <div style={{display: 'inline-block', fontSize: 16, float: 'left', marginRight: 9, marginLeft: 9, marginTop: 8}}>
                              <CircularProgress size={20} variant="static" value={item.percentCompleted}/>
                            </div>
                          )}
                          <div style={{display: 'inline-block', fontSize: 16, float: 'left', marginRight: 5, marginTop: 9}}>{item.file.name}</div>
                          {item.percentCompleted < 100 && (
                            <div style={{display: 'inline-block', fontSize: 16, float: 'left', marginRight: 5, marginTop: 9}}> - {item.percentCompleted}%</div>
                          )}
                          {item.url != "" && (
                            <div style={{display: 'inline-block', fontSize: 16, float: 'right', marginRight: 5}}>
                              <LazyLoad offset={100}>
                                <img src={item.url} style={{objectFit: 'scale-down', height: 40}} alt="..." />
                              </LazyLoad>
                            </div>
                          )}
                        </div>
                        {((i+1) != 1) && ((i+1) % this.state.exposure == 0) && (<div style={{width: '100%', height: 20}}></div>)}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    )}

  }

Upload3Exposures.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(
    withSnackbar(Upload3Exposures),
);

  // state = {
  //   urls: [],
  //   files: [],
  //   finishLoading: false,
  //   finishUploading: false,
  //   finishedCounter: 0,
  //   exposure: 3
  // };

  // handleChange = name => event => {
  //   this.setState({ [name]: event.target.checked });
  // };

  // editImages = async () => {
  //   var data = JSON.stringify({"images":["https://storage.googleapis.com/wesnapp/shootings/demo/2020~12~13T17:11:09/originals/wesnapp_1.jpg"],"output_path":"test-edited"});

  //   var config = {
  //     method: 'post',
  //     url: 'http://34.121.52.53:5000/at',
  //     headers: { 
  //       'Content-Type': 'application/json'
  //     },
  //     data : data
  //   };

  //   axios(config)
  //   .then(function (response) {
  //     console.log(JSON.stringify(response.data));
  //   })
  //   .catch(function (error) {
  //     console.log(error);
  //   });

  // }

  // componentDidMount() {
  // //  this.editImages()
  // }

  // handleChangeFiles(files){
  //   console.log(files);
  //   if (files.length > 0) {
  //     this.setState({finishLoading: false})
  //     var selectedFiles = this.state.files;
  //     var counter = 0
  //     for (var i = 0; i < files.length; i++) {
  //       var reader = new FileReader();
  //       var file = files[i]
  //       reader.onload = e => {
  //         selectedFiles.push({data: e.target.result, file: files[counter], size: files[counter].size, percentCompleted: 0, url: ''})
  //         counter += 1
  //         if (counter == files.length) {
  //           var sortedFiles = selectedFiles.sort(this.naturalCompare);
  //           this.setState({
  //             files: sortedFiles,
  //             finishUploading: false,
  //           }, () => {
  //             console.log("DONE");
  //             this.uploaderImages()
  //             this.setState({finishLoading: false})
  //           })
  //         }
  //       };
  //       if (!this.checkIfFileExisting(file, selectedFiles)) {
  //         reader.readAsDataURL(file);
  //       }
  //     }
  //   }
  // }

  // naturalCompare(a, b) {
  //   var ax = [], bx = [];
  //   a.file.name.replace(/(\d+)|(\D+)/g, function(_, $1, $2) { ax.push([$1 || Infinity, $2 || ""]) });
  //   b.file.name.replace(/(\d+)|(\D+)/g, function(_, $1, $2) { bx.push([$1 || Infinity, $2 || ""]) });
  //   while(ax.length && bx.length) {
  //       var an = ax.shift();
  //       var bn = bx.shift();
  //       var nn = (an[0] - bn[0]) || an[1].localeCompare(bn[1]);
  //       if(nn) return nn;
  //   }
  //   return ax.length - bx.length;
  // }

  // naturalCompareByUrl(a, b) {
  //   var ax = [], bx = [];
  //   a.replace(/(\d+)|(\D+)/g, function(_, $1, $2) { ax.push([$1 || Infinity, $2 || ""]) });
  //   b.replace(/(\d+)|(\D+)/g, function(_, $1, $2) { bx.push([$1 || Infinity, $2 || ""]) });
  //   while(ax.length && bx.length) {
  //       var an = ax.shift();
  //       var bn = bx.shift();
  //       var nn = (an[0] - bn[0]) || an[1].localeCompare(bn[1]);
  //       if(nn) return nn;
  //   }
  //   return ax.length - bx.length;
  // }

  // checkIfFileExisting = (file, files) => {
  //   for (var i = 0; i < files.length; i++) {
  //     if (files[i].file.name == file.name) {
  //       return true
  //     }
  //   }
  //   return false
  // }

  // uploaderImages = () => {
  //   var counter = 0
  //   var files = this.state.files
  //   if (files.length > 0) {
  //     if (files.length === 1) {
  //       this.uploadImageFunction(counter)
  //     } else if (files.length === 2) {
  //       this.uploadImageFunction(counter)
  //       this.uploadImageFunction(counter + 1)
  //     } else if (files.length === 3) {
  //       this.uploadImageFunction(counter)
  //       this.uploadImageFunction(counter + 1)
  //       this.uploadImageFunction(counter + 2)
  //     } else if (files.length === 4) {
  //       this.uploadImageFunction(counter)
  //       this.uploadImageFunction(counter + 1)
  //       this.uploadImageFunction(counter + 2)
  //       this.uploadImageFunction(counter + 3)
  //     } else if (files.length === 5) {
  //       this.uploadImageFunction(counter)
  //       this.uploadImageFunction(counter + 1)
  //       this.uploadImageFunction(counter + 2)
  //       this.uploadImageFunction(counter + 3)
  //       this.uploadImageFunction(counter + 4)
  //     }
  //     else if (files.length >= 6) {
  //       this.uploadImageFunction(counter)
  //       this.uploadImageFunction(counter + 1)
  //       this.uploadImageFunction(counter + 2)
  //       this.uploadImageFunction(counter + 3)
  //       this.uploadImageFunction(counter + 4)
  //       this.uploadImageFunction(counter + 5)
  //     }
  //   } else {
  //       alert('All images are uploaded successfully.')
  //   }
  // }

//   getSignedUrl = async (fileName) => {
//     let response = await axios.post("http://localhost:8080/api/storage/get-signed-url", {fileName: fileName} ,{headers: {'Content-Type': "application/json", 'Access-Control-Allow-Origin': '*'}})
//     return response.data
//   }

//   uploadImageFunction = async (counter) => {
//     var files = this.state.files
//     const file = files[counter]

//     let directory = `shootings/${this.props.requestId}/originals/bracketing/${this.props.exposure}Exposures/`

//     let signedUrl = await this.getSignedUrl(directory + file.file.name)

//     console.log('file', file)

//     const xhr = new XMLHttpRequest();
//     xhr.open("PUT", signedUrl, true);
//     xhr.onload = () => {
//       const status = xhr.status;
//       if (status === 200) {
//         this.setState({finishedCounter: this.state.finishedCounter + 1}, ()=> {
//           if (this.state.finishedCounter === this.state.files.length) {
//             // finished
//             this.handleSuccess()
//           }
//         })
//       } else {
//         console.log(xhr)
//       }
//     };

//     xhr.onerror = (e) => {
//       console.log("Something went wrong", e);
//     };
//     xhr.setRequestHeader('Content-Type', file.file.type);
//     xhr.upload.addEventListener("progress", this.uploadProgress(counter), false)
//     xhr.send(file.file);
//   }

//   getImageName = (image) => {
//     return image.file.name
//   }

//   handleSuccess = (sortedImages) => {
//     this.props.setEditStatus('started')
//     this.props.handleClick('Images uploaded successfully')
//     axios.post('http://34.121.52.53:5000/at',{images: sortedImages, output_path: 'demo-' + this.props.datetime + "-edited"}, {headers: {'Content-Type': 'application/json'}}).then((response) => {
//       console.log('edit finished');
//       this.props.setEditStatus('finished')
//       console.log(response.data);
//       this.props.setEditedImageGalleryArray(response.data)
//       this.props.setEditedImage(response.data.compressedImageUrl)
//       this.props.handleClick('Editing finished successfully')
//     })
//     .catch( (error) => {
//       console.log(error);
//     });
//     // var text = "Images in 3 Exposures where uploaded by the photographer -> " + this.props.main.state.photographerName + " to shoot number -> " + this.props.main.state.requestName + " in address -> " + this.props.main.state.shootingLocation
//     // axios.post('https://wesnapp.co.il/api/slack/photographer-notification',{text: text}, {headers: {'Content-Type': 'application/json'}}).then((response) => {
//     //   console.log(response.data);
//     // })
//     // .catch( (error) => {
//     //   console.log(error);
//     // });
//   }

//   handleAlert = () => {
//   }

//   uploadProgress = (id) => {
//     return (progress) => {
//       var arrayOfFiles = this.state.files
//       var percentCompleted = Math.round((progress.loaded * 100) / progress.total);
//       arrayOfFiles[id].percentCompleted = percentCompleted
//       this.setState({files: arrayOfFiles}, () => {
//       })
//     }
//   }

//   getNameFromUrl = (item) => {
//     var splitedUrl = item.url.split('/')
//     return splitedUrl[splitedUrl.length - 1]
//   }

//   render() {
//     const { classes, theme } = this.props;
//     return (
//       <div className={classes.root}>
//         <Grid container spacing={24} dir="ltr">
//           <Grid item xs={12} sm={12} md={12}>
//             <div>
//               {this.props.editStatus === 'waiting' && (
//                 <Dropzone onFilesAdded={this.handleChangeFiles.bind(this)} />
//               )}
//               <div style={{marginTop: 0, width: '100%', marginLeft: -2.5}}>
//                 {this.state.files.length > 0 && (
//                   <div>
//                     <div style={{fontWeight: 'bold', fontFamily: "'Montserrat', sans-serif", fontSize: 16, marginBottom: 20, marginTop: 15, textAlign: 'left', color: '#303030'}}>{this.state.files.length} New images {this.props.editStatus !== 'waiting' && (<span style={{cursor: 'pointer', color: '#6B4CF8'}} onClick={()=> this.props.handleOpenGallery('originals')}><u>Gallery</u></span>)}</div>
//                     {this.state.files.map((item, i) => (
//                       <div>
//                         <div style={{width: '100%', height: 50, marginBottom: 10, padding: 5,  alignItems: 'center', boxShadow: '0px 0px 6px 0px rgba(0,0,0,0.09)'}} className="imageCell">
//                           {item.percentCompleted == 100 && (
//                             <div style={{display: 'inline-block', fontSize: 16, float: 'left', marginRight: 9, marginLeft: 9}}>
//                               <img style={{width: 18, height: 18, borderRadius: 9, backgroundColor: 'rgb(120,203,117)', marginTop: 10}} src={vIcon} alt="..." />
//                             </div>
//                           )}
//                           {item.percentCompleted < 100 && (
//                             <div style={{display: 'inline-block', fontSize: 16, float: 'left', marginRight: 9, marginLeft: 9, marginTop: 8}}>
//                               <CircularProgress size={20} variant="static" value={item.percentCompleted}/>
//                             </div>
//                           )}
//                           <div style={{display: 'inline-block', fontSize: 16, float: 'left', marginRight: 5, marginTop: 9}}>{item.file.name}</div>
//                           {item.percentCompleted < 100 && (
//                             <div style={{display: 'inline-block', fontSize: 16, float: 'left', marginRight: 5, marginTop: 9}}> - {item.percentCompleted}%</div>
//                           )}
//                           {item.url != "" && (
//                             <div style={{display: 'inline-block', fontSize: 16, float: 'right', marginRight: 5}}>
//                               <LazyLoad offset={100}>
//                                 <img src={item.url} style={{objectFit: 'scale-down', height: 40}} alt="..." />
//                               </LazyLoad>
//                             </div>
//                           )}
//                         </div>
//                       </div>
//                     ))}
//                   </div>
//                 )}
//               </div>
//             </div>
//           </Grid>
//         </Grid>
//       </div>
//     )}

//   }

// Upload3Exposures.propTypes = {
//   classes: PropTypes.object.isRequired,
//   theme: PropTypes.object.isRequired,
// };

// export default withStyles(styles, { withTheme: true })(
//     Upload3Exposures
// );
