import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
// import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios';
import TextField from '@material-ui/core/TextField';
import { withRouter } from "react-router-dom";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

const styles = theme => ({
  list: {
    width: 250
  },
  fullList: {
    width: 'auto',
  },
  root: {
    backgroundColor: '#6C65FF',
    backgroundColor: 'white',
    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: 28,
      borderColor: '#DAE2FD',
    },
    '& .MuiOutlinedInput-input': {
      backgroundColor: '#ecf1fe',
      borderRadius: 28
    },
  },
  button: {
    height: 50,
    marginTop: 15,
    // float: 'left',
    borderRadius: 25,
    paddingLeft: 32,
    paddingRight: 32,
    boxShadow: 'none',
    hover: {
      backgroundColor: '#33D7A0'
    }
  }
});

function createData(id, name, mobile, startAt, watchShootings, useOrders, canSelect, accounter) {
  return { id, name, mobile, startAt, watchShootings, useOrders, canSelect, accounter };
}

class PhotoShoots extends React.Component {

  state = {
    isLoading: true,
    firstName: '',
    lastName: '',
    email: '',
    mobile: '',
    saveButtonText: 'save',
    updating: false,
    isBusiness: false,
    bName: '',
    errorBName: false,
    currentTab: 0,
    companyEmailForInvoice: '',
    companyNameForInvoice: '',
    companyVatNumber: ''
  }

  componentDidMount() {
    this.getUserDetails()
    this.getBusinessAccount()
  }

  getBusinessAccount = () => {
    var contactId = localStorage.getItem("contactId")
    var data = {contactId: contactId}
    axios.post('https://wesnapp.co.il' + '/api/account/get-business-account',data, {headers: {'Content-Type': 'application/json'}}).then((response) => {
        // console.log(response.data)    
        if (response.data.length > 0) {
            this.setState({isBusiness: true})
            this.setState({businessAccountId: response.data[0].AccountId})
            this.getBusinessAccountData(response.data[0].AccountId)
        }
    })
    .catch( (error) => {
      //console.log(error);
    });
  }

  getBusinessAccountData = (accountId) => {
    var data = {id: accountId}
    axios.post('https://wesnapp.co.il' + '/api/account/get-one',data, {headers: {'Content-Type': 'application/json'}}).then((response) => {
        console.log(response.data)
        var businessData = response.data[0]
        this.setState({
          businessData: businessData,
          bName: response.data[0].Name,
          companyVatNumber: businessData.Company_Number_VAT__c,
          companyNameForInvoice: businessData.Company_Name_for_Invoices__c,
          companyEmailForInvoice: businessData.Company_Email_for_Invoices__c,
          isLoading: false
        })    
    })
    .catch( (error) => {
      //console.log(error);
    });
  }

  getUserDetails = () => {

    // var data = {id: localStorage.getItem("accountId")}
    // axios.post('https://wesnapp.co.il' + '/api/account/get-one',data, {headers: {'Content-Type': 'application/json'}}).then((response) => {
    //     console.log(response.data[0]) 
    //     var accountData = response.data[0]
    //     this.setState({
    //         companyVatNumber: accountData.Company_Number_VAT__c,
    //         companyNameForInvoice: accountData.Company_Name_for_Invoices__c,
    //         companyEmailForInvoice: accountData.Company_Email_for_Invoices__c,
    //         isLoading: false
    //     })   
    // })
    // .catch( (error) => {
    //   //console.log(error);
    // });
  }

  validateEmail = (email) => {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  updateDetails = () => {
    console.log('asd')
    var allowToContinue = true
    if (allowToContinue) {
        this.setState({updating: true})
        var accountId = this.state.businessAccountId
        var data = {accountId: accountId, data: {
            Name: this.state.bName,
            Company_Email_for_Invoices__c: this.state.companyEmailForInvoice,
            Company_Name_for_Invoices__c: this.state.companyNameForInvoice,
            Company_Number_VAT__c: this.state.companyVatNumber
        }}
        axios.post('https://wesnapp.co.il' + '/api/account/update-account',data, {headers: {'Content-Type': 'application/json'}}).then((response) => {
            console.log(response.data) 
            this.setState({saveButtonText: 'Updated!', updating: false})
        })
        .catch( (error) => {
        //console.log(error);
        });
    }
  }

  handleChangeText = name => event => {
    if (name === 'phone') {
      this.setState({ [name]: event.target.value.replace('-', '') });
    } else {
      this.setState({ [name]: event.target.value });
    }
    this.setState({saveButtonText: 'Save'})
  };


  render() {
    const { classes, theme } = this.props;

    return (
        <div className={classes.root}>
            {/* {this.state.openAddNew && (
              <AddNewMember main={this} />
            )} */}
            <Grid container spacing={0} style={{padding: 30, paddingTop: 0}} justifyContent="center" justify="center">
                <Grid item xs={12} sm={12} md={11} xl={9}>
                    {this.state.isLoading === true && (
                        <CircularProgress style={{marginTop: 100}} />
                    )}
                </Grid>
                  <Grid item xs={12} sm={6} md={6} xl={4}>
                  {
                      (!this.state.isLoading) && (
                        <div>
                          <div style={{fontSize: 16, textAlign: 'left'}}>
                            You can edit and billing details here:
                          </div>
                          <div style={{marginTop: 20, display: 'inline-block', width: '100%'}}>
                            <TextField id="outlined-basic" label="Business name" fullWidth variant="outlined" onChange={this.handleChangeText('bName')} value={this.state.bName} />
                          </div>
                          <div style={{marginTop: 20, display: 'inline-block', width: '100%'}}>
                            <TextField id="outlined-basic" label="Company name for invoice" fullWidth variant="outlined" onChange={this.handleChangeText('companyNameForInvoice')} value={this.state.companyNameForInvoice} />
                          </div>
                          <div style={{marginTop: 20, display: 'inline-block', width: '100%'}}>
                            <TextField id="outlined-basic" label="Company number (VAT)" fullWidth variant="outlined" onChange={this.handleChangeText('companyVatNumber')} value={this.state.companyVatNumber} />
                          </div>
                          <div style={{marginTop: 20, display: 'inline-block', width: '100%'}}>
                            <TextField id="outlined-basic" label="Company email for invoices" fullWidth variant="outlined" onChange={this.handleChangeText('companyEmailForInvoice')} value={this.state.companyEmailForInvoice} />
                          </div>
                          <center>
                            {this.state.updating === false ? (
                                <Button
                                  variant="contained"
                                  color="primary"
                                  className={classes.button}
                                  style={{width: '40%', marginTop: 30}}
                                  onClick= {()=> this.updateDetails()}
                                >
                                  {this.state.saveButtonText}
                                </Button>
                            ) : (
                              <CircularProgress size={35} style={{marginTop: 10}}/>
                            )}
                          </center>
                        </div>
                      )
                    }
                  </Grid>
            </Grid>
        </div>
    );
  }
}

PhotoShoots.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(withRouter(PhotoShoots));
