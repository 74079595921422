import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios';
import { withRouter } from "react-router-dom";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import MembersTable from './MembersTable';
import AddNewMember from './AddNewMember';

var mobile = (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1)

const styles = theme => ({
  list: {
    width: 250
  },
  fullList: {
    width: 'auto',
  },
  root: {
    width: '100%',
    backgroundColor: '#6C65FF',
    backgroundColor: 'white'
  },
  button: {
    height: 50,
    marginTop: 15,
    // float: 'left',
    borderRadius: 25,
    paddingLeft: 32,
    paddingRight: 32,
    boxShadow: 'none',
    hover: {
      backgroundColor: '#33D7A0'
    }
  }
});

function createData(id, name, mobile, status, startAt, watchShootings, useOrders, canSelect, accounter) {
  return { id, name, mobile, status, startAt, watchShootings, useOrders, canSelect, accounter };
}

class PhotoShoots extends React.Component {

  state = {
    isLoading: false,
    openAddNew: false,
    tableData: null,
    bAccountId: '',
    requests: [],
    bAccountExists: null,
    bName: '',
    bEmail: '',
    errorBusinessName: false,
    errorBusinessEmail: false,
    creatingNewBusinessName: false,
    billingAddress: '',
    errorBillingAddress: false
  }

  componentDidMount() {
    this.getBusinessAccount()
  }

  componentWillMount() {

  }

  handleChangeText = name => event => {
    if (name === 'phone') {
      this.setState({ [name]: event.target.value.replace('-', '') });
    } else {
      this.setState({ [name]: event.target.value });
    }
  };

  getDateForSalesForce = () => {
    var dateObj = new Date();
    var m = String(dateObj.getDate())
    var d = String(dateObj.getMonth()+1)
    var y = String(dateObj.getFullYear())
    if (m.length == 1) {
      m = "0" + m
    }
    if (d.length == 1) {
      d = "0" + d
    }
    var newDate = y + "-" + d + "-" + m
    var newTime = String(dateObj.getHours() + ":" + dateObj.getMinutes())
    var dateAndTime = newDate + 'T' + newTime + ':00.000+0200'
    return dateAndTime
  }

  changeRelationshipStatus = (id, field, value) => {
    var data = {id: id}
    var dataToUpdate = {}
    if (field === 'watchShootings') {
      dataToUpdate['Watch_Team_Shootings__c'] = value
    } else if (field === 'useOrders') {
      dataToUpdate['Order_From_Business_Package__c'] = value
    } else if (field === 'canSelect') {
      dataToUpdate['Select_Images__c'] = value
    } else if (field === 'accounter') {
      dataToUpdate['Accounta__c'] = value
    } else if (field === 'active') {
      dataToUpdate['IsActive'] = value
    }

    if (field === 'active' && value === "false") {
      dataToUpdate['EndDate'] = this.getDateForSalesForce()
    }

    console.log(dataToUpdate)
    console.log(field)
    console.log(value)
    
    data.data = dataToUpdate
    console.log(data)
    axios.post('https://wesnapp.co.il' + '/api/account/update-relationship',data, {headers: {'Content-Type': 'application/json'}}).then((response) => {
        console.log(response.data)
        if (field === 'active') {
          this.getBusinessAccount()
        }
    })
    .catch( (error) => {
      //console.log(error);
    });
  }

  getBusinessAccount = () => {
    var contactId = localStorage.getItem("contactId")
    var data = {contactId: contactId}
    axios.post('https://wesnapp.co.il' + '/api/account/get-business-account',data, {headers: {'Content-Type': 'application/json'}}).then((response) => {
        console.log(response.data)    
        if (response.data.length > 0) {
          this.setState({bAccountExists: true})
          this.getAllRelatedAccounts(response.data[0].AccountId)
        } else {
          // Don't have business account
          this.setState({bAccountExists: false, bAccountId: '', tableData: []})
        }
    })
    .catch( (error) => {
      //console.log(error);
    });
  }

  getAllRelatedAccounts = (bAccountId) => {
    // var accountId = localStorage.getItem("accountId")
    var contactId = localStorage.getItem("contactId")
    this.setState({bAccountId: bAccountId})
    // console.log(accountId)
    // console.log(contactId)
    var data = {bAccountId: bAccountId}
    axios.post('https://wesnapp.co.il' + '/api/account/get-related-accounts',data, {headers: {'Content-Type': 'application/json'}}).then((response) => {
        console.log(response)
        var data = []
        var tableData = []
        response.data.forEach(element => {
          if (element.ContactId !== contactId) {
            var d = {
              id: element.Id,
              startDate: element.StartDate,
              contactName: element.Contact_Name__c,
              contactMobile: element.Contact_Mobile__c,
              adminManager: element.Admin_Manager__c,
              accountId: element.AccountId,
              contactId: element.ContactId,
              isActive: element.IsActive,
              accounter: element.Accounta__c,
              canUserOrders: element.Order_From_Business_Package__c,
              watchTeamShootings: element.Watch_Team_Shootings__c,
              canSelect: element.Select_Images__c,
              status: element.Relationship_Status__c,
            }
            data.push(d)
            tableData.push(createData(d.id, d.contactName, d.contactMobile, d.status, d.startDate, d.watchTeamShootings, d.canUserOrders, d.canSelect, d.accounter ))
          }
        });
        console.log(data)
        this.setState({tableData: tableData})
    })
    .catch( (error) => {
      //console.log(error);
    });
  }

  createNewBusinessAccount = () => {

    // Create new bAccount for the current person
    var canContinue = true
    if (this.state.bName.length < 2) {
      this.setState({errorBusinessName: true})
      canContinue = false
    }

    // if (this.state.billingAddress.length < 2) {
    //   this.setState({errorBillingAddress: true})
    //   canContinue = false
    // }

    if (canContinue) {
      this.setState({creatingNewBusinessName: true})
      var contactId = localStorage.getItem("contactId")
      // , billingAddress: this.state.billingAddress
      var data = {bName: this.state.bName, contactId: contactId}
      axios.post('https://wesnapp.co.il' + '/api/account/create-new-business-account',data, {headers: {'Content-Type': 'application/json'}}).then((response) => {
          console.log(response.data)
          this.setState({bAccountId: response.data, bAccountExists: true, tableData: null})
          this.getAllRelatedAccounts(response.data)
      })
      .catch( (error) => {
        //console.log(error);
      });
    }
  }

  render() {
    const { classes, theme } = this.props;

    return (
        <div className={classes.root}>
            {this.state.openAddNew && (
              <AddNewMember main={this} />
            )}
            <Grid container spacing={2} style={{padding: 30, paddingTop: 0}} justifyContent="center" justify="center">
                <Grid item xs={12} sm={12} md={11} xl={9}>
    
                    {this.state.bAccountExists && (
                      <div style={{fontSize: 24, verticalAlign: 'middle', color: 'rgb(138,137,142)', textAlign: 'left', fontFamily: "'Montserrat', sans-serif", marginTop: mobile ? 0 : 0, marginBottom: mobile ? 0 : 30}}>
                        <Button onClick={()=> this.setState({openAddNew: true})} style={{textTransform: 'none', display: 'inline-block', color: 'white', float: 'right', backgroundColor: '#33D7A0' , height: 40, borderRadius: 8, paddingLeft: 25, paddingRight: 25}}>+ Add new member</Button>
                      </div>
                    )}
                    {
                      (this.state.tableData !== null && this.state.bAccountExists === true) ? (
                        <MembersTable main={this} changeRelationshipStatus={this.changeRelationshipStatus} tableData={this.state.tableData}/>
                      ) : (
                        <div>
                          {this.state.tableData === null && (
                            <CircularProgress style={{marginTop: 100}} />
                          )}
                        </div>
                      )
                    }
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} xl={12}>
                  </Grid>
                  <Grid item xs={12} sm={6} md={5} xl={4}>
                  {
                      (this.state.bAccountExists === false && this.state.tableData !== null) && (
                        <div>
                          <div style={{fontSize: 16, textAlign: 'left'}}>
                            To add memebers to your account you need to define your business account:
                          </div>
                          <div style={{marginTop: 20, display: 'inline-block', width: '85%'}}>
                            <input className="regInput" placeholder="Enter your business name" onChange={this.handleChangeText('bName')} value={this.state.bName}/>
                          </div>
                          <div style={{height: 30}}>
                            {this.state.errorBusinessName && (
                              <div style={{color: 'red', fontSize: 15, fontFamily: "'Montserrat', sans-serif", marginTop: 8, width: '100%', float: 'left', textAlign: 'left'}}>Error business name</div>
                            )}
                          </div>
                          {/* <div style={{marginTop: -5, display: 'inline-block', width: '85%'}}>
                            <input className="regInput" placeholder="Enter your business address" onChange={this.handleChangeText('billingAddress')} value={this.state.billingAddress}/>
                          </div>
                          <div style={{height: 30}}>
                            {this.state.errorBillingAddress && (
                              <div style={{color: 'red', fontSize: 15, fontFamily: "'Montserrat', sans-serif", marginTop: 8, width: '100%', float: 'left', textAlign: 'left'}}>Error business address</div>
                            )}
                          </div> */}
                          <center>
                            {!this.state.creatingNewBusinessName ? (
                                <Button
                                  variant="contained"
                                  color="primary"
                                  className={classes.button}
                                  style={{width: '40%', marginTop: 10}}
                                  onClick= {()=> this.createNewBusinessAccount()}
                                >
                                  Create
                                </Button>
                            ) : (
                              <CircularProgress size={35} style={{marginTop: 10}}/>
                            )}
                          </center>
                        </div>
                      )
                    }
                  </Grid>
            </Grid>
        </div>
    );
  }
}

PhotoShoots.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(withRouter(PhotoShoots));
