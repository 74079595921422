import React, {useEffect, useState} from 'react';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import Lottie from 'react-lottie';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const GET_EDITED_ORGENIZED_IMAGES = 'https://py.wesnapp.co.il/server/get-edited-images-orgenized/'
const GET_ANGEL_IMAGES = 'https://py.wesnapp.co.il/server/get-angel-images/';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const DevelopmentGallery = () => {

    const {id} = useParams()

    const [editedImages, setEditedImages] = useState({imagesForGallery: []})
    const [galleryIndex, setGalleryIndex] = useState(0)
    const [galleryStatus, setGalleryStatus] = useState(false)
    const [galleryImages, setGalleryImages] = useState([])

    useEffect(() => {
        getEditedImages(id)
    }, [])

    const getEditedImages = async (requestId) => {
        const response = await axios.get(GET_ANGEL_IMAGES + requestId + '',{}, {headers: {'Content-Type': 'application/json'}});
        var data = response.data
        let images = data.images_infused
        var imagesForGallery = []
        for (var i = 0; i < images.compressed.length; i++) {
            imagesForGallery.push({
                src: images.compressed[i],
                thumbnail: images.thumbnail[i],
                thumbnailWidth:  320,
                thumbnailHeight: 174,
                isSelected: false,
                caption: "Image " + String(i)
            })
        }
        data['imagesForGallery'] = imagesForGallery
        setEditedImages(data)
        setGalleryImages(imagesForGallery)
    }

    const openGallery = (index) => {
        setGalleryIndex(index)
        setGalleryStatus(true);
    }

    return (
        <div style={{padding: 12, textAlign: 'left', overflow: 'scroll', height: '100vh'}}>
            {(galleryStatus && galleryImages.length > 0) && (
                <Lightbox
                    mainSrc={galleryImages[galleryIndex].src}
                    nextSrc={galleryImages[(galleryIndex + 1) % galleryImages.length].src}
                    prevSrc={galleryImages[(galleryIndex + galleryImages.length - 1) % galleryImages.length].src}
                    onCloseRequest={() => setGalleryStatus(false)}
                    onMovePrevRequest={() =>
                        {
                            console.log('galleryImages', galleryImages);
                            console.log('change index', galleryIndex);
                            console.log('new index', (galleryIndex + galleryImages.length - 1) % galleryImages.length);
                            setGalleryIndex((galleryIndex + galleryImages.length - 1) % galleryImages.length);
                        }
                    }
                    onMoveNextRequest={() =>
                        {
                            console.log('galleryImages', galleryImages);
                            console.log('change index', galleryIndex);
                            console.log('new index', (galleryIndex + 1) % galleryImages.length);
                            setGalleryIndex((galleryIndex + 1) % galleryImages.length)
                        }
                    }
                />
            )}
            <Grid container spacing={0} dir="ltr" justify="center">
                <Grid item xs={12} sm={10} md={8} xl={7}>
                    <Grid container spacing={4} dir="ltr" justify="center">
                        {editedImages.imagesForGallery.map((item, index) => (
                            <Grid item xs={12} sm={12} md={4} xl={3}>
                                <img onClick={()=> openGallery(index)} alt='' key={index} src={item.src} style={{width: '100%', height: 280, borderRadius: 12, objectFit: 'cover', cursor: 'pointer'}}/>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}
 
export default DevelopmentGallery;
// import React from 'react';
// const Test = () => {
//     return ( <div></div> );
// }
 
// export default Test;