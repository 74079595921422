import { CircularProgress, Container } from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import ReactPlayer from 'react-player'
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.primary.background,
    paddingTop: 24, paddingBottom: 24
  }
}));

const VideoComponent = (props) => {

    const classes = useStyles();
    const [videoLink, setVideoLink] = useState(null)

    useEffect(() => {
        let id = props.match.params.id
        getVideoData(id)
    }, [])

    const getVideoData = async (id) => {
        // let response = await axios.get('http://localhost:8080/api/video/' + id)
        let response = await axios.get('https://wesnapp.co.il/api/video/data/' + id)
        console.log('response', response)
        let videoLink = response.data[0].Video_Link__c
        console.log('videoLink', videoLink)
        setVideoLink(videoLink)
    }

    return (
        <div className={classes.root}>
            <Container maxWidth={'md'}>
                <img src={require('../../Assets/logo.png')} height='42px' alt='' />
                <div style={{marginTop: 24, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    {videoLink ? (
                        <ReactPlayer controls={true} url={videoLink} />
                    ) : (
                        <CircularProgress />
                    )}
                </div>
            </Container>
        </div>
    );
}
 
export default VideoComponent;