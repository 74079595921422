import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide(props) {
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    props.main.setState({openAlternativeDialog: false})
  };

  return (
    <div>
      <Dialog
        open={props.main.state.openAlternativeDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        {/* <DialogTitle id="alert-dialog-slide-title">{"Use Google's location service?"}</DialogTitle> */}
        <DialogContent>
          <div style={{width: '100%', textAlign: 'center'}}>
            <img alt='' src={require('../../../Assets/happyIcon.png')} width={60} height={60} />
          </div>
          <DialogContentText id="alert-dialog-slide-description">
            <div style={{width: '100%', textAlign: 'center', fontSize: 20, marginTop: 24}}>Thanks!<br/>We got your alternative hours. We will be in touch soon!</div>
          </DialogContentText>
        </DialogContent>
        {/* <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions> */}
      </Dialog>
    </div>
  );
}
