import React, {useEffect, useState, useContext} from 'react';
import Grid from '@material-ui/core/Grid';
import {makeStyles} from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import axios from 'axios';
import { setDate } from 'date-fns';
import Step1 from './Step1.js'
import Step2 from './Step2.js'
import Step3 from './Step3.js'
import moment from 'moment';
import { AuthContext } from '../../../contexts/Auth'
import history from '../../../utils/history'

const mobile = (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1)

const useStyles = makeStyles((theme) => ({
    backToAppButton: {
        zIndex: 999,
        position: 'fixed',
        top: 24,
        right: 24,
        height: 52, 
        textTransform: 'none',
        width: '100%',
        borderRadius: 26,
        // backgroundColor: theme.palette.primary.main,
        color: 'white',
        fontWeight: 'bold',
        textTransform: 'none',
        fontSize: mobile ? 16 : 18,
        boxShadow: '0px 5px 20px 0px rgba(59,102,246,0.4)',
        width: 180
    }
}))

export default function Order(props) {

    const classes = useStyles();

    const { isLoggedIn, accountData } = useContext(AuthContext)

    const [step, setStep] = useState(isLoggedIn ? 2 : 1)
    // const [step, setStep] = useState(3)
    const [phone, setPhone] = useState('')
    // const [phone, setPhone] = useState('0507864614')
    const [generatedCode, setGeneratedCode] = useState('')
    const [location, setLocation] = useState(null)
    const [datetime, setDatetime] = useState(null)
    const [email, setEmail] = useState('')
    const [fullname, setFullname] = useState('')
    
    const [code, setCode] = useState('')
    // const [code, setCode] = useState('1234')
    const [countryCode, setCountryCode] = useState('972')
    const [errorPhone, setErrorPhone] = useState('')
    const [errorCode, setErrorCode] = useState('')
    const [validCode, setValidCode] = useState(false)
    const [showCode, setShowCode] = useState(false)
    const [loading, setLoading] = useState(false)
    const [errorProduct, setErrorProduct] = useState(false)

    const [isNewUser, setIsNewUser] = useState(false)

    const [accountId, setAccountId] = useState(null)
    const [contactId, setContactId] = useState(null)
    const [requestId, setRequestId] = useState(null)
    const [orderId, setOrderId] = useState(null)

    const [products, setProducts] = useState([])
    const [productsToShow, setProductsToShow] = useState([])
    const [openPackages, setOpenPackages] = useState([])
    const [productsFromPackages, setProductsFromPackages] = useState([])

    const [selectedProduct, setSelectedProduct] = useState({id: ''})

    // const [selectedProduct, setSelectedProduct] = useState({id: ''})

    const [showMore, setShowMore] = useState(false)

    const [errorFullname, setErrorFullname] = useState(false)
    const [errorEmail, setErrorEmail] = useState(false)
    const [errorDateTime, setErrorDateTime] = useState(false)
    const [errorLocation, setErrorLocation] = useState(false)

    const [startMatching, setStartMatching] = useState(true)

    const [companyNameForInvoice, setCompanyNameForInvoice] = useState('')

    const [lat, setLat] = useState('')
    const [lng, setLng] = useState('')

    const [couponType, setCouponType] = useState('')
    const [couponCode, setCouponCode] = useState('')
    const [couponAmount, setCouponAmount] = useState(0)
    const [couponIsValid, setCouponIsValid] = useState(false)
    const [checkingCoupon, setCheckingCoupon] = useState(false)
    const [canApply, setCanApply] = useState(false)
    const [errorCoupon, setErrorCoupon] = useState(false)
    const [errorCouponReason, setErrorCouponReason] = useState('')
    const [couponResultText, setCouponResultText] = useState('')

    const [paymentURL, setPaymentURL] = useState(null)

    const [tab, setTab] = React.useState(0);

    const [connectToBusiness, setConnectToBusiness] = React.useState(false);
    const [businessAccountId, setBusinessAccountId] = React.useState(null);

    const [isPackage, setIsPackage] = React.useState(false);
    const [paymentData, setPaymentData] = React.useState(null);

    const [paymentType, setPaymentType] = React.useState(0);

    const [isAdmin, setIsAdmin] = React.useState(false);

    const getParameterByName = (name) => {
        var url = window.location.href;
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    useEffect(() => {
        if (props.landingPage) {
            var admin = getParameterByName('admin')
            if (admin != null && admin != undefined && admin != "") {
                if (admin === "wesnapp2020") {
                    setIsAdmin(true)
                    var userPhone = getParameterByName('mobile')
                    if (userPhone != null && userPhone != undefined && userPhone != "") {
                        setPhone(userPhone)
                    }
                }
            }
        }
    }, [])

    useEffect(() => {
        if (isLoggedIn) {
            setStep(2)
        }
    }, [])

    const getDataFromLoggedInAccount = async () => {
        if (accountData !== null) {
            setPhone(accountData.Mobile_Number__c)
            setEmail(accountData.PersonEmail)
            setFullname(accountData.Name)

            checkForOpenPackages(accountData.Id)

            setAccountId(accountData.Id)

            setEmail(accountData.PersonEmail)
            setFullname(accountData.Name)

            if (accountData.Company_Name_for_Invoices__c !== null && accountData.Company_Name_for_Invoices__c !== '') {
                setCompanyNameForInvoice(accountData.Company_Name_for_Invoices__c)
            }

            let specialProductsResponse = await axios.post('https://wesnapp.co.il' + '/api/product/get-special', {accountId: accountData.Id}, {headers: {'Content-Type': 'application/json'}})
            var specialProducts = []
            specialProductsResponse.data.forEach(product => {
                if (product.Active__c) {
                    specialProducts.push(product.WS_Product__c)
                }
            });
            // Get public products
            axios.post('https://wesnapp.co.il' + '/api/product/get-public',{id: accountData.Id}, {headers: {'Content-Type': 'application/json'}}).then((response) => {
            console.log('response.data', response.data)    
            if (response.data.length > 0) {
                    var publicProducts = []
                    response.data.forEach(product => {
                        publicProducts.push(product.Id)
                    });
                    getProducts([...specialProducts, ...publicProducts])
                } else {
                    alert('No products availables')
                }
            });
        }
    }

    useEffect(() => {
        getDataFromLoggedInAccount()
    }, [accountData])
    
    useEffect(() => {
        if (location === null) return
        getCoordinates(location.description)
    }, [location])

    useEffect(() => {
        if (selectedProduct.Id === '') return
        setErrorProduct(false)
    }, [selectedProduct])

    useEffect(() => {
        let test = async () => {
            if (step === 3) {
                history.push(window.location.pathname + '/completed')
                let url = await getPaymentURL()
                setPaymentURL(url)
            }
        }
        test()
    }, [step, paymentType])

    useEffect(() => {
        let test = async () => {
            if (couponIsValid && step === 3) {
                let url = await getPaymentURL()
                setPaymentURL(url)
            } else if (!couponIsValid && step === 3) {
                let url = await getPaymentURL()
                setPaymentURL(url)
            }
        }
        test()
    }, [couponIsValid, paymentType])

    useEffect(() => {
    }, [productsToShow])

    useEffect(() => {
        var tempProducts = []
        if (tab === 0) {
            // products
            for (let index = 0; index < products.length; index++) {
                const product = products[index];
                if (product.Product_Type__c === 'Single') {tempProducts.push(product)}
            }
        } else if (tab === 1) {
            // packages
            for (let index = 0; index < products.length; index++) {
                const product = products[index];
                if (product.Product_Type__c === 'Package') {tempProducts.push(product)}
            }
        }
        if (showMore) {
            setProductsToShow(tempProducts)
        } else {
            setProductsToShow(mobile ? tempProducts.slice(0, 3) : tempProducts.slice(0, 6))
        }
    }, [showMore, products, tab])

    const handleChangeSwitch = (event) => {
        if (event.target.name === 'connectToBusiness') {
            setConnectToBusiness(event.target.checked)
        }
        if (event.target.name === 'startMatching') {
            setStartMatching(event.target.checked)
        }
    };

    const validateEmail = () => {
        var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    const validateFullname = () => {
        if (fullname.split(' ').length < 2) return false; else return true
    }

    const validateDate = () => {
        if (datetime === null) return false;
        if (new Date(datetime).getTime() <= new Date().getTime) return false;
        return true;
    }

    const completeOrder = () => {
        // Check data
        console.log('complete')
        let canComplete = true
        if (isNewUser) {
            if (!validateEmail()) {
                console.log('error email')
                canComplete = false
                setErrorEmail(true)
            }
            if (!validateFullname()) {
                console.log('error fullname')
                canComplete = false
                setErrorFullname(true)
            }
            if (selectedProduct.Id === '') {
                console.log('error product')
                canComplete = false
                setErrorProduct(true)
            }
            if (!validateDate()) {
                console.log('error date')
                canComplete = false
                setErrorDateTime(true)
            }
            if (location === null || errorLocation === true) {
                console.log('error location')
                setErrorLocation(true)
                canComplete = false
            }
            console.log('can complete', canComplete)
            console.log(email)
            console.log(fullname)
            if (!canComplete) return
            //
            createRequest()
        } else {    
            if (selectedProduct.Id === '') {
                console.log('error product')
                canComplete = false
                setErrorProduct(true)
            }
            if (!validateDate()) {
                console.log('error date')
                canComplete = false
                setErrorDateTime(true)
            }
            if (location === null || errorLocation === true) {
                console.log('error location')
                setErrorLocation(true)
                canComplete = false
            }
            console.log('can complete', canComplete)
            if (!canComplete) return
            //
            createRequest()
        }
    
    }

    const getCoordinates = async (address) => {
        try {
          var link = "https://maps.googleapis.com/maps/api/geocode/json?address=" + encodeURI(address) + "&key=AIzaSyAaiPKKuOKyFcl_Hed_xF_1Jx9oQiKJu_s"
          const response = await axios.get(link);
          console.log(response.data.results[0]);
          if (response.data.results.length > 0) {
            if (response.data.results[0].types.includes('street_address') || response.data.results[0].types.includes('premise')) {
              console.log("yes");
              setLat(response.data.results[0].geometry.location.lat)
              setLng(response.data.results[0].geometry.location.lng)
              setErrorLocation(false)
            } else {
              console.log("no");
              console.log(response.data.results[0]);
              setErrorLocation(true)
            }
          } else {
            setErrorLocation(true)
        }
        } catch (err) {
            setErrorLocation(true)
        }
    }

    const getPackageFromSingleProduct = (productId) => {
        console.log(productId.slice(0, productId.length - 3))
        for (let index = 0; index < openPackages.length; index++) {
            let pack = openPackages[index];
            console.log(pack.singleProductId)
            if (pack.singleProductId === productId.slice(0, productId.length - 3)) {
                return pack
            }
        }
        return 'error'
    }
    
    const createRequest = async () => {
        setLoading(true)
        var address = location.description.split(", ")
        var street = address[0]
        var city = address[1]
        var country = address[2]
        var nameForInvoice = companyNameForInvoice
        if (nameForInvoice == '') { nameForInvoice = fullname }
        let firstName = fullname.split(' ')[0]
        let lastName = fullname.split(' ')[1]
        if (isNewUser) {
            // Update new user details
            await axios.post( 'https://wesnapp.co.il' + '/api/contact/update-contact',{id: accountId, data: { firstName: firstName, lastName: lastName, PersonEmail: email} }, {headers: {'Content-Type': 'application/json'}})
        }
        var data = {
          "firstName": firstName,
          "lastName": lastName,
          "mobile": phone,
          "email": email,
          "selectedProduct": selectedProduct.Id,
          "reference": 'dashboard',
          "datetime": datetime,
        //   ':00.000+0200',
          "address": location.description,
          "country": country,
          "whoWillBeOnSiteName": fullname,
          "whoWillBeOnSitePhone": phone,
          "city": city,
          "street": street,
          "officeName": nameForInvoice,
          "lng": lng.toString(),
          "lat": lat.toString(),
          "isPackage": isPackage,
          "connectToBusiness": connectToBusiness,
          "businessAccountId": businessAccountId,
          "useNow": true,
          "onlyOrder": selectedProduct.Product_Type__c === 'Package'
        }
        // if (admin) {
        //   data.reference = 'team'
        // }אני
        if (selectedProduct.Product_Type__c === 'Package') {
          data['singleProduct'] = selectedProduct.Link_to_Single_Product__c
        }
        if (isPackage) {
            data['selectedPackage'] = getPackageFromSingleProduct(selectedProduct.Id)
        }
        console.log(data);
        let url = 'https://wesnapp.co.il'
        // let url = 'http://localhost:8081'
        axios.post( url + '/api/action/create-order-new',data, {headers: {'Content-Type': 'application/json'}}).then((response) => {
            setLoading(false)
            console.log(response.data);
            setAccountId(response.data.accountId)
            setContactId(response.data.contactId)
            setOrderId(response.data.orderId)
            setRequestId(response.data.requestId)
            setStep(3)
            if (startMatching) {
                startMatchingAlgorithm(response.data.requestId)
            }
          })
        .catch( (error) => {
        });
    }

    const startMatchingAlgorithm = (requestId) => {
        var data = {requestId: requestId}
        axios.post('https://us-central1-wesnapp-d136a.cloudfunctions.net/startMatching',data, {headers: {'Content-Type': 'application/json'}}).then((response) => {
        })
        .catch( (error) => {
        });
    }

    const handleChangeRadioButton = (e, product) => {
        if (e.target.value) {
            setSelectedProduct(product)
        } else {
            setSelectedProduct({id: ''}) 
        }
    }

    const handleChangePhone = (event) => {
        setErrorPhone(false)
        setPhone(event.target.value);
        if (showCode) {
            setShowCode(false)
            setErrorCode(false)
            setLoading(false)
            setGeneratedCode('')
        }
    };  

    const handleChangeCode = (event) => {
        setErrorCode(false)
        setCode(event.target.value);
    };

    const reverseString = (str) => {
        var splitString = str.split("");    
        var reverseArray = splitString.reverse();   
        var joinArray = reverseArray.join(""); 
        return joinArray;
    }

    const validateCode = () => {
        console.log(code)
        console.log(generatedCode)
        if (String(code) === String(generatedCode) || String(generatedCode) === reverseString(String(code))) {
            setErrorCode(false)
            findClientUsingPhoneNumber()
        } else {
            setErrorCode(true)
        }
    }

    const generateRandomCode = () => {
        var code = Math.floor(1000 + Math.random() * 9000)
        return code
    }
    
    const sendVerificationCode = (theGeneratedCode) => {
        var data = {
          "phone": ("+" + countryCode + phone),
          "text": 'WeSnapp verification code: ' + theGeneratedCode
        }
        axios.post('https://wesnapp.co.il' + '/api/sms/send-message',data, {headers: {'Content-Type': 'application/json'}}).then((response) => {
            setLoading(false)
            setShowCode(true)
        })
        .catch( (error) => {
        });
    }

    const getProductsFromPackages = (products) => {
        if (products.length === 0) return
        var data = {"products": products}
        axios.post('https://wesnapp.co.il' + '/api/product/get-many',data, {headers: {'Content-Type': 'application/json'}}).then((response) => {
            if (response.data.length > 0) {
              setProductsFromPackages(response.data)
            }
        })
        .catch( (error) => {
        });
    }

    const handleFullname = (event) => {
        setErrorFullname(false)
        setFullname(event.target.value);
    };

    const handleEmail = (event) => {
        setErrorEmail(false)
        setEmail(event.target.value);
    };

    const handleDateTime = (event) => {
        setErrorDateTime(false)
        // setDatetime(event.target.value);
        setDatetime(moment(event).format());
    };

    const checkForOpenPackages = (clientAccountId) => {
        var data = { "id": clientAccountId}
        axios.post('https://wesnapp.co.il' + '/api/order/get-open-orders',data, {headers: {'Content-Type': 'application/json'}}).then((response) => {
          var products = []
          for (var i = 0; i < response.data.length; i++) {
            products.push(response.data[i].singleProductId)
          }
          setOpenPackages(response.data)
          console.log('open packages', response.data)
          getProductsFromPackages(products, response.data)
        })
        .catch( (error) => {
        });
    }

    const checkIfBusinessExists = (contactIdToCheck) => {
        var data = {contactId: contactIdToCheck}
        axios.post('https://wesnapp.co.il' + '/api/account/get-business-account',data, {headers: {'Content-Type': 'application/json'}}).then((response) => {
            console.log(response.data)    
            if (response.data.length > 0) {
                setBusinessAccountId(response.data[0].AccountId)
            } else {
              // Don't have business account
            }
        })
        .catch( (error) => {
          //console.log(error);
        });
    }
    
    const findClientUsingPhoneNumber = async () => {
        setLoading(true)
        var data = {"mobile": phone}
        axios.post('https://wesnapp.co.il' + '/api/account/find-person-account-by-mobile',data, {headers: {'Content-Type': 'application/json'}}).then(async (response) => {
            console.log(response);
            var accounts = response.data
            if (accounts.length !== 0) {
                var accountData = accounts[0]
                console.log('accountData', accountData)
                checkForOpenPackages(accountData.Id)

                setAccountId(accountData.Id)

                setEmail(accountData.PersonEmail)
                setFullname(accountData.Name)
                if (accountData.Company_Name_for_Invoices__c !== null && accountData.Company_Name_for_Invoices__c !== '') {
                    setCompanyNameForInvoice(accountData.Company_Name_for_Invoices__c)
                }

                let specialProductsResponse = await axios.post('https://wesnapp.co.il' + '/api/product/get-special', {accountId: accountData.Id}, {headers: {'Content-Type': 'application/json'}})
                var specialProducts = []
                specialProductsResponse.data.forEach(product => {
                  if (product.Active__c) {
                    specialProducts.push(product.WS_Product__c)
                  }
                });
                // Get public products
                axios.post('https://wesnapp.co.il' + '/api/product/get-public',data, {headers: {'Content-Type': 'application/json'}}).then((response) => {
                    console.log('response.data', response.data)
                    if (response.data.length > 0) {
                        var publicProducts = []
                        response.data.forEach(product => {
                            publicProducts.push(product.Id)
                        });
                        getProducts([...specialProducts, ...publicProducts])
                    } else {
                        alert('No products availables')
                    }
                });

                setStep(2)
                setLoading(false)
            } else {
              // Create new client 
              setIsNewUser(true)
              var accountData = {mobile: phone, lastName: 'temp', firstName: 'temp', email: 'temp@wesnapp.io'}
              axios.post('https://wesnapp.co.il' + '/api/account/create-new-client-person-account',accountData, {headers: {'Content-Type': 'application/json'}}).then((response) => {
                console.log('new account data') 
                console.log(response.data)   
                setAccountId(response.data.accountId)
                setContactId(response.data.contactId)
                setStep(2)
                setLoading(false)
              })
              // Get public products
              axios.post('https://wesnapp.co.il' + '/api/product/get-public',data, {headers: {'Content-Type': 'application/json'}}).then((response) => {
                console.log('response.data', response.data)    
                if (response.data.length > 0) {
                  var publicProducts = []
                  response.data.forEach(product => {
                    publicProducts.push(product.Id)
                  });
                  getProducts(publicProducts)
                } else {
                    alert('No products availables')
                }
              });
            }
        })
        .catch( (error) => {
        });
    }

    const getProducts = (products) => {
        var data = {"products": products}
        axios.post('https://wesnapp.co.il' + '/api/product/get-many',data, {headers: {'Content-Type': 'application/json'}}).then((response) => {
            if (response.data.length > 0) {
              var products = response.data
              products.sort((a, b) => b.Total_Cost__c - a.Total_Cost__c);
              products.reverse()
              setProducts(products)
            }
        })
        .catch( (error) => {
          console.log(error);
        });
    }
    
    const checkIfPhoneIsValid = () => {
        let substring1 = " 9725"
        let substring2 = " 97205"
        let tempPhone = phone
        if (tempPhone.includes(substring1) || tempPhone.includes(substring2)) {
            if (tempPhone.indexOf(substring1) !== -1) {
            tempPhone = tempPhone.replace(" 9725", "05");
            tempPhone = tempPhone.replace("-", "");
            if (tempPhone[0] == "0" && tempPhone[1] == "5" && (tempPhone[2] == "2" || tempPhone[2] == "3" || tempPhone[2] == "0" || tempPhone[2] == "4" || tempPhone[2] == "5" || tempPhone[2] == "8")) {
                if (tempPhone.length == 10 && (/^\d+$/.test(tempPhone))) {
                setPhone(tempPhone)
                return true
                }
            } else {
                return false;
            }
            } else {
            tempPhone = tempPhone.replace(" 97205", "05");
            tempPhone = tempPhone.replace("-", "");
            if (tempPhone[0] == "0" && tempPhone[1] == "5" && (tempPhone[2] == "2" || tempPhone[2] == "3" || tempPhone[2] == "0" || tempPhone[2] == "4" || tempPhone[2] == "5" || tempPhone[2] == "8")) {
                if (tempPhone.length == 10 && (/^\d+$/.test(tempPhone))) {
                setPhone(tempPhone)
                return true
                }
            } else {
                return false;
            }
            }
        } else {
            tempPhone = tempPhone.replace("-", "");
            if (tempPhone[0] == "0" && tempPhone[1] == "5" && (tempPhone[2] == "2" || tempPhone[2] == "3" || tempPhone[2] == "0" || tempPhone[2] == "4" || tempPhone[2] == "5" || tempPhone[2] == "8")) {
            if (tempPhone.length == 10 && (/^\d+$/.test(tempPhone))) {
                setPhone(tempPhone)
                return true
            }
            } else {
            return false;
            }
        }
    }

    const validatePhone = () => {
        if (checkIfPhoneIsValid()) {
            setErrorPhone(false)
            // check if admin
            if (isAdmin) {
                findClientUsingPhoneNumber()
                return
            }
            // real
            let theGeneratedCode = generateRandomCode()
            console.log(theGeneratedCode)
            setGeneratedCode(theGeneratedCode)
            setLoading(true)
            sendVerificationCode(theGeneratedCode)
            // test
            // let theGeneratedCode = '1234'
            // setGeneratedCode(theGeneratedCode)
            // setLoading(false)
            // setShowCode(true)
        } else {
            setErrorPhone(true)
        }
    }

    const getTotalCost = () => {
        let toPayFor = selectedProduct.Total_Cost__c
        let total = 0
        if (couponIsValid) {
          if (couponType === 'cash') {
            total = toPayFor - couponAmount
          } else {
            total = (toPayFor * ((100 - couponAmount) / 100))
          }
        } else {
          total = toPayFor
        }
        return (total * 1.17).toFixed(2)
    }
    
    const getPaymentURL = async () => {

        var maxpay = "2"
        var sum = getTotalCost()
        var cred_type = null
        var paymentFor = null
        if (paymentType === 0) {
          cred_type = "1"
        } else {
          cred_type = "8"
        }
        if (sum >= 1000 && sum < 2000) {
          maxpay = "2"
        } else if (sum >= 2000 && sum < 3000) {
          maxpay = "3"
        } else if (sum >= 3000 && sum < 4000) {
          maxpay = "4"
        } if (sum >= 5000) {
          maxpay = "5"
        }

        if (selectedProduct.Product_Type__c === 'Single') {
            paymentFor = 1
        } else if (selectedProduct.Product_Type__c === 'Package') {
            paymentFor = 5
        }

        var link = "https://wesnapp.co.il/api/payment/get-payment-url"
        let data = {
          // Account 
          contactId: contactId,
          accountId: accountId,
          // Product
          productId: selectedProduct.Id,
          // Request and Order
          requestId: requestId,
          orderId: orderId,
          // Amount
          productTotalAmount: (selectedProduct.Total_Cost__c * 1.17).toFixed(2), // Before coupon discount
          totalAmountToPay: getTotalCost(),
          // Coupon
          couponCode: couponCode,
          couponType: couponType,
          couponAmount: couponAmount,
          // Payment For
          paymentFor: paymentFor,

          // paymentType
          paymentType: cred_type,
          maxPayments: maxpay,
          saveToken: true
          
        }
        setPaymentData(data)
        console.log('data', data)
        const response = await axios.post(link, data, {headers: {'Content-Type': 'application/json'}})
        console.log('response', response)
        setPaymentURL(response.data)
        return response.data
    }

    const checkIfCouponIsValid = () => {
        setCouponIsValid(false)
        setCheckingCoupon(true)
        var data = { coupon: couponCode, accountId: accountId, requestId: requestId, productId: selectedProduct.Id }
        axios.post("https://wesnapp.co.il/api/coupons/check-coupon",data, {headers: {'Content-Type': 'application/json'}}).then((response) => {
            console.log('coupon', response.data);
            if (response.data.allowed) {
              let type = ''
              let amount = 0
              if (response.data.discountAmount !== null) {
                type = 'cash'
                amount = response.data.discountAmount
              } else {
                type = 'percentages'
                amount = response.data.amount
              } 
              setCheckingCoupon(false)
              setCouponType(type)
              setCanApply(true)
              setErrorCoupon(false)
              setCouponResultText('Coupon is valid')
              setCouponAmount(amount)
              setTimeout(() => {
                setCouponIsValid(true)
              }, 500);
            } else {
              setCouponIsValid(false)
              setCouponType('')
              setCouponAmount(0)
              setCheckingCoupon(false)
              setCanApply(false)
              setErrorCoupon(true)
              setCouponResultText(response.data.reason)
              setTimeout(() => {
                setCouponIsValid(false)
              }, 500);
            }
        })
        .catch( (error) => {
          console.log(error);
        });
    }
    
    const handleChangeCoupon = (event) => {
        let newValue = event.target.value
        if (newValue === '') {
          setErrorCoupon(false)
          setCouponResultText('')
          setCouponResultText('')
          setCouponCode(newValue)
          setCanApply(false)
        } else {
          setCouponCode(newValue)
          setCanApply(true)
        }
    };
    
    const applyCoupon = () => {
    
    }

    const removeCoupon = () => {
        setCouponIsValid(false)
        setCouponType('')
        setCouponResultText('')
        setCouponAmount(0)
        setCheckingCoupon(false)
        setCanApply(false)
        setErrorCoupon(false)
        setCouponResultText('')
        setCouponCode('')
    }

    const getPriceAfterCouponBeforeVat = () => {
        let toPayFor = selectedProduct.Total_Cost__c
        let total = 0
        if (couponIsValid) {
          if (couponType === 'cash') {
            total = toPayFor - couponAmount
          } else {
            total = (toPayFor * ((100 - couponAmount) / 100))
          }
        } else {
          total = toPayFor
        }
        return total.toFixed(2)
    }

    return (
        <>
            <div style={{width: '100%', paddingTop: 0}}>
                <div style={{height: mobile ? 100 : 120, color: 'white', marginTop: 32, backgroundColor: '#6334FF', width: '100%', padding: '32px 16px', display: 'flex', marginBottom: 12, alignItems: 'center', justifyContent: 'space-between', width: '100%', boxShadow: '0px 4px 10px #6334FF10', borderRadius: 15}}>
                    <div style={{fontSize: 24, fontWeight: 'bold'}}>New Order</div>
                </div>
                {(mobile && props.landingPage) && (
                    <div style={{width: 30, maxWidth: 30, padding: 0, height: 30, borderRadius: 15, position: 'fixed', top: 12.5, left: 24, cursor: 'pointer'}} onClick={()=> {history.push('/')}}><CloseRoundedIcon style={{width: 26, height: 26, marginTop: 2, color: '#4A4A4A'}} /></div>
                )}
                {(!mobile && props.landingPage) && (
                    <img src={require('../../../Assets/logo.png')} width='auto' height='30px' alt='' style={{position: 'fixed', top: 39, left: 26, zIndex: 999}}/>
                )}
                {(!mobile && props.landingPage) && (
                    <Button
                        variant="contained"
                        onClick={()=> {history.push('/'); window.location.reload()}}
                        className={classes.backToAppButton}
                    >
                        Back to App
                    </Button>
                )}
                <Grid container spacing={1} dir="ltr" justify="center">
                    {step === 1 && (
                        <Step1
                            errorPhone={errorPhone}
                            validCode={validCode} 
                            showCode={showCode} 
                            handleChangeCode={handleChangeCode} 
                            validateCode={validateCode} 
                            validatePhone={validatePhone}
                            validCode={validCode} 
                            loading={loading}
                            handleChangePhone={handleChangePhone}
                            errorCode={errorCode}
                            landingPage={props.landingPage}
                            phone={phone}
                        />
                    )}
                    {step === 2 && (
                        <Step2
                            errorFullname={errorFullname} 
                            handleFullname={handleFullname} 
                            isNewUser={isNewUser} 
                            errorEmail={errorEmail} 
                            handleEmail={handleEmail}
                            handleDateTime={handleDateTime} 
                            setErrorLocation={setErrorLocation}
                            setLocation={setLocation}
                            errorLocation={errorLocation}
                            productsToShow={productsToShow}
                            selectedProduct={selectedProduct}
                            handleChangeRadioButton={handleChangeRadioButton}
                            showMore={showMore}
                            products={products}
                            setShowMore={setShowMore}
                            errorProduct={errorProduct}
                            completeOrder={completeOrder}
                            couponAmount={couponAmount}
                            errorDateTime={errorDateTime} 
                            errorCoupon={errorCoupon}
                            handleChangeCoupon={handleChangeCoupon}
                            setSelectedProduct={setSelectedProduct}
                            setStartMatching={setStartMatching}
                            startMatching={startMatching}
                            tab={tab}
                            isAdmin={isAdmin}
                            setTab={setTab}
                            connectToBusiness={connectToBusiness}
                            handleChangeSwitch={handleChangeSwitch}
                            businessAccountId={businessAccountId}
                            productsFromPackages={productsFromPackages}
                            openPackages={openPackages}
                            isPackage={isPackage}
                            setIsPackage={setIsPackage}
                            loading={loading}
                            landingPage={props.landingPage}
                        />
                    )}
                    {step === 3 && (
                        <Step3
                            selectedProduct={selectedProduct} 
                            datetime={datetime} 
                            location={location} 
                            checkingCoupon={checkingCoupon} 
                            checkIfCouponIsValid={checkIfCouponIsValid}
                            canApply={canApply} 
                            errorDateTime={errorDateTime} 
                            couponType={couponType}
                            couponIsValid={couponIsValid}
                            handleChangeCoupon={handleChangeCoupon}
                            paymentData={paymentData}
                            loading={loading}
                            paymentURL={paymentURL}
                            couponResultText={couponResultText}
                            errorCouponReason={errorCouponReason}
                            errorCoupon={errorCoupon}
                            isPackage={isPackage}
                            history={history}
                            couponAmount={couponAmount}
                            paymentType={paymentType}
                            setPaymentType={setPaymentType}
                            getTotalCost={getTotalCost}
                            removeCoupon={removeCoupon}
                            getPriceAfterCouponBeforeVat={getPriceAfterCouponBeforeVat}
                            couponCode={couponCode}
                            landingPage={props.landingPage}
                        />
                    )}
                </Grid>
            </div>
        </>
    );
}
 