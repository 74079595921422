import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { CircularProgress } from '@material-ui/core';
import history from '../../../utils/history';
import axios from 'axios';

export default function FormDialog({open, setOpen}) {

    const [creatingProject, setCreatingProject] = React.useState(false)
    const [projectName, setProjectName] = React.useState('')

    const handleClose = () => {
        setOpen(false);
    };

    const handleCreateNewProject = () => {
        if (projectName === '') {
            alert('Project name cannot be empty')
            return
        }
        setCreatingProject(true)
        const contactId = localStorage.getItem("contactId");
        const accountId = localStorage.getItem("accountId");
        let data = {
            contactId,
            accountId,
            projectName,
            status: 'Image Upload Pending',
        }
        axios.post('https://us-central1-wesnapp-d136a.cloudfunctions.net/createNewAutoEditProject',data, {headers: {'Content-Type': 'application/json'}}).then((response) => {
        // axios.post('http://localhost:5000/wesnapp-d136a/us-central1/createNewAutoEditProject',data, {headers: {'Content-Type': 'application/json'}}).then((response) => {
            setCreatingProject(false)
            setOpen(false)
            history.push('/p/dashboard/auto-edit/' + response.data.slice(0,-3))
        })
        .catch( (error) => {
            console.log(error);
        });
}

    return (
        <div>
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Start new editing project</DialogTitle>
            <DialogContent>
            <DialogContentText>
                Add raw images and let our AI algorithm work for you
            </DialogContentText>
            <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Project Name"
                type="text"
                // disabled={projectName === ''}
                onChange={(e) => setProjectName(e.target.value)}
                fullWidth
            />
            </DialogContent>
            <DialogActions>
            <Button onClick={handleClose} color="primary">
                Cancel
            </Button>
            {creatingProject ? (
                <CircularProgress />
            ) : (
                <Button onClick={handleCreateNewProject} color="primary">
                    Create
                </Button>
            )}
            </DialogActions>
        </Dialog>
        </div>
    );
}
