import React, {useContext} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import { Container } from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Hidden from '@material-ui/core/Hidden';
import Paper from '@material-ui/core/Paper';
import Collapse from '@material-ui/core/Collapse';
import clsx from 'clsx';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import InboxIcon from '@material-ui/icons/Inbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import BillingIcon from '@material-ui/icons/CreditCard';
import SettingsIcon from '@material-ui/icons/Settings';
import ImageIcon from '@material-ui/icons/Image';
import history from '../../../utils/history';
import NewAutoEditDialog from './NewAutoEditDialog';
import {AuthContext} from '../../../contexts/Auth';

function ListItemLink(props) {
  return <ListItem button component="a" {...props} />;
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    backgroundColor: 'white',
    boxShadow: '0px 4px 10px #6334FF10'
  },
  menuButton: {
    // marginRight: theme.spacing(2),
  },
  newOrderButton: {
    color: 'white',
    backgroundColor: theme.palette.primary.success,
    textTransform: 'none',
    fontWeight: 'bold',
    paddingLeft: 18,
    paddingRight: 18,
    '&:hover': {
      backgroundColor: theme.palette.primary.success,
    },
    boxShadow: `0 4px 10px ${theme.palette.primary.success}40`,
    borderRadius: 8
  },
  title: {
    flexGrow: 1,
  },
  menu: {
    background: 'white',
    position: 'fixed',
    top: 62,
    left: 0,
    width: '100%',
    zIndex: 999999,
    transition: theme.transitions.create(['height', 'display'],  {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  menuOpen: {
    height: 246,
    paddingTop: 16,
    paddingBottom: 32,
    paddingRight: 16,
    paddingLeft: 16,
    display: 'block',
    boxShadow: 'rgb(99 52 255 / 5%) 0px 4px 8px 0px',
    boxShadow: '0px 4px 10px #6334FF10'
  },
  menuClose: {
    height: 0,
    padding: 0,
    boxShadow: 'none',
  },
  list: {
    width: '100%',
    transition: theme.transitions.create(['height', 'display'],  {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen * 2,
    }),
  },
  listOpen: {
    display: 'block',
  },
  listClose: {
    display: 'none'
  }
}));

const whiteIcon = require("../../../Assets/logo.png");

export default function ButtonAppBar(props) {
  const classes = useStyles();

  const [value, setValue] = React.useState(0);
  const [openMenu, setOpenMenu] = React.useState(false);
  const [openNewAutoEditDialog, setOpenNewAutoEditDialog] = React.useState(false);

  const {accountData} = useContext(AuthContext)

  React.useEffect(() => {
    if (window.location.pathname.includes('p/dashboard/photo-shoots')) {
      setValue(0)
    }
    if (window.location.pathname.includes('p/dashboard/auto-edit')) {
      setValue(1)
    }
    if (window.location.pathname.includes('p/dashboard/billing')) {
      setValue(2)
    }
    if (window.location.pathname.includes('p/dashboard/account')) {
      setValue(3)
    }
  }, [])

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if ((accountData && accountData.Allow_Auto_Edit__c)) {
      if (newValue === 0) {
        history.push('/p/dashboard/photo-shoots')
      }
      if (newValue === 1) {
        history.push('/p/dashboard/auto-edit')
      }
      if (newValue === 2) {
        history.push('/p/dashboard/billing')
      }
      if (newValue === 3) {
        history.push('/p/dashboard/account')
      }
    } else {
      if (newValue === 0) {
        history.push('/p/dashboard/photo-shoots')
      }
      if (newValue === 1) {
        history.push('/p/dashboard/billing')
      }
      if (newValue === 2) {
        history.push('/p/dashboard/account')
      }
    }
  };

  return (
    <div className={classes.root}>
      <NewAutoEditDialog open={openNewAutoEditDialog} setOpen={setOpenNewAutoEditDialog} />
      <Paper className={classes.menu} elevation={0}
        className={clsx(classes.menu, {
          [classes.menuOpen]: openMenu,
          [classes.menuClose]: !openMenu,
        })}
      >
        <List component="nav" aria-label="main mailbox folders"
          className={clsx(classes.List, {
            [classes.listOpen]: openMenu,
            [classes.listClose]: !openMenu,
          })}
        >
          <ListItem button onClick={() => {history.push('/p/dashboard/photo-shoots'); setValue(0); setOpenNewAutoEditDialog(false)}}>
            <ListItemIcon>
              <ImageIcon />
            </ListItemIcon>
            <ListItemText primary="Photo Shoots" />
          </ListItem>
          {(accountData && accountData.Allow_Auto_Edit__c) && (
            <ListItem button onClick={() => {history.push('/p/dashboard/auto-edit'); setValue(1); setValue(0); setOpenNewAutoEditDialog(false)}}>
              <ListItemIcon>
                <ImageIcon />
              </ListItemIcon>
              <ListItemText primary="Auto Edit" />
            </ListItem>
          )}
          <ListItem button onClick={() => {history.push('/p/dashboard/billing'); setValue(2); setValue(0); setOpenNewAutoEditDialog(false)}}>
            <ListItemIcon>
              <BillingIcon />
            </ListItemIcon>
            <ListItemText primary="Billing" />
          </ListItem>
          <ListItem button onClick={() => {history.push('/p/dashboard/account'); setValue(3); setValue(0); setOpenNewAutoEditDialog(false)}}>
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary="Settings" />
          </ListItem>
          {(accountData && accountData.Allow_Auto_Edit__c) && (
            <ListItem button>
              <Button fullWidth className={classes.newOrderButton} onClick={() => setOpenNewAutoEditDialog(true)}>
                + New Auto Edit
              </Button>
            </ListItem>
          )}
          {/* <ListItem button>
            <Button fullWidth className={classes.newOrderButton} onClick={() => history.push('/p/dashboard/order')}>
              + New Order
            </Button>
          </ListItem> */}
        </List>
      </Paper>
      <AppBar elevation={0} className={classes.appBar} position="fixed">
        <Container maxWidth="xl">
          {/* <Toolbar> */}
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', height: 62}}>
              <div>
                <img src={whiteIcon} alt="Avatar" className="text" height="auto" width="40" style={{width: 160, height: 32}} />
              </div>
              <Hidden smDown>
                <div>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    centered
                    style={{width: '100%', height: 62}}
                  >
                    <Tab label="Photo Shoots" style={{textTransform: 'none', height: 62}} />
                    {(accountData && accountData.Allow_Auto_Edit__c) && (
                      <Tab label="Auto Edit" style={{textTransform: 'none', height: 62}} />
                    )}
                    <Tab label="Billing" style={{textTransform: 'none', height: 62}} />
                    <Tab label="Account" style={{textTransform: 'none', height: 62}} />
                  </Tabs>
                </div>
              </Hidden>
              <div>
                <Hidden mdUp>
                  <IconButton edge="end" className={classes.menuButton} color="inherit" aria-label="menu" onClick={() => openMenu ? setOpenMenu(false) : setOpenMenu(true)}>
                    {openMenu ? <CloseIcon style={{color: 'black'}} /> : <MenuIcon style={{color: 'black'}} />}
                  </IconButton>
                </Hidden>
                <Hidden smDown>
                  {(accountData && accountData.Allow_Auto_Edit__c) && (
                    <Button className={classes.newOrderButton} onClick={() => setOpenNewAutoEditDialog(true)}>
                      + New Auto Edit
                    </Button>
                   )}
                </Hidden>
              </div>
            </div>
            {/* <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={() => props.main.setState({sideMenuOpen: true})}>
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              <img src={whiteIcon} alt="Avatar" className="text" height="auto" width="40"  style={{ position: 'absolute', left: 'calc(50% - 20px)', top: 14}}/>
            </Typography>
            <IconButton edge="end" className={classes.menuButton} color="inherit" aria-label="menu" onClick={() => props.main.setState({sideMenuOpen: true})}>
              <MenuIcon />
            </IconButton> */}
            {
            // <Button color="inherit">Login</Button>
            }
          {/* </Toolbar> */}
        </Container>
      </AppBar>
    </div>
  );
}
