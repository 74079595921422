
const font = "'Montserrat', sans-serif";

export default ({
  fontFamily: font,
  fontFamily: "'Montserrat', sans-serif",
  direction: "ltr",
  overrides: {
    // MuiFormLabel: {
    //   root: {
    //     color: "green",
    //     fontSize: "1em"
    //   }
    // },
    // MuiInputBase: {
    //   root: {
    //     color: "green",
    //     fontSize: "1.2em"
    //   }
    // },
    MuiOutlinedInput: {
      root: {
        "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
          border: "2px solid",
          borderColor: "#6C65FF"
        }
      }
    }
  },
  typography: {
    fontFamily: "'Montserrat', sans-serif",
    h1: {
      fontSize: 24,
      fontWeight: 'semi-bold',
      textAllign: 'left',
      color: '#1F1F55'
    }
  },
  palette: {
    background: {
      default: '#F9F8FD'
    },
    primary: {
      main: '#6334FF',
      white: '#F9F8FD',
      dark: '#3f4148',
      gray: '#99a1a9',
      green: '#33D7A0',
      darkText: '#1F1F55',
      lightText: '#7F7E90',
      background: '#F9F8FD',
      success: '#33D7A0'
    },
    secondary: {
      main: '#6334FF',
      white: '#ffffff',
      dark: '#3f4148',
      gray: '#99a1a9',
      green: '#33D7A0'
    },
  },
})