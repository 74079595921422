import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import AppBar from './AppBar';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import { createBrowserHistory } from "history";
import { withRouter } from "react-router-dom";
import axios from 'axios'
import { Button } from '@material-ui/core';
import Order from '../Client/Pages/Order/Order'
const history = createBrowserHistory();

const styles = theme => ({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  root: {
    // width: '100vw',
    height: '100vh',
    // maxWidth: '100vw',
    // minWidth: '100vw',
    backgroundColor: '#FFF',
    overflowY: 'scroll',
    overflowX: 'hidden',
    // background: 'linear-gradient(90deg, #6C65FF 50%, #FFF 50%)',
    // position: 'absolute',
    // top: 0, left: 0,
    // right: 0
  }
});

const mobile = (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1)

class Template extends React.Component {

  state = {
      approving: false,
      rejecting: false,
      rejected: false,
      approved: false,
      pending: false
  }

  componentDidMount() {
      // console.log(this.props.match.params.id)
      // this.getRequestData(this.props.match.params.id)
  }

  getRequestData = (id) => {
    var data = {id: id}
    console.log(data)
    // axios.post('https://wesnapp.co.il' + '/api/accountcontactrelation/get-one',data, {headers: {'Content-Type': 'application/json'}}).then((response) => {
    axios.post('https://wesnapp.co.il' + '/api/accountcontactrelation/get-one',data, {headers: {'Content-Type': 'application/json'}}).then((response) => {
        console.log(response.data)
        var relationship = response.data[0]
        var status = relationship.Relationship_Status__c
        if (status === null || status === 'Pending') {
            this.setState({pending: true})
        } else if (status === 'Approved') {
            this.setState({approved: true})
        } else if (status === 'Refused') {
            this.setState({rejected: true})
        }
    })
    .catch( (error) => {
      //console.log(error);
    });
  }

  approve = () => {
    this.setState({approving: true})
    var data = {id: this.state.id, data: {Relationship_Status__c: 'Approved'}}
    axios.post('https://wesnapp.co.il' + '/api/account/update-relationship',data, {headers: {'Content-Type': 'application/json'}}).then((response) => {
        console.log(response.data)
        this.setState({approving: false, approved: true, pending: false})
    })
    .catch( (error) => {
      //console.log(error);
    });
  }

  reject = () => {
    this.setState({rejecting: true})
    var data = {id: this.state.id, data: {Relationship_Status__c: 'Refused'}}
    axios.post('https://wesnapp.co.il' + '/api/account/update-relationship',data, {headers: {'Content-Type': 'application/json'}}).then((response) => {
        console.log(response.data)
        this.setState({rejecting: false, rejected: true, pending: false})
    })
    .catch( (error) => {
      //console.log(error);
    });
  }

  render() {
    const { classes, theme } = this.props;
    return (
      <div className={classes.root}>
        {console.log('in order')}
        <AppBar/>
        <Order landingPage={true} style={{width: '100vw', maxWidth: '100vw', left: 0}}/>
      </div>
    );
  }
}

Template.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(Template);
