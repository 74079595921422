import React from 'react';

const Room = ({color, size}) => (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
    width={size} height={size} viewBox="0 0 100.000000 100.000000"
    >
        <g transform="translate(0.000000,100.000000) scale(0.100000,-0.100000)"
        stroke="none" fill={color}>
            <path d="M52 938 c-9 -9 -12 -101 -12 -353 0 -319 1 -343 18 -358 16 -15 50
            -17 264 -17 216 0 247 2 252 16 3 9 4 20 0 25 -3 5 -54 9 -115 9 l-109 0 0 49
            c0 52 -7 65 -29 52 -9 -6 -12 -24 -10 -55 l4 -46 -117 0 -118 0 0 140 0 140
            159 0 c107 0 162 4 167 11 13 22 -24 29 -173 29 l-153 0 0 165 0 165 413 2
            412 3 0 -118 0 -118 -115 3 -115 3 3 60 c3 62 -14 117 -30 93 -4 -7 -8 -68 -8
            -135 l0 -122 -75 1 c-46 0 -81 -4 -90 -12 -24 -20 -10 -25 78 -30 78 -5 82 -6
            85 -30 1 -14 5 -36 8 -49 5 -24 5 -24 20 -3 10 14 14 45 12 102 l-2 82 115 1
            114 2 0 -282 0 -283 -112 0 -112 0 0 121 c1 117 -7 149 -30 135 -16 -10 -15
            -268 1 -284 19 -19 248 -16 276 4 l22 15 0 424 c0 233 -3 430 -6 439 -5 14
            -54 16 -443 16 -327 0 -440 -3 -449 -12z"/>
        </g>
    </svg>
)

export default Room;