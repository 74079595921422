import React from 'react';

const Room = ({color, size}) => (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
    width={size} height={size} viewBox="0 0 100.000000 100.000000"
    >
        <g transform="translate(0.000000,100.000000) scale(0.100000,-0.100000)"
        stroke="none" fill={color}>
            <path d="M68 649 c-16 -9 -18 -27 -18 -143 0 -107 -3 -136 -15 -146 -12 -10
            -15 -42 -15 -167 l0 -154 48 3 c45 3 47 4 50 35 l3 32 370 1 369 1 0 -24 c0
            -34 22 -49 67 -45 l38 3 2 147 c2 107 -1 151 -10 159 -9 8 -11 47 -9 139 5
            188 52 170 -450 170 -262 0 -419 -4 -430 -11z m840 -156 c2 -107 1 -122 -13
            -117 -11 5 -15 22 -15 70 0 35 -4 64 -8 64 -5 0 -14 10 -21 23 -11 21 -15 22
            -158 19 -85 -2 -153 -8 -160 -14 -9 -7 -13 -37 -13 -88 0 -77 0 -77 -25 -72
            -24 4 -25 8 -25 67 0 101 -5 104 -182 107 -172 3 -168 6 -168 -105 0 -67 -2
            -73 -21 -74 -15 -1 -19 2 -15 13 3 9 6 65 6 125 l0 109 408 -2 407 -3 3 -122z
            m-78 -56 l0 -62 -132 0 -133 0 0 65 0 65 133 -3 132 -3 0 -62z m-402 3 l1 -60
            -135 0 -134 0 0 60 0 60 133 0 134 0 1 -60z m497 -165 l0 -55 -431 0 -431 0 0
            55 0 55 431 0 431 0 0 -55z m-5 -130 c0 -18 3 -41 7 -51 4 -12 2 -15 -8 -12
            -8 2 -16 20 -19 39 l-5 34 -390 3 c-401 3 -429 0 -438 -37 -3 -9 -4 1 -4 21
            l0 37 429 0 428 0 0 -34z"/>
        </g>
    </svg>
)

export default Room;