import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import Loading from './Components/Loading/Loading';

const PrivateRoute = ({component, isLoggedIn, ...rest}) => {
    return (
        // Show the component only when the user is logged in
        // Otherwise, redirect the user to /signin page
        <Route {...rest} render={props => (
            isLoggedIn ? (component) : <Redirect to="/login" />
        )} />
    );
};

export default PrivateRoute;