import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles((theme) => ({
  backgroundColor: theme.palette.primary.background,
  background: theme.palette.primary.background,
  root: {
    '& .MuiTableBody-root': {
      paddingTop: 100,
    }
  },
  table: {
    minWidth: 350,
    backgroundColor: theme.palette.primary.background,
    background: theme.palette.primary.background
  }
}))

export default function SimpleTable(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
    <TableContainer component={Paper} elevation={0}>
      <Table className={classes.table} aria-label="simple table">
        {props.header && (
          <TableHead>
            <TableRow>
              <TableCell><b>Order Id</b></TableCell>
              <TableCell><b>Doc Id</b></TableCell>
              <TableCell align="left"><b>Type</b></TableCell>
              <TableCell align="left"><b>Total Amount (+VAT)</b></TableCell>
              <TableCell align="left"><b>Date</b></TableCell>
              <TableCell align="left"><b>Link</b></TableCell>
            </TableRow>
          </TableHead>
        )}
            <TableBody className={classes.root}>
              {props.tableData.map((row, i) => (
                <TableRow key={row.number} style={{marginTop: i === 0 ? 50 : 0}}>
                  <TableCell align="left" style={{width: '20%'}}>#{row.order}</TableCell>
                  <TableCell component="th" scope="row" style={{width: '15%'}}>
                    #{row.number}
                  </TableCell>
                  <TableCell align="left" style={{width: '20%'}}>{row.type}</TableCell>
                  <TableCell align="left" style={{width: '20%'}}>{row.totalAmount}₪</TableCell>
                  <TableCell align="left" style={{width: '20%'}}>{row.date}</TableCell>
                  <TableCell align="left" style={{width: '15%'}}>
                    <div onClick={() => window.open(row.link, '_blank')} className='linkButton'>
                        Open
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
      </Table>
    </TableContainer>
    </div>
  );
}
