import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const PublicRoute = ({component: Component, restricted, history, landingPage, isLoggedIn, ...rest}) => {
    return (
        // restricted = false meaning public route
        // restricted = true meaning restricted route
        <Route {...rest} render={props => (
            isLoggedIn && restricted ?
                <Redirect to="/c/dashboard" />
            : (<Component {...props} history={history} landingPage={landingPage} />)
        )} />
    );
};

export default PublicRoute;