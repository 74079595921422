import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios';
import { withRouter } from "react-router-dom";
import TextField from '@material-ui/core/TextField';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import CreditCardInput from 'react-credit-card-input';
import CreditCardRoundedIcon from '@material-ui/icons/CreditCardRounded';
import { IconButton } from '@material-ui/core';
import MoreVertRoundedIcon from '@material-ui/icons/MoreVertRounded';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Skeleton from '@material-ui/lab/Skeleton';

var mobile = (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1)

const styles = theme => ({
  list: {
    width: 250
  },
  fullList: {
    width: 'auto',
  },
  root: {
    width: '100%',
    backgroundColor: '#6C65FF',
    backgroundColor: 'white',
    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: 28,
      borderColor: '#DAE2FD',
    },
    '& .MuiOutlinedInput-input': {
      backgroundColor: '#ecf1fe',
      borderRadius: 28
    },
  },
  button: {
    color: 'white',
    height: 50,
    backgroundColor: theme.palette.primary.main,
    textTransform: 'none',
    fontWeight: 'bold',
    paddingLeft: 32,
    paddingRight: 32,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
    boxShadow: `0 4px 10px ${theme.palette.primary.main}40`,
    borderRadius: 25,
    marginTop: 12
  },
  defaultTag: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 4,
    paddingBottom: 4,
    fontSize: 11,
    borderRadius: 4,
  }
});

function createData(id, name, mobile, startAt, watchShootings, useOrders, canSelect, accounter) {
  return { id, name, mobile, startAt, watchShootings, useOrders, canSelect, accounter };
}

class PhotoShoots extends React.Component {

  state = {
    isLoading: true,
    openAddNew: false,
    tableData: null,
    bAccountId: '',
    requests: [],
    bAccountExists: null,
    bEmail: '',
    errorBusinessName: false,
    errorBusinessEmail: false,
    creatingNewBusinessName: false,
    billingAddress: '',
    errorBillingAddress: false,
    bName: '',
    cardNumber: '',
    cvc: '',
    expiry: '',
    errorCard: false,
    newCardOpen: false,
    cards: [],
    anchorEl: null,
    cardIndex: null,
    addingCard: false,
    loadingCards: false
  }

  componentDidMount() {
    this.getExistingCards()
  }

  getExistingCards = async () => {
    this.setState({loadingCards: true})
    let cards = await axios.post('https://us-central1-wesnapp-d136a.cloudfunctions.net/getAccountTokens', {accountId: localStorage.getItem('accountId')}, {headers: {'Content-Type': 'application/json'}})
    console.log('cards', cards.data)
    this.setState({
      cards: cards.data,
      newCardOpen: false,
      addingCard: false,
      loadingCards: false
    })
  }

  handleCardNumberChange = (e) => {
    this.setState({errorCard: false})
    this.setState({cardNumber: e.target.value})
  }

  handleCardExpiryChange = (e) => {
    this.setState({errorCard: false})
    this.setState({expiry: e.target.value})
  }

  handleCardCVCChange = (e) => {
    this.setState({errorCard: false})
    this.setState({cvc: e.target.value})
  }

  saveCard = () => {
    this.setState({addingCard: true})
    let data = {
      accountId: localStorage.getItem("accountId"),
      cardNumber: this.state.cardNumber,
      expirationDate: this.state.expiry,
      cvc: this.state.cvc,
    }
    axios.post('https://us-central1-wesnapp-d136a.cloudfunctions.net/createNewToken',data, {headers: {'Content-Type': 'application/json'}}).then((response) => {
    // axios.post('https://us-central1-wesnapp-d136a.cloudfunctions.net/createNewToken',data, {headers: {'Content-Type': 'application/json'}}).then((response) => {
      console.log('res', response.data)
      this.setState({
        cardNumber: '',
        expiry: '',
        cvc: '',
      })
      this.getExistingCards()
    })
    .catch( (error) => {
      //console.log(error);
    });
  }

  makeDefaultCard = async () => {
    this.setState({anchorEl: null})
    await axios.post('https://us-central1-wesnapp-d136a.cloudfunctions.net/makeDefault', {cardId: this.state.cards[this.state.cardIndex].Id, accountId: localStorage.getItem('accountId')}, {headers: {'Content-Type': 'application/json'}})
    this.getExistingCards()
  }

  removeCard = async () => {
    this.setState({anchorEl: null})
    await axios.post('https://us-central1-wesnapp-d136a.cloudfunctions.net/removeCard', {cardId: this.state.cards[this.state.cardIndex].Id}, {headers: {'Content-Type': 'application/json'}})
    this.getExistingCards()
  }

  handleCloseCardMenu = () => {
    this.setState({anchorEl: null})
  }

  handleCloseCardMenu = () => {
    this.setState({anchorEl: null})
  }

  handleOpenCardMenu = (event, index) => {
    this.setState({anchorEl: event.currentTarget, cardIndex: index})
  };
 
  render() {
    const { classes, theme } = this.props;

    return (
      <div style={{backgroundColor: 'white', borderRadius: '23px', boxShadow: '0px 4px 10px #6334FF10', marginTop: 0, width: '100%', padding: 24, marginBottom: 24}}>
        <Menu
          id="simple-menu"
          anchorEl={this.state.anchorEl}
          keepMounted
          open={Boolean(this.state.anchorEl)}
          onClose={this.handleCloseCardMenu}
        >
          <MenuItem onClick={this.makeDefaultCard}>Make Default</MenuItem>
          <MenuItem onClick={this.removeCard}>Remove</MenuItem>
        </Menu>
        <Grid container spacing={2} style={{padding: 0, paddingTop: 0, width: '100%'}} justifyContent="center" justify="center">
          <Grid item xs={12} sm={10} md={8} xl={7}>
            <div style={{fontSize: 20, color: 'rgb(40,40,53)', textAlign: 'left', marginLeft: 0, fontFamily: "'Montserrat', sans-serif"}}>
                <b>Payment Methods</b>
            </div>
          </Grid>   
          <Grid item xs={12} sm={6} md={8} xl={7}>
            <div style={{width: '100%', display: 'flex', flexFlow: 'column', gap: 12}}>
              {this.state.loadingCards && (
                <>
                  <Skeleton variant="rect" width={'100%'} height={70} />
                  <Skeleton variant="rect" width={'100%'} height={70} />
                  <Skeleton variant="rect" width={'100%'} height={70} />
                </>
              )}
              {!this.state.loadingCards && (
                <>
                  {this.state.cards.map((item, index) => (
                    <div style={{width: '100%', background: 'white', gap: 12, padding: '14px 16px', display: 'flex', alignItems: 'center', borderRadius: '12px', boxShadow: '0px 4px 10px #6334FF10'}} key={index}>
                      <CreditCardRoundedIcon />
                      <div style={{flexGrow: 1}}>**** **** **** {item.Last_4_Digits__c}. Expires: {item.Expiration_Date__c}</div>
                      {item.Default_Card__c && (
                        <div className={classes.defaultTag}>Default</div>
                      )}
                      <IconButton onClick={(e)=> this.handleOpenCardMenu(e, index)}>
                        <MoreVertRoundedIcon />
                      </IconButton>
                    </div>
                  ))}
                </>
              )}
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

PhotoShoots.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(withRouter(PhotoShoots));
