import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PhoneIcon from '@material-ui/icons/Phone';
import MembersIcon from '@material-ui/icons/AccountTree';
import Grid from '@material-ui/core/Grid';
import axios from 'axios';
import { withRouter } from "react-router-dom";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import MembersPage from './MembersPage';
import BusinessDetailsPage from './BusinessDetailsPage';

import { AuthContext } from '../../../../contexts/Auth';

var mobile = (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1)

const styles = theme => ({
  list: {
    width: 250
  },
  fullList: {
    width: 'auto',
  },
  root: {
    width: '100vw',
    height: '100vh',
    backgroundColor: '#6C65FF',
    backgroundColor: 'white'
  },
  button: {
    height: 50,
    marginTop: 15,
    float: 'left',
    borderRadius: 25,
    paddingLeft: 32,
    paddingRight: 32,
    boxShadow: 'none',
    hover: {
      backgroundColor: '#33D7A0'
    }
  }
});

class PhotoShoots extends React.Component {

  static contextType = AuthContext

  state = {
    isLoading: false,
    requests: [],
    currentTab: 0,

  }

  componentWillMount() {
  }

  handleChangeTab = (event, newValue) => {
    this.setState({currentTab: newValue})
  };

  componentDidMount = () => {
    var contactId = localStorage.getItem("contactId")
  }

  render() {
    const { classes, theme } = this.props;

    

    return (
      <div className={styles.root} style={{backgroundColor: 'white', padding: mobile ? 10 : 0, paddingTop: mobile ? 30 : 0, borderRadius: '35px 35px 0 0px', marginTop: 10, minHeight: '100vh'}}>
              <div>
                <Grid container spacing={2} style={{padding: 0, paddingTop: 0}} justifyContent="center" justify="center">
                  <Grid item xs={12} sm={12} md={10} xl={9}>
                    <div style={{fontSize: 24, color: 'rgb(40,40,53)', textAlign: 'left', marginLeft: 0, fontFamily: "'Montserrat', sans-serif", marginTop: mobile ? 0 : 15, marginBottom: mobile ? 0 : 30}}>
                      <b>My Company</b>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={11} xl={9} style={{marginTop: 20, paddingBottom: 40}}>
                  <Paper square elevation={0}>
                    <Tabs
                      value={this.state.currentTab}
                      centered
                      onChange={this.handleChangeTab}
                      indicatorColor="primary"
                      textColor="primary"
                      aria-label="icon label tabs example"
                    >
                      <Tab style={{marginLeft: 20, marginRight: 20}} icon={<PhoneIcon />} label="Company Details" />
                      <Tab style={{marginLeft: 20, marginRight: 20}} icon={<MembersIcon />} label="Members" />
                    </Tabs>
                  </Paper>
                </Grid>
                {this.state.currentTab === 0 && (
                  <Grid item xs={12} sm={12} md={11} xl={9} style={{}}>
                    <BusinessDetailsPage />
                  </Grid>
                )}
                {this.state.currentTab === 1 && (
                  <Grid item xs={12} sm={12} md={11} xl={9} style={{}}>
                      <MembersPage />
                  </Grid>
                )}
                </Grid>
            </div>
        </div>
    );
  }
}

PhotoShoots.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(withRouter(PhotoShoots));
