import React, {useState} from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import { fade, makeStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import VideoPreviewDialog from './VideoPreviewDialog';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    // flexGrow: 1,
    // display: 'none',
    textAlign: 'left',
    fontWeight: 'bold',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  allButton: {
    color: 'white',
    textTransform: 'none',
    fontWeight: 'bold',
    paddingLeft: 18,
    paddingRight: 18,
    color: theme.palette.primary.main,
    backgroundColor: fade(theme.palette.primary.main, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.primary.main, 0.25),
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.primary.main, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.primary.main, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
  selectAllButton: {
    border: '1px solid black',
    borderColor: theme.palette.primary.main,
    height: 42,
    borderRadius: 21,
    textTransform: 'none',
    fontWeight: 'semi-bold',
    paddingLeft: 18,
    paddingRight: 18,
    color: theme.palette.primary.main,
    '&:disabled': {
      borderColor: 'lightGray'
    }
  },
}));

export default function SearchAppBar(props) {
  const classes = useStyles();

  const [anchorElEmail, setAnchorElEmail] = React.useState(null);
  const [anchorElDownload, setAnchorElDownload] = React.useState(null);

  const [openPreviewDialog, setOpenPreviewDialog] = useState(false)

  const handleClickEmail = (event) => {
    setAnchorElEmail(event.currentTarget);
  };

  const handleClickDownload = (event) => {
    setAnchorElDownload(event.currentTarget);
  };

  const handleCloseDownload = () => {
    setAnchorElDownload(null);
  };

  const handleCloseEmail = () => {
    setAnchorElEmail(null);
  };

  const emailWithoutLogo = () => {
    setAnchorElEmail(null);
    props.sendToMyEmail('regular')
  }

  const emailWithLogo = () => {
    setAnchorElEmail(null);
    props.sendToMyEmail('logo')
  }
  
  const downloadWithoutLogo = () => {
    setAnchorElDownload(null);
    props.downloadZipFile(props.selectedInThePast, 'WeSnapp_' + props.requestData.Name + '_edited.zip')
  }

  const downloadWithLogo = () => {
    setAnchorElDownload(null);
    props.downloadZipFileWatermark(props.selectedInThePast, 'WeSnapp_' + props.requestData.Name + '_edited.zip')
  }

  return (
    <div style={{background: '#F9F8FD', position: 'sticky', top: 62, display: 'flex', height: 86, width: '100%', alignItems: 'center', zIndex: 999}}>
        {/* <Toolbar> */}
            {/* <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="open drawer"
            >
            <MenuIcon />
            </IconButton> */}
            {props.requestData && (
                <VideoPreviewDialog setOpenVideoDialog={props.setOpenVideoDialog} createSnackbar={props.createSnackbar} open={openPreviewDialog} setOpen={setOpenPreviewDialog} videoLink={props.requestData.Video_Link__c} videoId={props.requestData.Video_Id__c} />
            )}
            <div style={{display: 'flex', gap: 12, flexGrow: 1, alignItems: 'center'}}>
              <Typography className={classes.title} variant="h6" noWrap>
                Request #{props.requestData.Name}
              </Typography>
              {props.showSelectAll && (
                <Button className={classes.selectAllButton} onClick={()=> props.selectAll()}>Select All</Button>
              )}
            </div>
            <div style={{display: 'flex', gap: 12, alignItems: 'center'}}>
              <Button disabled={props.selectedInThePast.length === 0} className={classes.selectAllButton} onClick={handleClickDownload}>Download Images</Button>
              <Menu
                id="simple-menu"
                anchorEl={anchorElDownload}
                keepMounted
                open={Boolean(anchorElDownload)}
                onClose={handleCloseDownload}
              >
                <MenuItem onClick={downloadWithLogo}>With Company Logo</MenuItem>
                <MenuItem onClick={downloadWithoutLogo}>Without Company Logo</MenuItem>
              </Menu>
              <Button disabled={(props.selectedInThePast.length === 0 || props.logo_preferences.url === '')} className={classes.selectAllButton} onClick={handleClickEmail}>Email Images</Button>
              <Menu
                id="simple-menu"
                anchorEl={anchorElEmail}
                keepMounted
                open={Boolean(anchorElEmail)}
                onClose={handleCloseEmail}
              >
                <MenuItem onClick={emailWithLogo}>With Company Logo</MenuItem>
                <MenuItem onClick={emailWithoutLogo}>Without Company Logo</MenuItem>
              </Menu>
              {props.requestData.Video_Link__c ? (
                <Button onClick={() => setOpenPreviewDialog(true)} className={classes.selectAllButton}>Watch Video</Button>
              ) : (
                <Button onClick={() => props.setOpenVideoDialog(true)} className={classes.selectAllButton} disabled={props.selectedInThePast.length === 0}>Create Video</Button>
              )}
            </div>
        {/* </Toolbar> */}
    </div>
  );
}
