import React, {useState, useEffect} from 'react';
import Chip from '@material-ui/core/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(3),
    },
  },
}));

export default function Tags(props) {
  const classes = useStyles();

  const [single1, setSingle1] = useState(null)
  const [single2, setSingle2] = useState(null)
  const [lenses, setLenses] = useState([])
  const [cameras, setCameras] = useState([])

  useEffect(() => {
    getAllEquipments()
  }, [])

  const getAllEquipments = () => {
    axios.get('https://wesnapp.co.il/api/equipment/get-all',{}, {headers: {'Content-Type': 'application/json'}}).then((response) => {
      console.log(response);
      var arrayOfEquipments = response.data
      var cameras = []
      var lenses = []
      for (var i = 0; i < arrayOfEquipments.length; i++) {
        if (arrayOfEquipments[i].Equipment_Type__c == "Camera" && arrayOfEquipments[i].Brand__c != null && arrayOfEquipments[i].Product_Model__c != null) {
          cameras.push(arrayOfEquipments[i])
        } else if (arrayOfEquipments[i].Equipment_Type__c == "Lens" && arrayOfEquipments[i].Brand__c != null && arrayOfEquipments[i].Product_Model__c != null) {
          lenses.push(arrayOfEquipments[i])
        }
      }
      var camerasObject = cameras.map(suggestion => ({
        value: suggestion.Id,
        label: suggestion.Brand__c + " " + suggestion.Product_Model__c,
      }));
      setCameras(camerasObject)
      var lensesObject = lenses.map(suggestion => ({
        value: suggestion.Id,
        label: suggestion.Brand__c + " " + suggestion.Product_Model__c,
      }));
      setLenses(lensesObject)
    })
    .catch( (error) => {
      console.log(error);
    });
  }

  const handleChangeCameras = (e, v) => {
    console.log(e, v);
    props.main.setState({cameras: v})
  };

  const handleChangeLenses = (e, v) => {
    console.log(e, v);
    props.main.setState({lenses: v})
  };

  return (
    <div className={classes.root}>
      <Autocomplete
        multiple
        id="cameras"
        options={cameras}
        value={props.main.state.cameras}
        onChange={handleChangeCameras}
        getOptionLabel={(option) => option.label}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            label="Cameras"
            fullWidth
            placeholder="Select your professional cameras"
          />
        )}
      />
      <Autocomplete
        multiple
        id="lenses"
        options={lenses}
        value={props.main.state.lenses}
        onChange={handleChangeLenses}
        getOptionLabel={(option) => option.label}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            label="Lenses"
            fullWidth
            placeholder="Select your different lenses"
          />
        )}
      />
    </div>
  );
}