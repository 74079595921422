import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import CircularProgress from '@material-ui/core/CircularProgress';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide(props) {
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    // setOpen(false);
    // this.
  };

  return (
    <div>
      <Dialog
        open={props.main.state.openCheckingDialog}
        TransitionComponent={Transition}
        keepMounted
        fullWidth={'sm'}
        maxWidth={'sm'}
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{"Cheking if someone was faster then you..."}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <div style={{width: '100%', textAlign: 'center', marginTop: 24}}><CircularProgress style={{marginBottom: 24}}/></div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}
