import React, {useEffect, useState} from 'react';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

var mobile = (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1)

const noImage = require("../../../../Assets/no_image.png");

const Card = (props) => {

    const [image, setImage] = useState('loading')

    useEffect(() => {
        let test = async () => {
            console.log('get image', props.item.Id)
            let img = await props.getPreviewImage(props.item.Id)
            console.log('img', img)
            setImage(img)
        }
        test()
    }, [])

    return (
        <div style={{height: mobile ? 330 : 310, width: '100%', backgroundColor: '#ebf1ff', borderRadius: 15}} onClick={() => props.goToShootPage(props.item)} className={'shootBox'}>
            {(image != "" && image != "loading") && (
            <div style={{width: '100%', marginLeft: '0%', "hover": {transform: 'scale(1.3)'}, borderRadius: '18px 18px 0 0',position: 'relative', top: 0, zIndex: 2, height: mobile ? 240 : 220, backgroundImage: "url('" + image + "')", backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: '50% 50%', cursor: 'pointer'}}>
            <div style={{ display: 'inline-block', position: 'absolute', bottom: -20, right: 15, backgroundColor: 'rgba(94,44,237,1)', fontSize: 14, borderRadius: 8, padding: 4, color: 'white', width: 40, height: 40, borderRadius: 20, marginTop: 5, fontSize: 20, fontWeight: 'bold', fontFamily: '"Times New Roman", Times, serif', cursor: 'pointer', userSelect: 'none'}} onClick={(e)=> props.openCloseDetails(e,props.i)} className={"infoButton"}>i</div>
            <div style={{ display: 'inline-block', position: 'absolute', top: 15, left: 15, backgroundColor: 'rgba(0,0,0,0.8)', fontSize: 14, borderRadius: 8, padding: 4, color: 'white', userSelect: 'none'}}>#{props.item.Name}</div>
            {
                props.changeStatusFormat(props.item.Status__c)
            }
                {props.item.OpenDetails && (
                <div style={{width: '100%', height: '100%', backgroundColor: 'rgba(255,255,255,0.95)'}}>
                    <div style={{position: 'relative', top: '24%', fontSize: 16, fontWeight: 'bold'}}>Photographer</div>
                    <div style={{position: 'relative', top: '26%', fontSize: 16}}>{props.item.Photographer_Name__c}</div>
                    <div style={{position: 'relative', top: '30%', fontSize: 16}}><b>Paid:</b> {props.item.Paid__c ? ('Yes') : ('No')}</div>
                    <div style={{position: 'relative', top: '34%', fontSize: 16, fontWeight: 'bold'}}>Product</div>
                    <div style={{position: 'relative', top: '36%', fontSize: 16}}>{props.item.Public_Product_Name__c}</div>
                </div>
                )}
            </div>
            )}
            {(image == "" && image != "loading") && (
            <div style={{width: '100%', marginLeft: '0%', borderRadius: '18px 18px 0 0',position: 'relative', top: 0, zIndex: 2, height: mobile ? 240 : 220, backgroundColor: "#d9e2ff"}}>
            <div style={{ display: 'inline-block', position: 'absolute', bottom: -20, right: 15, backgroundColor: 'rgba(94,44,237,1)', fontSize: 14, borderRadius: 8, padding: 4, color: 'white', width: 40, height: 40, borderRadius: 20, marginTop: 5, fontSize: 20, fontWeight: 'bold', fontFamily: '"Times New Roman", Times, serif', cursor: 'pointer', userSelect: 'none'}} onClick={(e)=> props.openCloseDetails(e,props.i)} className={"infoButton"}>i</div>
                <div style={{ display: 'inline-block', position: 'absolute', top: 15, left: 15, backgroundColor: 'rgba(0,0,0,0.8)', fontSize: 14, borderRadius: 8, padding: 4, color: 'white'}}>#{props.item.Name}</div>
                {props.changeStatusFormat(props.item.Status__c)}
                <img style={{position: 'absolute', top: 'calc(50% - 35px)', width: 70, height: 70, left: 'calc(50% - 35px)'}} src={noImage}/>
                {props.item.OpenDetails && (
                <div style={{width: '100%', height: '100%', backgroundColor: 'rgba(255,255,255,0.95)'}}>
                    <div style={{position: 'relative', top: '24%', fontSize: 16, fontWeight: 'bold'}}>Photographer</div>
                    <div style={{position: 'relative', top: '26%', fontSize: 16}}>{props.item.Photographer_Name__c}</div>
                    <div style={{position: 'relative', top: '30%', fontSize: 16}}><b>Paid:</b> {props.item.Paid__c ? ('Yes') : ('No')}</div>
                    <div style={{position: 'relative', top: '34%', fontSize: 16, fontWeight: 'bold'}}>Product</div>
                    <div style={{position: 'relative', top: '36%', fontSize: 16}}>{props.item.Public_Product_Name__c}</div>
                </div>
                )}
            </div>
            )}
            {(image === "loading" && props.item.OpenDetails !== true) && (
            <div style={{width: '100%', marginLeft: '0%', borderRadius: '18px 18px 0 0',position: 'relative', top: 0, zIndex: 2, height: mobile ? 240 : 220, backgroundImage: "url('" + image + "')", backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: '50% 50%'}}>
                <CircularProgress style={{marginTop: '26%'}}/>
            </div>
            )}
            <div style={{width: '100%', marginTop: 15, marginLeft: 15, textAlign: 'left', fontSize: 15, color:'black'}}>{props.item.ShootingStreet__c}, {props.item.ShootingCity__c}</div>
            <div style={{width: '100%', marginTop: 15, marginLeft: 15, textAlign: 'left', fontSize: 15, color: 'black'}}>
            {
            props.getFormatedDate(props.item.Shooting_Date_Time__c)
            }
            </div>
        </div>
    );
}
 
export default Card;