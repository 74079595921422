import { TextField } from '@material-ui/core';
import React from 'react';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';

class LocationSearchInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = { address: '' };
  }
 
  handleChange = formattedAddress => {
    // console.log(address)
    this.props.main.setState({ formattedAddress });
  };
 
  handleSelect = address => {
    console.log(address)
    this.props.main.setState({formattedAddress: address}, () => {
      // alert(address + '1')
      // this.props.main.getCoordinates(address)
    })
    // this.props.main.getCoordinates(address)
    // geocodeByAddress(address)
    //   .then(results => getLatLng(results[0]))
    //   .then(latLng => console.log('Success', latLng))
    //   .catch(error => console.error('Error', error));
  };
 
  render() {
    return (
      <PlacesAutocomplete
        value={this.props.address}
        onChange={this.handleChange}
        searchOptions={
          {componentRestrictions: { country: ['il'] },
          types: ['address']
        }}
        onSelect={this.handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div style={{boxShadow: suggestions.length !== 0 ? '0 4px 10px 0 rgba(0,0,0,.25)' : '0 4px 10px 0 rgba(0,0,0,.0)', borderRadius: 30, backgroundColor: 'white', maxWidth: '100%', width: '100%', zIndex: '999999'}}>
            <TextField
              {...getInputProps({
                placeholder: 'Choose specific address',
                className: 'location-search-input',
              })}
              fullWidth
              label={'Address'}
              id={"location_ph"}
              style={{display: 'inline-block', float: 'left', width: '100%'}}
            />
            <div className="autocomplete-dropdown-container" style={{position: 'absolute', marginTop: 70, backgroundColor: 'white', display: suggestions.length !== 0 ? 'block' : 'none', boxShadow: suggestions.length !== 0 ? '0 4px 10px 0 rgba(0,0,0,.25)' : '0 4px 10px 0 rgba(0,0,0,.0)', borderRadius: 20, backgroundColor: 'white', maxWidth: '100%',zIndex: '999999', padding: 12}}>
              {loading && <div>Loading...</div>}
              {suggestions.map(suggestion => {
                const className = suggestion.active
                  ? 'suggestion-item--active'
                  : 'suggestion-item';
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { backgroundColor: '#fafafa', cursor: 'pointer', borderRadius: 30, padding: 12, backgroundColor: 'none', textAlign: 'left' }
                  : { backgroundColor: '#ffffff', cursor: 'pointer', borderRadius: 30, padding: 12, backgroundColor: 'none', textAlign: 'left' };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                    // onClick={() => {this.handleSelect(suggestion); this.setState({address: ''})}}
                  >
                    {/* {console.log(suggestion)} */}
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    );
  }
}

export default LocationSearchInput
