import React, {useEffect, useState} from 'react';
import {
    CircularProgress,
    Grid,
    Button,
    TextField,
    Radio,
    FormGroup,
    FormControlLabel,
    Switch
} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Skeleton from '@material-ui/lab/Skeleton';

import Tabs from './Tabs';
import Location from './Location';

import { TimePicker,DatePicker } from 'antd';
import moment from 'moment';
import '../../../../node_modules/antd/dist/antd.css';

import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import { makeStyles } from '@material-ui/styles'

const mobile = (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1)

const useStyles = makeStyles((theme) => ({
    coverImageDesktop: {
        width: '100%',
        position: 'fixed',
        height: '100%',
        objectFit: 'cover',
        right: 0,
        top: 0 
    }, 
    coverImageMobile: {
        width: '100%',
        // height: '70vh',
        objectFit: 'cover',
        backgroundColor:  theme.palette.primary.main,
    },
    insidePlatform: {
        width: '100%',
        minHeight: '100%',
        pddingTop: 60, paddingBottom: 20,
        backgroundColor: theme.palette.primary.background
    },
    contentDesktop: {
        width: '100%',
        minHeight: '100%',
        position: 'fixed',
        // left: 0,
        backgroundColor: theme.palette.primary.background,
        padding: 24
    },
    contentMobile: {
        width: '100vw',
        position: 'fixed',
        maxHeight: 'calc(100% - 58px)',
        overflowY: 'scroll',
        bottom: 0,
        backgroundColor: theme.palette.primary.background,
        borderRadius: '35px 35px 0 0',
        padding: 24
    },
    continueButton: {
        width: '100%',
        height: 58,
        borderRadius: 29,
        marginTop: 32,
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        fontWeight: 'bold',
        textTransform: 'none',
        fontSize: mobile ? 16 : 18,
        boxShadow: '0px 5px 20px 0px rgba(59,102,246,0.4)'
    },
    title: {
        fontWeight: 900,
        fontSize: mobile ? 22 : 34,
        color: theme.palette.primary.darkText,
        textAlign: 'left'
    },
    subTitle: {
        fontWeight: 500,
        fontSize: mobile ? 14 : 18,
        color: theme.palette.primary.lightText,
        textAlign: 'left',
        opacity: 0.6,
        marginTop: 12
    },
    gridDesktop: {
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        background: theme.palette.primary.background
    },
    productTitle: {fontSize: 16, fontWeight: 'bold', color: theme.palette.darkText},
    productSubTitle: {fontSize: 12, color: theme.palette.lightText},
    productPriceText: {fontSize: 14, fontWeight: 'bold', marginTop: 14,color: theme.palette.primary.main},
    notSelectedProduct: {
        width: 16, height: 16,
        display: 'inline-block', verticalAlign: 'middle', marginTop: 4, backgroundColor: 'transparent', border: '1px solid ' + theme.palette.primary.main, width: 16, height: 16, borderRadius: 8, marginLeft: 4, marginRight: 12, marginTop: -12
    },
    selectedProduct: {
        width: 17, height: 17,
        display: 'inline-block', verticalAlign: 'middle', marginTop: 4, color: theme.palette.primary.main, marginLeft: 4, marginRight: 12, marginTop: -12
    },
    showMoreButton: {
        marginTop: 12, color: theme.palette.primary.main, fontWeight: 'bold', textTransform: 'none'
    },
    mobileContainer: {
        backgroundColor: theme.palette.primary.background,
        padding: 24,
        marginTop: 55,
        borderRadius: '24px 24px 0 0',
        height: 'calc(100vh - 55px)',
        overflowY: 'scroll'
    },
    root: {
        backgroundColor: theme.palette.primary.main,
    }
}));

const Step2 = ({
    errorFullname,
    handleFullname,
    isNewUser,
    errorEmail,
    handleEmail,
    handleDateTime,
    errorDateTime,
    setErrorLocation,
    setLocation,
    errorLocation,
    productsToShow,
    selectedProduct,
    handleChangeRadioButton,
    showMore,
    products,
    setShowMore,
    errorProduct,
    setSelectedProduct,
    completeOrder,
    loading,
    tab,
    setTab,
    connectToBusiness,
    handleChangeSwitch,
    businessAccountId,
    productsFromPackages,
    isAdmin,
    startMatching,
    openPackages,
    isPackage,
    setIsPackage,
    landingPage
}) => {
    const classes = useStyles();

    const getBalance = (productId) => {
        for (let index = 0; index < openPackages.length; index++) {
            let pack = openPackages[index];
            if (pack.singleProductId === productId.slice(0, productId.length - 3)) {
                return pack.balance
            }
        }
        return 'error'
    }


    const disabledDate = (current) => {
        // Can not select days before today and today
        return current && current < moment().subtract(1, 'days').endOf('day');
      }
    
    //   const getDateTimeToDisplay = () => {
    //     var dateAndTime = ''
    //     var splitedDate = props.main.state.date.split('-')
    //     dateAndTime = splitedDate[1] + "/" +  splitedDate[2] + "/" + splitedDate[0] + " " + props.main.state.time
    //     return dateAndTime
    //   }
    
      const onchangeDateTime = (e) => {
        console.log(e._d)
        handleDateTime(e._d)
        // handleDateChangeFrom(e._d)
        // handleTimeChangeFrom(e._d)
      }

      const getPageStyle = () => {
          if (!landingPage) {
              return classes.insidePlatform
          }
          if (mobile) {
            return classes.coverImageMobile
          } else {
            return classes.coverImageDesktop
          }
      }

    return (
        <>
            <Grid item xs={12} sm={12} md={12} xl={12} lg={12} className={classes.root}>
                {/* {landingPage && (
                    <img alt="" src={require('../../../Assets/orderBackground.jpg')} className={ mobile ? classes.coverImageMobile : classes.coverImageDesktop} />
                )} 
                {landingPage && (
                    <div alt="" className={ mobile ? classes.coverImageMobile : classes.coverImageDesktop}></div>
                )}  */}
                <div className={getPageStyle()}>
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    className={
                        (mobile || !landingPage) ? 
                            classes.mobileContainer
                         : classes.gridDesktop
                    }
                > 
                {console.log('test', (mobile || !landingPage))}
                    <Grid item xs={mobile ? 12 : 11} sm={mobile ? 12 : !landingPage ? 10 : 8} md={mobile ? 12 : !landingPage ? 9 : 7} xl={mobile ? 12 : !landingPage ? 8 : 6} lg={mobile ? 12 : !landingPage ? 7 : 5}> 
                        <div className={classes.title}>
                            New Order
                        </div>
                        <div className={classes.subTitle}>
                            Order photo shoot and get your real-estate images within 24 hours.
                        </div>
                        <Grid
                            container
                            direction="row"
                            justify="center"
                            alignItems="center"
                            spacing={2}
                        > 
                            <Grid item xs={12} sm={12} md={6} xl={6} lg={6}> 
                                {isNewUser && (
                                    <div style={{marginTop: 12, width: '100%'}}>
                                        <TextField id="fullName" label="Full Name" fullWidth
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            error={errorFullname}
                                            helperText={errorFullname ? 'Error full name' : ''}
                                            onChange={handleFullname}
                                        />
                                    </div>
                                )}
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} xl={6} lg={6}> 
                                {isNewUser && (
                                    <div style={{marginTop: 12, width: '100%'}}>
                                        <TextField id="email" label="Email" fullWidth
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            error={errorEmail}
                                            helperText={errorEmail ? 'Error email' : ''}
                                            onChange={handleEmail}
                                        />
                                    </div>  
                                )}
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} xl={6} lg={6}> 
                                <div style={{marginTop: 12}}>
                                    <div style={{fontSize: 12, color: errorDateTime ? '#f44336' : 'gray', width: '100%', textAlign: 'left', fontFamily: 'Arial'}}>Date & Time</div>
                                    <DatePicker
                                        renderExtraFooter={() => <div style={{height: 0}}><div style={{backgroundColor: 'white', width: '50%', position: 'relative', top: 10, height: 50}}></div></div> }
                                        minuteStep={15}
                                        // bordered={false}
                                        format={'DD/MM/YYYY HH:mm'}
                                        hideDisabledOptions={true}
                                        placeholder=""
                                        onChange={onchangeDateTime}
                                        clearIcon={(<div></div>)}
                                        disabledDate={disabledDate}
                                        showToday={false}
                                        showNow={false}
                                        id='dateAndTime'
                                        disabledHours={()=>[0,1,2,3,4,5]}
                                        style={{
                                            width: '100%',
                                            height: 32,
                                            background: 'transparent',
                                            "antd-input:hover:focus:select": {
                                                borderBottom: '2px solid black',
                                                outline: 'none',
                                            },
                                            outline: 'none',
                                            border: 'none',
                                            borderBottom: errorDateTime ? '2px solid #f44336' : '1px solid gray',
                                            color: 'black',
                                            padding: '4px 5px 4px 0px'
                                        }}
                                        // style={{
                                        //     // backgroundColor: '#ecf1fe', outline: 'none', fontSize: 20, width: '100%', paddingTop: 7, borderRadius: 28, height: 56, paddingLeft: 20, paddingRight: 20, border: '1px solid #DAE2FD',
                                        // "antd-input:hover:focus:select": {
                                        //     // border: '1px solid #DAE2FD',
                                        //     outline: 'none',
                                        // },}}
                                        showTime
                                    />
                                    {errorDateTime && (
                                        <div style={{fontSize: 12, marginTop: 3, color: '#f44336', width: '100%', textAlign: 'left', fontFamily: 'Arial', marginTop: 4}}>Error date and time</div>
                                    )}
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} xl={6} lg={6}> 
                                <div style={{marginTop: 12, width: '100%'}}>
                                    <Location style={{width: '100%'}} setErrorLocation={setErrorLocation} setLocation={setLocation} errorLocation={errorLocation}/>
                                </div>
                            </Grid>
                        </Grid>
                        {businessAccountId !== null && (
                            <div style={{marginTop: 24, width: '100%'}}>
                                <FormGroup row>
                                    <FormControlLabel
                                        control={<Switch checked={connectToBusiness} onChange={handleChangeSwitch} name="connectToBusiness" />}
                                        label="Connect to my business account"
                                    />
                                </FormGroup>
                            </div>
                        )}
                        {isAdmin && (
                            <div style={{marginTop: 24, width: '100%'}}>
                                <FormGroup row>
                                    <FormControlLabel
                                        control={<Switch checked={startMatching} onChange={handleChangeSwitch} name="startMatching" />}
                                        label="Start auto-matching for this photoshoot"
                                    />
                                </FormGroup>
                            </div>
                        )}
                        <div style={{marginTop: 24, width: '100%', marginBottom: 24}}>
                            <Tabs tab={tab} setTab={setTab} />
                        </div>
                        {/* {productsFromPackages.length > 0 && (
                            <div style={{marginTop: 18, width: '100%', textAlign: 'left', fontSize: 16}}>
                                Use from open package:
                            </div>
                        )} */}
                        <Grid
                            container
                            direction="row"
                            justify="center"
                            alignItems="center"
                            spacing={2}
                        > 
                            {(productsToShow.length === 0) && (
                                <Grid item xs={12} sm={12} md={6} xl={6} lg={6}> 
                                    <Skeleton variant="rect" width={'100%'} height={70} style={{marginTop: 0}} />
                                </Grid>
                            )} 
                            {(productsToShow.length === 0) && (
                                <Grid item xs={12} sm={12} md={6} xl={6} lg={6}> 
                                    <Skeleton variant="rect" width={'100%'} height={70} style={{marginTop: 0}} />
                                </Grid>
                            )} 
                            {(productsToShow.length === 0) && (
                                <Grid item xs={12} sm={12} md={6} xl={6} lg={6}> 
                                    <Skeleton variant="rect" width={'100%'} height={70} style={{marginTop: 0}} />
                                </Grid>
                            )} 
                            {(productsToShow.length === 0) && (
                                <Grid item xs={12} sm={12} md={6} xl={6} lg={6}> 
                                    <Skeleton variant="rect" width={'100%'} height={70} style={{marginTop: 0}} />
                                </Grid>
                            )} 
                            {productsToShow.length > 0 && productsFromPackages.map((item, index) => (
                                <Grid item xs={12} sm={12} md={6} xl={6} lg={6}> 
                                    <div style={{marginTop: 0, width: '100%'}} onClick={()=> {setSelectedProduct(item); setIsPackage(true)}}>
                                        <div style={{width: '100%', borderRadius: 8, padding: 8, textAlign: 'left', border: (selectedProduct.Id === item.Id && isPackage) ? '1px solid #6334FF' : '0px solid rgba(0,0,0,0.1)', backgroundColor: (selectedProduct.Id === item.Id && isPackage) ? 'white' : 'white', height: 70, boxShadow: '0px 4px 8px 0px rgba(99,52,255,0.1)'}}>
                                            {(selectedProduct.Id === item.Id && isPackage) ? (
                                                <CheckCircleIcon 
                                                    className={classes.selectedProduct}
                                                />
                                            ) : (
                                                <div className={classes.notSelectedProduct}>
                                                </div>
                                            )}
                                            <div style={{display: 'inline-block', verticalAlign: 'middle', marginTop: 4}}>
                                                {console.log(item)}
                                                <div className={classes.productTitle}>{item.Public_Product_Name__c.slice(0, 20)} - {getBalance(item.Id)}</div>
                                                <div className={classes.productSubTitle}>{item.Number_of_Images_for_Customer__c} proffesional images</div>
                                            </div>
                                            <div style={{display: 'inline-block', verticalAlign: 'middle', textAlign: 'center', float: 'right'}}>
                                                <div style={{fontSize: 14, fontWeight: 'bold', marginTop: 14, color: '#57C282'}}>Paid</div>
                                            </div>
                                        </div>
                                    </div>
                                </Grid>
                            ))} 
                            {productsToShow.map((item, index) => (
                                <Grid item xs={12} sm={12} md={6} xl={6} lg={6}> 
                                <div style={{marginTop: 0, width: '100%'}} onClick={()=> {setSelectedProduct(item); setIsPackage(false)}}>
                                        <div style={{width: '100%', borderRadius: 8, padding: 8, textAlign: 'left', border: (selectedProduct.Id === item.Id && !isPackage) ? '1px solid #6334FF' : '0px solid rgba(0,0,0,0.1)', backgroundColor: (selectedProduct.Id === item.Id && !isPackage) ? 'white' : 'white', height: 70, boxShadow: '0px 4px 8px 0px rgba(99,52,255,0.1)'}}>
                                            {(selectedProduct.Id === item.Id && !isPackage) ? (
                                                <CheckCircleIcon 
                                                    className={classes.selectedProduct}
                                                />
                                            ) : (
                                                <div className={classes.notSelectedProduct}>
                                                </div>
                                            )}
                                            <div style={{display: 'inline-block', verticalAlign: 'middle', marginTop: 4}}>
                                                <div className={classes.productTitle}>{item.Public_Product_Name__c.slice(0, 20)}</div>
                                                <div className={classes.productSubTitle}>{item.Number_of_Images_for_Customer__c} proffesional images</div>
                                            </div>
                                            <div style={{display: 'inline-block', verticalAlign: 'middle', textAlign: 'center', float: 'right'}}>
                                                <div className={classes.productPriceText}>{item.Total_Cost__c} ILS</div>
                                            </div>
                                        </div>
                                    </div>
                                </Grid>
                            ))}
                        </Grid>
                        
                        {/* {productsFromPackages.length > 0 && (
                            <div style={{marginTop: 0, width: '100%', textAlign: 'left', borderBottom: '1px solid rgba(0,0,0,0.2)'}}>
                            </div>
                        )} */}
                        {(!showMore && products.length > 3) && (
                            <div style={{width: '100%'}}>
                                <center>
                                    <Button className={classes.showMoreButton} onClick={()=> setShowMore(true)}>Show More</Button>
                                </center>
                            </div>
                        )}
                            {showMore && (
                            <div style={{width: '100%'}}>
                                <center>
                                    <Button className={classes.showMoreButton} onClick={()=> setShowMore(false)}>Show Less</Button>
                                </center>
                            </div>
                        )}
                        {errorProduct && (
                            <div style={{width: '100%', fontSize: 14, textAlign: 'left', color: 'red'}}>Please select product</div>
                        )}
                        {loading && (
                            <center>
                                <CircularProgress size={48} style={{width: '100%', height: 48, width: 48, marginTop: 37}} />
                            </center>
                        )}
                        {!loading && (
                            <Button variant="contained" onClick={()=> completeOrder()} className={classes.continueButton} style={{width: (!mobile || !landingPage) ? 140 : '100%'}}>Order</Button>
                        )}
                    </Grid>
                    </Grid>
                </div>
            </Grid> 
        </>
    );
}
 
export default Step2;