import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
// import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
// import List from '@material-ui/core/List';
// import Divider from '@material-ui/core/Divider';
// import ListItem from '@material-ui/core/ListItem';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PhoneIcon from '@material-ui/icons/Phone';

import BillingIcon from '@material-ui/icons/CreditCard';
import MembersIcon from '@material-ui/icons/AccountTree';
import Grid from '@material-ui/core/Grid';
import { SnackbarProvider, useSnackbar } from 'notistack';
import Snackbar from '@material-ui/core/Snackbar';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios';
import { withRouter } from "react-router-dom";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import { AuthContext } from '../../../../contexts/Auth';

import { Layout, Menu, Breadcrumb } from 'antd';
import { CameraOutlined, CreditCardOutlined, UserOutlined, LogoutOutlined } from '@ant-design/icons';
import { Hidden } from '@material-ui/core';

const { TabPane } = Tabs;

const { Header, Content, Footer, Sider } = Layout;

// import Menu from './SettingMenuContent';
// import MembersPage from './MembersPage';
// import DetailsPage from './DetailsPage';
// import BusinessDetails from './BusinessDetails';

const saleBanner = require("../../../../Assets/sale-banner.png");
var mobile = (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1)

const cameraIcon = require("../../../../Assets/cameraTableIcon.png");

const noImage = require("../../../../Assets/no_image.png");

const dateIcon = require("../../../../Assets/dateIcon.png");
const locationIcon = require("../../../../Assets/locationIcon.png");
const userIcon = require("../../../../Assets/userIcon.png");
const imageIcon = require("../../../../Assets/imageIcon.png");


const styles = theme => ({
  list: {
    width: 250
  },
  fullList: {
    width: 'auto',
  },
  root: {
    width: '100vw',
    height: '100vh',
    backgroundColor: '#6C65FF',
    backgroundColor: 'white'
  },
  button: {
    height: 50,
    marginTop: 15,
    float: 'left',
    borderRadius: 25,
    paddingLeft: 32,
    paddingRight: 32,
    boxShadow: 'none',
    hover: {
      backgroundColor: '#33D7A0'
    }
  }
});

class PhotoShoots extends React.Component {

  static contextType = AuthContext

  state = {
    isLoading: false,
    requests: [],
    currentTab: 0,

  }

  componentWillMount() {
  }

  handleChangeTab = (event, newValue) => {
    this.setState({currentTab: newValue})
  };

  componentDidMount = () => {
    var contactId = localStorage.getItem("contactId")
  }

  logout = () => {
    localStorage.removeItem('wesnapp-token');
    this.props.history.push("/login")
    window.location.reload();
  }

  render() {
    const { classes, theme } = this.props;

    return (
      <div className={styles.root} style={{backgroundColor: 'white', borderRadius: '35px 35px 0 0px', marginTop: 10, minHeight: '100vh'}}>
              <div>
                <Grid container spacing={2} style={{padding: 0, paddingTop: 0}} justifyContent="center" justify="center">
                  {/* <Grid item xs={12} sm={12} md={10} xl={9}>
                    <div style={{fontSize: 24, color: 'rgb(40,40,53)', textAlign: 'left', marginLeft: 0, fontFamily: "'Montserrat', sans-serif", marginTop: mobile ? 0 : 15, marginBottom: mobile ? 0 : 30}}>
                      <b>My Account</b>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={11} xl={9} style={{marginTop: -20, paddingBottom: 40}}>
                  <Paper square elevation={0}>
                    <Tabs
                      value={this.state.currentTab}
                      centered
                      onChange={this.handleChangeTab}
                      indicatorColor="primary"
                      textColor="primary"
                      aria-label="icon label tabs example"
                    >
                      <Tab style={{marginLeft: 20, marginRight: 20}} icon={<PhoneIcon />} label="General Details" />
                      <Tab style={{marginLeft: 20, marginRight: 20}} icon={<BillingIcon />} label="Billing Details" />
                    </Tabs>
                  </Paper>
                </Grid>
                {this.state.currentTab === 0 && (
                  <Grid item xs={12} sm={12} md={11} xl={9} style={{}}>
                    <DetailsPage />
                  </Grid>
                )}
                {this.state.currentTab === 1 && (
                  <Grid item xs={12} sm={12} md={11} xl={9} style={{}}>
                    <BusinessDetails />
                  </Grid>
                )} */}
                </Grid>
            </div>
        </div>
    );
  }
}

PhotoShoots.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(withRouter(PhotoShoots));
