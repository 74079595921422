import React, { createContext, useState, useEffect } from 'react';
import ReactPixel from 'react-facebook-pixel';

export const PixelContext = createContext();

function PixelContextProvider(props) {

    useEffect(() => {
        const advancedMatching = { }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
        const options = {
            autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
            debug: false, // enable logs
        };
        ReactPixel.init('717379229167457', advancedMatching, options);
    }, [])
    
    return (
        <PixelContext.Provider value={{
        }}>
            {props.children}
        </PixelContext.Provider>
    );
}
 
export default PixelContextProvider;