import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function FormDialog(props) {

  const handleClickOpen = () => {
    props.main.setState({smsDialog: true})
  };

  const handleClose = () => {
    props.main.setState({smsDialog: false})
  };

  const handleChangeText = name => event => {
    props.main.setState({ [name]: event.target.value });
  };

  return (
    <div>
      <Dialog fullWidth open={props.main.state.smsDialog} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Verification</DialogTitle>
        <DialogContent>
          <DialogContentText>
            We sent you a varification code via SMS. Please enter your code from the messages app.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Code"
            type="number"
            fullWidth
            onChange={handleChangeText('inputCode')}
          />
          {props.main.state.errorCode && (
            <div style={{color: 'red', fontSize: 15, fontFamily: "'Montserrat', sans-serif", float: 'left', marginTop: 8}}>Error verification code</div>
          )}
        </DialogContent>
        <DialogActions style={{width: '100%'}}>
          <Button onClick={handleClose} color="primary" style={{float: 'left'}}>
            Cancel
          </Button>
          <Button onClick={handleClose} color="primary" style={{float: 'right', display: 'none'}}>
            Resend Code
          </Button>
          <Button onClick={() => props.main.verifyCode()} color="primary" style={{float: 'right'}}>
            Enter
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
