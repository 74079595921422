import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PhoneIcon from '@material-ui/icons/Phone';
import Table from './BillingTable';
import Grid from '@material-ui/core/Grid';
import axios from 'axios';
import { withRouter } from "react-router-dom";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import { AuthContext } from '../../../../contexts/Auth';


var mobile = (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1)

const styles = theme => ({
  list: {
    width: 250
  },
  fullList: {
    width: 'auto',
  },
  root: {
    width: '100vw',
    height: '100vh',
    backgroundColor: '#6C65FF',
    backgroundColor: 'white'
  },
  button: {
    height: 50,
    marginTop: 15,
    float: 'left',
    borderRadius: 25,
    paddingLeft: 32,
    paddingRight: 32,
    boxShadow: 'none',
    hover: {
      backgroundColor: '#33D7A0'
    }
  }
});

class PhotoShoots extends React.Component {

  static contextType = AuthContext

  state = {
    isLoading: false,
    documents: [],
    currentTab: 0,
    tableData: []
  }

  componentWillMount() {
    this.getUserDocuments()
  }

  createData = (number, type, order, totalAmount, date, link) => {
    return {number, type, order, totalAmount, date, link}
  }

  getUserDocuments = () => {
    var accountId = localStorage.getItem("accountId")
    var data = {accountId: accountId}
    axios.post('https://wesnapp.co.il' + '/api/account/get-related-documents',data, {headers: {'Content-Type': 'application/json'}}).then((response) => {
        console.log(response.data)
        var docs = response.data
        docs.sort((a, b) => (new Date(b.Document_Date__c).getTime()) - (new Date(a.Document_Date__c).getTime()));
        var relevantDocuments = []   
        var tableData = [] 
        var obj = {}
        if (docs.length > 0) {
          docs.forEach(doc => {
            if (doc.Document_Type__c === 'Invoice' || doc.Document_Type__c === 'Receipt' || doc.Document_Type__c === 'Invoice / Receipt') {
              if (!doc.Document_Date__c.includes('2019')) {
                relevantDocuments.push(doc)
                var tableRow = this.createData(doc.Name, doc.Document_Type__c, doc.WS_Order_Name__c, doc.Document_Total_Amount__c, doc.Document_Date__c, doc.Document_Link__c)
                tableData.push(tableRow)
                if (doc.WS_Order_Name__c in obj) {
                  var tempData = obj[doc.WS_Order_Name__c]
                  tempData.push(tableRow)
                  obj[doc.WS_Order_Name__c] = tempData
                } else {
                  obj[doc.WS_Order_Name__c] = [tableRow]
                }
              }
            }
          });
          tableData.sort((a, b) => (new Date(b.date).getTime()) - (new Date(a.date).getTime()));
          console.log(tableData)
          console.log(Object.keys(obj))
          this.setState({documents: relevantDocuments, tableData: obj})
        }
    })
    .catch( (error) => {
      //console.log(error);
    });
  }

  handleChangeTab = (event, newValue) => {
    this.setState({currentTab: newValue})
  };

  componentDidMount = () => {
    var contactId = localStorage.getItem("contactId")
  }

  render() {
    const { classes, theme } = this.props;

    

    return (
      <div className={styles.root} style={{marginTop: 10, padding: mobile ? 10 : 0, paddingTop: mobile ? 30 : 0, paddingBottom: 150}}>
        <div style={{fontSize: 24, color: 'rgb(40,40,53)', textAlign: 'left', fontFamily: "'Montserrat', sans-serif", marginTop: mobile ? 0 : 27, marginBottom: mobile ? 0 : 30}}>
            <b>Billing</b>
        </div>
        {Object.keys(this.state.tableData).length > 0 && (
          <>
            {Object.keys(this.state.tableData).map((key, i) => (
              <div style={{marginTop: i !== 0 ? 0 : 0, width: '100%'}}>
                <Table tableData={this.state.tableData[key]} header={i === 0} />
              </div>
            ))}
            <div style={{textAlign: 'left', marginTop: 12}}>* For invoices before Jan 2020 please contact us</div>
            </>
        )}
      </div>
    );
  }
}

PhotoShoots.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(withRouter(PhotoShoots));
