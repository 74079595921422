import { CircularProgress, Container } from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import ReactPlayer from 'react-player'
import { Skeleton } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import { Button } from '@material-ui/core';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.primary.background,
    paddingTop: 24, paddingBottom: 24
  }
}));

const VideoComponent = (props) => {

    const classes = useStyles();
    const [requestId, setRequestId] = useState('')
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (props.match.params.id) {
            setRequestId(props.match.params.id)
        }
    }, [])

    const startMatching = async () => {
        setLoading(true)
        let response = await axios.post('https://wesnapp.co.il/api/match/start-match', {requestId: requestId}, {headers: {'Content-Type': 'application/json'}})
        setLoading(false)
        setRequestId('')
        alert(response.data)
        
    }

    return (
        <div className={classes.root}>
            <Container maxWidth={'md'}>
                <img src={require('../../Assets/logo.png')} height='24px' alt='' />
                <div style={{marginTop: 24, display: 'flex', alignItems: 'center', justifyContent: 'center', flexFlow: 'column'}}>
                    <Typography style={{fontSize: 18}}>Start matching manually</Typography>
                    <TextField style={{marginTop: 12}} value={requestId} label="Request Id" onChange={(e)=> setRequestId(e.target.value)} />
                    <div>
                        {loading ? (
                            <CircularProgress />
                        ) : (
                            <Button onClick={startMatching} style={{marginTop: 12}} disabled={requestId.length < 18} variant="fill">Start Matching</Button>
                        )}
                    </div>
                </div>
            </Container>
        </div>
    );
}
 
export default VideoComponent;