import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios';
import { withRouter } from "react-router-dom";
import TextField from '@material-ui/core/TextField';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

var mobile = (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1)

const styles = theme => ({
  list: {
    width: 250
  },
  fullList: {
    width: 'auto',
  },
  root: {
    width: '100%',
    backgroundColor: '#6C65FF',
    backgroundColor: 'white',
    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: 28,
      borderColor: '#DAE2FD',
    },
    '& .MuiOutlinedInput-input': {
      backgroundColor: '#ecf1fe',
      borderRadius: 28
    },
  },
  button: {
    color: 'white',
    height: 50,
    backgroundColor: theme.palette.primary.main,
    textTransform: 'none',
    fontWeight: 'bold',
    paddingLeft: 32,
    paddingRight: 32,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
    boxShadow: `0 4px 10px ${theme.palette.primary.main}40`,
    borderRadius: 25
  },
});

function createData(id, name, mobile, startAt, watchShootings, useOrders, canSelect, accounter) {
  return { id, name, mobile, startAt, watchShootings, useOrders, canSelect, accounter };
}

class PhotoShoots extends React.Component {

  state = {
    isLoading: true,
    openAddNew: false,
    tableData: null,
    bAccountId: '',
    requests: [],
    bAccountExists: null,
    bEmail: '',
    errorBusinessName: false,
    errorBusinessEmail: false,
    creatingNewBusinessName: false,
    billingAddress: '',
    errorBillingAddress: false,
    bName: ''
  }

  componentDidMount() {
    this.getBusinessAccount()
  }

  componentWillMount() {

  }

  handleChangeText = name => event => {
    if (name === 'phone') {
      this.setState({ [name]: event.target.value.replace('-', '') });
    } else {
      this.setState({ [name]: event.target.value });
    }
  };


  getBusinessAccountData = (accountId) => {
    var data = {id: accountId}
    axios.post('https://wesnapp.co.il' + '/api/account/get-one',data, {headers: {'Content-Type': 'application/json'}}).then((response) => {
        console.log(response.data)
        var businessData = response.data[0]
        this.setState({
          businessData: businessData,
          bName: response.data[0].Name,
          bAccountExists: true,
          bAccountId: response.data[0].AccountId,
          isLoading: false
        })
    })
    .catch( (error) => {
      //console.log(error);
    });
  }

  getBusinessAccount = () => {
    var contactId = localStorage.getItem("contactId")
    var data = {contactId: contactId}
    axios.post('https://wesnapp.co.il' + '/api/account/get-business-account',data, {headers: {'Content-Type': 'application/json'}}).then((response) => {
        console.log(response.data)    
        if (response.data.length > 0) {
            this.getBusinessAccountData(response.data[0].AccountId)
        } else {
          // Don't have business account
          this.setState({bAccountExists: false, bAccountId: '', tableData: [], isLoading: false})
        }
    })
    .catch( (error) => {
      //console.log(error);
    });
  }

  createNewBusinessAccount = () => {

    // Create new bAccount for the current person
    var canContinue = true
    if (this.state.bName.length < 2) {
      this.setState({errorBusinessName: true})
      canContinue = false
    }

    // if (this.state.billingAddress.length < 2) {
    //   this.setState({errorBillingAddress: true})
    //   canContinue = false
    // }

    if (canContinue) {
      this.setState({creatingNewBusinessName: true})
      var contactId = localStorage.getItem("contactId")
      // , billingAddress: this.state.billingAddress
      var data = {bName: this.state.bName, contactId: contactId}
      axios.post('https://wesnapp.co.il' + '/api/account/create-new-business-account',data, {headers: {'Content-Type': 'application/json'}}).then((response) => {
          console.log(response.data)
          this.setState({bAccountId: response.data, bAccountExists: true, tableData: null})
          this.getAllRelatedAccounts(response.data)
      })
      .catch( (error) => {
        //console.log(error);
      });
    }
  }

  render() {
    const { classes, theme } = this.props;

    return (
      <div style={{backgroundColor: 'white', borderRadius: '23px', boxShadow: '0px 4px 10px #6334FF10', marginTop: 0, width: '100%', padding: 24, marginBottom: 24}}>
      <Grid container spacing={2} style={{padding: 0, paddingTop: 0, width: '100%'}} justifyContent="center" justify="center">
      <Grid item xs={12} sm={10} md={8} xl={7}>
              <div style={{fontSize: 20, color: 'rgb(40,40,53)', textAlign: 'left', marginLeft: 0, fontFamily: "'Montserrat', sans-serif"}}>
                  <b>Businness Related</b>
              </div>
          </Grid>   
            <Grid item xs={12} sm={6} md={8} xl={7}>
                  {
                      (this.state.bAccountExists === false && this.state.isLoading === false) ? (
                        <div>
                          <div style={{fontSize: 16, textAlign: 'left'}}>
                            To add memebers to your account you need to define your business account:
                          </div>
                          <div style={{marginTop: 20, display: 'inline-block', width: '85%'}}>
                            <TextField id="outlined-basic" label="Enter your business name" fullWidth  onChange={this.handleChangeText('bName')} value={this.state.bName} />
                          </div>
                          <div style={{height: 30}}>
                            {this.state.errorBusinessName && (
                              <div style={{color: 'red', fontSize: 15, fontFamily: "'Montserrat', sans-serif", marginTop: 8, width: '100%', float: 'left', textAlign: 'left'}}>Error business name</div>
                            )}
                          </div>
                          <center>
                            {!this.state.creatingNewBusinessName ? (
                                <Button
                                  variant="contained"
                                  color="primary"
                                  className={classes.button}
                                  onClick= {()=> this.createNewBusinessAccount()}
                                >
                                  Create
                                </Button>
                            ) : (
                              <CircularProgress size={35} style={{marginTop: 10}}/>
                            )}
                          </center>
                        </div>
                      ) : (
                        <center>
                            {this.state.isLoading === false && (
                                <div style={{fontSize: 18, textAlign: 'center', marginTop: 30, width: '100%'}}>
                                    Business account name {this.state.bName}
                                </div>
                            )}
                        </center>
                      )
                    }
                  </Grid>
            </Grid>
        </div>
    );
  }
}

PhotoShoots.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(withRouter(PhotoShoots));
