const mobile = (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);

const drawerWidth = 360;

export default (theme) => ({
    root: {
        display: 'flex',
        width: '100%',
        // backgroundColor: 'rgb(255,255,255)',
        backgroundColor: theme.palette.primary.background,
        textAlign: 'left',
        minHeight: '100%',
        paddingBottom: 120
    },
    photosActionBar: {
        display: 'flex',
        width: '100%',
        textAlign: 'left',
        alignItems: 'center'
    },
    titleFlexGrow: {
        flexGrow: 1
    },
    imageContainer: {backgroundColor: 'rgb(255,255,255)', boxShadow: '0px 4px 8px 0px rgba(99,52,255,0.05)', borderRadius: 24, width: '100%', height: 280},
    image: {backgroundColor: 'rgb(255,255,255)', boxShadow: '0px 4px 8px 0px rgba(99,52,255,0.05)', cursor: 'pointer', borderRadius: '24px 24px 0 0', width: '100%', height: 210, objectFit: 'cover'},
    imageActionBar: {display: 'flex', padding: 12, marginTop: 2, alignItems: 'center'},
    imageName: {fontSize: 18, flexGrow: 1},
    rowTitle: {display: 'inline-block', fontFamily: "'Montserrat', sans-serif", fontSize: 15, fontWeight: 'bold', textAlign: 'left', color: 'rgb(40,40,53)', flexGrow: 1},
    rowValue: {display: 'inline-block', fontSize: 15, color: 'rgba(0,0,0,0.6)'},
    rowItem: {display: 'flex', marginTop: 18},
    selectAllButton: {
        border: '1px solid black',
        borderColor: theme.palette.primary.main,
        height: 42,
        borderRadius: 21,
        textTransform: 'none',
        fontWeight: 'semi-bold',
        paddingLeft: 18,
        paddingRight: 18,
        color: theme.palette.primary.main
    },
    drawerDownloadButton: {
        border: '1px solid black',
        display: 'block',
        borderColor: theme.palette.primary.main,
        height: 48,
        borderRadius: 24,
        textTransform: 'none',
        fontWeight: 'semi-bold',
        paddingLeft: 18,
        paddingRight: 18,
        width: '100%',
        marginTop: 24,
        color: theme.palette.primary.main
    },
    drawerDisabledDownloadButton: {
        border: '1px solid black',
        display: 'block',
        borderColor: 'gray',
        height: 48,
        borderRadius: 24,
        textTransform: 'none',
        fontWeight: 'semi-bold',
        paddingLeft: 18,
        paddingRight: 18,
        width: '100%',
        marginTop: 24,
        color: theme.palette.primary.main
    },
    selectButton: {
        border: '1px solid black',
        borderColor: theme.palette.primary.main,
        height: 38,
        fontWeight: 'semi-bold',
        borderRadius: 19,
        textTransform: 'none',
        paddingLeft: 18,
        paddingRight: 18,
        color: theme.palette.primary.main,
        width: 98
    },
    selectedButton: {
        border: '1px solid black',
        borderColor: theme.palette.primary.main,
        backgroundColor: theme.palette.primary.main,
        height: 38,
        borderRadius: 19,
        textTransform: 'none',
        paddingLeft: 18,
        paddingRight: 18,
        color: 'white',
        width: 98
    },
    selectionConfirmedButton: {
        border: '1px solid black',
        borderColor: theme.palette.primary.success,
        backgroundColor: theme.palette.primary.success,
        height: 38,
        borderRadius: 19,
        textTransform: 'none',
        paddingLeft: 18,
        paddingRight: 18,
        color: 'white',
        width: 98,
        '&:hover': {
            borderColor: theme.palette.primary.success,
            backgroundColor: theme.palette.primary.success,
            color: 'white'
        }
    },
    formControl: {
        margin: theme.spacing(3),
        textAlign: 'left',
        float: 'left',
    },
    title: {
        flexGrow: 1,
    },
    text: {
        color: 'rgb(40,40,53)'
    },
    hide: {
        display: 'none',
    },
    menuButton: {
        marginRight: 36,
        color: 'black'
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        maxWidth: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        borderRadius: !mobile ? '35px 0px 0px 35px' : '35px 35px 0px 0px',
        backgroundColor: 'rgb(238,242,249)',
        border: 'none'
    },
    drawerOpen: {
        width: drawerWidth,
        maxWidth: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        backgroundColor: 'rgb(238,242,249)',
        borderRadius: '24px 0 0 24px',
        border: 'none',
        backgroundColor: 'rgb(255,255,255)', boxShadow: '0px 4px 8px 0px rgba(99,52,255,0.05)'
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9) + 1,
        },
        backgroundColor: 'rgb(238,242,249)',
        borderRadius: '24px 0 0 24px',
        border: 'none',
        backgroundColor: 'rgb(255,255,255)', boxShadow: '0px 4px 8px 0px rgba(99,52,255,0.05)'
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    mapWrapper: {
        width: '100%',
        display: 'inline-block',
        verticalAlign: 'top',
        borderRadius: 30,
        height: 200,
        position: 'relative'
    },
    content: {
        flexGrow: 1,
        // paddingLeft: theme.spacing(2),
        // paddingRight: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        paddingTop: theme.spacing(2),
        width: '100%'
    }
}); 
  