import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const steps = [
    {
        imgPath: require('./Images/1.jpg'),
    },
    {
        imgPath: require('./Images/2.jpg'),
    },
    {
        imgPath: require('./Images/3.jpg'),
    },
    {
        imgPath: require('./Images/4.jpg'),
    },
    {
        imgPath: require('./Images/5.jpg'),
    },
];

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    position: 'relative'
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    height: 50,
    paddingLeft: theme.spacing(4),
    backgroundColor: theme.palette.background.default,
  },
  img: {
    display: 'block',
    overflow: 'hidden',
    width: '100%',
    height: '100vh',
    objectFit: 'cover'
  },
}));

function SwipeableTextMobileStepper() {
  const classes = useStyles();
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = steps.length;

  const handleNext = () => {
    let step = activeStep === 4 ? 0 : activeStep + 1
    setActiveStep(step);
  };

  const handleBack = () => {
    let step = activeStep === 0 ? 4 : activeStep - 1
    setActiveStep(step);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <div className={classes.root}>
      <KeyboardArrowLeft style={{position: 'absolute', left: 16, color: 'white', top: 'calc(50vh - 40px)', height: 80, width: 80, zIndex: 9999, cursor: 'pointer'}} onClick={handleNext} />
      <KeyboardArrowRight style={{position: 'absolute', right: 16, color: 'white', top: 'calc(50vh - 40px)', height: 80, width: 80, zIndex: 9999, cursor: 'pointer'}} onClick={handleBack} />
      <AutoPlaySwipeableViews
        axis={'rtl' === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {steps.map((step, index) => (
          <div key={step.label}>
            {Math.abs(activeStep - index) <= 2 ? (
              <img className={classes.img} src={step.imgPath} alt={''} />
            ) : null}
          </div>
        ))}
      </AutoPlaySwipeableViews>
    </div>
  );
}

export default SwipeableTextMobileStepper;
