import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
// import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
// import List from '@material-ui/core/List';
// import Divider from '@material-ui/core/Divider';
// import ListItem from '@material-ui/core/ListItem';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
// import ListItemText from '@material-ui/core/ListItemText';
// import InboxIcon from '@material-ui/icons/MoveToInbox';
// import MailIcon from '@material-ui/icons/Mail';
// import Info from '@material-ui/icons/Info';
// import SideMenu from './SideMenu';
// import View from './View';
// import SmsDialog from './SmsDialog';
import Skeleton from '@material-ui/lab/Skeleton';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { SnackbarProvider, useSnackbar } from 'notistack';
import Snackbar from '@material-ui/core/Snackbar';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import SearchAppBar from './SearchAppBar';
import PhotoShootsInProgress from './PhotoShootsInProgress';
import WaitingForSelection from './WaitingForSelection';
import { withRouter } from "react-router-dom";
import TodayRoundedIcon from '@material-ui/icons/TodayRounded';
import InsertDriveFileRoundedIcon from '@material-ui/icons/InsertDriveFileRounded';
import ImageRoundedIcon from '@material-ui/icons/ImageRounded';
import history from '../../../../utils/history'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import { AuthContext } from '../../../../contexts/Auth';
import { Hidden } from '@material-ui/core';

const saleBanner = require("../../../../Assets/sale-banner.png");
var mobile = (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1)

const cameraIcon = require("../../../../Assets/cameraTableIcon.png");

const noImage = require("../../../../Assets/no_image.png");

const dateIcon = require("../../../../Assets/dateIcon.png");
const locationIcon = require("../../../../Assets/locationIcon.png");
const userIcon = require("../../../../Assets/userIcon.png");
const imageIcon = require("../../../../Assets/imageIcon.png");

const styles = theme => ({
  list: {
    width: 250
  },
  fullList: {
    width: 'auto',
  },
  root: {
    // width: '100vw',
    // height: '100vh',
    backgroundColor: theme.palette.primary.background,
    // backgroundColor: 'red',
    // backgroundColor: 'white'
  },
  button: {
    height: 50,
    marginTop: 15,
    float: 'left',
    borderRadius: 25,
    paddingLeft: 32,
    paddingRight: 32,
    boxShadow: 'none',
    hover: {
      backgroundColor: '#33D7A0'
    }
  },
  selectImagesButton: {
    color: '#FE8859',
    backgroundColor: '#FE8859' + '30',
    textTransform: 'none',
    fontWeight: 'bold',
    paddingLeft: 18,
    paddingRight: 18,
    '&:hover': {
      backgroundColor: '#FE8859' + '30',
    },
    // boxShadow: '0 4px 10px #6334FF93',
    borderRadius: 18
  },
  newOrderButton: {
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    textTransform: 'none',
    fontWeight: 'bold',
    paddingLeft: 18,
    paddingRight: 18,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
    width: '100%',
    position: 'fixed',
    bottom: 0,
    left: 0,
    zIndex: 9999,
    borderRadius: 0,
    height: 52
  }
});

class PhotoShoots extends React.Component {

  static contextType = AuthContext

  state = {
    isLoading: false,
    requests: [],
    requestsToShow: [],
    loadingRequests: true
  }

  componentWillMount() {
  }

  componentDidMount = () => {
    var contactId = localStorage.getItem("contactId")
    if (contactId !== null && contactId !== undefined) {
      this.getRelatedRequests(contactId)
      // this.getRequestFromRelationship(contactId)
    }
  }

  getRequestFromRelationship = async (contactId) => {
    // var data = {
    //   contactId: contactId
    // }
    // console.log('xxx')
    // var response = await axios.post('https://wesnapp.co.il' + '/api/request/get-common-by-contact',data, {headers: {'Content-Type': 'application/json'}})
    // console.log('xxx')
    // console.log(response)
  }

  getRelatedRequests = async (contactId) => {

    var relevantRequests = []

    var data = {id: contactId}
    var response = await axios.post('https://wesnapp.co.il' + '/api/request/get-common-by-contact',data, {headers: {'Content-Type': 'application/json'}})
    // var response = await axios.post('http://localhost:8080' + '/api/request/get-common-by-contact',data, {headers: {'Content-Type': 'application/json'}})
    var requests = response.data
    requests.forEach((element, i) => {
      requests[i].Id = requests[i].Id.slice(0, -3)
    });
    axios.post('https://wesnapp.co.il' + '/api/request/get-by-contact',data, {headers: {'Content-Type': 'application/json'}}).then(async (response) => {
    // var response = await axios.post('https://wesnapp.co.il' + '/api/request/get-common-by-contact',data, {headers: {'Content-Type': 'application/json'}})
        if (response.data.length > 0 || requests.length > 0) {
          var responseData = []
          if (response.data.length > 0) {
            responseData = response.data.reverse()
          }
          responseData = responseData.concat(requests)
          responseData = responseData.reverse()
          responseData.sort((a, b) => (new Date(b.Shooting_Date_Time__c).getTime()) - (new Date(a.Shooting_Date_Time__c).getTime()));

          for (let index = 0; index < responseData.length; index++) {
            const r = responseData[index];
            if (r.Status__c !== 'Shooting Canceled By Costumer' && r.Status__c !== 'Shooting Canceled By WeSnapp') {
              if (r.Public_Product_Name__c !== 'Extra Image') {
                relevantRequests.push(r)
              }
            }
          }
          
          this.setState({requests: relevantRequests, requestsToShow: relevantRequests, loadingRequests: false}, ()=> {
            for (var i=0; i<relevantRequests.length; i++) {
              relevantRequests[i]['PreviewImage'] = 'loading'
              relevantRequests[i]['OpenDetails'] = false
              relevantRequests[i].Id = relevantRequests[i].Id.slice(0, -3)
            }

            this.setState({requests: relevantRequests}, ()=> {
              for (var i=0; i<relevantRequests.length; i++) {
                this.getPreviewImage(this.state.requests[i].Id, i)
              }
            })
          })
          // console.log(requests);
          // this.setState({requests: requests})
        } else {
          // var responseData = requests
          // this.setState({requests})
          // responseData.sort((a, b) => (new Date(b.Shooting_Date_Time__c).getTime()) - (new Date(a.Shooting_Date_Time__c).getTime()));

          // this.setState({requests: responseData, loadingRequests: false}, ()=> {
          //   for (var i=0; i<response.data.length; i++) {
          //     responseData[i]['PreviewImage'] = 'loading'
          //     responseData[i]['OpenDetails'] = false
          //     responseData[i].Id = responseData[i].Id.slice(0, -3)
          //   }

          //   this.setState({requests: responseData}, ()=> {
          //     for (var i=0; i<responseData.length; i++) {
          //       this.getPreviewImage(this.state.requests[i].Id, i)
          //     }
          //   })
          // })
        }
    })
    .catch( (error) => {
      //console.log(error);
    });
  }

  getPreviewImage = (requestId, i) => {
    console.log('get preview image')
    axios.get('https://py.wesnapp.co.il' + '/server/get-preview-image/' + requestId + '-edited' ,{}, {headers: {'Content-Type': 'application/json'}}).then((response) => {
      var updatedArray = this.state.requests
      updatedArray[i].PreviewImage = response.data
      console.log(response.data)
      this.setState({requests: updatedArray})
    })
    .catch( (error) => {
      //console.log(error);
    });
  }

  handleChangeText = name => event => {
    if (name == 'phone') {
      this.setState({ [name]: event.target.value.replace('-', '') });
    } else {
      this.setState({ [name]: event.target.value });
    }
  };

  getFormatedDate = (date) => {
    var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    var d = new Date(date);
    var day = days[d.getDay()];
    var hr = d.getHours();
    var min = d.getMinutes();
    if (min < 10) {
        min = "0" + min;
    }
    var ampm = "am";
    if( hr > 12 ) {
        hr -= 12;
        ampm = "pm";
    }
    var date = d.getDate();
    var month = months[d.getMonth()];
    var year = d.getFullYear();
    var x = document.getElementById("time");
    return day + " " + hr + ":" + min + ampm + " " + date + " " + month + " " + year;
  }

  openCloseDetails = (e,i) => {
    if (!e) var e = window.event;
     e.cancelBubble = true;
     if (e.stopPropagation) e.stopPropagation();
    var requests = this.state.requests
    requests[i].OpenDetails = !requests[i].OpenDetails
    this.setState({requests: requests})

  }

  goToShootPage = (item) => {
    window.event.stopPropagation();
    this.props.updateShootPage(true)
    this.props.history.push("/c/dashboard/photo-shoot/" + item.Id)
    window.event.stopPropagation();
  }

  changeStatusFormat = (status) => {
    var status_to_present = ''
    var color = '#FF8404'
    if (
        status == 'Basic details' ||
        status == 'Full details' ||
        status == 'Product selected' ||
        status == 'Summary approved' ||
        status == 'Pending for match'
      ) {
        status_to_present = 'Pending for match'
      }
      else if (
          status == 'Matched'
        ) {
          status_to_present = 'Pending for shoot'
        } else if (
          status == 'Image Upload Pending' ||
          status == 'Images Uploaded' ||
          status == 'AT Editing Pending' ||
          status == 'AT Editing Issue' ||
          status == 'AT Editing Complete' ||
          status == 'In Editing process' ||
          status == 'AT In Editing Process'
        ) {
          status_to_present = 'In editing'
        } else if (status == 'Editing Complete / Waiting For Selection') {
          status_to_present = 'Ready for selection'
        } else if (status == 'Process Completed') {
          status_to_present = 'Completed'
          color = '#33D7A0'
        } else if (
          status == 'Shooting Canceled By Costumer' ||
          status == 'Shooting Canceled By WeSnapp'
        ) {
          color = '#D43600'
          status_to_present = 'Shooting canceled'
        } else if (
          status == 'Shooting Delayed By Costumer' ||
          status == 'Shooting Delayed By WeSnapp'
        ) {
          color = '#D43600'
          status_to_present = 'Shooting delayed'
        }
    return (
      <div style={{ display: 'inline-block', userSelect: 'none', position: 'absolute', top: 15, right: 15, backgroundColor: color, fontSize: 14, borderRadius: 8, padding: 4, color: 'white'}}>{status_to_present}</div>
    )
  }

  onChangeSearchText = (e) => {
    let relevantRequests = []
    let value = e.target.value
    if (value === '') {
      this.setState({requestsToShow: [...this.state.requests]})
    } else {
      for (let index = 0; index < this.state.requests.length; index++) {
        const item = this.state.requests[index];
        if (
          (item.ShootingStreet__c && item.ShootingStreet__c.toLowerCase().includes(value)) ||
          (item.ShootingCity__c && item.ShootingCity__c.toLowerCase().includes(value)) ||
          (item.Photographer_Name__c && item.Photographer_Name__c.toLowerCase().includes(value)) ||
          (item.Public_Product_Name__c.toLowerCase().includes(value)) ||
          (this.getFormatedDate(item.Shooting_Date_Time__c).toLowerCase().includes(value))
          ) {
          relevantRequests.push(item)
        }
      }
      this.setState({requestsToShow: relevantRequests})
    }
  }

  getCompleted = () => {
    let rRequests = []
    for (let index = 0; index < this.state.requestsToShow.length; index++) {
      const r = this.state.requestsToShow[index];
      if (r.Status__c === 'Process Completed') {
        rRequests.push(r)
      }
    }
    return rRequests
  }

  getAlmostCompleted = () => {
    let rRequests = []
    for (let index = 0; index < this.state.requestsToShow.length; index++) {
      const r = this.state.requestsToShow[index];
      if (
        r.Status__c === 'Image Upload Pending' ||
        r.Status__c === 'Images Uploaded' ||
        r.Status__c === 'AT Editing Pending' ||
        r.Status__c === 'AT Editing Issue' ||
        r.Status__c === 'AT Editing Complete' ||
        r.Status__c === 'In Editing process' ||
        r.Status__c === 'In Editing processAT In Editing Process'
      ) {
        rRequests.push(r)
      }
    }
    return rRequests
  }

  getMatched = () => {
    let rRequests = []
    for (let index = 0; index < this.state.requests.length; index++) {
      const r = this.state.requests[index];
      if (
        r.Status__c === 'Matched' ||
        r.Status__c === 'Repeated Shooting (New photographer)' ||
        r.Status__c === 'Repeated Shooting (Same photographer)' ||
        r.Status__c === 'WS Auto Match' ||
        r.Status__c === 'Summary approved' ||
        r.Status__c === 'Pending for match'        
      ) {
        rRequests.push(r)
      }
    }
    return rRequests
  }

  getWaiting = () => {
    let rRequests = []
    for (let index = 0; index < this.state.requests.length; index++) {
      const r = this.state.requests[index];
      if (
          r.Status__c === 'Editing Complete / Waiting For Selection'
        ) {
        rRequests.push(r)
      }
    }
    return rRequests
  }

  // <div className={styles.root} style={{ borderRadius: '0px 0px 0 0px', marginTop: 0, minHeight: '100vh', padding: mobile ? 10 : 0, paddingTop: mobile ? 30 : 0, overflow: 'scroll', background: '#F9F8FD'}}>
  render() {
    const { classes, theme } = this.props;

    return (
        <div className={classes.root}>
          <Hidden mdUp>
            <Button className={classes.newOrderButton} onClick={() => history.push('/c/dashboard/order')}>+ New Order</Button>
          </Hidden>
          {this.state.isLoading === true && (
            <div className="loading"><CircularProgress style={{marginTop: 'calc(50vh - 50px)'}}/><div><div style={{fontFamily: "'Montserrat', sans-serif", fontSize: mobile ? 25 : 20, padding: mobile ? 20 : 0, color: 'black', marginTop: 20, fontWeight: 'bold'}}></div></div></div>
          )}
          <div style={{height: mobile ? 100 : 120, color: 'white', marginTop: 32, backgroundColor: '#6334FF', width: '100%', padding: '32px 16px', display: 'flex', marginBottom: 12, alignItems: 'center', justifyContent: 'space-between', width: '100%', boxShadow: '0px 4px 10px #6334FF10', borderRadius: 15}}>
            <div style={{fontSize: 24, fontWeight: 'bold'}}>Welcome, {(this.context.accountData && this.context.accountData.FirstName) && this.context.accountData.FirstName}!</div>
          </div>
          <Grid container spacing={2} style={{padding: 0, paddingTop: 0}} justifyContent="center" justify="center">
            <Grid item xs={12} sm={12} md={6} xl={6}>
              <PhotoShootsInProgress />
              <Grid container spacing={2} style={{padding: 0, paddingTop: 0}} justifyContent="center" justify="center">
                {(this.getMatched().length === 0 && !this.state.loadingRequests) && (
                  <Grid item xs={12} sm={12} md={12} xl={12}>
                    <div style={{height: mobile ? 80 : 92, padding: '32px 16px', display: 'flex', marginBottom: 0, alignItems: 'center', justifyContent: 'space-between', width: '100%', backgroundColor: 'rgb(255,255,255)', boxShadow: '0px 4px 10px #6334FF10', borderRadius: 15}}>
                      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', fontWeight: 'bold', width: '100%'}}>
                        No upcoming photo shoots. <u onClick={()=> history.push('/c/dashboard/order')} style={{color: '#33D7A0', marginLeft: 8, cursor: 'pointer'}}>Order Now</u>
                      </div>
                    </div>
                  </Grid>
                )}
                {this.getMatched().map((item, i) => (
                  <Grid item xs={12} sm={12} md={12} xl={12}>
                    <div style={{height: mobile ? 80 : 92, padding: '32px 16px', display: 'flex', marginBottom: 0, alignItems: 'center', justifyContent: 'space-between', width: '100%', backgroundColor: 'rgb(255,255,255)', boxShadow: '0px 4px 10px #6334FF10', borderRadius: 15}}>
                      <div style={{display: 'flex', alignItems: 'center'}}>
                        {/* <img src={require('../../../../Assets/calendarIcon.png')} alt='' style={{height: 42}} /> */}
                        <div style={{height: mobile ? 52 : 62, width: mobile ? 52 : 62, minWidth: mobile ? 52 : 62, borderRadius: mobile ? 26 : 30, background: '#33D7A030', color: '#33D7A030', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          <TodayRoundedIcon style={{color: '#33D7A0'}} />
                        </div>
                        <div style={{marginLeft: 12, textAlign: 'left'}}>{this.getFormatedDate(item.Shooting_Date_Time__c)} at {item.ShootingStreet__c}, {item.ShootingCity__c}</div>
                      </div>
                      <div>
                        {/* <a><u>Open</u></a> */}
                      </div>
                    </div>
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={6} xl={6}>
              <WaitingForSelection />
              <Grid container spacing={2} style={{padding: 0, paddingTop: 0}} justifyContent="center" justify="center">
                {(this.getWaiting().length === 0 && !this.state.loadingRequests) && (
                  <Grid item xs={12} sm={12} md={12} xl={12}>
                    <div style={{height: mobile ? 80 : 92, padding: '32px 16px', display: 'flex', marginBottom: 0, alignItems: 'center', justifyContent: 'space-between', width: '100%', backgroundColor: 'rgb(255,255,255)', boxShadow: '0px 4px 10px #6334FF10', borderRadius: 15}}>
                      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', textAlign: 'center', fontWeight: 'bold'}}>
                        You are good. No action is needed.
                      </div>
                    </div>
                  </Grid>
                )}
                {this.getWaiting().map((item, i) => (
                  <Grid item xs={12} sm={12} md={12} xl={12}>
                    <div style={{height: mobile ? 80 : 92, padding: '32px 16px', display: 'flex', marginBottom: 0, alignItems: 'center', justifyContent: 'space-between', width: '100%', backgroundColor: 'rgb(255,255,255)', boxShadow: '0px 4px 10px #6334FF10', borderRadius: 15}}>
                      <div style={{display: 'flex', alignItems: 'center'}}>
                        {/* <img src={require('../../../../Assets/pdfIcon.png')} alt='' style={{height: 42}} /> */}
                        <div style={{height: mobile ? 52 : 62, width: mobile ? 52 : 62, minWidth: mobile ? 52 : 62, borderRadius: mobile ? 26 : 30, background: '#FE8B5930', color: 'rgb(237,129,73)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          <ImageRoundedIcon style={{color: '#FE8B59'}} />
                        </div>
                        <div style={{marginLeft: 12, textAlign: 'left'}}>{item.ShootingStreet__c}, {item.ShootingCity__c}</div>
                      </div>
                      <div>
                        <Hidden mdUp>
                          <Button onClick={() => history.push('/c/dashboard/photo-shoot/' + item.Id)} className={classes.selectImagesButton}>Select</Button>
                        </Hidden>
                        <Hidden smDown>
                          <Button onClick={() => history.push('/c/dashboard/photo-shoot/' + item.Id)} className={classes.selectImagesButton}>Select Images</Button>
                        </Hidden>
                      </div>
                    </div>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
          <SearchAppBar onChangeSearchText={this.onChangeSearchText} />
          <Grid container spacing={2} style={{padding: 0, paddingTop: 0}} justifyContent="center" justify="center">
            <Grid item xs={12} sm={12} md={12} xl={12}>
              <Grid container spacing={2} style={{padding: mobile ? 0 : 0}}>
                {this.state.loadingRequests && 
                    [1,1,1,1,1,1,1,1,1,1,1,1].map(item => (
                      <Grid item xs={12} sm={6} md={4} xl={4}>
                        <Box pt={0.5}>
                        <Skeleton variant="rect" width={'100%'} height={220} />
                          <Skeleton />
                          <Skeleton width="60%" />
                        </Box>
                      </Grid>
                    ))
                  }
                
                {this.getAlmostCompleted().map((item, i) => (
                  <Grid item xs={12} sm={6} md={4} xl={4} onClick={() => this.goToShootPage(item)} style={{cursor: 'pointer'}}>
                    {
                    // <Link to={"/shoot?id=" + item.Id} style={{color:'white', fontWeight: 'bold', fontSize: 18}}>
                    }
                    <div style={{height: mobile ? 330 : 310, width: '100%', backgroundColor: 'rgb(255,255,255)', boxShadow: '0px 4px 10px #6334FF10', borderRadius: 15}} onClick={() => this.goToShootPage(item)} className={'shootBox'}>
                      {(item.PreviewImage != "" && item.PreviewImage != "loading") && (
                        <div style={{width: '100%', marginLeft: '0%', "hover": {transform: 'scale(1.3)'}, borderRadius: '18px 18px 0 0',position: 'relative', top: 0, zIndex: 2, height: mobile ? 240 : 220, backgroundImage: "url('" + item.PreviewImage + "')", backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: '50% 50%', cursor: 'pointer'}}>
                        <div style={{ display: 'inline-block', position: 'absolute', bottom: -20, right: 15, backgroundColor: 'rgba(94,44,237,1)', fontSize: 14, borderRadius: 8, padding: 4, color: 'white', width: 40, height: 40, borderRadius: 20, marginTop: 5, fontSize: 20, fontWeight: 'bold', fontFamily: '"Times New Roman", Times, serif', cursor: 'pointer', userSelect: 'none'}} onClick={(e)=> this.openCloseDetails(e,i)} className={"infoButton"}>i</div>
                        <div style={{ display: 'inline-block', position: 'absolute', top: 15, left: 15, backgroundColor: 'rgba(0,0,0,0.8)', fontSize: 14, borderRadius: 8, padding: 4, color: 'white', userSelect: 'none'}}>#{item.Name}</div>
                        <div style={{ display: 'inline-block', position: 'absolute', top: 15, right: 15, backgroundColor: '#FE8B59', fontSize: 14, borderRadius: 8, padding: 4, color: 'white', userSelect: 'none'}}>In Editing</div>
                        {
                          // this.changeStatusFormat(item.Status__c)
                        }
                          {item.OpenDetails && (
                            <div style={{width: '100%', height: '100%', backgroundColor: 'rgba(255,255,255,0.95)'}}>
                              <div style={{position: 'relative', top: '24%', fontSize: 16, fontWeight: 'bold'}}>Photographer</div>
                              <div style={{position: 'relative', top: '26%', fontSize: 16}}>{item.Photographer_Name__c}</div>
                              <div style={{position: 'relative', top: '30%', fontSize: 16}}><b>Paid:</b> {item.Paid__c ? ('Yes') : ('No')}</div>
                              <div style={{position: 'relative', top: '34%', fontSize: 16, fontWeight: 'bold'}}>Product</div>
                              <div style={{position: 'relative', top: '36%', fontSize: 16}}>{item.Public_Product_Name__c}</div>
                            </div>
                          )}
                        </div>
                      )}
                      {(item.PreviewImage == "" && item.PreviewImage != "loading") && (
                        <div style={{width: '100%', marginLeft: '0%', borderRadius: '18px 18px 0 0',position: 'relative', top: 0, zIndex: 2, height: mobile ? 240 : 220, backgroundColor: "#d9e2ff"}}>
                        <div style={{ display: 'inline-block', position: 'absolute', bottom: -20, right: 15, backgroundColor: '#6C65FF', fontSize: 14, borderRadius: 8, padding: 4, color: 'white', width: 40, height: 40, borderRadius: 20, marginTop: 5, fontSize: 20, fontWeight: 'bold', fontFamily: '"Times New Roman", Times, serif', cursor: 'pointer', userSelect: 'none'}} onClick={(e)=> this.openCloseDetails(e,i)} className={"infoButton"}>i</div>
                          <div style={{ display: 'inline-block', position: 'absolute', top: 15, left: 15, backgroundColor: 'rgba(0,0,0,0.8)', fontSize: 14, borderRadius: 8, padding: 4, color: 'white'}}>#{item.Name}</div>
                          <div style={{ display: 'inline-block', position: 'absolute', top: 15, right: 15, backgroundColor: '#FE8B59', fontSize: 14, borderRadius: 8, padding: 4, color: 'white', userSelect: 'none'}}>In Editing</div>
                          {/* {this.changeStatusFormat(item.Status__c)} */}
                          <img style={{position: 'absolute', top: 'calc(50% - 35px)', width: 70, height: 70, left: 'calc(50% - 35px)'}} src={noImage}/>
                          {item.OpenDetails && (
                            <div style={{width: '100%', height: '100%', backgroundColor: 'rgba(255,255,255,0.95)'}}>
                              <div style={{position: 'relative', top: '24%', fontSize: 16, fontWeight: 'bold'}}>Photographer</div>
                              <div style={{position: 'relative', top: '26%', fontSize: 16}}>{item.Photographer_Name__c}</div>
                              <div style={{position: 'relative', top: '30%', fontSize: 16}}><b>Paid:</b> {item.Paid__c ? ('Yes') : ('No')}</div>
                              <div style={{position: 'relative', top: '34%', fontSize: 16, fontWeight: 'bold'}}>Product</div>
                              <div style={{position: 'relative', top: '36%', fontSize: 16}}>{item.Public_Product_Name__c}</div>
                            </div>
                          )}
                        </div>
                      )}
                      {(item.PreviewImage === "loading" && item.OpenDetails !== true) && (
                        <div style={{width: '100%', marginLeft: '0%', borderRadius: '18px 18px 0 0',position: 'relative', top: 0, zIndex: 2, height: mobile ? 240 : 220, backgroundImage: "url('" + item.PreviewImage + "')", backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: '50% 50%'}}>
                          <CircularProgress style={{marginTop: '26%'}}/>
                        </div>
                      )}
                      <div style={{width: '100%', marginTop: 15, marginLeft: 15, textAlign: 'left', fontSize: 15, color:'black'}}>{item.ShootingStreet__c}, {item.ShootingCity__c}</div>
                      <div style={{width: '100%', marginTop: 15, marginLeft: 15, textAlign: 'left', fontSize: 15, color: 'black'}}>
                      {
                        this.getFormatedDate(item.Shooting_Date_Time__c)
                      }
                      </div>
                    </div>
                    {
                    // <Button style={{height: mobile ? 45 : 60, marginTop: 0, width: '100%', backgroundColor: 'rgb(94,44,237)', borderRadius: mobile ? '0 0 22.5px 22.5px' : '0 0 30px 30px', position: 'relative', top: -50}}>
                    //   <Link to={"/shoot?id=" + item.Id} style={{color:'white', fontWeight: 'bold', fontSize: 18}}>To Shoot</Link>
                    // </Button>
                    }
                    {
                      // </Link>
                    }
                  </Grid>
                ))}
                {this.getCompleted().map((item, i) => (
                  <Grid item xs={12} sm={6} md={4} xl={4} onClick={() => this.goToShootPage(item)} style={{cursor: 'pointer'}}>
                    {
                    // <Link to={"/shoot?id=" + item.Id} style={{color:'white', fontWeight: 'bold', fontSize: 18}}>
                    }
                    <div style={{height: mobile ? 330 : 310, width: '100%', backgroundColor: 'rgb(255,255,255)', boxShadow: '0px 4px 10px #6334FF10', borderRadius: 15}} onClick={() => this.goToShootPage(item)} className={'shootBox'}>
                      {(item.PreviewImage != "" && item.PreviewImage != "loading") && (
                        <div style={{width: '100%', marginLeft: '0%', "hover": {transform: 'scale(1.3)'}, borderRadius: '18px 18px 0 0',position: 'relative', top: 0, zIndex: 2, height: mobile ? 240 : 220, backgroundImage: "url('" + item.PreviewImage + "')", backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: '50% 50%', cursor: 'pointer'}}>
                        <div style={{ display: 'inline-block', position: 'absolute', bottom: -20, right: 15, backgroundColor: 'rgba(94,44,237,1)', fontSize: 14, borderRadius: 8, padding: 4, color: 'white', width: 40, height: 40, borderRadius: 20, marginTop: 5, fontSize: 20, fontWeight: 'bold', fontFamily: '"Times New Roman", Times, serif', cursor: 'pointer', userSelect: 'none'}} onClick={(e)=> this.openCloseDetails(e,i)} className={"infoButton"}>i</div>
                        <div style={{ display: 'inline-block', position: 'absolute', top: 15, left: 15, backgroundColor: 'rgba(0,0,0,0.8)', fontSize: 14, borderRadius: 8, padding: 4, color: 'white', userSelect: 'none'}}>#{item.Name}</div>
                        <div style={{ display: 'inline-block', position: 'absolute', top: 15, right: 15, backgroundColor: '#33D7A0', fontSize: 14, borderRadius: 8, padding: 4, color: 'white', userSelect: 'none'}}>Completed</div>
                        {
                          // this.changeStatusFormat(item.Status__c)
                        }
                          {item.OpenDetails && (
                            <div style={{width: '100%', height: '100%', backgroundColor: 'rgba(255,255,255,0.95)'}}>
                              <div style={{position: 'relative', top: '24%', fontSize: 16, fontWeight: 'bold'}}>Photographer</div>
                              <div style={{position: 'relative', top: '26%', fontSize: 16}}>{item.Photographer_Name__c}</div>
                              <div style={{position: 'relative', top: '30%', fontSize: 16}}><b>Paid:</b> {item.Paid__c ? ('Yes') : ('No')}</div>
                              <div style={{position: 'relative', top: '34%', fontSize: 16, fontWeight: 'bold'}}>Product</div>
                              <div style={{position: 'relative', top: '36%', fontSize: 16}}>{item.Public_Product_Name__c}</div>
                            </div>
                          )}
                        </div>
                      )}
                      {(item.PreviewImage == "" && item.PreviewImage != "loading") && (
                        <div style={{width: '100%', marginLeft: '0%', borderRadius: '18px 18px 0 0',position: 'relative', top: 0, zIndex: 2, height: mobile ? 240 : 220, backgroundColor: "#d9e2ff"}}>
                        <div style={{ display: 'inline-block', position: 'absolute', bottom: -20, right: 15, backgroundColor: '#6C65FF', fontSize: 14, borderRadius: 8, padding: 4, color: 'white', width: 40, height: 40, borderRadius: 20, marginTop: 5, fontSize: 20, fontWeight: 'bold', fontFamily: '"Times New Roman", Times, serif', cursor: 'pointer', userSelect: 'none'}} onClick={(e)=> this.openCloseDetails(e,i)} className={"infoButton"}>i</div>
                          <div style={{ display: 'inline-block', position: 'absolute', top: 15, left: 15, backgroundColor: 'rgba(0,0,0,0.8)', fontSize: 14, borderRadius: 8, padding: 4, color: 'white'}}>#{item.Name}</div>
                        <div style={{ display: 'inline-block', position: 'absolute', top: 15, right: 15, backgroundColor: '#33D7A0', fontSize: 14, borderRadius: 8, padding: 4, color: 'white', userSelect: 'none'}}>Completed</div>
                          {/* {this.changeStatusFormat(item.Status__c)} */}
                          <img style={{position: 'absolute', top: 'calc(50% - 35px)', width: 70, height: 70, left: 'calc(50% - 35px)'}} src={noImage}/>
                          {item.OpenDetails && (
                            <div style={{width: '100%', height: '100%', backgroundColor: 'rgba(255,255,255,0.95)'}}>
                              <div style={{position: 'relative', top: '24%', fontSize: 16, fontWeight: 'bold'}}>Photographer</div>
                              <div style={{position: 'relative', top: '26%', fontSize: 16}}>{item.Photographer_Name__c}</div>
                              <div style={{position: 'relative', top: '30%', fontSize: 16}}><b>Paid:</b> {item.Paid__c ? ('Yes') : ('No')}</div>
                              <div style={{position: 'relative', top: '34%', fontSize: 16, fontWeight: 'bold'}}>Product</div>
                              <div style={{position: 'relative', top: '36%', fontSize: 16}}>{item.Public_Product_Name__c}</div>
                            </div>
                          )}
                        </div>
                      )}
                      {(item.PreviewImage === "loading" && item.OpenDetails !== true) && (
                        <div style={{width: '100%', marginLeft: '0%', borderRadius: '18px 18px 0 0',position: 'relative', top: 0, zIndex: 2, height: mobile ? 240 : 220, backgroundImage: "url('" + item.PreviewImage + "')", backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: '50% 50%'}}>
                          <CircularProgress style={{marginTop: '26%'}}/>
                        </div>
                      )}
                      <div style={{width: '100%', marginTop: 15, marginLeft: 15, textAlign: 'left', fontSize: 15, color:'black'}}>{item.ShootingStreet__c}, {item.ShootingCity__c}</div>
                      <div style={{width: '100%', marginTop: 15, marginLeft: 15, textAlign: 'left', fontSize: 15, color: 'black'}}>
                      {
                        this.getFormatedDate(item.Shooting_Date_Time__c)
                      }
                      </div>
                    </div>
                    {
                    // <Button style={{height: mobile ? 45 : 60, marginTop: 0, width: '100%', backgroundColor: 'rgb(94,44,237)', borderRadius: mobile ? '0 0 22.5px 22.5px' : '0 0 30px 30px', position: 'relative', top: -50}}>
                    //   <Link to={"/shoot?id=" + item.Id} style={{color:'white', fontWeight: 'bold', fontSize: 18}}>To Shoot</Link>
                    // </Button>
                    }
                    {
                      // </Link>
                    }
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </div>
    );
  }
}

PhotoShoots.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(withRouter(PhotoShoots));
