import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function FormDialog(props) {

  const handleChangeComment = name => event => {
    props.setComment(event.target.value)
  };

  return (
    <div>
      <Dialog fullWidth={'sm'} maxWidth={'sm'} open={props.commentDialogOpen} onClose={() => props.setCommentDialogOpen(false)} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Comment</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please add any relevant detail about the shooting or the images
          </DialogContentText>
          <TextField
            onChange={handleChangeComment('comment')}
            id="outlined-multiline-static"
            label="Comment"
            multiline
            value={props.comment}
            autoFocus
            fullWidth
            rows="4"
            defaultValue=""
            margin="normal"
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => props.setCommentDialogOpen(false)} color="primary">
            Cancel
          </Button>
          {props.addingCommentInProgress ? (
            <CircularProgress size={25} style={{marginRight: 15}}/>
          ) : (
            <Button onClick={() => props.addComment()} disabled={props.comment == ""} color="primary">
              Send
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
