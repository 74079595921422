import React, {useState, useEffect} from 'react';
import {
    Grid,
    Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import MuiStyleFunction from './PhotoShoot.style';

const useStyles = makeStyles(MuiStyleFunction);

const PhotoShoot = (props) => {

    const classes = useStyles()

    return (
        <Grid item xs={12} sm={12} md={4} xl={3}>
            <div className={classes.imageContainer}>
                <img alt='' src={props.item.src} className={classes.image} onClick={()=> props.openGallery(props.i)} />
                <div className={classes.imageActionBar}>
                    <div className={classes.imageName}>Photo #{props.i + 1}</div>
                    {props.item.isSelected || props.selectedInThePast.includes(props.getLinkAfterReplacement(props.item.src)) ? (
                        <Button
                            className={props.selectedInThePast.includes(props.getLinkAfterReplacement(props.item.src)) ? classes.selectionConfirmedButton : classes.selectedButton}
                            onClick={()=> !props.selectedInThePast.includes(props.getLinkAfterReplacement(props.item.src)) ? props.onSelectImage(props.i) : props.createSnackbar('info', 'Already Seleced')}
                        >
                            Selected
                        </Button>
                    ) : (
                        <Button className={classes.selectButton} onClick={()=> props.onSelectImage(props.i)}>Select</Button>
                    )}
                </div>
            </div>
        </Grid>
    );
}
 
export default PhotoShoot;