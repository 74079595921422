import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';
import PublishRoundedIcon from '@material-ui/icons/PublishRounded';
import axios from 'axios';
import { Alert } from 'antd';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide(props) {

  const [recieptFile, setRecieptFile] = React.useState(null)
  const [recieptNumber, setRecieptNumber] = React.useState(null)
  const [recieptDate, setRecieptDate] = React.useState(null)
  const [uploading, setUploading] = React.useState(null)
  const [formattedRecieptDate, setFormattedRecieptDate] = React.useState(null)

  const handleClose = () => {
    props.setOpen(false);
  };

  const handleChangeRecieptNumber = (event) => {
    setRecieptNumber(event.target.value)
  };

  const handleChangeRecieptDate = (date) => {
    var dateObj = new Date(date);
    var m = String(dateObj.getDate())
    var d = String(dateObj.getMonth()+1)
    var y = String(dateObj.getFullYear())
    if (m.length === 1) {
      m = "0" + m
    }
    if (d.length === 1) {
      d = "0" + d
    }
    var newDate = y + "-" + d + "-" + m
    console.log(newDate)
    console.log(new Date(date))
    setRecieptDate(newDate)
    setFormattedRecieptDate(new Date(date))
  }

  const onChangeFile = async (e, typeOfFile) => {
    console.log(e.target.files[0])
    setRecieptFile(e.target.files[0])
  }

  const getSignedUrl = async (fileName, contentType) => {
    let response = await axios.post("https://wesnapp.co.il/api/storage/get-signed-url", {fileName: fileName, contentType: contentType} ,{headers: {'Content-Type': "application/json", 'Access-Control-Allow-Origin': '*'}})
    return response.data
  }

  const storeReciept = async (file) => {
    console.log('file', file)
    let directory = `invoices/${localStorage.getItem('contactId')}/${(new Date().getTime())}_`
    // , file.name.substring(file.name.lastIndexOf(".")+1) === 'pdf' ? `appliction/${file.name.substring(file.name.lastIndexOf(".")+1)}` : `image/${file.name.substring(file.name.lastIndexOf(".")+1)}`
    let signedUrl = await getSignedUrl(directory + 'document.' + file.name.substring(file.name.lastIndexOf(".")+1))
    const xhr = new XMLHttpRequest();
    xhr.open("PUT", signedUrl, true);
    xhr.onload = () => {
      const status = xhr.status;
      if (status === 200) {
        console.log('uploadFinished')
      } else {
        console.log(xhr)
      }
    };
    xhr.onerror = (e) => {
      console.log('e', e)
      alert("שגיאה במהלך העלאת הקובץ. אנה נסה שוב או צור קשר עם צוות התמיכה", e);
    };
    xhr.setRequestHeader('Content-Type', file.type);
    xhr.send(file);
    return `https://storage.googleapis.com/wesnapp/${directory + 'document.' + file.name.substring(file.name.lastIndexOf(".")+1)}`
  }

  const uploadReciept = async () => {
    setUploading(true)
    if (recieptFile === null) {
      alert('יש להעלות את הקבלה')
      setUploading(false)
    } else if (recieptNumber === null) {
      alert('יש להזמין מספר קבלה')
      setUploading(false)
    } else if (recieptDate === null) {
      alert('יש לבחור תאריך קבלה')
      setUploading(false)
    } else {
      let recieptUrl = await storeReciept(recieptFile)
      console.log(recieptUrl)
      // + 'T' + '12:00' + ':00.000+0200'
      var data = {requestId: props.row.id, data: {Link_to_Receipt__c: recieptUrl, Status__c: 'Receipt Pending for Approval', Photographer_Receipt_Date__c: recieptDate, Photographer_Receipt__c: recieptNumber}}
      console.log(data)
      axios.post('https://wesnapp.co.il/api/payment-request/update-request',data, {headers: {'Content-Type': 'application/json'}}).then((response) => {
        setUploading(false)
        sendNotification(recieptUrl, props.row.id)
        props.getData()
        handleClose()
      })
      .catch( (error) => {
        console.log('error')
      });
    }
  }

  const sendNotification = (recieptFile, paymentRequestId) => {
    var text = "The photographer upload new <" + recieptFile + "|reciept> to this <" + "https://wesnapp.lightning.force.com/lightning/r/Photographer_Payment__c/" + paymentRequestId + "/view"  + "|payment request>"
    axios.post('https://wesnapp.co.il/api/slack/matching-notification',{"text": text}, {headers: {'Content-Type': 'application/json'}}).then((response) => {
      console.log(response.data);
    }).catch( (error) => {
      console.log(error);
    });
  }

  return (
    <div>
      <Dialog
        open={props.open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">Upload Reciept</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Upload reciept and fill the reciept number and date
          </DialogContentText>
            <div>
                <input
                    accept="image/*,application/pdf"
                    id="contained-button-file"
                    style={{display: 'none'}}
                    type="file"
                    onChange={ (e) => onChangeFile(e, props.row.docId)}
                />
                <label htmlFor="contained-button-file">
                    <Button component="span" elevation={0} style={{  marginTop: 0, textTransform: 'none', height: 50, paddingLeft: 24, paddingRight: 24, borderRadius: 25, display: 'inline-block', color: 'white', backgroundColor: 'rgba(253, 97, 43, 0.2)'}} >
                        <PublishRoundedIcon style={{color: 'rgba(253, 97, 43, 1)', verticalAlign: 'middle', display: 'inline-block', width: 27, height: 27, marginRight: 8, marginTop: 4, verticalAlign: 'middle'}}/><div style={{verticalAlign: 'middle', display: 'inline-block', color: 'rgba(253, 97, 43, 1)', marginTop: 4}}><b>Upload</b></div>
                    </Button>
                </label>
                <div style={{marginTop: 12}}>{recieptFile ? recieptFile.name : ''}</div>
                <div style={{width: '100%', marginTop: 12}}>
                    <div style={{float: 'left', fontSize: 16, marginBottom: 8}}>Document number</div>
                    <input type={"text"} className="regInput" onChange={(e) => handleChangeRecieptNumber(e)} placeholder="Document number*" style={{float: 'right'}} />
                    {/* <div style={{float: 'left', color: '#FD612B', marginTop: 3, textAlign: 'left'}}>{this.state.errorNumber}</div> */}
                    <div style={{float: 'left', fontSize: 16, marginBottom: 8, marginTop: 12}}>Document date</div>
                        <KeyboardDatePicker
                          style={{width: '100%'}}
                          disableToolbar
                          variant="inline"
                          format="MM/dd/yyyy"
                          margin="normal"
                          id="date-picker-inline"
                          label=""
                          value={recieptDate}
                          onChange={handleChangeRecieptDate}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                          fullWidth
                          InputProps={{
                            disableUnderline: true,
                            }}
                          className="regInput"
                          style={{padding: 10, display: 'inline-block', width: '100%', marginTop: 0}}
                        />
                    </div>
                    <div style={{width: '100%', marginTop: 24}}>
                      <center>
                        {uploading ? (
                            <CircularProgress size={35} />
                          ) : (
                            <Button onClick={uploadReciept} color="primary" style={{paddingLeft: 24, paddingRight: 24, borderRadius: 24, height: 48, backgroundColor: '#33D7A0', color: 'white', textTransform: 'none', fontWeight: 'bold'}}>
                              Upload Reciept
                            </Button>
                        )}
                      </center>
                    </div>
                </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
