import React from 'react';
import {Button} from '@material-ui/core'
import ExposuresMenu from './ExposuresMenu'

const UploadedStation = ({
    getUploadStationStyle,
    setUploadStation,
    exposures4,
    exposures5,
    exposures6,
    exposures7,
    exposures8,
    exposures9,
    setExposures4,
    setExposures5,
    setExposures6,
    setExposures7,
    setExposures8,
    setExposures9
}) => {
    return ( 
        <div style={{width: '100%'}}>
            <div style={{display: 'inline-block', fontWeight: 'bold', fontFamily: "'Montserrat', sans-serif", fontSize: 20, textAlign: 'left', width: '100%', color: '#818BA6', marginBottom: 19}}>Stations</div>
            <div style={{ width: '100%', marginLeft: -2.5, maxWidth: 300, marginTop: 10}}>
                {/* <Button variant="contained" type="submit" elevation={0} fullWidth style={getUploadStationStyle('Raws')} onClick={ () => setUploadStation('Raws')}>
                    Raw's
                </Button> */}
                <Button variant="contained" type="submit" elevation={0} fullWidth style={getUploadStationStyle('1 Exposures')} onClick={ () => setUploadStation('1 Exposures')}>
                    1 Exposures
                </Button>
                <Button variant="contained" type="submit" elevation={0} fullWidth style={getUploadStationStyle('3 Exposures')} onClick={ () => setUploadStation('3 Exposures')}>
                    3 Exposures
                </Button>
                {exposures4 && (
                    <Button variant="contained" type="submit" elevation={0} fullWidth style={getUploadStationStyle('4 Exposures')} onClick={ () => setUploadStation('4 Exposures')}>
                        4 Exposures
                    </Button>
                )}
                {exposures5 && (
                    <Button variant="contained" type="submit" elevation={0} fullWidth style={getUploadStationStyle('5 Exposures')} onClick={ () => setUploadStation('5 Exposures')}>
                        5 Exposures
                    </Button>
                )}
                {exposures6 && (
                    <Button variant="contained" type="submit" elevation={0} fullWidth style={getUploadStationStyle('6 Exposures')} onClick={ () => setUploadStation('6 Exposures')}>
                        6 Exposures
                    </Button>
                )}
                {exposures7 && (
                    <Button variant="contained" type="submit" elevation={0} fullWidth style={getUploadStationStyle('7 Exposures')} onClick={ () => setUploadStation('7 Exposures')}>
                        7 Exposures
                    </Button>
                )}
                {exposures8 && (
                    <Button variant="contained" type="submit" elevation={0} fullWidth style={getUploadStationStyle('8 Exposures')} onClick={ () => setUploadStation('8 Exposures')}>
                        8 Exposures
                    </Button>
                )}
                {exposures9 && (
                    <Button variant="contained" type="submit" elevation={0} fullWidth style={getUploadStationStyle('9 Exposures')} onClick={ () => setUploadStation('9 Exposures')}>
                        9 Exposures
                    </Button>
                )}
                <ExposuresMenu
                    exposures4={exposures4}
                    exposures5={exposures5}
                    exposures6={exposures6}
                    exposures7={exposures7}
                    exposures8={exposures8}
                    exposures9={exposures9}
                    setExposures4={setExposures4}
                    setExposures5={setExposures5}
                    setExposures6={setExposures6}
                    setExposures7={setExposures7}
                    setExposures8={setExposures8}
                    setExposures9={setExposures9}
                />
            </div>
        </div>
    );
}
 
export default UploadedStation;