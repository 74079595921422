import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
// import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios';
import { withRouter } from "react-router-dom";
import TextField from '@material-ui/core/TextField';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

const styles = theme => ({
  list: {
    width: 250
  },
  fullList: {
    width: 'auto',
  },
  root: {
    backgroundColor: '#6C65FF',
    backgroundColor: 'white',
    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: 28,
      borderColor: '#DAE2FD',
    },
    '& .MuiOutlinedInput-input': {
      backgroundColor: '#ecf1fe',
      borderRadius: 28
    },
  },
  button: {
    color: 'white',
    height: 50,
    backgroundColor: theme.palette.primary.main,
    textTransform: 'none',
    fontWeight: 'bold',
    paddingLeft: 32,
    paddingRight: 32,
    marginTop: 30,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
    boxShadow: `0 4px 10px ${theme.palette.primary.main}40`,
    borderRadius: 25
  },
});

function createData(id, name, mobile, startAt, watchShootings, useOrders, canSelect, accounter) {
  return { id, name, mobile, startAt, watchShootings, useOrders, canSelect, accounter };
}

class PhotoShoots extends React.Component {

  state = {
    isLoading: true,
    firstName: '',
    lastName: '',
    email: '',
    mobile: '',
    saveButtonText: 'Save',
    updating: false,
    isBusiness: false,
    bName: '',
    errorBName: false,
    currentTab: 0,
    companyEmailForInvoice: '',
    companyNameForInvoice: '',
    companyVatNumber: ''
  }

  componentDidMount() {
    this.getUserDetails()
    this.getBusinessAccount()
  }

  getBusinessAccount = () => {
    var contactId = localStorage.getItem("contactId")
    var data = {contactId: contactId}
    axios.post('https://wesnapp.co.il' + '/api/account/get-business-account',data, {headers: {'Content-Type': 'application/json'}}).then((response) => {
        // console.log(response.data)    
        if (response.data.length > 0) {
            this.setState({isBusiness: true})
            this.setState({businessAccountId: response.data[0].AccountId})
            this.getBusinessAccountData(response.data[0].AccountId)
        }
    })
    .catch( (error) => {
      //console.log(error);
    });
  }

  getBusinessAccountData = (accountId) => {
    var data = {id: accountId}
    axios.post('https://wesnapp.co.il' + '/api/account/get-one',data, {headers: {'Content-Type': 'application/json'}}).then((response) => {
        console.log(response.data)
        var businessData = response.data[0]
        this.setState({
          businessData: businessData,
          bName: response.data[0].Name
        })    
    })
    .catch( (error) => {
      //console.log(error);
    });
  }

  getUserDetails = () => {
    var data = {id: localStorage.getItem("accountId")}
    axios.post('https://wesnapp.co.il' + '/api/account/get-one',data, {headers: {'Content-Type': 'application/json'}}).then((response) => {
        console.log(response.data[0]) 
        var accountData = response.data[0]
        this.setState({
            companyVatNumber: accountData.Company_Number_VAT__c,
            companyNameForInvoice: accountData.Company_Name_for_Invoices__c,
            companyEmailForInvoice: accountData.Company_Email_for_Invoices__c,
            isLoading: false
        })
    })
    .catch( (error) => {
      //console.log(error);
    });
  }

  validateEmail = (email) => {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  updateDetails = () => {
    console.log('asd')
    var allowToContinue = true
    if (allowToContinue) {
        this.setState({updating: true})
        var accountId = localStorage.getItem("accountId")
        var data = {accountId: accountId, data: {
            Company_Email_for_Invoices__c: this.state.companyEmailForInvoice,
            Company_Name_for_Invoices__c: this.state.companyNameForInvoice,
            Company_Number_VAT__c: this.state.companyVatNumber
        }}
        axios.post('https://wesnapp.co.il' + '/api/account/update-account',data, {headers: {'Content-Type': 'application/json'}}).then((response) => {
            console.log(response.data) 
            this.setState({saveButtonText: 'Updated!', updating: false})
            // Set last update date and time
            axios.post('https://us-central1-wesnapp-d136a.cloudfunctions.net/updateContact',{id: localStorage.getItem("accountId"), data: {Last_Confirmed_Settings__c: new Date().toISOString()}}, {headers: {'Content-Type': 'application/json'}}).then((response) => {
            })
            .catch( (error) => {
            });
        })
        .catch( (error) => {
        });
    }
  }

  handleChangeText = name => event => {
    if (name === 'phone') {
      this.setState({ [name]: event.target.value.replace('-', '') });
    } else {
      this.setState({ [name]: event.target.value });
    }
    this.setState({saveButtonText: 'Save'})
  };


  render() {
    const { classes, theme } = this.props;

    return (
      <div style={{backgroundColor: 'white', borderRadius: '23px', boxShadow: '0px 4px 10px #6334FF10', marginTop: 0, width: '100%', padding: 24, marginBottom: 24}}>
      <Grid container spacing={2} style={{padding: 0, paddingTop: 0, width: '100%'}} justifyContent="center" justify="center">
      <Grid item xs={12} sm={10} md={8} xl={7}>
              <div style={{fontSize: 20, color: 'rgb(40,40,53)', textAlign: 'left', marginLeft: 0, fontFamily: "'Montserrat', sans-serif"}}>
                  <b>Business Details</b>
              </div>
          </Grid>   
            <Grid item xs={12} sm={6} md={8} xl={7}>
                  {
                      (!this.state.isLoading) && (
                        <div>
                          <div style={{fontSize: 16, textAlign: 'left'}}>
                            You can edit and billing details here:
                          </div>
                          <div style={{marginTop: 20, display: 'inline-block', width: '100%'}}>
                            <TextField id="outlined-basic" label="Company name for invoice" fullWidth  onChange={this.handleChangeText('companyNameForInvoice')} value={this.state.companyNameForInvoice} />
                          </div>
                          <div style={{marginTop: 20, display: 'inline-block', width: '100%'}}>
                            <TextField id="outlined-basic" label="Company number (VAT)" fullWidth  onChange={this.handleChangeText('companyVatNumber')} value={this.state.companyVatNumber} />
                          </div>
                          <div style={{marginTop: 20, display: 'inline-block', width: '100%'}}>
                            <TextField id="outlined-basic" label="Company email for invoices" fullWidth  onChange={this.handleChangeText('companyEmailForInvoice')} value={this.state.companyEmailForInvoice} />
                          </div>
                          <center>
                            {this.state.updating === false ? (
                                <Button
                                  variant="contained"
                                  color="primary"
                                  className={classes.button}
                                  onClick= {()=> this.updateDetails()}
                                >
                                  {this.state.saveButtonText}
                                </Button>
                            ) : (
                              <CircularProgress size={35} style={{marginTop: 10}}/>
                            )}
                          </center>
                        </div>
                      )
                    }
                  </Grid>
            </Grid>
        </div>
    );
  }
}

PhotoShoots.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(withRouter(PhotoShoots));
