import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import SmsDialog from '../SmsDialog';
import OrderStepper from './OrderStepper';
import Grid from '@material-ui/core/Grid';
import { SnackbarProvider, useSnackbar } from 'notistack';
import Snackbar from '@material-ui/core/Snackbar';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios';

var mobile = (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1)

const styles = theme => ({
  list: {
    width: 250
  },
  fullList: {
    width: 'auto',
  },
  root: {
    width: '100vw',
    maxWidth: '100vw',
    height: '100vh',
    backgroundColor: '#6C65FF',
    backgroundColor: 'white'
  }
});

class Template extends React.Component {

  state = {
    lat: 32,
    lng: 32,
    smsDialog: false,
    willBeOnSite: true,
    connectToBusiness: false,
    isBusinessAccount: false,
    businessAccountId: null,
    errorFirstName: false,
    errorLastName: false,
    errorPhoneNumber: false,
    errorAddress: false,
    errorDate: false,
    errorCode: false,
    errorTime: false,
    errorOfficeName: false,
    errorWillBeOnSiteName: false,
    errorWillBeOnSitePhone: false,
    errorSelectProduct: false,
    errorEmail: false,

    formatedDate: null,
    formatedTime: null,

    isLoading: false,

    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    officeName: '',
    code: '',
    inputCode: '',

    formattedAddress: '',
    whoWillBeOnSiteName: '',
    whoWillBeOnSitePhone: '',
    date: '',
    time: '',

    products: [],
    // selectedProduct: '-1',
    selectedProduct: 'a011t00000E7X6cAAF',
    selectedProductData: null,
    countryCode: '972',

    openPackages: [],
    productsFromPackages: [],
    searchForOpenPackages: true,
    isPackage: false,
    selectedPackage: null,

    requestId: null,

    useNow: true,

    activeStep: 1,

    admin: false,

    mlsId: null,

    url_address: '',

    canUpdateRequest: true
  }

  validateMobile = (phone) => {
    console.log(phone);
    var substring1 = " 9725"
    var substring2 = " 97205"
    if (phone.includes(substring1) || phone.includes(substring2)) {
      console.log("@@");
      if (phone.indexOf(substring1) !== -1) {
        console.log(phone);
        phone = phone.replace(" 9725", "05");
        phone = phone.replace("-", "");
        if (phone[0] == "0" && phone[1] == "5" && (phone[2] == "2" || phone[2] == "3" || phone[2] == "0" || phone[2] == "4" || phone[2] == "5" || phone[2] == "8")) {
          if (phone.length == 10 && (/^\d+$/.test(phone))) {
            this.setState({phone: phone})
            return true
          }
        } else {
          return false;
        }
      } else {
        phone = phone.replace(" 97205", "05");
        phone = phone.replace("-", "");
        console.log(phone);
        if (phone[0] == "0" && phone[1] == "5" && (phone[2] == "2" || phone[2] == "3" || phone[2] == "0" || phone[2] == "4" || phone[2] == "5" || phone[2] == "8")) {
          if (phone.length == 10 && (/^\d+$/.test(phone))) {
            this.setState({phone: phone})
            return true
          }
        } else {
          return false;
        }
      }
    } else {
      phone = phone.replace("-", "");
      if (phone[0] == "0" && phone[1] == "5" && (phone[2] == "2" || phone[2] == "3" || phone[2] == "0" || phone[2] == "4" || phone[2] == "5" || phone[2] == "8")) {
        if (phone.length == 10 && (/^\d+$/.test(phone))) {
          this.setState({phone: phone})
          return true
        }
      } else {
        return false;
      }
    }
  }

  validateEmail = (email) => {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  resetErrors = () => {
    this.setState({
      errorFirstName: false,
      errorLastName: false,
      errorPhoneNumber: false,
      errorAddress: false,
      errorDate: false,
      errorCode: false,
      errorOfficeName: false,
      errorTime: false,
      errorWillBeOnSiteName: false,
      errorWillBeOnSitePhone: false,
      errorSelectProduct: false,
      errorEmail: false,
    //   admin: false
    })
  }

  getCoordinates = async (address) => {
    try {
      var link = "https://maps.googleapis.com/maps/api/geocode/json?address=" + encodeURI(address) + "&key=AIzaSyAaiPKKuOKyFcl_Hed_xF_1Jx9oQiKJu_s"
      const response = await axios.get(link);
      // console.log(response.data.results[0]);
      if (response.data.results.length > 0) {
        if (response.data.results[0].types.includes('street_address')) {
          console.log("yes");
          this.setState({errorAddress: false, lat: response.data.results[0].geometry.location.lat, lng: response.data.results[0].geometry.location.lng, formattedAddress: response.data.results[0].formatted_address}, ()=> {
            console.log(this.state.formattedAddress)
            return true
          })
        } else {
          console.log("no");
          this.setState({errorAddress: true})
          return false
        }
      } else {
        this.setState({errorAddress: true})
        return false
      }
    } catch (err) {
      console.error(err);
      return false
    }
  }

  nextStep = async () => {
    this.resetErrors()
    var step = this.state.activeStep
    if (step === 0) {
      var allowToContinue = true
      if (this.state.firstName.length <= 1) {
        this.setState({errorFirstName: true})
        allowToContinue = false
      }
      if (this.state.lastName.length <= 1) {
        this.setState({errorLastName: true})
        allowToContinue = false
      }
      if (!this.validateEmail(this.state.email)) {
        this.setState({errorEmail: true})
        allowToContinue = false
      }
      if (!this.validateMobile(this.state.phone)) {
        this.setState({errorPhoneNumber: true})
        allowToContinue = false
      }
      if (allowToContinue) {
        this.continueStepOne()
      }
    } else if (step == 1) {
      var allowToContinue = true
      if (this.state.useNow) {
        var location = await this.getCoordinates(this.state.formattedAddress)
        console.log(location)
        if (location === false) {
          this.setState({errorAddress: true})
          allowToContinue = false
        }
        if (!this.state.willBeOnSite) {
          if (this.state.whoWillBeOnSiteName.length <= 1) {
            this.setState({errorWillBeOnSiteName: true})
            allowToContinue = false
          }
          if (!this.validateMobile(this.state.whoWillBeOnSitePhone)) {
            this.setState({errorWillBeOnSitePhone: true})
            allowToContinue = false
          }
        }
        if (this.state.date == '') {
          this.setState({errorDate: true})
          allowToContinue = false
        }
        if (this.state.time == '') {
          this.setState({errorTime: true})
          allowToContinue = false
        } else {
          var min = this.state.time.split(':')[1]
          console.log(min)
          console.log(min)
          if (min === '0' || min === '00' || min === '15' || min === '30' || min === '45') {
            // time is ok
          } else {
            this.setState({errorDate: true})
            allowToContinue = false
          }
        }
        if (this.state.selectedProduct == 0) {
          this.setState({errorSelectProduct: true})
          allowToContinue = false
        }
        if (allowToContinue) {
          this.continueStepTwo()
        }
      } else {
        // use after
        this.setState({formattedAddress: 'Waiting for scheduled'})
        this.continueStepTwoWithoutUse()
      }
    }
  }

  getParameterByName = (name) => {
    var url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  componentDidMount() {

    this.getProducts(['a011t00000E7X6cAAF'])

    var admin = this.getParameterByName('admin')
    if (admin !== null && admin !== undefined && admin !== "") {
      if (admin === "wesnapp2020") {
        this.setState({admin: true})
      }
    }

    var mlsId = this.getParameterByName('id')
    if (mlsId !== null && mlsId !== undefined && mlsId !== "") {
      this.setState({mlsId: mlsId})
      var data = {
        "mlsId": mlsId
      }
      // axios.post('https://wesnapp.co.il' + '/api/request/find-by-mls-id',data, {headers: {'Content-Type': 'application/json'}}).then((response) => {
      axios.post('https://wesnapp.co.il' + '/api/request/get-homehero-by-mls-id',data, {headers: {'Content-Type': 'application/json'}}).then((response) => {
          console.log(response.data);
          if (response.data.length > 0) {
            // Check date
            if ((new Date(response.data[0].Shooting_Date_Time__c).getTime() - new Date().getTime()) >= 86400) {
              this.setState({requestId: response.data[0].Id, canUpdateRequest: true})
            } else {
              this.setState({requestId: response.data[0].Id, canUpdateRequest: false})
            }
          }
      })
      .catch( (error) => {
        //console.log(error);
      });
    }

    var firstName = this.getParameterByName('firstName')
    if (firstName !== null && firstName !== undefined && firstName !== "") {
      this.setState({firstName: firstName})
    }

    var lastName = this.getParameterByName('lastName')
    if (lastName !== null && lastName !== undefined && lastName !== "") {
      this.setState({lastName: lastName})
    }

    var phone = this.getParameterByName('mobile')
    if (phone !== null && phone !== undefined && phone !== "") {
      this.setState({phone: phone})
    }

    var email = this.getParameterByName('email')
    if (email !== null && email !== undefined && email !== "") {
      this.setState({email: email})
    }

    var officeName = this.getParameterByName('officeName')
    if (officeName !== null && officeName !== undefined && officeName !== "") {
      this.setState({officeName: officeName})
    }

    var address = this.getParameterByName('address')
    if (address !== null && address !== undefined && address !== "") {
      this.setState({url_address: address})
      // checkCoor
      this.getCoordinates(address)
    }

    var reference = this.getParameterByName('reference')
    if (reference !== null && reference !== undefined && reference !== "") {
      this.setState({reference: reference})
    }
  }

  componenetDidUpdate() {
  }

  loading = (flag) => {
    this.setState({isLoading: flag})
  }

  generateRandomCode = () => {
    var code = Math.floor(1000 + Math.random() * 9000)
    return code
  }

  sendVerificationCode = () => {
    var data = {
      "phone": ("+" + this.state.countryCode + this.state.phone),
      "text": 'WeSnapp verification code: ' + this.state.code
    }
    axios.post('https://wesnapp.co.il' + '/api/sms/send-message',data, {headers: {'Content-Type': 'application/json'}}).then((response) => {
        console.log(response);
    })
    .catch( (error) => {
      //console.log(error);
    });
  }

  findClientUsingPhoneNumber = () => {
    this.setState({isLoading: true})
    var data = {
      "mobile": this.state.phone,
    }
    console.log(data)
    axios.post('https://wesnapp.co.il' + '/api/account/find-person-account-by-mobile',data, {headers: {'Content-Type': 'application/json'}}).then((response) => {
        console.log(response);
        var accounts = response.data
        if (accounts.length !== 0) {
          var accountData = accounts[0]
          // .Id .PersonContactId
          // alert(toString(accountData))
          // alert(accountData.Id)
        //   this.checkForOpenPackages(accountData.Id)
        //   this.getSpecialProducts(accountData.Id)
          // this.setState({activeStep: 1})
          // Create Request
          if (this.state.requestId !== null) {
            this.updateRequest()
          } else {
              this.createRequest()
          }
        } else {
          // Create new client 
          this.setState({isLoading: true})
          var accountData = {
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            email: this.state.email,
            mobile: this.state.phone
          }
          axios.post('https://wesnapp.co.il' + '/api/account/create-new-homehero-person-account',accountData, {headers: {'Content-Type': 'application/json'}}).then((response) => {
        // axios.post('http://localhost:8080' + '/api/account/create-new-homehero-person-account',accountData, {headers: {'Content-Type': 'application/json'}}).then((response) => {
              console.log('new account data')    
            console.log(response.data.accountId);
            console.log(response.data.contactId);

            // Create Request
            if (this.state.requestId !== null) {
              this.updateRequest()
            } else {
                this.createRequest()
            }
            // this.setState({isLoading: false, activeStep: 1})
          })
        }
    })
    .catch( (error) => {
      //console.log(error);
    });
  }

  verifyCode = () => {
    // console.log(this.state.code)
    // console.log(this.state.inputCode)
    this.setState({errorCode: false})
    if (String(this.state.code) === this.state.inputCode) {
      this.findClientUsingPhoneNumber()
      this.setState({smsDialog: false})
    } else {
      this.setState({errorCode: true})
    }
  }

  continueStepOne = () => {
    if (this.state.admin) {
      this.findClientUsingPhoneNumber()
      this.setState({activeStep: 1})
    } else {
      this.setState({smsDialog: true})
      this.setState({code: this.generateRandomCode()}, () => {
        this.sendVerificationCode()
      })
    }
  }

  continueStepTwoWithoutUse = () => {
    // if (this.state.requestId !== null) {
    //   this.updateRequest()
    // } else {
      if (this.state.selectedProductData.Product_Type__c === 'Single') {
        this.createRequestWithoutUse()
      } else {
        this.createRequestWithoutUse()
      }
    // }
  }

  continueStepTwo = () => {

    // Create user if there is no a user
    this.findClientUsingPhoneNumber()

    // if (this.state.requestId !== null) {
    //   this.updateRequest()
    // } else {
    //     this.createRequest()
    // }
  }

  getProducts = (products) => {
    console.log("!@#");
    var data = {
      "products": products
    }
    console.log(data)
    axios.post('https://wesnapp.co.il' + '/api/product/get-many',data, {headers: {'Content-Type': 'application/json'}}).then((response) => {
      console.log(response.data);
        if (response.data.length > 0) {
          var products = response.data
          products.sort((a, b) => b.Total_Cost__c - a.Total_Cost__c);
          products.reverse()
          this.setState({products: products, selectedProductData: products[0]})
        } else {
          // this.setState({newUser: true})
        }
    })
    .catch( (error) => {
      console.log(error);
    });
  }

  updateRequest = () => {
    this.loading(true)
    var address = this.state.formattedAddress.split(", ")
    var street = address[0]
    var city = address[1]
    var country = address[2]
    var officeName = this.state.officeName
    if (this.state.officeName == '') {
      officeName = this.state.firstName + " " + this.state.lastName
    }
    var data = {
      "requestId": this.state.requestId,
      "adminAccountId": '0011t00000K1YuWAAV',
      "contactId": localStorage.getItem('contactId'),
      "firstName": this.state.firstName,
      "lastName": this.state.lastName,
      "mobile": this.state.phone,
      "email": this.state.email,
      "selectedProduct": this.state.selectedProduct,
    //   "selectedProduct": 'a011t00000E7X6cAAF',
      "reference": 'homehero',
      "datetime": this.state.date + 'T' + this.state.time + ':00.000+0200',
      "address": this.state.formattedAddress,
      "country": country,
      "whoWillBeOnSiteName": this.state.whoWillBeOnSiteName,
      "whoWillBeOnSitePhone": this.state.whoWillBeOnSitePhone,
      "city": city,
      "street": street,
      "officeName": officeName,
      "lng": this.state.lng.toString(),
      "lat": this.state.lat.toString(),
      "isPackage": this.state.isPackage,
      "connectToBusiness": true,
      "businessAccountId": '0011t00000K1YuHAAV'
    }
    if (this.state.isPackage) {
      data['selectedPackage'] = this.state.selectedPackage
    }
    console.log(data);
    axios.post('https://wesnapp.co.il' + '/api/action/update-request',data, {headers: {'Content-Type': 'application/json'}}).then((response) => {
        this.loading(false)
        console.log(response.data);
        // this.setState({accountId: response.data.accountId, contactId: response.data.contactId, orderId: response.data.orderId, requestId: response.data.requestId}, () => {
        this.setState({activeStep: 2})
        // })
      })
    .catch( (error) => {
    });
  }

  createRequestWithoutUse = () => {
    this.loading(true)
  
    var officeName = this.state.officeName
    if (this.state.officeName == '') {
      officeName = this.state.firstName + " " + this.state.lastName
    }
    var data = {
      "firstName": this.state.firstName,
      "lastName": this.state.lastName,
      "mobile": this.state.phone,
      "email": this.state.email,
      "selectedProduct": this.state.selectedProduct,
      "reference": 'homehero',
      "officeName": officeName,
      "isPackage": this.state.isPackage,
      "connectToBusiness": this.state.connectToBusiness,
      "businessAccountId": this.props.businessAccountId,
      "useNow": false,
      "onlyOrder": this.state.selectedProductData.Product_Type__c === 'Package'
    }
    if (this.state.selectedProductData.Product_Type__c === 'Package') {
      data['singleProduct'] = this.state.selectedProductData.Link_to_Single_Product__c
    }
    if (this.state.isPackage) {
      data['selectedPackage'] = this.state.selectedPackage
    }
    axios.post('https://wesnapp.co.il' + '/api/action/create-order-homehero',data, {headers: {'Content-Type': 'application/json'}}).then((response) => {
      // axios.post('http://localhost:8080' + '/api/action/create-order-homehero',data, {headers: {'Content-Type': 'application/json'}}).then((response) => {
        this.loading(false)
        console.log(response.data);
        this.setState({accountId: response.data.accountId, contactId: response.data.contactId, orderId: response.data.orderId, requestId: response.data.requestId}, () => {
          this.setState({activeStep: 2})
        })
      })
    .catch( (error) => {
    });
  }

  createRequest = () => {
    this.loading(true)
    var address = this.state.formattedAddress.split(", ")
    var street = address[0]
    var city = address[1]
    var country = address[2]
    // console.log(city);
    // console.log(country);
    // console.log(street);
    var officeName = this.state.officeName
    if (this.state.officeName == '') {
      officeName = this.state.firstName + " " + this.state.lastName
    }
    var data = {
      "firstName": this.state.firstName,
      "lastName": this.state.lastName,
      "mobile": this.state.phone,
      "email": this.state.email,
      "selectedProduct": this.state.selectedProduct,
      "reference": 'homehero',
      "datetime": this.state.date + 'T' + this.state.time + ':00.000+0200',
      "address": this.state.formattedAddress,
      "country": country,
      "whoWillBeOnSiteName": this.state.whoWillBeOnSiteName,
      "whoWillBeOnSitePhone": this.state.whoWillBeOnSitePhone,
      "city": city,
      "street": street,
      "officeName": officeName,
      "lng": this.state.lng.toString(),
      "lat": this.state.lat.toString(),
      "isPackage": this.state.isPackage,
      "connectToBusiness": true,
      "businessAccountId": '0011t00000K1YuHAAV',
      "useNow": true,
      "onlyOrder": false
    }
    // if (this.state.selectedProductData.Product_Type__c === 'Package') {
    //   data['singleProduct'] = this.state.selectedProductData.Link_to_Single_Product__c
    // }
    if (this.state.isPackage) {
      data['selectedPackage'] = this.state.selectedPackage
    }

    if (this.state.mlsId) {
      data['mlsId'] = this.state.mlsId
    }

    console.log(data);
    axios.post('https://wesnapp.co.il' + '/api/action/create-order-homehero',data, {headers: {'Content-Type': 'application/json'}}).then((response) => {
    // axios.post('http://localhost:8080' + '/api/action/create-order-homehero',data, {headers: {'Content-Type': 'application/json'}}).then((response) => {
      this.loading(false)
        console.log(response.data);
        this.setState({accountId: response.data.accountId, contactId: response.data.contactId, orderId: response.data.orderId, requestId: response.data.requestId}, () => {
          this.setState({activeStep: 2})
        })
      })
    .catch( (error) => {
    });
  }

  checkForOpenPackages = (clientId) => {
    var data = {
      "id": clientId,
    }
    axios.post('https://wesnapp.co.il' + '/api/order/get-open-orders',data, {headers: {'Content-Type': 'application/json'}}).then((response) => {
      console.log(response.data);
      var products = []
      for (var i = 0; i < response.data.length; i++) {
        products.push(response.data[i].singleProductId)
      }
      this.setState({openPackages: response.data})
      this.getProductsFromPackages(products, response.data)
    })
    .catch( (error) => {
      //console.log(error);
    });
  }

  getProductsFromPackages = (products, packages) => {
    console.log("!@#");
    var data = {
      "products": products,
    }
    axios.post('https://wesnapp.co.il' + '/api/product/get-many',data, {headers: {'Content-Type': 'application/json'}}).then((response) => {
        console.log(response.data);
        if (response.data.length > 0) {
          this.setState({productsFromPackages: response.data, searchForOpenPackages: false})
        } else {
          this.setState({searchForOpenPackages: false})
        }

    })
    .catch( (error) => {
      console.log(error);
    });
  }

  render() {
    const { classes, theme } = this.props;
    return (
      <div className={styles.root} style={{backgroundColor: 'white', borderRadius: '35px 35px 0 0px', marginTop: 10, minHeight: '100vh'}}>
          <div>
            <SmsDialog main={this}/>
            {this.state.isLoading === true && (
              <div className="loading" style={{backgroundColor: 'rgba(255,255,255,0.8)', minHeight: '100vh', minWidth: '100vw', backdropFilter: 'blur(4px)'}}>
                <CircularProgress style={{marginTop: 'calc(50vh - 50px)'}}/>
                <div>
                  <div style={{fontFamily: "'Montserrat', sans-serif", fontSize: mobile ? 25 : 20, padding: mobile ? 20 : 0, color: 'black', marginTop: 20, fontWeight: 'bold'}}>
                    Please wait, the process may take a few seconds
                  </div>
                </div>
              </div>
            )}
            <Grid container spacing={2} style={{padding: 0, paddingTop: 0}} justifyContent="center" justify="center">
              {/* <Grid item xs={12} sm={12} md={10} xl={9}>
                <div style={{fontSize: 24, color: 'rgb(40,40,53)', textAlign: 'left', marginLeft: 0, fontFamily: "'Montserrat', sans-serif", marginTop: mobile ? 0 : 15, marginBottom: mobile ? 0 : 30}}>
                  <b>New Order</b>
                </div>
              </Grid> */}
              <Grid item xs={12} sm={12} md={6} xl={6}>
                  <div style={{fontSize: 24, color: 'rgb(40,40,53)', textAlign: 'left', marginLeft: mobile ? 10 : 0, fontFamily: "'Montserrat', sans-serif", marginTop: mobile ? 15 : 15, marginBottom: mobile ? 0 : 30}}>
                    <b>New Order</b>
                  </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12} xl={12}>
              </Grid>
              <Grid item xs={12} sm={12} md={6} xl={6} style={{marginTop: -20, paddingBottom: 40}}>
                  <OrderStepper landingPage={this.props.landingPage} isBusinessAccount={this.props.isBusinessAccount} main={this}  />
              </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

Template.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(Template);
