import React, { createContext, useState } from 'react';

export const StyleContext = createContext();

function StyleContextProvider(props) {

    const [isDrawerOpen, setIsDrawerOpen] = useState(false)
    
    return (
        <StyleContext.Provider value={{
            isDrawerOpen: isDrawerOpen,
            setIsDrawerOpen: setIsDrawerOpen
        }}>
            {props.children}
        </StyleContext.Provider>
    );
}
 
export default StyleContextProvider;