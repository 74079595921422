import React, {useEffect, useState} from 'react';
import {
    CircularProgress,
    Grid,
    Button,
    TextField,
    InputAdornment
} from '@material-ui/core';

import { makeStyles } from '@material-ui/styles'
import MuiPhoneNumber from "material-ui-phone-number";
import {phone as phoneValidator} from 'phone';

const mobile = (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1)

const useStyles = makeStyles((theme) => ({
    coverImageDesktop: {
        width: '50%',
        position: 'fixed',
        height: '100%',
        objectFit: 'cover',
        right: 0,
        top: 0 
    }, 
    coverImageMobile: {
        width: '100%',
        height: '70vh',
        objectFit: 'cover'
    },
    contentDesktop: {
        width: '50%',
        minHeight: '100%',
        position: 'fixed',
        left: 0,
        backgroundColor: theme.palette.primary.background,
        padding: 24
    },
    contentMobile: {
        width: 'calc(100vw - 0px)',
        position: 'fixed',
        bottom: 0,
        borderRadius: '35px 35px 0 0',
        // background: theme.palette.background,
        backgroundColor: theme.palette.primary.background,
        padding: 24
    },
    continueButton: {
        width: '100%',
        height: 58,
        borderRadius: 29,
        marginTop: 32,
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        fontWeight: 'bold',
        textTransform: 'none',
        fontSize: mobile ? 16 : 18,
        boxShadow: '0px 5px 20px 0px rgba(59,102,246,0.4)'
    },
    title: {
        fontWeight: 900,
        fontSize: mobile ? 22 : 34,
        color: theme.palette.primary.darkText,
        textAlign: 'left'
    },
    subTitle: {
        fontWeight: 500,
        fontSize: mobile ? 14 : 18,
        color: theme.palette.primary.lightText,
        textAlign: 'left',
        opacity: 0.6,
        marginTop: 12
    },
    gridDesktop: {
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)'
    }
}));
  
const Step1 = ({
    errorPhone,
    validCode,
    showCode,
    handleChangeCode,
    validateCode,
    validatePhone,
    loading,
    handleChangePhone,
    errorCode,
    phone,
    validatePhoneForE164
}) => {
    const classes = useStyles();

    return (
        <Grid item xs={12} sm={12} md={12} xl={12} lg={12}> 
            <img alt="" src={require('../../../Assets/orderBackground.jpg')} className={ mobile ? classes.coverImageMobile : classes.coverImageDesktop} />
            <div  className={ mobile ? classes.contentMobile : classes.contentDesktop}>
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    className={
                        mobile ? {} : classes.gridDesktop
                    }
                > 
                    <Grid item xs={mobile ? 12 : 11} sm={mobile ? 12 : 10} md={mobile ? 12 : 9} xl={mobile ? 12 : 8} lg={mobile ? 12 : 6}> 
                        <div className={classes.title}>
                            Hi there :)
                        </div>
                        <div className={classes.subTitle}>
                            We glad that you here for your next order
                        </div>
                        <div style={{marginTop: 24, width: '100%'}}>
                            {/* <TextField id="phoneNumber" label="Phone Number" fullWidth
                                onChange={handleChangePhone}
                                error={errorPhone}
                                disabled={validCode}
                                value={phone}
                                helperText={errorPhone ? 'Error mobile number' : ''}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">+972</InputAdornment>,
                                }}
                            /> */}
                            <MuiPhoneNumber
                                style={{width: '100%', marginTop: 42}}
                                name="phone"
                                label="Phone Number"
                                disableAreaCodes={true}
                                data-cy="user-phone"
                                onlyCountries={['il', 'us', 'fr', 'gb']}
                                defaultCountry={"il"}
                                value={phone}
                                onChange={handleChangePhone}
                            />
                            {(!validatePhoneForE164() && phone) && (
                                <div style={{marginTop: 16, textDecoration: 'none', cursor: 'pointer', fontSize: 16, width: '100%', textAlign: 'left', color: 'red'}}>
                                    Phone number is not valid
                                </div>
                            )}   
                        </div>
                        {showCode && (
                            <div style={{marginTop: 24, width: '100%'}}>
                                <TextField id="verificationCode" label="Code" fullWidth 
                                    error={errorCode}
                                    helperText={errorCode ? 'Error code' : ''}
                                    onChange={handleChangeCode}
                                />
                        </div>
                        )}
                        {showCode && !loading && (
                            <Button id="start_order_button" variant="contained" onClick={()=> validateCode()} className={classes.continueButton}>Continue</Button>
                        )}
                        {!showCode && !loading && (
                            <Button id="start_order_button" variant="contained" onClick={()=> validatePhone()} className={classes.continueButton}>Get Started</Button>
                        )}
                        {loading && (
                            <center>
                                <CircularProgress size={48} style={{width: '100%', height: 48, width: 48, marginTop: 37}} />
                            </center>
                        )}
                    </Grid>
                </Grid>
            </div>
        </Grid>
    );
}
 
export default Step1;