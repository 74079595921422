import React, {useEffect, useState} from 'react';
import {
    CircularProgress,
    Grid,
    Button,
    TextField,
    Checkbox,
    Radio
} from '@material-ui/core';

import Skeleton from '@material-ui/lab/Skeleton';

import CameraIcon from '@material-ui/icons/PhotoCameraRounded';
import DateIcon from '@material-ui/icons/TodayRounded';
import LocationIcon from '@material-ui/icons/LocationOnRounded';
import CreditCardRoundedIcon from '@material-ui/icons/CreditCardRounded';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import PaymentType from './PaymentType';
import axios from 'axios';

import { makeStyles } from '@material-ui/styles'
import { FormatListBulletedRounded } from '@material-ui/icons';

const mobile = (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1)

const useStyles = makeStyles((theme) => ({
    coverImageDesktop: {
        width: '100%',
        position: 'fixed',
        height: '100%',
        objectFit: 'cover',
        right: 0,
        top: 0 
    }, 
    coverImageMobile: {
        width: '100%',
        height: '70vh',
        objectFit: 'cover',
        backgroundColor:  theme.palette.primary.main
    },
    contentDesktop: {
        width: '50%',
        // minHeight: '100%',
        position: 'fixed',
        left: 0,
        // backgroundColor: theme.palette.primary.background,
        padding: 24
    },
    contentMobile: {
        width: '100vw',
        position: 'fixed',
        maxHeight: 'calc(100% - 58px)',
        overflowY: 'scroll',
        bottom: 0,
        backgroundColor: theme.palette.primary.background,
        borderRadius: '35px 35px 0 0',
        padding: 24
    },
    continueButton: {
        width: '100%',
        height: 58,
        borderRadius: 29,
        marginTop: 32,
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        fontWeight: 'bold',
        textTransform: 'none',
        fontSize: mobile ? 16 : 18,
        boxShadow: '0px 5px 20px 0px rgba(59,102,246,0.4)'
    },
    title: {
        fontWeight: 900,
        fontSize: mobile ? 22 : 34,
        color: theme.palette.primary.darkText,
        textAlign: 'left'
    },
    subTitle: {
        fontWeight: 500,
        fontSize: mobile ? 14 : 18,
        color: theme.palette.primary.lightText,
        textAlign: 'left',
        opacity: 0.6,
        marginTop: 12,
        marginBottom: 24
    },
    gridDesktop: {
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)'
    },
    iconWrapper: {
        height: 80, width: '100%', marginTop: 8
    },
    iconBackground: {
        width: 60, height: 60, borderRadius: 12, display: 'inline-block', verticalAlign: 'middle', backgroundColor: 'rgba(59, 102, 246, 0.2)', float: 'left', marginTop: 0,
        boxShadow: '0px 4px 8px 0px rgba(99,52,255,0.1)',
        background: 'white',
        backgroundColor: 'white'
    },
    icon: {
        height: 24, width: 24, marginTop: 18, marginLeft: 0, color: 'rgba(59, 102, 246, 1)',
        color: theme.palette.primary.main
    },
    insidePlatform: {
        width: '100%',
        minHeight: '100%',
        pddingTop: 60, paddingBottom: 20,
        // backgroundColor: theme.palette.primary.background
    },
    backToAppButton: {
        backgroundColor: 'white',
        height: 52, 
        borderRadius: 26,
        color: '#6334FF',
        width: 160,
        marginTop: 24,
        zIndex: 999,
        textTransform: 'none',
        fontSize: 17,
        fontWeight: 'bold'
    },
    iconTitle: {
        borderRadius: 12,
        display: 'inline-block',
        verticalAlign: 'middle',
        float: 'left',
        marginTop: 0,
        color: theme.palette.primary.darkText,
        textAlign: 'left',
        marginLeft: 24,
        marginTop: 24,
        fontSize: 15
    }
}));

const Step2 = ({
    selectedProduct,
    datetime,
    location,
    checkingCoupon,
    checkIfCouponIsValid,
    canApply,
    couponType,
    couponIsValid,
    paymentURL,
    couponResultText,
    couponAmount,
    errorCoupon,
    handleChangeCoupon,
    isPackage,
    history,
    paymentType,
    setPaymentType,
    getTotalCost,
    paymentData,
    removeCoupon,
    getPriceAfterCouponBeforeVat,
    couponCode,
    landingPage
}) => {
    const classes = useStyles();

    const [cardNumber, setCardNumber] = React.useState('')
    const [cvc, setCvc] = React.useState('')
    const [expiry, setExpiry] = React.useState('')
    const [errorCard, setErrorCard] = React.useState(false)
    const [newCardOpen, setNewCardOpen] = React.useState(false)
    const [cards, setCards] = React.useState([])
    const [anchorEl, setAnchorEl] = React.useState(null)
    const [cardIndex, setCardIndex] = React.useState(null)
    const [addingCard, setAddingCard] = React.useState(false)
    const [loadingCards, setLoadingCards] = React.useState(false)
    const [saveCardForLater, setSaveCardForLater] = React.useState(false)
    const [resposeData, setResposeData] = React.useState('')
  
    useEffect(() => {
      getExistingCards()
      let body = {
        TranzilaTK: '',
        remarks: '',
        expmonth: '',
        year: '',
        sum: ''
      }
    }, [])
  
    const getExistingCards = async () => {
      setLoadingCards(true)
      console.log('getCards')
      let cards = await axios.post('https://us-central1-wesnapp-d136a.cloudfunctions.net/getAccountTokens', {accountId: localStorage.getItem('accountId')}, {headers: {'Content-Type': 'application/json'}})
      console.log('cards', cards.data)
      setCards(cards.data)
      setNewCardOpen(false)
      setAddingCard(false)
      setLoadingCards(false)
    }


    const chargeCard = async (card) => {
        setAddingCard(true)
        let res = await axios.post('https://us-central1-wesnapp-d136a.cloudfunctions.net/makePayment', {paymentData: paymentData, sum: paymentData.totalAmountToPay, TranzilaTK: card.Token__c, expmonth: card.Expiration_Date__c.split('/')[0], expyear: card.Expiration_Date__c.split('/')[1]}, {headers: {'Content-Type': 'application/json'}})
        let resData = res.data
        if (resData.includes('rror')) {
          setResposeData(resData.split('<a')[0])
        } else {
          setResposeData(res.data)
        }
        console.log('res', res)
        setAddingCard(false)
      }

    const [state, setState] = React.useState({
        coupon: false
    });

    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
        if (event.target.checked === false) {
            removeCoupon()
        }
    };

    const getPageStyle = () => {
        if (!landingPage) {
            return classes.insidePlatform
        }
        if (mobile) {
          return classes.coverImageMobile
        } else {
          return classes.coverImageDesktop
        }
    }

  return (
      <>
          <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
              {/* {landingPage && (
                  <img alt="" src={require('../../../Assets/orderBackground.jpg')} className={ mobile ? classes.coverImageMobile : classes.coverImageDesktop} />
              )} 
              {landingPage && (
                  <div alt="" className={ mobile ? classes.coverImageMobile : classes.coverImageDesktop}></div>
              )}  */}
              <div className={getPageStyle()}>
              <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                  className={
                      (mobile || !landingPage) ? {} : classes.gridDesktop
                  }
              > 
                  <Grid item xs={mobile ? 12 : 11} sm={mobile ? 12 : !landingPage ? 10 : 8} md={mobile ? 12 : !landingPage ? 9 : 7} xl={mobile ? 12 : !landingPage ? 8 : 6} lg={mobile ? 12 : !landingPage ? 7 : 5}> 
                    <div className={classes.title}>
                        Almost Done!
                    </div>
                    <div className={classes.subTitle}>
                        Please complete the payment
                    </div>
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        spacing={2}
                    > 
                        <Grid item xs={12} sm={12} md={6} xl={6} lg={6}> 
                            <div className={classes.iconWrapper}>
                                <div className={classes.iconBackground}>
                                    <CameraIcon size={30} className={classes.icon}/></div>
                                <div className={classes.iconTitle}>{selectedProduct.Public_Product_Name__c}</div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} xl={6} lg={6}> 
                            <div className={classes.iconWrapper}>
                                <div className={classes.iconBackground}>
                                    <CameraIcon size={30} className={classes.icon}/></div>
                                <div className={classes.iconTitle}>{selectedProduct.Public_Product_Name__c}</div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} xl={6} lg={6}> 
                            <div className={classes.iconWrapper}>
                                <div className={classes.iconBackground}>
                                    <LocationIcon size={30} className={classes.icon}/></div>
                                <div className={classes.iconTitle}>{location.description}</div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} xl={6} lg={6}> 
                            <div className={classes.iconWrapper}>
                                <div className={classes.iconBackground}>
                                    <CreditCardRoundedIcon size={30} className={classes.icon}/></div>
                                <div className={classes.iconTitle}>{getPriceAfterCouponBeforeVat()} ILS + VAT</div>
                            </div>
                        </Grid>
                    </Grid>
                    {/* Coupon */}
                    {!isPackage && (
                        <div style={{textAlign: 'left'}}>
                            <FormControlLabel
                                control={<Switch checked={state.coupon} onChange={handleChange} name="coupon" />}
                                label="I have a coupon"
                            />
                        </div>
                    )}
                    {(!isPackage && state.coupon) && (
                        <center>
                            <div style={{fontFamily: "'Montserrat', sans-serif", fontSize: 16, color: '#333', fontWeight: 'bold', textAlign: 'left', marginBottom: 0, marginTop: 12, color: 'rgb(40,40,53)', width: '100%'}}>
                                <div style={{display: 'inline-block', verticalAlign: 'bottom', width: 'calc(100% - 80px'}}><TextField id="coupon" label="Coupon" error={errorCoupon} value={couponCode} onChange={handleChangeCoupon} fullWidth/></div> 
                                <div style={{display: 'inline-block', verticalAlign: 'bottom'}}>
                                    {checkingCoupon ? (
                                        <CircularProgress size={40} style={{marginLeft: 15}}/>
                                    ) : (
                                        <Button helper helperText="Error coupon" variant="primary" style={{marginLeft: 10, width: 70, height: 40, backgroundColor: '#6334FF', color: 'white', textTransform: 'none', fontWeight: 'bold', borderRadius: 18, opacity: canApply ? 1 : 0.5}} disabled={!canApply} onClick={()=> checkIfCouponIsValid()}>Apply</Button>
                                    )}
                                </div>
                            </div>
                            {errorCoupon && (
                                <div style={{fontFamily: "'Montserrat', sans-serif", fontSize: 14, color: '#333', marginTop: 4, textAlign: 'left', marginBottom: 0, color: 'red', width: '100%'}}>
                                    {couponResultText}
                                </div>
                            )}
                            {couponResultText === 'Coupon is valid' && (
                                <div style={{fontFamily: "'Montserrat', sans-serif", fontSize: 14, color: '#333', marginTop: 4, textAlign: 'left', marginBottom: 0, color: 'green', width: '100%'}}>
                                    {couponResultText}
                                </div>
                            )}
                        </center>
                    )}
                    {/* End Coupon */}
                    {(couponIsValid && !isPackage) && (
                        <div style={{fontSize: 14, marginTop: 12, marginBottom: 0, fontFamily: "'Arial", fontWeight: 'regular'}}>
                        <b>
                            {couponType === 'cash' ? (
                                <div><span style={{fontWeight: 'regular'}}>Coupon off {couponAmount} ILS added</span> <span  style={{fontWeight: 'regular'}} onClick={()=> removeCoupon()} style={{color: 'red', cursor: 'pointer', fontWeight:'regular'}}><u>Remove</u></span></div>
                                ) : (
                                <div><span style={{fontWeight: 'regular'}}>Coupon off {couponAmount} % added</span> <span  style={{fontWeight: 'regular'}} onClick={()=> removeCoupon()} style={{color: 'red', cursor: 'pointer', fontWeight:'regular'}}><u>Remove</u></span></div>
                            )}
                            <br/>
                        </b>
                        </div>
                    )}
                    {isPackage && (
                        <div style={{fontSize: 18, marginTop: 24, marginBottom: 20, fontFamily: "'Montserrat', sans-serif"}}>
                            <b>
                                <span>Already paid by package</span>
                            </b>
                            <div>
                                <Button
                                    variant="contained"
                                    onClick={()=> {history.push('/'); window.location.reload()}}
                                    className={classes.backToAppButton}                                    
                                >
                                    Back to App
                                </Button>
                            </div>
                        </div>
                    )}
                    {selectedProduct.Product_Type__c === 'Package' && (
                        <PaymentType paymentType={paymentType} setPaymentType={setPaymentType} />
                    )}
                    {/* {!isPackage && (
                        <>
                            {paymentURL ? (
                                <div style={{width: '100%'}}>
                                    <iframe style={{width: '100%', height: 350, marginTop: 24, frameBorder: 0, borderRadius: 0, border: 0}} src={paymentURL}>
                                    </iframe>
                                    <div style={{marginTop: 12, marginBottom: 12, width: '100%', textAlign: 'center', fontSize: 16}}>The process might take few seconds. Please wait.</div>
                                </div>
                            ) : (
                                <center>
                                    <CircularProgress size={50} style={{marginTop: 32}}/>
                                </center>
                            )}
                        </>
                    )} */}
                    {(!isPackage && !landingPage) && (
                        <>
                            <div style={{fontSize: 11}} dangerouslySetInnerHTML={{__html: resposeData}} />
                            {!resposeData.includes('succe') && (
                            <>
                                <div style={{marginTop: 20, width: '100%', height: 5}}></div>
                                <div style={{textAlign: 'left', fontWeight: 'bold', fontSize: 20}}>Use saved card</div>
                                <div style={{marginTop: 20, width: '100%', height: 5}}></div>
                                <div style={{width: '100%', display: 'flex', flexFlow: 'column', gap: 12}}>
                                {loadingCards && (
                                    <>
                                        <Skeleton variant="rect" width={'100%'} height={70} />
                                        <Skeleton variant="rect" width={'100%'} height={70} />
                                        <Skeleton variant="rect" width={'100%'} height={70} />
                                    </>
                                    )}
                                    {!loadingCards && (
                                    <>
                                        {cards.map((item, index) => (
                                        <div style={{width: '100%', background: 'white', gap: 12, textAlign: 'left', padding: '14px 16px', display: 'flex', alignItems: 'center', borderRadius: '12px', boxShadow: '0px 4px 10px #6334FF10'}} key={index}>
                                            <CreditCardRoundedIcon />
                                            <div style={{flexGrow: 1, textAlign: 'left'}}>**** **** **** {item.Last_4_Digits__c}. Expires: {item.Expiration_Date__c.replace(/ /g, '')}</div>
                                            {item.Default_Card__c && (
                                            <div className={classes.defaultTag}>Default</div>
                                            )}
                                            {addingCard ? <CircularProgress /> : 
                                            <Button className={classes.defaultTag} onClick={() => chargeCard(item)}>Charge Card</Button>
                                            }
                                        </div>
                                        ))}
                                    </>
                                    )}
                                </div>
                                <div style={{marginTop: 20, width: '100%', height: 5}}></div>
                                <div style={{textAlign: 'left', fontWeight: 'bold', fontSize: 20}}>Use new card</div>
                                <div style={{marginTop: 20, width: '100%', height: 5}}></div>
                                <FormGroup row>
                                    <FormControlLabel
                                    control={<Checkbox checked={saveCardForLater} onChange={(e) => setSaveCardForLater(e.target.checked)} name="checkedA" />}
                                    label="Save card for next payments"
                                    />
                                </FormGroup>
                                <iframe style={{width: '100%', height: 490, marginTop: 24, frameBorder: 0, borderRadius: 0, border: 0}} src={paymentURL} />
                                <div style={{marginTop: 12, marginBottom: 12, width: '100%', textAlign: 'center', fontSize: 16}}>The process might take few seconds. Please wait.</div>
                            </>
                            )}   
                            {/* {paymentURL ? (
                                <div style={{width: '100%'}}>
                                    <iframe style={{width: '100%', height: 490, marginTop: 24, frameBorder: 0, borderRadius: 0, border: 0}} src={paymentURL}>
                                    </iframe>
                                    <div style={{marginTop: 12, marginBottom: 12, width: '100%', textAlign: 'center', fontSize: 16}}>The process might take few seconds. Please wait.</div>
                                </div>
                            ) : (
                                <center>
                                    <CircularProgress size={50} style={{marginTop: 32}}/>
                                </center>
                            )} */}
                        </>
                    )}
                    {/* {(!isPackage && !landingPage) && (
                        <>
                             {paymentURL ? (
                                <div style={{width: '100%'}}>
                                    <FormGroup row>
                                        <FormControlLabel
                                        control={<Checkbox checked={saveCardForLater} onChange={(e) => setSaveCardForLater(e.target.checked)} name="checkedA" />}
                                        label="Save card for next payments"
                                        />
                                    </FormGroup>
                                    <iframe style={{width: '100%', height: 490, marginTop: 24, frameBorder: 0, borderRadius: 0, border: 0}} src={paymentURL}>
                                    </iframe>
                                    <div style={{marginTop: 12, marginBottom: 12, width: '100%', textAlign: 'center', fontSize: 16}}>The process might take few seconds. Please wait.</div>
                                </div>
                            ) : (
                                <center>
                                    <CircularProgress size={50} style={{marginTop: 32}}/>
                                </center>
                            )}
                        </>
                    )} */}
                    </Grid>
                </Grid>
                </div>
            </Grid>
        </>
    );
}
 
export default Step2;