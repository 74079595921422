import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
// import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
// import List from '@material-ui/core/List';
// import Divider from '@material-ui/core/Divider';
// import ListItem from '@material-ui/core/ListItem';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PhoneIcon from '@material-ui/icons/Phone';

import BillingIcon from '@material-ui/icons/CreditCard';
import MembersIcon from '@material-ui/icons/AccountTree';
import Grid from '@material-ui/core/Grid';
import { SnackbarProvider, useSnackbar } from 'notistack';
// import Snackbar from '@material-ui/core/Snackbar';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios';
import { withRouter } from "react-router-dom";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import Location from './Location';
import { TextField } from '@material-ui/core';

const saleBanner = require("../../../../Assets/sale-banner.png");
var mobile = (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1)

const cameraIcon = require("../../../../Assets/cameraTableIcon.png");

const noImage = require("../../../../Assets/no_image.png");

const dateIcon = require("../../../../Assets/dateIcon.png");
const locationIcon = require("../../../../Assets/locationIcon.png");
const userIcon = require("../../../../Assets/userIcon.png");
const imageIcon = require("../../../../Assets/imageIcon.png");


const styles = theme => ({
  list: {
    width: 250
  },
  fullList: {
    width: 'auto',
  },
  root: {
    backgroundColor: '#6C65FF',
    backgroundColor: 'white',
    width: '100%',
    boxShadow: '0px 4px 10px #6334FF10'
  },
  buttonv: {
    height: 50,
    marginTop: 15,
    // float: 'left',
    borderRadius: 25,
    paddingLeft: 32,
    paddingRight: 32,
    boxShadow: 'none',
    hover: {
      backgroundColor: '#33D7A0'
    }
  },
  button: {
    color: 'white',
    height: 50,
    backgroundColor: theme.palette.primary.main,
    textTransform: 'none',
    fontWeight: 'bold',
    paddingLeft: 32,
    paddingRight: 32,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
    boxShadow: `0 4px 10px ${theme.palette.primary.main}40`,
    borderRadius: 25
  },
});

class PhotoShoots extends React.Component {

  state = {
    isLoading: false,
    firstName: '',
    lastName: '',
    email: '',
    mobile: '',
    protfolio: '',
    address: '',
    phone: '',
    error_phone: "", error_firstName: "",error_lastName: "",error_email: "",error_address: "",
    formattedAddress: '',
    country: '',
    street: '',
    city: '',
    lat: '', lng: '',
  }

  componentWillMount() {
      
  }

  componentDidMount = () => {
    var contactId = localStorage.getItem("contactId")
    var userData = this.props.userData
    console.log(userData)
    this.setState({
        firstName: userData.FirstName,
        lastName: userData.LastName,
        mobile: userData.Mobile_Number__c,
        email: userData.PersonEmail,
        protfolio: userData.Portfolio_link__pc,
        formattedAddress: userData.BillingAddress.street + ', ' + userData.BillingAddress.city + ', ' + userData.BillingAddress.country,
        city: userData.BillingAddress.city,
        street: userData.BillingAddress.street,
        country: userData.BillingAddress.country
    })
  }

  getCoordinates = async (address) => {
    try {
      var link = "https://maps.googleapis.com/maps/api/geocode/json?address=" + encodeURI(address) + "&key=AIzaSyAaiPKKuOKyFcl_Hed_xF_1Jx9oQiKJu_s"
      const response = await axios.get(link);
      console.log(response.data.results[0])
      if (response.data.results.length > 0) {
        if (response.data.results[0].types.includes('street_address')) {
          var address = response.data.results[0].formatted_address.split(", ")
          var street = address[0]
          var city = address[1]
          var country = address[2]
          console.log(address)

          this.setState(
            { formattedAddress: address,
              error_address: '',
              // lat: response.data.results[0].geometry.location.lat,
              // lng: response.data.results[0].geometry.location.lng,
              city: city,
              street: street,
              country: country
            }, ()=> {
          })
        } else {
          this.setState({error_address: 'Please choose specific address (including street number)'})
        }
      } else {
        this.setState({error_address: "We wasn't able to find the locations. Check again"})
      }
    } catch (err) {
      this.setState({error_address: "We wasn't able to find the locations. Check again"})
    }
  }

  handleChangeText = name => event => {
    // console.log("@@");
    // console.log( event.target.value);
    this.setState({ [name]: event.target.value });
  };

  handleChangeLocation = (value) => {
    // console.log(value.description);
    this.setState({ "address": value.description });
  };

  validateEmail = (email) => {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  validateMobile = (phone) => {
    console.log(phone);
    var substring1 = " 9725"
    var substring2 = " 97205"
    if (phone.includes(substring1) || phone.includes(substring2)) {
      // console.log("@@");
      if (phone.indexOf(substring1) !== -1) {
        // console.log(phone);
        phone = phone.replace(" 9725", "05");
        phone = phone.replace("-", "");
        if (phone[0] == "0" && phone[1] == "5" && (phone[2] == "2" || phone[2] == "0" || phone[2] == "3" || phone[2] == "4" || phone[2] == "5" || phone[2] == "8" || phone[2] == "3")) {
          if (phone.length == 10 && (/^\d+$/.test(phone))) {
            this.setState({phone: phone})
            return true
          }
        } else {
          return false;
        }
      } else {
        phone = phone.replace(" 97205", "05");
        phone = phone.replace("-", "");
        // console.log(phone);
        if (phone[0] == "0" && phone[1] == "5" && (phone[2] == "2" || phone[2] == "0" || phone[2] == "3" || phone[2] == "4" || phone[2] == "5" || phone[2] == "8" || phone[2] == "3")) {
          if (phone.length == 10 && (/^\d+$/.test(phone))) {
            this.setState({phone: phone})
            return true
          }
        } else {
          return false;
        }
      }
    } else {
      phone = phone.replace("-", "");
      if (phone[0] == "0" && phone[1] == "5" && (phone[2] == "2" || phone[2] == "0" || phone[2] == "3" || phone[2] == "4" || phone[2] == "5" || phone[2] == "8" || phone[2] == "3")) {
        if (phone.length == 10 && (/^\d+$/.test(phone))) {
          this.setState({phone: phone})
          return true
        }
      } else {
        return false;
      }
    }
  }

  save = async () => {
    var allowToContinue = true
    this.setState({error_phone: "", error_firstName: "",error_lastName: "",error_email: "",error_address: "",error_group: "", error_acceptTerms: "", error_comment: ""})
    if (this.state.firstName == "") {
      this.setState({error_firstName: "First name empty"})
      allowToContinue = false
    }
    if (this.state.lastName == "") {
      this.setState({error_lastName: "Last name empty"})
      allowToContinue = false
    }
    if (this.state.email == "") {
      this.setState({error_email: "Email empty"})
      allowToContinue = false
    } else {
      if (!this.validateEmail(this.state.email)) {
        this.setState({error_email: "Email is not valid"})
        allowToContinue = false
      }
    }
    // if (!this.validateMobile(this.state.phone)) {
    //   this.setState({error_phone: "Error phone number"})
    //   allowToContinue = false
    // }
    if (this.state.formattedAddress === "") {
      this.setState({error_address: "We wasn't able to find the locations. Check again"})
      allowToContinue = false
    } else {
      await this.getCoordinates(this.state.formattedAddress)
      if (this.state.city !== '' && this.state.street !== '' && this.state.country !== '') {

      } else {
        this.setState({error_address: "We wasn't able to find the locations. Check again"})
        allowToContinue = false
      }
    }
    if (allowToContinue) {
      this.saveNewDataInDB()
    }
  }

  saveNewDataInDB = () => {
    this.setState({updating: true})
    var data = {
      id: localStorage.getItem("accountId"),
      data: {
        FirstName: this.state.firstName,
        LastName: this.state.lastName,
        PersonEmail: this.state.email,
        Portfolio_link__pc: this.state.portfolio,
        BillingCity: this.state.city,
        BillingStreet: this.state.street,
        BillingCountry: this.state.country
      }
     };
     axios.post('https://wesnapp.co.il/api/photographer/update-profile',data, {headers: {'Content-Type': 'application/json'}}).then((response) => {
      this.setState({updating: false})

      if (this.props.popup) {
        this.props.openSuccess()
        this.props.nextStep()
      } else {
        this.props.main.setState({openSuccess: true})
      }
      // Send slack notification
      var text = "⚠️ The photographer <" + "https://wesnapp.lightning.force.com/lightning/r/Account/" + localStorage.getItem("accountId") + "/view" + "|" + this.state.firstName + ' ' + this.state.lastName + "> changed his details in general settings"
      axios.post('https://wesnapp.co.il/api/slack/photographer-notification',{text: text}, {headers: {'Content-Type': 'application/json'}}).then((response) => {
      })
      .catch( (error) => {
      });
      // Set last update date and time
      axios.post('https://us-central1-wesnapp-d136a.cloudfunctions.net/updateContact',{id: localStorage.getItem("accountId"), data: {Last_Confirmed_Settings__c: new Date().toISOString()}}, {headers: {'Content-Type': 'application/json'}}).then((response) => {
      })
      .catch( (error) => {
      });
     })
      this.props.getUserDetails()
     .catch( (error) => {
     });
  }

  render() {
    const { classes, theme } = this.props;

    return (
        <div style={{backgroundColor: 'white', borderRadius: '23px', boxShadow: '0px 4px 10px #6334FF10', marginTop: 0, width: '100%', padding: 24, marginBottom: 24}}>
            <Grid container spacing={2} style={{padding: 0, paddingTop: 0, width: '100%'}} justifyContent="center" justify="center">
            <Grid item xs={12} sm={10} md={8} xl={7}>
                    <div style={{fontSize: 20, color: 'rgb(40,40,53)', textAlign: 'left', marginLeft: 0, fontFamily: "'Montserrat', sans-serif"}}>
                        <b>General Details</b>
                    </div>
                </Grid>
                <Grid item xs={12} sm={10} md={8} xl={7}>
                    <Grid container spacing={2} dir="ltr">
                        <Grid item xs={6} sm={6} md={6}>
                            <TextField fullWidth label='First Name' id={"firstName_ph"} type={"text"}  onChange={this.handleChangeText("firstName")} placeholder="First name*" value={this.state.firstName} style={{float: 'right'}} />
                            <div style={{marginLeft: 12, float: 'left', color: 'red', marginTop: 3, textAlign: 'left'}}>{this.state.error_firstName}</div>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6}>
                            <TextField fullWidth label='Last Name' id={"lastName_ph"} type={"text"}  onChange={this.handleChangeText("lastName")} placeholder="Last name*" value={this.state.lastName} style={{float: 'right'}} />
                            <div style={{marginLeft: 12, float: 'left', color: 'red', marginTop: 3, textAlign: 'left'}}>{this.state.error_lastName}</div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <TextField fullWidth label='Email' id={"email_ph"} type={"email"}  onChange={this.handleChangeText("email")} placeholder="Email*" value={this.state.email} style={{float: 'right'}} />
                            <div style={{marginLeft: 12, float: 'left', color: 'red', marginTop: 3, textAlign: 'left'}}>{this.state.error_email}</div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <TextField fullWidth label='Phone' disabled id={"phone_ph"} type={"tel"}  onChange={this.handleChangeText("phone")} placeholder="Phone*" value={this.state.mobile} style={{float: 'right'}} />
                            <div style={{marginLeft: 12, float: 'left', color: 'red', marginTop: 3, textAlign: 'left'}}>{this.state.error_phone}</div>
                        </Grid>
                        
                        <Grid item xs={12} sm={12} md={12}>
                            <Location fullWidth label='Address' main={this} address={this.state.formattedAddress}/>
                            <div style={{marginLeft: 12, float: 'left', color: 'red', marginTop: 3, textAlign: 'left'}}>{this.state.error_address}</div>
                        </Grid>
                
                        <Grid item xs={12} sm={12} md={12}>
                            <TextField fullWidth label='Protfolio Link' id={"protfolio_ph"} type={"text"}  value={this.state.protfolio}  onChange={this.handleChangeText("portfolio")} placeholder="Portfolio link (Website / Instagram / Facebook)" style={{float: 'right'}} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                        {this.state.updating ? (
                          <div style={{width: '100%', marginTop: 5}}>
                            <center>
                              <CircularProgress size={40} />
                            </center>
                          </div>
                        ) : (
                          <div style={{width: '100%', textAlign: 'center'}}>
                            <center>
                              <Button id={"register_ph"} onClick={() => this.save()} elevation={0} className={classes.button}>
                                <b>{this.props.popup ? 'Continue' : 'Save'}</b>
                              </Button>
                            </center>
                          </div>
                        )}
                      </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
  }
}

PhotoShoots.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(withRouter(PhotoShoots));
