import React from "react";

const imgWithClick = { cursor: "pointer" };

const Photo = ({ index, onClick, photo, margin, direction, top, left, src }) => {

  const imgStyle = { margin: margin };
  if (direction === "column") {
    imgStyle.position = "absolute";
    imgStyle.left = left;
    imgStyle.top = top;
  }

  const handleClick = event => {
    onClick(event, { photo, index });
  };

  return (
    <img
      style={onClick ? { ...imgStyle, ...imgWithClick } : imgStyle}
    //   {...photo}
      src={photo.image}
      onClick={onClick ? handleClick : null}
      alt="img"
      width={100}
      height={100}
      style={{zIndex: 999999999999}}
    />
  );
};

export default Photo;
