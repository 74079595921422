import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {AppBar, Typography, Toolbar} from '@material-ui/core';
import PropTypes from 'prop-types';
import AppBarComponent from '../AppBar';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import { createBrowserHistory } from "history";
import { withRouter } from "react-router-dom";
import axios from 'axios'
import { Button } from '@material-ui/core';
import Map from './Map';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ImageIcon from '@material-ui/icons/Image';
import WorkIcon from '@material-ui/icons/Work';
import BeachAccessIcon from '@material-ui/icons/BeachAccess';
import PaymentIcon from '@material-ui/icons/Payment';
import LocationIcon from '@material-ui/icons/LocationOn';
import DateIcon from '@material-ui/icons/Event';
import Skeleton from '@material-ui/lab/Skeleton';
import DurationIcon from '@material-ui/icons/Schedule';
import moment from 'moment';
import CheckingDialog from './CheckingDialog';
import MapDialog from './MapDialog';
import WonDialog from './WonDialog';
import LoseDialog from './LoseDialog';
import NotAvailableDialog from './NotAvailableDialog';
import Collapse from '@material-ui/core/Collapse';

import { TimePicker,DatePicker } from 'antd';
import '../../../../node_modules/antd/dist/antd.css';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

const history = createBrowserHistory();

const styles = theme => ({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  formControl: {
    width: '100%',
    marginTop: 12
  },
  root: {
    width: '100vw',
    height: '100vh',
    backgroundColor: '#FFF',
    fontWeight: 'bold',
    // background: 'linear-gradient(90deg, #6C65FF 50%, #FFF 50%)',
    position: 'fixed',
    top: 0, left: 0,
    '& .MuiSelect-outlined': {
      borderRadius: 20
    }
  }
});

const mobile = (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1)

class Template extends React.Component {

  state = {
      approving: false,
      rejecting: false,
      rejected: false,
      approved: false,
      pending: true,
      loading: true,
      loaded: false,
      openTakenPopup: false,
      openApprovedPopup: false,
      relationship: null,
      status: '',
      openCheckingDialog: false,
      openMapDialog: false,
      openNotAvailable: false,
      openAlternativeDialog: false,
      alternative1: null,
      alternative2: null,
      requestId: null
  }

  componentDidMount() {
      console.log(this.props.match.params.id)
      this.getRequestData(this.props.match.params.id)
      console.log(this.getSfDateAndTime());
      console.log(this.getDT())
  }

  getDT = () => {
    var dt = moment().format('YYYY-MM-DDTh:mm:ss') + '.000+0200'
    return dt
    // console.log(dt)
  }

  getRequestData = (id) => {
    var data = {id: id}
    console.log(data)
    this.setState({id: id})
    // axios.post('https://wesnapp.co.il' + '/api/accountcontactrelation/get-one',data, {headers: {'Content-Type': 'application/json'}}).then((response) => {
    axios.post('https://wesnapp.co.il' + '/api/match/get-one',data, {headers: {'Content-Type': 'application/json'}}).then((response) => {
        console.log(response.data)
        var relationship = response.data[0]
        // this.isStillRelevant(relationship.Request__c)
        this.setState({requestId: relationship.Request__c})
        this.setState({relationship: response.data[0], loading: false}, ()=> {
            var status = relationship.Matching_Status__c
            if (status === 'Pending') {
                this.checkDate()
            } else if (status === 'Won') {
                this.setState({status: 'won'})
            } else if (status === 'Rejected') {
              this.setState({status: 'rejected'})
            } else if (status === 'Closed' || status === 'Colsed') {
                this.setState({status: 'closed'})
            } else if (status === 'Not Available') {
              this.setState({status: 'not available'})
            }
            this.setState({loaded: true})
        })
        if (response.data[0].Seen_offer__c === false) {
            this.updateSeenAt()
        }
    })
    .catch( (error) => {
      //console.log(error);
    });
  }

  getDateTime =  (dateAndTime) => {
    var dt = moment(dateAndTime).format('MMMM Do YYYY, h:mm a')
    console.log(dt)
    return dt
  }

  updateSeenAt = () => {
    var data = {
        id: this.state.id,
        data: {
            'Seen_Offer_Date_Time__c': this.getSfDateAndTime(),
            'Seen_offer__c': true
        }
    }
    axios.post('https://wesnapp.co.il' + '/api/match/update-one',data, {headers: {'Content-Type': 'application/json'}}).then((response) => {
        console.log(response.data)
        if (response.data === true) {
        } else {
        }
    })
    .catch( (error) => {
      //console.log(error);
    });
  }

getSfDateAndTime = () => {
  var dt = moment(new Date()).format('YYYY-MM-DDTh:mm:ss') + '.000+0200'
  return dt
}

isStillRelevant = async (id) => {
  var data = {id: id}
  // var response = await axios.post('https://wesnapp.co.il' + '/api/match/get-one',data, {headers: {'Content-Type': 'application/json'}})
  // console.log(response.data)
  var response = await axios.post('https://wesnapp.co.il' + '/api/request/get-one',data, {headers: {'Content-Type': 'application/json'}})
  console.log(response.data[0].Status__c)
  if (response.data[0].Status__c !== 'WS Auto Match' && response.data[0].Status__c !== 'Pending for match') {
    this.setState({status: 'closed', openCheckingDialog: false}, ()=> {
      // return false
    })
    return false
  } else {
    this.setState({status: 'available', openCheckingDialog: false}, ()=> {
      // return true
    })
    return true
  }
  
  // var relationship = response.data[0]
  // var statuss = relationship.Matching_Status__c
  // this.setState({relationship: response.data[0]}, ()=> {
  //     var status = relationship.Matching_Status__c
  //     if (status === 'Rejected'|| status === 'Not Available') {
  //       this.setState({status: 'rejected', openCheckingDialog: false}, ()=> {
  //       })
  //     } else if (status === 'Pending') {
  //       this.setState({status: 'pending', openCheckingDialog: false}, ()=> {
  //       })
  //     } else if (status === 'Closed' || status === 'Colsed') {
  //         this.setState({status: 'closed', openCheckingDialog: false}, ()=> {
  //         })
  //     } else if (status === 'Not Available') {
  //       this.setState({status: 'not available', openCheckingDialog: false}, ()=> {
  //       })
  //   }
  //   })

  //   if (statuss === 'Rejected' || statuss === 'Not Available') {
  //       return false
  //   } else if (statuss === 'Pending') {
  //       return true
  //   } else if (statuss === 'Closed' || statuss === 'Colsed') {
  //         this.setState({openTakenPopup: true})
  //         return false
  //   }
}

notAvailableDialog = async () => {
  this.setState({openAlternativeDialog: true})
}

notAvailable = async () => {
    if (this.state.alternative1 === null || this.state.alternative2 === null) {
      alert('Suggest two alternatives')
      return
    }
    this.setState({loading: true, openCheckingDialog: true, openNotAvailable: false})
    var date = this.state.relationship.Request_Shooting_Date_Time__c.split('T')
    var Alternative1 = date[0] + 'T' + this.state.alternative1 + ':00.000+0200'
    var Alternative2 = date[0] + 'T' + this.state.alternative2 + ':00.000+0200'
    console.log(Alternative1)
    console.log(Alternative2)
    var data = {id: this.state.id, data: {Matching_Status__c: 'Not Available', Alternative_1__c: Alternative1, Alternative_2__c: Alternative2}}
    axios.post('https://wesnapp.co.il' + '/api/match/update-one',data, {headers: {'Content-Type': 'application/json'}}).then((response) => {
        console.log(response.data)
        if (response.data) {
            this.setState({loading: false, status: 'not available', openCheckingDialog: false, openAlternativeDialog: true, openNotAvailable: false})
            // var text =  'Suggested Different Availability | ' + this.state.relationship.Name + ": The photographer - " + this.state.relationship.Photographer_Name__c + " is not available for photo shoot. Date & Time - " + this.getDateTime(this.state.relationship.Request_Shooting_Date_Time__c) + ', Location - ' + this.state.relationship.Request_Shooting_Street__c + ', ' + this.state.relationship.Request_Shooting_City__c + ". The photographer is available at " + this.state.alternative1 + ' or at ' + this.state.alternative2
            var text = "⚠️ Suggested Different Availability | Request <https://wesnapp.lightning.force.com/lightning/r/Request__c/" + this.state.relationship.Request__c + "/view|" + this.state.relationship.Request_Name__c + ">: The photographer - <https://wesnapp.lightning.force.com/lightning/r/Account/" + this.state.relationship.Photographer__c + "/view|" + this.state.relationship.Photographer_Name__c + "> *suggested a different time* <https://wesnapp.lightning.force.com/lightning/r/WS_Matching__c/" + this.state.relationship.Id + "/view|" + this.state.relationship.Name + "> for the photo shoot. *Original Date & Time - " + this.getDateTime(this.state.relationship.Request_Shooting_Date_Time__c) + "*, Location - " + this.state.relationship.Request_Shooting_Street__c + ', ' + this.state.relationship.Request_Shooting_City__c + ". *Option 1: " + this.state.alternative1 + " / Option 2: " + this.state.alternative2 + "*"
            axios.post('https://wesnapp.co.il/api/slack/matching-notification',{"text": text}, {headers: {'Content-Type': 'application/json'}}).then((response) => {
              console.log(response.data);
            })
            .catch( (error) => {
              console.log(error);
            });
        } else {
          var text = this.state.relationship.Name + ": error while 'not available' photoshoot. " + JSON.stringify(response.data)
          axios.post('https://wesnapp.co.il/api/slack/matching-notification',{"text": text}, {headers: {'Content-Type': 'application/json'}}).then((response) => {
            console.log(response.data);
          }).catch( (error) => {
            console.log(error);
          });
          this.setState({loading: false})
        }
    })
    .catch( (error) => {
      var text = this.state.relationship.Name + ": error while approving photoshoot. " + JSON.stringify(error)
      axios.post('https://wesnapp.co.il/api/slack/matching-notification',{"text": text}, {headers: {'Content-Type': 'application/json'}}).then((response) => {
        console.log(response.data);
      }).catch( (error) => {
        console.log(error);
      });
      this.setState({loading: false})
      //console.log(error);
    });
  }


  approve = async () => {
    if (this.state.openNotAvailable) return
    var datetime = moment().valueOf()
    console.log('date time', datetime)
    this.setState({loading: true, openCheckingDialog: true})

    // Update when approved - When_Approved__c
    var update = { id: this.state.id, data: {When_Approved__c: datetime }}
    var timeResponse = await axios.post('https://wesnapp.co.il' + '/api/match/update-one',update, {headers: {'Content-Type': 'application/json'}})

    var isStillRelevant = await this.isStillRelevant(this.state.requestId)
    console.log('isStillRelevant', isStillRelevant)
    if (!isStillRelevant) {
      this.setState({loading: false})
      return
    }

    const timer = setTimeout(async () => {
      console.log('This will run after 3 second!')
      var notRelevant = false
      var relevantRequests = await axios.post('https://wesnapp.co.il' + '/api/match/get-many',{q:{Request__c: this.state.requestId, Matching_Status__c: 'Pending'}}, {headers: {'Content-Type': 'application/json'}})
      console.log(relevantRequests)
      for (let index = 0; index < relevantRequests.data.length; index++) {
        const r = relevantRequests.data[index];
        if (this.state.id != r.Id && r.When_Approved__c !== null) {
          if (parseInt(datetime) > parseInt(r.When_Approved__c)) {
            console.log('earlier datetime', r.When_Approved__c)
            notRelevant = true
            break
          }
        }
      }
      console.log(notRelevant)
      if (notRelevant) {
        this.setState({loading: false, status: 'closed'})
        this.setState({openTakenPopup: true}) 
        return
      }
      var data = { id: this.state.id, data: {Matching_Status__c: 'Won', Matched_Approved__c: true, Approved_Date_Time__c: this.getSfDateAndTime() }}
      axios.post('https://wesnapp.co.il' + '/api/match/update-one',data, {headers: {'Content-Type': 'application/json'}}).then(async (response) => {
          console.log(response.data)
          if (response.data === true) {
              var matchRes = await axios.post('https://wesnapp.co.il' + '/api/match/get-one',data, {headers: {'Content-Type': 'application/json'}})
              console.log(matchRes.data)
              if (matchRes.data[0].Matching_Status__c === 'Won') {
                this.setState({loading: false, status: 'won'})
                this.setState({openApprovedPopup: true})            
                // var text = 'Won Matched | ' + this.state.relationship.Name + ": The photographer - " + this.state.relationship.Photographer_Name__c + " took our offer for photo shoot. Date & Time - " + this.getDateTime(this.state.relationship.Request_Shooting_Date_Time__c) + ', Location - ' + this.state.relationship.Request_Shooting_Street__c + ', ' + this.state.relationship.Request_Shooting_City__c
                var text = "🚀 Won Matched | Request <https://wesnapp.lightning.force.com/lightning/r/Request__c/" + this.state.relationship.Request__c + "/view|" + this.state.relationship.Request_Name__c + ">: The photographer - <https://wesnapp.lightning.force.com/lightning/r/Account/" + this.state.relationship.Photographer__c + "/view|" + this.state.relationship.Photographer_Name__c + "> *took our offer* <https://wesnapp.lightning.force.com/lightning/r/WS_Matching__c/" + this.state.relationship.Id + "/view|" + this.state.relationship.Name + "> for photo shoot. *Date & Time - " + this.getDateTime(this.state.relationship.Request_Shooting_Date_Time__c) + "*, Location - " + this.state.relationship.Request_Shooting_Street__c + ', ' + this.state.relationship.Request_Shooting_City__c
                axios.post('https://wesnapp.co.il/api/slack/matching-notification',{"text": text}, {headers: {'Content-Type': 'application/json'}}).then((response) => {
                  console.log(response.data);
                })
                .catch( (error) => {
                  console.log(error);
                });
              } else {
                this.setState({loading: false, status: 'closed'})
                this.setState({openTakenPopup: true})            
              }
          } else {
              var text = this.state.relationship.Name + ": error while approving photoshoot. " + JSON.stringify(response.data)
              axios.post('https://wesnapp.co.il/api/slack/matching-notification',{"text": text}, {headers: {'Content-Type': 'application/json'}}).then((response) => {
                console.log(response.data);
              }).catch( (error) => {
                console.log(error);
              });
              this.setState({loading: false})
          }
      })
      .catch( (error) => {
        var text = this.state.relationship.Name + ": error while approving photoshoot. " + JSON.stringify(error)
        axios.post('https://wesnapp.co.il/api/slack/matching-notification',{"text": text}, {headers: {'Content-Type': 'application/json'}}).then((response) => {
          console.log(response.data);
        }).catch( (error) => {
          console.log(error);
        });
      });
    }, 3000);
  }

  reject = () => {
    if (this.state.openNotAvailable) return
    this.setState({loading: true})
    var data = {id: this.state.id, data: {Matching_Status__c: 'Rejected'}}
    axios.post('https://wesnapp.co.il' + '/api/match/update-one',data, {headers: {'Content-Type': 'application/json'}}).then((response) => {
        console.log(response.data)
        if (response.data) {
            this.setState({loading: false, status: 'rejected'})
            // var text =  'Rejected | ' + this.state.relationship.Name + ": The photographer - " + this.state.relationship.Photographer_Name__c + " reject offer for photo shoot. Date & Time - " + this.getDateTime(this.state.relationship.Request_Shooting_Date_Time__c) + ', Location - ' + this.state.relationship.Request_Shooting_Street__c + ', ' + this.state.relationship.Request_Shooting_City__c
            var text = "🚫 Rejected Offer | Request <https://wesnapp.lightning.force.com/lightning/r/Request__c/" + this.state.relationship.Request__c + "/view|" + this.state.relationship.Request_Name__c + ">: The photographer - <https://wesnapp.lightning.force.com/lightning/r/Account/" + this.state.relationship.Photographer__c + "/view|" + this.state.relationship.Photographer_Name__c + "> *rejected our offer* <https://wesnapp.lightning.force.com/lightning/r/WS_Matching__c/" + this.state.relationship.Id + "/view|" + this.state.relationship.Name + "> for photo shoot. *Date & Time - " + this.getDateTime(this.state.relationship.Request_Shooting_Date_Time__c) + "*, Location - " + this.state.relationship.Request_Shooting_Street__c + ', ' + this.state.relationship.Request_Shooting_City__c 
            axios.post('https://wesnapp.co.il/api/slack/matching-notification',{"text": text}, {headers: {'Content-Type': 'application/json'}}).then((response) => {
              console.log(response.data);
            })
            .catch( (error) => {
              console.log(error);
            });
        } else {
          var text = this.state.relationship.Name + ": error while approving photoshoot. " + JSON.stringify(response.data)
          axios.post('https://wesnapp.co.il/api/slack/matching-notification',{"text": text}, {headers: {'Content-Type': 'application/json'}}).then((response) => {
            console.log(response.data);
          }).catch( (error) => {
            console.log(error);
          });
          this.setState({loading: false})
        }
    })
    .catch( (error) => {
      var text = this.state.relationship.Name + ": error while approving photoshoot. " + JSON.stringify(error)
      axios.post('https://wesnapp.co.il/api/slack/matching-notification',{"text": text}, {headers: {'Content-Type': 'application/json'}}).then((response) => {
        console.log(response.data);
      }).catch( (error) => {
        console.log(error);
      });
      this.setState({loading: false})
      //console.log(error);
    });
  }

  checkDate = (date) => {
      var datetime = moment(this.state.relationship.Request_Shooting_Date_Time__c)
      var now_datetime = moment(new Date())
      console.log(datetime.format('X') )
      console.log(now_datetime.format('X') )
      if (datetime.format('X') > now_datetime.format('X')) {
          console.log('ok')
          this.setState({status: 'available'})
      } else {
          console.log('passed')
          this.setState({status: 'passed'})
      }
  }

  getTitle = () => {
    if (this.state.loading) {
      return 'Loading...'
    }
    if (!this.state.loading && this.state.status === 'won') {
      return 'You won the job!'
    }
    if (!this.state.loading && this.state.status === 'rejected') {
      return 'You rejected the job!'
    }
    if (!this.state.loading && this.state.status === 'not available') {
      return 'We got your alternative hours and we will be in touch soon!'
    }
    if (!this.state.loading && this.state.status === 'closed') {
      return 'Oppsy the photoshoot was taken. Be faster next time!'
    }
    if (!this.state.loading && this.state.status === 'closed') {
      return 'Date is over...'
    }
    return 'New photo shoot offer!'
  }

  disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < moment().subtract(1, 'days').endOf('day');
  }

  getDateTimeToDisplay = () => {
    var dateAndTime = ''
    var splitedDate = this.props.main.state.date.split('-')
    dateAndTime = splitedDate[1] + "/" +  this.splitedDate[2] + "/" + this.splitedDate[0] + " " + this.props.main.state.time
    return dateAndTime
  }

  onchangeDateTime = (e) => {
    console.log(e._d)
    // this.handleDateChangeFrom(e._d)
    // this.handleTimeChangeFrom(e._d)
  }

  handleTimeChangeFrom = date => {
    var dateObj = new Date(date);
    var newTime = String(dateObj.getHours() + ":" + dateObj.getMinutes())
    this.props.main.setState({time: newTime, formatedTime: new Date(date)})
  }

  handleDateChangeFrom = date => {
    var dateObj = new Date(date);
    var m = String(dateObj.getDate())
    var d = String(dateObj.getMonth()+1)
    var y = String(dateObj.getFullYear())
    if (m.length == 1) {
      m = "0" + m
    }
    if (d.length == 1) {
      d = "0" + d
    }
    var newDate = y + "-" + d + "-" + m
    this.props.main.setState({date: newDate, formatedDate: new Date(date)})
  };

  handleChange = name => event => {
    this.props.main.setState({[name]: event.target.checked})
  };


  handleChangeHour = (event, type) => {
    console.log(event.target.value)
    if (type === 1) {
      this.setState({alternative1: event.target.value})
    } else {
      this.setState({alternative2: event.target.value})
    }
  };

  render() {
    const { classes, theme } = this.props;
    return (
      <div className={classes.root} style={{overflow: 'scroll'}}>
        {/* <AppBarComponent/> */}
        <AppBar className={classes.appBar}>
          <Toolbar>
            {/* <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton> */}
            {/* <Typography variant="h6" className={classes.title}>
              Map
            </Typography> */}
            <Typography variant="h6" className={classes.title}>
              <img alt='' src={require('../../../Assets/white_logo.png')} style={{height: mobile ? 24 : 34, float: 'left'}}/>
            </Typography>
          </Toolbar>
        </AppBar>
        {this.state.relationship && (
          <div style={{width: '100%', display: 'inline-block', verticalAlign: 'top', borderRadius: 0, height: '30%', position: 'relative'}}>
              <Map google={window.google} main={this} lat={this.state.relationship.Request_Lat__c} lng={this.state.relationship.Request_Lng__c}/>
          </div>
        )}
        <WonDialog main={this} />
        <LoseDialog main={this} />
        <NotAvailableDialog main={this} />
        {this.state.openMapDialog && (
          <MapDialog main={this} lat={this.state.relationship.Request_Lat__c} lng={this.state.relationship.Request_Lng__c} />
        )}
        <CheckingDialog main={this}/>
        {this.state.loaded ? (
          <Grid container spacing={0} style={{padding: 10, paddingTop: 8}} justifyContent="center" justify="center">
            <Grid item xs={12} sm={12} md={7} xl={7} style={{marginBottom: 0}}>
                <div style={{width: '100%', fontSize: 18, fontWeight: 'bold', textAlign: 'left', marginTop: 52, marginBottom: 8}}>
                    {/* {this.state.relationship && (
                      <div>
                        Hi {this.state.relationship.Photographer_Name__c},
                      </div>
                    )} */}
                </div>
                <div style={{width: '100%', fontSize: 18, fontWeight: 'regular', textAlign: 'left', marginTop: 20, marginBottom: 0, paddingTop: 12}}>
                    {this.getTitle()}
                </div>
                {/* <div style={{width: '100%', display: 'inline-block', verticalAlign: 'top', borderRadius: 30, height: 200, position: 'relative'}}>
                    <Map google={window.google} lat={32.800520} lng={35.524320}/>
                </div> */}
            </Grid>
            <Grid item xs={12} sm={12} md={12} xl={12}>
                <ListItem style={{padding: 8}}>
                    <ListItemAvatar>
                    <Avatar style={{backgroundColor: '#ECF1FE'}}>
                        <LocationIcon style={{color: '#6433ff'}} />
                    </Avatar>
                    </ListItemAvatar>
                    {this.state.relationship && (
                        <ListItemText primary="Location" style={{fontSize: 16}} secondary={this.state.relationship.Request_Shooting_Street__c + ', ' + this.state.relationship.Request_Shooting_City__c} />
                    )}
                </ListItem>
                {/* {this.state.relationship && (
                    <div style={{textAlign: 'left', marginLeft: 65, cursor: 'pointer', marginTop: -6}} onClick={()=> this.setState({openMapDialog: true})}><u>Show on map</u></div>
                )} */}
            </Grid>
            <Grid item xs={12} sm={12} md={12} xl={12}>
                <ListItem style={{width: '100vw', padding: 8}}>
                    <ListItemAvatar>
                    <Avatar style={{backgroundColor: '#ECF1FE'}}>
                        <DateIcon style={{color: '#6433ff'}} />
                    </Avatar>
                    </ListItemAvatar>
                    {this.state.relationship && (
                        <ListItemText primary="Date & Time"  style={{fontSize: 16}}  secondary={this.getDateTime(this.state.relationship.Request_Shooting_Date_Time__c)} />
                    )}
                </ListItem>
            </Grid>
            {/* <Grid item xs={12} sm={12} md={12} xl={12}>
                <ListItem style={{padding: 8}}>
                    <ListItemAvatar>
                    <Avatar style={{backgroundColor: '#ECF1FE'}}>
                        <DurationIcon style={{color: '#6433ff'}} />
                    </Avatar>
                    </ListItemAvatar>
                    {this.state.relationship && (
                        <ListItemText primary="Duration"  style={{fontSize: 16}}  secondary={this.state.relationship.Shooting_Duration__c + ' min'} />
                    )}
                </ListItem>
            </Grid> */}
            <Grid item xs={12} sm={12} md={12} xl={12}>
                <ListItem style={{padding: 8}}>
                    <ListItemAvatar>
                    <Avatar style={{backgroundColor: '#ECF1FE'}}>
                        <ImageIcon style={{color: '#6433ff'}} />
                    </Avatar>
                    </ListItemAvatar>
                    {this.state.relationship && (
                        <ListItemText primary="Images" style={{fontSize: 16}} secondary={this.state.relationship.Photographer_Min_Number_of_Images__c + ' Images, ' + this.state.relationship.Shooting_Duration__c + ' min'} />
                    )}
                </ListItem>
            </Grid>
            <Grid item xs={12} sm={12} md={12} xl={12}>
                <ListItem style={{padding: 8}}>
                    <ListItemAvatar>
                    <Avatar style={{backgroundColor: '#ECF1FE'}}>
                        <PaymentIcon style={{color: '#6433ff'}} />
                    </Avatar>
                    </ListItemAvatar>
                    {this.state.relationship && (
                      <div>
                        {this.state.relationship.Photographer_Extra_Fee__c === null ? (
                          <ListItemText primary="Payment" style={{fontSize: 16}} secondary={this.state.relationship.Photogrpaher_Total_Fee__c + ' ILS'} />
                        ) : (
                          <ListItemText primary="Payment" style={{fontSize: 16}} secondary={this.state.relationship.Photographer_Product_Fee__c + ' ILS + ' + this.state.relationship.Photographer_Extra_Fee__c + ' ILS bonus'} />
                        )}
                      </div>
                    )}
                </ListItem>
            </Grid>
            {/* <div style={{position: 'fixed', bottom: 20, width: '100%'}}> */}
                {/* <Grid container spacing={1} style={{padding: 20, paddingTop: 40}} justifyContent="center" justify="center"> */}
                    {this.state.status === '' && (
                        <Grid item xs={12} sm={12} md={12} xl={12}>
                            <Button style={{width: '100%', height: 50, marginTop: 10, float: 'left', borderRadius: 25, color: '#4A4A4A', fontWeight: 'bold', textTransform: 'none'}}>
                                Checking...
                            </Button>
                        </Grid>
                    )}
                    {this.state.status === 'passed' && (
                        <Grid item xs={12} sm={12} md={12} xl={12}>
                            <Button style={{width: '100%', height: 50, marginTop: 10, float: 'left', borderRadius: 25, backgroundColor: '#E5E5E5', color: '#4A4A4A', fontWeight: 'bold', textTransform: 'none'}}>
                                Date Passed
                            </Button>
                        </Grid>
                    )}
                    {(!this.state.loading && this.state.status === 'available') && (
                        <Grid item xs={12} sm={12} md={12} xl={12}>
                            <Button onClick={()=> this.approve()} style={{width: '100%', maxWidth: 400, height: 50, marginTop: 10, float: 'right', borderRadius: 25, backgroundColor: '#23CE6B', color: 'white', opacity: this.state.openNotAvailable ? 0.3 : 1, fontWeight: 'bold', textTransform: 'none'}}>
                                Accept
                            </Button>
                        </Grid>
                    )}
                    {(!this.state.loading && this.state.status === 'available') && (
                        <Grid item xs={12} sm={12} md={12} xl={12}>
                            <Button onClick={()=> this.setState({openNotAvailable: true})} style={{width: '100%', maxWidth: 400, opacity: this.state.openNotAvailable ? 0.3 : 1, height: 50, marginTop: 10, float: 'right', borderRadius: 25, backgroundColor: '#F6B540', color: 'white', fontWeight: 'bold', textTransform: 'none'}}>
                                Available in another time
                            </Button>
                        </Grid>
                    )}
                    <Collapse in={this.state.openNotAvailable} style={{width: 'calc(100vw - 0px)'}}>
                      <div style={{ paddingLeft: 10, paddingRight: 10}}>
                        <div style={{marginTop: 12, width: '100%'}}>
                          <div style={{textAlign: 'center', width: '100%', marginBottom: 12, opacity: 0.5}}>Suggest different date & time:</div>
                          <FormControl variant="outlined" className={classes.formControl}>
                            <InputLabel id="demo-simple-select-filled-label">Suggest an alternative (1)</InputLabel>
                            <Select
                              labelId="demo-simple-select-filled-label"
                              id="demo-simple-select-filled"
                              value={this.state.alternative1}
                              label="Suggest an alternative (1)"
                              onChange={(e) => this.handleChangeHour(e,1)}
                              fullWidth
                            >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              <MenuItem value={'7:00'}>7:00</MenuItem>
                              <MenuItem value={'7:30'}>7:30</MenuItem>
                              <MenuItem value={'8:00'}>8:00</MenuItem>
                              <MenuItem value={'8:30'}>8:30</MenuItem>
                              <MenuItem value={'9:00'}>9:00</MenuItem>
                              <MenuItem value={'9:30'}>9:30</MenuItem>
                              <MenuItem value={'10:00'}>10:00</MenuItem>
                              <MenuItem value={'10:30'}>10:30</MenuItem>
                              <MenuItem value={'11:00'}>11:00</MenuItem>
                              <MenuItem value={'11:30'}>11:30</MenuItem>
                              <MenuItem value={'12:00'}>12:00</MenuItem>
                              <MenuItem value={'12:30'}>12:30</MenuItem>
                              <MenuItem value={'13:00'}>13:00</MenuItem>
                              <MenuItem value={'13:30'}>13:30</MenuItem>
                              <MenuItem value={'14:00'}>14:00</MenuItem>
                              <MenuItem value={'14:30'}>14:30</MenuItem>
                              <MenuItem value={'15:00'}>15:00</MenuItem>
                              <MenuItem value={'15:30'}>15:30</MenuItem>
                              <MenuItem value={'16:00'}>16:00</MenuItem>
                              <MenuItem value={'16:30'}>16:30</MenuItem>
                              <MenuItem value={'17:00'}>17:00</MenuItem>
                              <MenuItem value={'17:30'}>17:30</MenuItem>
                              <MenuItem value={'18:00'}>18:00</MenuItem>
                              <MenuItem value={'18:30'}>18:30</MenuItem>
                              <MenuItem value={'19:00'}>19:00</MenuItem>
                              <MenuItem value={'19:30'}>19:30</MenuItem>
                              <MenuItem value={'20:00'}>20:00</MenuItem>
                            </Select>
                          </FormControl>
                          <FormControl variant="outlined" className={classes.formControl}>
                            <InputLabel id="demo-simple-select-filled-label">Suggest an alternative (2)</InputLabel>
                            <Select
                              labelId="demo-simple-select-filled-label"
                              id="demo-simple-select-filled"
                              value={this.state.alternative2}
                              label="Suggest an alternative (2)"
                              onChange={(e) => this.handleChangeHour(e,2)}
                              fullWidth
                            >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              <MenuItem value={'7:00'}>7:00</MenuItem>
                              <MenuItem value={'7:30'}>7:30</MenuItem>
                              <MenuItem value={'8:00'}>8:00</MenuItem>
                              <MenuItem value={'8:30'}>8:30</MenuItem>
                              <MenuItem value={'9:00'}>9:00</MenuItem>
                              <MenuItem value={'9:30'}>9:30</MenuItem>
                              <MenuItem value={'10:00'}>10:00</MenuItem>
                              <MenuItem value={'10:30'}>10:30</MenuItem>
                              <MenuItem value={'11:00'}>11:00</MenuItem>
                              <MenuItem value={'11:30'}>11:30</MenuItem>
                              <MenuItem value={'12:00'}>12:00</MenuItem>
                              <MenuItem value={'12:30'}>12:30</MenuItem>
                              <MenuItem value={'13:00'}>13:00</MenuItem>
                              <MenuItem value={'13:30'}>13:30</MenuItem>
                              <MenuItem value={'14:00'}>14:00</MenuItem>
                              <MenuItem value={'14:30'}>14:30</MenuItem>
                              <MenuItem value={'15:00'}>15:00</MenuItem>
                              <MenuItem value={'15:30'}>15:30</MenuItem>
                              <MenuItem value={'16:00'}>16:00</MenuItem>
                              <MenuItem value={'16:30'}>16:30</MenuItem>
                              <MenuItem value={'17:00'}>17:00</MenuItem>
                              <MenuItem value={'17:30'}>17:30</MenuItem>
                              <MenuItem value={'18:00'}>18:00</MenuItem>
                              <MenuItem value={'18:30'}>18:30</MenuItem>
                              <MenuItem value={'19:00'}>19:00</MenuItem>
                              <MenuItem value={'19:30'}>19:30</MenuItem>
                              <MenuItem value={'20:00'}>20:00</MenuItem>
                            </Select>
                          </FormControl>
                        </div>
                      </div>
                      <Button onClick={()=> this.notAvailable()} style={{width: '100%', maxWidth: 400, height: 50, marginTop: 10, float: 'right', borderRadius: 25, backgroundColor: '#23CE6B', color: 'white', fontWeight: 'bold', textTransform: 'none'}}>
                                Send Suggestions
                        </Button>
                        <Button onClick={()=> this.setState({openNotAvailable: false})} style={{width: '50%', height: 50, marginTop: 10, marginBottom: 24, borderRadius: 25, color: '#4A4A4A', fontWeight: 'bold', textTransform: 'none'}}>
                            Close
                        </Button>
                    </Collapse>
                    {(!this.state.loading && this.state.status === 'available') && (
                        <Grid item xs={12} sm={12} md={12} xl={12}>
                            <Button onClick={()=> this.reject()} style={{width: '100%', maxWidth: 400, opacity: this.state.openNotAvailable ? 0.3 : 1, height: 50, marginTop: 10, float: 'left', borderRadius: 25, backgroundColor: '#FF3366', color: 'white', fontWeight: 'bold', textTransform: 'none'}}>
                                Reject
                            </Button>
                        </Grid>
                    )}
                    {(this.state.loading && this.state.status !== '') && (
                        <Grid item xs={12} sm={12} md={12} xl={12}>
                            <CircularProgress style={{width: 40, height: 40, marginTop: 8, color: '#4A4A4A'}} />
                        </Grid>
                    )}
                    {/* {this.state.status === 'won' && (
                        <Grid item xs={12} sm={12} md={12} xl={12}>
                            <Button style={{width: '100%', height: 50, marginTop: 30, float: 'right', borderRadius: 25, backgroundColor: '#23CE6B', color: 'white', fontWeight: 'bold', textTransform: 'none'}}>
                                Won
                            </Button>
                        </Grid>
                    )} */}
                    {this.state.status === 'won' && (
                        <Grid item xs={12} sm={12} md={12} xl={12}>
                            <Button onClick={()=> window.open(this.state.relationship.Photographer_Google_Calendar_Short_Link__c, '_blank')} style={{width: '100%', height: 50, marginTop: 12, float: 'right', borderRadius: 25, backgroundColor: 'white', border: '1px solid #3B83F5', color: '#3B83F5', fontWeight: 'bold', textTransform: 'none'}}>
                                <img alt='' src={require('../../../Assets/calendarIcon.png')} style={{width: 35, height: 35, marginRight: 12, display: 'inline-block', verticalAlign: 'middle' }}/><div style={{display: 'inline-block', verticalAlign: 'middle' }}>Add to Calendar</div>
                            </Button>
                        </Grid>
                    )}
                    {this.state.status === 'won' && (
                        <Grid item xs={12} sm={12} md={12} xl={12}>
                            <Button onClick={()=> window.open('https://waze.com/ul?q=' + this.state.relationship.Request_Shooting_Street__c + ', ' + this.state.relationship.Request_Shooting_City__c, '_blank')} style={{width: '100%', height: 50, marginTop: 12, float: 'right', borderRadius: 25, backgroundColor: 'white', border: '1px solid #3B83F5', color: '#3B83F5', fontWeight: 'bold', textTransform: 'none'}}>
                                <img alt='' src={require('../../../Assets/wazeIcon.png')} style={{width: 35, height: 35, marginRight: 12, display: 'inline-block', verticalAlign: 'middle' }}/><div style={{display: 'inline-block', verticalAlign: 'middle' }}>Navigate with Waze</div>
                            </Button>
                        </Grid>
                    )}
                    {/* {(this.state.status === 'rejected') && (
                        <Grid item xs={12} sm={12} md={12} xl={12}>
                            <Button style={{width: '100%', height: 50, marginTop: 10, float: 'left', borderRadius: 25, backgroundColor: '#E5E5E5', color: '#4A4A4A', fontWeight: 'bold', textTransform: 'none'}}>
                                Rejected
                            </Button>
                        </Grid>
                    )} */}
                    {/* {(this.state.status === 'closed') && (
                        <Grid item xs={12} sm={12} md={12} xl={12}>
                            <Button style={{width: '100%', height: 50, marginTop: 10, float: 'left', borderRadius: 25, backgroundColor: '#E5E5E5', color: '#4A4A4A', fontWeight: 'bold', textTransform: 'none'}}>
                                Close
                            </Button>
                        </Grid>
                    )} */}
                {/* </Grid> */}
            {/* </div> */}
        </Grid>
        ) : (
          <div style={{width: '100%', padding: 10}}>
            <Skeleton variant="text" style={{marginTop: 12, width: '50%'}} />
            <Skeleton variant="text" style={{marginTop: 12, width: '90%'}} />
            <Skeleton variant="text" style={{marginTop: 12, width: '30%'}} />
            <Skeleton variant="circle" width={50} height={50} style={{marginTop: 24}} />
            <Skeleton variant="circle" width={50} height={50} style={{marginTop: 24}} />
            <Skeleton variant="circle" width={50} height={50} style={{marginTop: 24}} />
            <Skeleton variant="circle" width={50} height={50} style={{marginTop: 24}} />
            <Skeleton variant="rect" style={{marginTop: 24, height: '50px', width: '100%'}} />
            <Skeleton variant="rect" style={{marginTop: 12, height: '50px', width: '100%'}} />
            <Skeleton variant="rect" style={{marginTop: 12, height: '50px', width: '100%'}} />
          </div>
        )}
      </div>
    );
  }
}

Template.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(Template);
