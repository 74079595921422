import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import NewOrder from '@material-ui/icons/FiberNew';
import SettingsIcon from '@material-ui/icons/Settings';
import LogoutIcon from '@material-ui/icons/ExitToApp';
import BillingIcon from '@material-ui/icons/Receipt';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
});

export default function SwipeableTemporaryDrawer(props) {
  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (side, open) => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [side]: open });
  };

  const logout = () => {
    localStorage.removeItem('wesnapp-token');
    // this.props.history.push("/login")
    window.location.reload();
  }

  const sideList = side => (
    <div
      className={classes.list}
      role="presentation"
      onClick={()=>props.main.setState({sideMenuOpen: false})}
      onKeyDown={()=>props.main.setState({sideMenuOpen: false})}
    >
      <List>
        <Link to="/p/dashboard/photo-shoots" style={{color:'black', textDecoration: 'none'}}>
          <ListItem button key={"Photo Shoots"}>
            <ListItemIcon><PhotoCamera /></ListItemIcon>
            <ListItemText primary={"Photo Shoots"} />
          </ListItem>
        </Link>
        {/* <Link to="/p/dashboard/billing" style={{color:'black', textDecoration: 'none'}}> */}
          <ListItem button key={"New Order"} onClick={()=> alert('דף זה זמין כרגע רק דרך המחשב')}>
            <ListItemIcon><BillingIcon /></ListItemIcon>
            <ListItemText primary={"Billing"} />
          </ListItem>
        {/* </Link> */}
        <Link to="/p/dashboard/settings" style={{color:'black', textDecoration: 'none'}}>
          <ListItem button key={"Settings"}>
            <ListItemIcon><SettingsIcon /></ListItemIcon>
            <ListItemText primary={"Settings"} />
          </ListItem>
        </Link>
        <ListItem button key={"New Order"} onClick={ () => logout()}>
          <ListItemIcon><LogoutIcon /></ListItemIcon>
          <ListItemText primary={"Logout"} />
        </ListItem>
      </List>
    </div>
  );

  return (
    <div>
      <SwipeableDrawer
        open={props.main.state.sideMenuOpen}
        onClose={()=>props.main.setState({sideMenuOpen: false})}
        onOpen={()=>props.main.setState({sideMenuOpen: true})}
      >
        {sideList('left')}
      </SwipeableDrawer>
    </div>
  );
}
