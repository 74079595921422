import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles({
  table: {
    width: '100%',
  },
});

export default function SimpleTable(props) {
  const classes = useStyles();

  return (
    <div>
        <TableContainer component={Paper} elevation={0}>
        <Table className={classes.table} aria-label="simple table">
            <TableHead>
            <TableRow>
                <TableCell>Photo Shoot</TableCell>
                <TableCell align="left">Location</TableCell>
                <TableCell align="left">Product</TableCell>
                <TableCell align="left">Date & Time</TableCell>
                <TableCell align="left">Price</TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
            {props.tableData.map(row => (
                <TableRow key={row.name}>
                <TableCell component="th" id={'labelId'} scope="row" padding="35">
                    {row.name}
                    </TableCell>
                    <TableCell align="left">{row.location}</TableCell>
                    <TableCell align="left">{row.product}</TableCell>
                    <TableCell align="left">{row.datetime}</TableCell>
                    <TableCell align="left">{row.price}</TableCell>
                </TableRow>
            ))}
            </TableBody>
        </Table>
        </TableContainer>
    </div>
  );
}