import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles((theme) => ({
    deleteButton: {
        height: 42,
        width: 'auto',
        boxShadow: '0 0 20px #eee', 
        fontSize: 14,
        color: 'white',
        padding: '8px 16px',
        background: 'rgb(255,128,8)',
        background: theme.palette.primary.main,
        boxShadow: `0px 8px 20px ${theme.palette.primary.main}60`,
        borderRadius: 12,
        textTransform: 'none',
        margin: 12,
        '&:hover': {
            background: theme.palette.primary.main,
        },
        transition: theme.transitions.create(['background'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        })
    },
    cancelButton: {
        height: 42,
        width: 'auto',
        fontSize: 14,
        color: '#4A4A4A',
        padding: '8px 16px',
        background: 'rgb(255,128,8)',
        background: '#FFFFFF',
        boxShadow: '0px 8px 20px #4A4A4A30',
        borderRadius: 12,
        textTransform: 'none',
        margin: 12,
        '&:hover': {
            background: '#FFFFFF',
        },
        transition: theme.transitions.create(['background'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        })
    },
    paper: {
        borderRadius: 18,
        padding: 12
    },
    dialogActions: {
        display: 'flex',
        justifyContent: 'center'
    }
}));

export default function AlertDialog({open, setOpen, handleDelete}) {

  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
  };

  const callHandleDelete = () => {
    handleDelete()
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth maxWidth={'xs'}
        classes={{paper: classes.paper}}
      >
        <DialogTitle id="alert-dialog-title">{"Are you want to start AT for this station?"}</DialogTitle>
        <DialogContent>
          {/* <DialogContentText id="alert-dialog-description">
            When you delete, you will not be able to recover the information back.
          </DialogContentText> */}
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button onClick={handleClose} color="primary" className={classes.cancelButton}>
            Cancel
          </Button>
          <Button onClick={callHandleDelete} color="primary" className={classes.deleteButton}>
            Start AT
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
