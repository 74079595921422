// import React from 'react';
// import { withStyles } from '@material-ui/core/styles';
// import PropTypes from 'prop-types';
// import CircularProgress from '@material-ui/core/CircularProgress';
// // import Shooting from '../Pages/PhotoShoot/Shooting';
// import Shooting from '../Pages/PhotoShootPage/PhotoShoot';
// // import Order from '../Pages/Order/Order';
// import PhotoShoots from '../Pages/PhotoShoots/PhotoShoots';
// import Settings from '../Pages/Settings/Settings';
// import { SnackbarProvider, useSnackbar } from 'notistack';
// // import { createBrowserHistory } from "history";
// import { withRouter } from "react-router-dom";
// import axios from 'axios';
// import {
//   BrowserRouter as Router,
//   Switch,
//   Route,
//   Link
// } from "react-router-dom";
// import NewTermsDialog from './NewTermsDialog'
// import EditSettingDialog from '../../Photographer/Pages/Settings/EditSettingDialog'
// import { FormatListBulletedRounded } from '@material-ui/icons';

// var mobile = (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1)

// const styles = theme => ({
//   list: {
//     width: 250,
//   },
//   fullList: {
//     width: 'auto',
//   },
//   root: {
//     // width: '100vw',
//     // height: '100vh',
//     // overflow: 'hidden',
//   },
//   success: { backgroundColor: '#33D7A0', color: 'white', textWeight: 'bold' },
// });

// // const history = createBrowserHistory();

// class Template extends React.Component {

//   state = {
//     deviceType: 'desktop',
//     page: '',
//     isLoading: true,
//     editSettingsPopup: false,
//     skipPersonalDetails: false
//   }

//   closeEditSettingsPopup = () => {
//     this.setState({editSettingsPopup: false})
//   }

//   openEditSettingsPopup = () => {
//     this.setState({editSettingsPopup: true})
//   }

//   componentDidMount() {
//     var page = this.getParameterByName('page')
//     this.setState({page: page})
//     this.checkData()
//   }

//   checkData = () => {
//     var showSettingPage = false
//     if (this.props.main.state.userData === null) return 
//     console.log('this.props.main.state.userData', this.props.main.state.userData)
//     var lastCinfirmed = this.props.main.state.userData.Last_Confirmed_Settings__c
//     if (lastCinfirmed === null) {
//       showSettingPage = true
//     } else {
//       var change = Math.abs(new Date().getTime() - new Date(lastCinfirmed).getTime())
//       // 2 months
//       if (change > 5184000000) {
//         showSettingPage = true
//       }
//     }
//     if (showSettingPage) {
//       this.setState({editSettingsPopup: true, isLoading: false, skipPersonalDetails: this.props.main.state.userData.Activate_Perfect_Matching__c === true ? false : true})
//     } else {
//       this.setState({isLoading: false})
//     }
//   }

//   getParameterByName = (name, url) => {
//     if (!url) url = window.location.href;
//     name = name.replace(/[\[\]]/g, '\\$&');
//     var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
//         results = regex.exec(url);
//     if (!results) return null;
//     if (!results[2]) return '';
//     return decodeURIComponent(results[2].replace(/\+/g, ' '));
//   }

//   getPageStyle = () => {
//     if (!mobile) {
//       return {width: 'calc(100vw - 120px)',backgroundColor: 'white', display: 'inline-block', borderRadius: '0px 0px 0px 20px', verticalAlign:'top', overflowY: 'scroll', overflowX: 'hidden', height: '100%', left: 120, position: 'fixed', top: 94}
//       // return {width: 'calc(100vw - 0px)', minHeight: '100vh', backgroundColor: 'white', display: 'inline-block', borderRadius: '35px 0px 0px 35px', verticalAlign:'top', overflowY: 'scroll', height: '100vh', left: '0px', position: 'sticky', position: '-webkit-sticky', position: 'absolute', top: 0}
//     } else {
//       return {marginTop: 0, width: '100vw', height: '100vh', paddingBottom: 50, backgroundColor: 'white', display: 'inline-block', verticalAlign:'top', overflowY: 'scroll', overflowX: 'hidden', paddingLeft: 0, paddingRight: 0, position: 'fixed', left: 0, top: 55, backgroundColor: "linear-gradient(to right , #6334FF, #6334FF)"}
//     }
//   }

//   render() {
//     const { classes, theme } = this.props;
//     return (
//       <div style={this.getPageStyle()}>
//         {/* <NewTermsDialog/> */}
//         {console.log('this.props.main.state.finishedToLoadUserData', this.props.main.state.finishedToLoadUserData)}
//         {this.props.main.state.finishedToLoadUserData &&
//           <EditSettingDialog
//             userData={this.props.main.state.userData}
//             cameras={this.props.main.state.cameras}
//             lenses={this.props.main.state.lenses}
//             trippleD={this.props.main.state.trippleD}
//             drones={this.props.main.state.drones}
//             editSettingsPopup={this.state.editSettingsPopup}
//             closeEditSettingsPopup={this.closeEditSettingsPopup}
//             getUserDetails={this.props.main.getUserDetails}
//             skipPersonalDetails={this.state.skipPersonalDetails}
//           />
//         }
//         {this.state.isLoading === true ? (
//           <div className="loading">
//             {/* <CircularProgress style={{marginTop: 'calc(50vh - 50px)'}}/><div><div style={{fontFamily: "'Montserrat', sans-serif", fontSize: mobile ? 25 : 20, padding: mobile ? 20 : 0, color: 'black', marginTop: 20, fontWeight: 'bold'}}></div></div> */}
//           </div>
//         ) : (
//           <SnackbarProvider maxSnack={10} classes={{
//             variantSuccess: classes.success,
//             variantError: classes.error,
//             variantWarning: classes.warning,
//             variantInfo: classes.info
//           }}>
//             {this.state.editSettingsPopup === false && (
//               <Switch>
//                 <Route path="/p/dashboard/photo-shoots">
//                   <PhotoShoots main={this} history={this.props.history}/>
//                 </Route>
//                 <Route path="/p/dashboard/shoot/:id">
//                   <Shooting main={this} history={this.props.history}/>
//                 </Route>
//                 <Route path="/p/dashboard/billing">
//                   <Billing main={this} history={this.props.history}/>
//                 </Route>
//                 <Route path="/p/dashboard/settings">
//                   <Settings getUserDetails={this.props.main.getUserDetails} main={this} history={this.props.history}/>
//                 </Route>
//                 {/* <Route path="/p/dashboard/shoot">
//                   <Shooting main={this} history={this.props.history}/>
//                 </Route>
//                 <Route path="/p/dashboard/order">
//                   <Order main={this} history={this.props.history}/>
//                 </Route> */}
//               </Switch>
//             )}
//           </SnackbarProvider>
//         )}
//       </div>
//     );
//   }
// }

// Template.propTypes = {
//   classes: PropTypes.object.isRequired,
//   theme: PropTypes.object.isRequired,
// };

// export default withStyles(styles, { withTheme: true })(Template);

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
// import Shooting from '../Pages/PhotoShoot/Shooting';
// import Order from '../Pages/Order/Order';
// import Settings from '../Pages/Settings/Settings';
import PhotoShoots from '../Pages/PhotoShoots/PhotoShoots';
import AutoEditProjects from '../Pages/AutoEditProjects/AutoEditProjects';
import PhotoShoot from '../Pages/PhotoShootPage/PhotoShoot';
import AutoEditPage from '../Pages/AutoEditPage/PhotoShoot';
// import MyCompany from '../Pages/Company/Company';
import Settings from '../Pages/Settings/Settings';
import Billing from '../Pages/Billing/Billing';
import { SnackbarProvider, useSnackbar } from 'notistack';
import { withRouter } from "react-router-dom";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import Fade from '@material-ui/core/Fade';
import history from '../../../utils/history';
import OrderMobile from '../../Landings/OrderPage/Order'

var mobile = (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1)

const styles = theme => ({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  root: {
    // width: '100vw',
    // height: '100vh',
    // backgroundColor: 'red'
  },
  success: { backgroundColor: '#33D7A0', color: 'white', textWeight: 'bold' },
});

class Template extends React.Component {

  state = {
    deviceType: 'desktop',
    page: '',
    isLoading: false
  }

  componentDidMount() {
    var page = this.getParameterByName('page')
    this.setState({page: page})
  }

  getParameterByName = (name, url) => {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  // getPageStyle = () => {
  //   if (!mobile) {
  //     return {width: 'calc(100vw - 120px)',backgroundColor: '#F9F8FD', display: 'inline-block', borderRadius: '0px 0px 0px 20px', verticalAlign:'top', overflowY: 'scroll', overflowX: 'hidden', height: 'calc(100vh - 94px)', left: 120, position: 'fixed', top: 94}
  //   } else {
  //     return {marginTop: 55, width: '100vw', height: '100vh', paddingBottom: 50, backgroundColor: 'white', display: 'inline-block', verticalAlign:'top', overflowY: 'scroll', paddingLeft: 0, paddingRight: 0, backgroundColor: 'rgb(94,44,237)'}
  //   }
  // }

  render() {
    const { classes, theme } = this.props;
    return (
      <div style={{paddingTop: 60}}>
        {this.state.isLoading === true ? (
          <div className="loading"><CircularProgress style={{marginTop: 'calc(50vh - 50px)'}}/><div><div style={{fontFamily: "'Montserrat', sans-serif", fontSize: mobile ? 25 : 20, padding: mobile ? 20 : 0, color: 'black', marginTop: 20, fontWeight: 'bold'}}></div></div></div>
        ) : (
            <Switch>
              {/* <Route path="/p/dashboard/order">
                <OrderMobile landingPage={false} />
              </Route> */}
              <Route path="/p/dashboard/photo-shoots">
                <PhotoShoots updateShootPage={this.props.updateShootPage} main={this} history={history}/>
              </Route>
              {(this.props.accountData && this.props.accountData.Allow_Auto_Edit__c) && (
                <Route path="/p/dashboard/auto-edit/:id" component={AutoEditPage}/>
              )}
              {(this.props.accountData && this.props.accountData.Allow_Auto_Edit__c) && (
                <Route path="/p/dashboard/auto-edit" exact>
                  <AutoEditProjects updateShootPage={this.props.updateShootPage} main={this} history={history}/>
                </Route>
              )}
              {/* <Route path="/p/dashboard/shoot">
                <Shooting updateShootPage={this.props.updateShootPage} main={this} history={history}/>
              </Route> */}
              <Route path="/p/dashboard/billing">
                <Billing isBusinessAccount={this.props.isBusinessAccount} businessAccountId={this.props.businessAccountId} main={this} history={history}/>
              </Route>
              <Route path="/p/dashboard/account">
                <Settings accountData={this.props.accountData} getUserDetails={this.props.getUserDetails} main={this} history={this.props.history}/>
              </Route>
              <Route path="/p/dashboard/photo-shoot/:id" component={PhotoShoot}/>
              {/* <Route path="/p/dashboard/settings">
                <Settings main={this} history={history}/>
              </Route>
              <Route path="/p/dashboard/account">
                <MyAccount isBusinessAccount={this.props.isBusinessAccount} businessAccountId={this.props.businessAccountId} main={this} history={history}/>
              </Route>

              <Route path="/p/dashboard/company">
                <MyCompany main={this} history={history}/>
              </Route> */}
            </Switch>
        )}
      </div>
    );
  }
}

Template.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(Template);
