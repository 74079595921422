import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import { withRouter } from "react-router-dom";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

const styles = theme => ({
  root: {
      '& .MuiOutlinedInput-notchedOutline': {
        borderRadius: 28,
        borderColor: '#DAE2FD',
      },
      '& .MuiOutlinedInput-input': {
        backgroundColor: '#ecf1fe',
        borderRadius: 28
      },
      // "&:hover .MuiOutlinedInput-notchedOutline": {
      //   borderColor: '#6C65FF',
      // },
    },
  list: {
    width: 250
  },
  fullList: {
    width: 'auto',
  },
  button: {
    color: 'white',
    height: 50,
    backgroundColor: theme.palette.primary.main,
    textTransform: 'none',
    fontWeight: 'bold',
    paddingLeft: 32,
    paddingRight: 32,
    marginTop: 30,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
    boxShadow: `0 4px 10px ${theme.palette.primary.main}40`,
    borderRadius: 25
  },
});

function createData(id, name, mobile, startAt, watchShootings, useOrders, canSelect, accounter) {
  return { id, name, mobile, startAt, watchShootings, useOrders, canSelect, accounter };
}

class PhotoShoots extends React.Component {

  state = {
    isLoading: true,
    firstName: '',
    lastName: '',
    email: '',
    mobile: '',
    saveButtonText: 'Save',
    updating: false,
    isBusiness: false,
    bName: '',
    errorBName: false,
    currentTab: 0
  }

  componentDidMount() {
    this.getUserDetails()
    this.getBusinessAccount()
  }

  getBusinessAccount = () => {
    var contactId = localStorage.getItem("contactId")
    var data = {contactId: contactId}
    axios.post('https://wesnapp.co.il' + '/api/account/get-business-account',data, {headers: {'Content-Type': 'application/json'}}).then((response) => {
        // console.log(response.data)    
        if (response.data.length > 0) {
            this.setState({isBusiness: true})
            this.setState({businessAccountId: response.data[0].AccountId})
            this.getBusinessAccountData(response.data[0].AccountId)
        }
    })
    .catch( (error) => {
      //console.log(error);
    });
  }

  getBusinessAccountData = (accountId) => {
    var data = {id: accountId}
    axios.post('https://wesnapp.co.il' + '/api/account/get-one',data, {headers: {'Content-Type': 'application/json'}}).then((response) => {
        console.log(response.data)
        var businessData = response.data[0]
        this.setState({businessData: businessData, bName: response.data[0].Name})    
    })
    .catch( (error) => {
      //console.log(error);
    });
  }

  getUserDetails = () => {
    var contactId = localStorage.getItem("contactId")
    var data = {id: contactId}
    axios.post('https://wesnapp.co.il' + '/api/contact/get-one',data, {headers: {'Content-Type': 'application/json'}}).then((response) => {
        console.log(response.data[0]) 
        var contactData = response.data[0]
        this.setState({
            firstName: contactData.FirstName,
            lastName: contactData.LastName,
            email: contactData.Email,
            mobile: contactData.MobilePhone,
            isLoading: false
        })   
    })
    .catch( (error) => {
      //console.log(error);
    });
  }

  validateEmail = (email) => {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  updateDetails = () => {
    var allowToContinue = true
    if (this.state.firstName.length < 2) {
        this.setState({errorFirstName: true})
        allowToContinue = false
    }
    if (this.state.lastName.length < 2) {
        this.setState({errorLastName: true})
        allowToContinue = false
    }
    if (this.state.bName.length < 2) {
        this.setState({errorBName: true})
        allowToContinue = false
    }
    if (!this.validateEmail(this.state.email)) {
        this.setState({errorEmail: true})
        allowToContinue = false
    }
    if (allowToContinue) {
        this.setState({updating: true})
        var accountId = localStorage.getItem("accountId")
        var data = {id: accountId, data: {
            FirstName: this.state.firstName,
            LastName: this.state.lastName,
            PersonEmail: this.state.email
        }}
        axios.post('https://wesnapp.co.il' + '/api/contact/update-contact',data, {headers: {'Content-Type': 'application/json'}}).then((response) => {
            console.log(response.data) 
            this.setState({saveButtonText: 'Updated!', updating: false})
        })
        .catch( (error) => {
        });

        // Set last update date and time
        axios.post('https://us-central1-wesnapp-d136a.cloudfunctions.net/updateContact',{id: localStorage.getItem("accountId"), data: {Last_Confirmed_Settings__c: new Date().toISOString()}}, {headers: {'Content-Type': 'application/json'}}).then((response) => {
        })
        .catch( (error) => {
        });

        var accountId = this.state.businessAccountId
        var data = {id: accountId, data: {
            Name: this.state.bName
        }}
        axios.post('https://wesnapp.co.il' + '/api/contact/update-contact',data, {headers: {'Content-Type': 'application/json'}}).then((response) => {
            console.log(response.data) 
            this.setState({saveButtonText: 'Updated!', updating: false})
        })
        .catch( (error) => {
        //console.log(error);
        });
    }
  }

  handleChangeText = name => event => {
    if (name === 'phone') {
      this.setState({ [name]: event.target.value.replace('-', '') });
    } else {
      this.setState({ [name]: event.target.value });
    }
    this.setState({saveButtonText: 'Save'})
  };


  render() {
    const { classes, theme } = this.props;

    return (
          <div style={{backgroundColor: 'white', borderRadius: '23px', boxShadow: '0px 4px 10px #6334FF10', marginTop: 0, width: '100%', padding: 24, marginBottom: 24}}>
            <Grid container spacing={2} style={{padding: 0, paddingTop: 0, width: '100%'}} justifyContent="center" justify="center">
            <Grid item xs={12} sm={10} md={8} xl={7}>
                    <div style={{fontSize: 20, color: 'rgb(40,40,53)', textAlign: 'left', marginLeft: 0, fontFamily: "'Montserrat', sans-serif"}}>
                        <b>General Settings</b>
                    </div>
                </Grid>   
                  <Grid item xs={12} sm={6} md={8} xl={7}>
                  {
                      (!this.state.isLoading) && (
                        <div>
                          <div style={{fontSize: 16, textAlign: 'left'}}>
                            You can edit and change your account details here:
                          </div>
                          <div style={{marginTop: 20, display: 'inline-block', width: '100%'}}>
                            <TextField id="outlined-basic" label="First Name" fullWidth  onChange={this.handleChangeText('firstName')} value={this.state.firstName} />
                          </div>
                          <div style={{marginTop: 20, display: 'inline-block', width: '100%'}}>
                            <TextField id="outlined-basic" label="Last Name" fullWidth  onChange={this.handleChangeText('lastName')} value={this.state.lastName} />
                          </div>
                          <div style={{marginTop: 20, display: 'inline-block', width: '100%'}}>
                            <TextField id="outlined-basic" label="Email" fullWidth  onChange={this.handleChangeText('email')} value={this.state.email} />
                          </div>
                          <div style={{marginTop: 20, display: 'inline-block', width: '100%'}}>
                            <TextField disabled id="outlined-basic" label="Mobile" fullWidth  onChange={this.handleChangeText('mobile')} value={this.state.mobile} />
                          </div>
                          <center>
                            {this.state.updating === false ? (
                                <Button
                                  variant="contained"
                                  color="primary"
                                  className={classes.button}
                                  onClick= {()=> this.updateDetails()}
                                >
                                  {this.state.saveButtonText}
                                </Button>
                            ) : (
                              <CircularProgress size={35} style={{marginTop: 10}}/>
                            )}
                          </center>
                        </div>
                      )
                    }
                  </Grid>
            </Grid>
        </div>
    );
  }
}

PhotoShoots.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(withRouter(PhotoShoots));
