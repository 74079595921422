import React from 'react';

const Room = ({color, size}) => (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
    width={size} height={size} viewBox="0 0 100.000000 100.000000"
    >
        <g transform="translate(0.000000,100.000000) scale(0.100000,-0.100000)"
        stroke="none" fill={color}>
            <path d="M314 817 c-289 -213 -303 -224 -297 -247 3 -11 6 -20 8 -20 1 0 17
            14 36 30 81 73 425 320 438 315 8 -3 79 -52 159 -108 307 -218 312 -221 325
            -208 14 14 27 4 -227 184 -100 70 -200 141 -222 157 l-41 29 -179 -132z"/>
            <path d="M250 640 c0 -5 -5 -10 -11 -10 -14 0 -59 -47 -59 -61 0 -20 61 -69
            86 -69 28 0 74 43 74 69 0 23 -28 61 -46 61 -8 0 -14 5 -14 10 0 6 -7 10 -15
            10 -8 0 -15 -4 -15 -10z"/>
            <path d="M80 324 c0 -230 1 -237 22 -255 20 -18 44 -19 403 -19 283 0 384 3
            393 12 18 18 17 498 0 498 -38 -1 -39 -11 -36 -243 l3 -227 -45 0 -45 0 4 52
            c2 29 6 55 10 58 28 22 42 50 33 67 -6 10 -16 33 -23 52 -12 32 -13 32 -21 12
            -4 -13 -5 -34 -2 -47 5 -20 2 -26 -15 -31 -19 -5 -21 -12 -21 -84 0 -75 -1
            -79 -22 -79 -22 0 -23 4 -23 77 0 74 -1 78 -24 81 -20 3 -23 8 -18 35 2 18 1
            41 -3 52 -7 19 -7 19 -17 0 -30 -58 -21 -141 13 -128 11 4 14 -8 14 -56 l0
            -61 -64 0 -64 0 6 67 c4 56 2 68 -13 79 -14 10 -22 11 -32 3 -9 -8 -17 -8 -26
            0 -22 17 -27 3 -27 -76 l0 -73 -60 0 c-33 0 -60 4 -60 10 0 6 5 10 10 10 17 0
            13 179 -5 196 -27 28 -35 5 -35 -107 l0 -109 -25 0 c-14 0 -25 5 -25 10 0 6 3
            10 8 10 13 0 8 184 -5 198 -25 26 -33 0 -33 -109 l0 -109 -40 0 -40 0 0 235 0
            235 -25 0 -25 0 0 -236z"/>
            <path d="M641 482 c-7 -6 -28 -50 -47 -97 -20 -47 -39 -85 -44 -85 -4 0 -13 7
            -20 15 -18 21 -86 20 -99 -2 -14 -26 -26 -8 -46 72 -26 100 -32 105 -126 105
            -67 0 -80 -3 -89 -19 -14 -26 -13 -198 1 -207 21 -13 30 15 31 100 l1 87 66
            -3 66 -3 16 -72 c15 -62 51 -131 70 -132 3 -1 12 10 19 24 18 34 62 34 85 -1
            l17 -26 23 22 c12 12 37 56 55 98 17 42 35 81 39 85 10 10 74 15 95 7 10 -3
            27 -35 41 -74 27 -79 41 -98 51 -72 7 18 -40 162 -59 180 -17 15 -132 14 -146
            -2z"/>
        </g>
    </svg>
)

export default Room;