import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
// import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PhoneIcon from '@material-ui/icons/Phone';

import BillingIcon from '@material-ui/icons/CreditCard';
import MembersIcon from '@material-ui/icons/AccountTree';
import Grid from '@material-ui/core/Grid';
import { SnackbarProvider, useSnackbar } from 'notistack';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios';
import CameraAltRoundedIcon from '@material-ui/icons/CameraAltRounded';
import { withRouter } from "react-router-dom";
import { Tabs, Radio, Space } from 'antd';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';

// import Menu from './SettingMenuContent';
// import MembersPage from './MembersPage';
// import DetailsPage from './DetailsPage';
import GeneralDetails from './GeneralDetails';
import EquipmentDetails from './EquipmentDetails';
import BillingDetails from './BillingDetails';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import { Layout, Menu, Breadcrumb } from 'antd';
import { CameraOutlined, CreditCardOutlined, UserOutlined, LogoutOutlined } from '@ant-design/icons';
import { Hidden } from '@material-ui/core';

const { TabPane } = Tabs;

const { Header, Content, Footer, Sider } = Layout;

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const saleBanner = require("../../../../Assets/sale-banner.png");
var mobile = (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1)

const cameraIcon = require("../../../../Assets/cameraTableIcon.png");

const noImage = require("../../../../Assets/no_image.png");

const dateIcon = require("../../../../Assets/dateIcon.png");
const locationIcon = require("../../../../Assets/locationIcon.png");
const userIcon = require("../../../../Assets/userIcon.png");
const imageIcon = require("../../../../Assets/imageIcon.png");


const styles = theme => ({
  list: {
    width: 250
  },
  fullList: {
    width: 'auto',
  },
  root: {
    width: '100vw',
    height: '100vh',
    backgroundColor: '#6C65FF',
    backgroundColor: 'white'
  },
  button: {
    height: 50,
    marginTop: 15,
    float: 'left',
    borderRadius: 25,
    paddingLeft: 32,
    paddingRight: 32,
    boxShadow: 'none',
    hover: {
      backgroundColor: '#33D7A0'
    }
  }
});

class PhotoShoots extends React.Component {

  state = {
    isLoading: false,
    requests: [],
    currentTab: 0,
    openSuccess: false,

  }

  handleCloseSuccessMessage = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({openSuccess: false})
  };

  componentWillMount() {
    // if (this.props.main.state.auth == false) {
    //   this.props.history.push("/login")
    // }
  }

  handleChangeTab = (event, newValue) => {
    this.setState({currentTab: newValue})
  };

  componentDidMount = () => {
    var contactId = localStorage.getItem("contactId")
  }

  logout = () => {
    localStorage.removeItem('wesnapp-token');
    this.props.history.push("/login")
    window.location.reload();
  }

  render() {
    const { classes, theme } = this.props;

    return (
      <div className={styles.root} style={{marginTop: 10}}>
        <Snackbar open={this.state.openSuccess} autoHideDuration={3000} onClose={this.handleCloseSuccessMessage}>
          <Alert onClose={this.handleCloseSuccessMessage} severity="success">
            Details updated successfully!
          </Alert>
        </Snackbar>
        <div>
          <div>
          <div style={{fontSize: 24, color: 'rgb(40,40,53)', textAlign: 'left', marginLeft: 0, fontFamily: "'Montserrat', sans-serif", padding: '16px 0'}}>
            <b>Settings</b>
          </div>
          <Hidden mdUp>
            <Tabs tabPosition={'top'} tabBarStyle={{textAlign: 'left'}}>
              <TabPane
                tab={
                  <span>
                    <UserOutlined />
                    General
                  </span>
                }
                key="1"
                style={{textAlign: 'left'}}
              >
                {this.props.accountData !== null && (
                  <GeneralDetails popup={false} getUserDetails={this.props.getUserDetails} userData={this.props.accountData} main={this} />
                )}
              </TabPane>
              <TabPane
                tab={
                  <span>
                    <CreditCardOutlined />
                    Billing
                  </span>
                }
                key="2"
                style={{textAlign: 'left'}}
              >
                {this.props.accountData !== null && (
                  <BillingDetails popup={false} getUserDetails={this.props.getUserDetails} userData={this.props.accountData} main={this} />
                )}
              </TabPane>
              <TabPane
                tab={
                  <span>
                    <CameraOutlined />
                    Equipment
                  </span>
                }
                key="3"
                style={{textAlign: 'left'}}
              >
                {this.props.accountData !== null && (
                  <EquipmentDetails popup={false} getUserDetails={this.props.getUserDetails} userData={this.props.accountData} drones={this.props.main.props.main.state.drones} cameras={this.props.main.props.main.state.cameras} lenses={this.props.main.props.main.state.lenses} trippleD={this.props.main.props.main.state.trippleD}  main={this}/>
                )}
              </TabPane>
            </Tabs>
          </Hidden>
          <Hidden smDown>
            <Tabs tabPosition={'left'} tabBarStyle={{minWidth: 250, textAlign: 'left'}}>
              <TabPane
                tab={
                  <span>
                    <UserOutlined />
                    General Settings
                  </span>
                }
                key="1"
                style={{minWidth: 200, textAlign: 'left', paddingBottom: 24}}
              >
                {this.props.accountData !== null && (
                  <GeneralDetails popup={false} getUserDetails={this.props.getUserDetails} userData={this.props.accountData} main={this} />
                )}
              </TabPane>
              <TabPane
                tab={
                  <span>
                    <CreditCardOutlined />
                    Billing Details
                  </span>
                }
                key="2"
                style={{minWidth: 200, textAlign: 'left', paddingBottom: 24}}
              >
                {this.props.accountData !== null && (
                  <BillingDetails popup={false} getUserDetails={this.props.getUserDetails} userData={this.props.accountData} main={this} />
                )}
              </TabPane>
              <TabPane
                tab={
                  <span>
                    <CameraOutlined />
                    Equipment Details
                  </span>
                }
                key="3"
                style={{minWidth: 200, textAlign: 'left', paddingBottom: 24}}
              >
                {this.props.accountData !== null && (
                  <EquipmentDetails popup={false} getUserDetails={this.props.getUserDetails} userData={this.props.accountData} drones={this.props.main.props.main.state.drones} cameras={this.props.main.props.main.state.cameras} lenses={this.props.main.props.main.state.lenses} trippleD={this.props.main.props.main.state.trippleD}  main={this}/>
                )}
              </TabPane>
              <TabPane
                onClick={this.logout}
                tab={
                  <span style={{color: 'red'}} onClick={this.logout}>
                    <LogoutOutlined />
                    Logout
                  </span>
                }
                key="5"
                style={{minWidth: 200, textAlign: 'left', paddingBottom: 24}}
              >
                {this.props.accountData !== null && (
                  <EquipmentDetails popup={false} getUserDetails={this.props.getUserDetails} userData={this.props.accountData} drones={this.props.main.props.main.state.drones} cameras={this.props.main.props.main.state.cameras} lenses={this.props.main.props.main.state.lenses} trippleD={this.props.main.props.main.state.trippleD}  main={this}/>
                )}
              </TabPane>
            </Tabs>
          </Hidden>
          </div>
          <Hidden mdUp>
            <div style={{color: 'red', width: '100%', textAlign: 'left', cursor: 'pointer'}} onClick={this.logout}>Logout</div>
          </Hidden>
        </div>
      </div>
    );
  }
}

PhotoShoots.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(withRouter(PhotoShoots));
