import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  }
});

const useStyles = makeStyles((theme) => ({
  roundedCorners: {
    borderRadius: 24
  },
  confirmSelection: {
    paddingLeft: 18,
    paddingRight: 18,
    height: 42,
    borderRadius: 21,
    backgroundColor: theme.palette.primary.success,
    color: 'white',
    textTransform: 'none'
  },
  dialogContent: {
    borderTop: '1px solid rgba(0,0,0,0.2)'
  }
}))

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
}))(MuiDialogActions);

export default function CustomizedDialogs(props) {

  const classes = useStyles()

  return (
    <div>
      <Dialog onClose={()=> props.setDialogStatus(false)} aria-labelledby="customized-dialog-title" open={props.open} classes={{root: classes.roundedCorners, container: classes.roundedCorners, paper: classes.roundedCorners }}>
        <DialogTitle id="customized-dialog-title" onClose={()=> props.setDialogStatus(false)}>
          Confirm Dialog
        </DialogTitle>
        <DialogContent classes={{root: classes.dialogContent}}>
          <div style={{paddingTop: 24, paddingBottom: 24, paddingLeft: 12, paddingRight: 12, textAlign: 'center'}}>
          <Typography gutterBottom>
            <b>Note</b> - Once you select photos, you won't be able to change your selection.
          </Typography>
          </div>
        </DialogContent>
        <DialogActions>
          {props.addingImagesToSalesforce ? (
            <CircularProgress size={42} />
          ) : (
            <Button autoFocus onClick={()=> props.addImagesToSelection()} color="primary" className={classes.confirmSelection}>
              Confirm Selection
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
