import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import Shooting from '../Pages/PhotoShoot/Shooting';
import Order from '../Pages/Order/Order';
import Settings from '../Pages/Settings/Settings';
import PhotoShoots from '../Pages/PhotoShoots/PhotoShoots';
import PhotoShoot from '../Pages/PhotoShoot/PhotoShoot';
import MyCompany from '../Pages/Company/Company';
import MyAccount from '../Pages/Account/Account';
import Billing from '../Pages/Billing/Billing';
import { SnackbarProvider, useSnackbar } from 'notistack';
import { withRouter } from "react-router-dom";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import Fade from '@material-ui/core/Fade';
import history from '../../../utils/history';
import OrderMobile from '../../Landings/OrderPage/Order'

var mobile = (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1)

const styles = theme => ({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  root: {
    // width: '100vw',
    // height: '100vh',
    // backgroundColor: 'red'
  },
  success: { backgroundColor: '#33D7A0', color: 'white', textWeight: 'bold' },
});

class Template extends React.Component {

  state = {
    deviceType: 'desktop',
    page: '',
    isLoading: false
  }

  componentDidMount() {
    var page = this.getParameterByName('page')
    this.setState({page: page})
  }

  getParameterByName = (name, url) => {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  // getPageStyle = () => {
  //   if (!mobile) {
  //     return {width: 'calc(100vw - 120px)',backgroundColor: '#F9F8FD', display: 'inline-block', borderRadius: '0px 0px 0px 20px', verticalAlign:'top', overflowY: 'scroll', overflowX: 'hidden', height: 'calc(100vh - 94px)', left: 120, position: 'fixed', top: 94}
  //   } else {
  //     return {marginTop: 55, width: '100vw', height: '100vh', paddingBottom: 50, backgroundColor: 'white', display: 'inline-block', verticalAlign:'top', overflowY: 'scroll', paddingLeft: 0, paddingRight: 0, backgroundColor: 'rgb(94,44,237)'}
  //   }
  // }
  

  render() {
    const { classes, theme } = this.props;
    return (
      <div style={{paddingTop: 60}}>
        {this.state.isLoading === true ? (
          <div className="loading"><CircularProgress style={{marginTop: 'calc(50vh - 50px)'}}/><div><div style={{fontFamily: "'Montserrat', sans-serif", fontSize: mobile ? 25 : 20, padding: mobile ? 20 : 0, color: 'black', marginTop: 20, fontWeight: 'bold'}}></div></div></div>
        ) : (
            <Switch>
              <Route path="/c/dashboard/order">
                <OrderMobile landingPage={false} />
              </Route>
              <Route path="/c/dashboard/photo-shoots">
                <PhotoShoots updateShootPage={this.props.updateShootPage} main={this} history={history}/>
              </Route>
              <Route path="/c/dashboard/shoot">
                <Shooting updateShootPage={this.props.updateShootPage} main={this} history={history}/>
              </Route>
              <Route path="/c/dashboard/photo-shoot/:id" component={PhotoShoot}/>
              <Route path="/c/dashboard/settings">
                <Settings main={this} history={history}/>
              </Route>
              <Route path="/c/dashboard/account">
                <MyAccount isBusinessAccount={this.props.isBusinessAccount} businessAccountId={this.props.businessAccountId} main={this} history={history}/>
              </Route>
              <Route path="/c/dashboard/billing">
                <Billing isBusinessAccount={this.props.isBusinessAccount} businessAccountId={this.props.businessAccountId} main={this} history={history}/>
              </Route>
              <Route path="/c/dashboard/company">
                <MyCompany main={this} history={history}/>
              </Route>
            </Switch>
        )}
      </div>
    );
  }
}

Template.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(Template);
