import React, {useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/styles';
import { Box, Button, Checkbox, Container, IconButton } from '@material-ui/core';
import axios from 'axios';

const useStyle = makeStyles((theme) => ({
  imageInRowContainer: {
    display: 'flex',
    width: '100%',
    height: 340 * (9/16),
    // boxShadow: '0px 4px 10px #00000020',
    borderRadius: 8,
    background: 'white',
    position: 'relative'
  },
  imageInColumnContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: 8,
    height: 72,
    width: '100%',
    paddingTop: 8,
    paddingBottom: 8, paddingLeft: 8,
    boxShadow: '0px 4px 10px #00000020',
    background: 'white',
    position: 'relative'
  },
  imageInRow: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0, left: 0,
    borderRadius: 8,
    objectFit: 'cover',
    cursor: 'pointer'
  },
  imageInColumn: {
    height: 72 - 16,
    width: 72 - 16,
    borderRadius: 8,
    objectFit: 'cover',
    cursor: 'pointer'
  },
  checkbox: {
    zIndex: 99,
    // background: 'white',
    '$:hover': {
      // background: 'white',
    }
  },
}))

const Image = (props) => {

  const classes = useStyle()

  const [surffixLowercase, setSurffixLowercase] = React.useState(null)

  const [previewImageUrl, setPreviewImageUrl] = React.useState(null)

  React.useEffect(() => {
    checkIfCompressedImageIsReady()
  }, [])

  const checkIfCompressedImageIsReady = async () => {
    // axios.get(props.image ,{}, {headers: {'Content-Type': 'application/json'}}).then((response) => {
    //   setPreviewImageUrl(getCompressedImage(props.image))
    // })
    // .catch( (error) => {
    //   console.log('xxxxxxxxxxx', props.image)
    //   setPreviewImageUrl(props.image)
    // });
    setPreviewImageUrl(getCompressedImage(props.image))
  }

  const handleChange = (event) => {
    if (event.target.checked) {
      props.setSelectedImages(
        [
          ...props.selectedImages,
          props.image
        ]
      )
    } else {
      let newSelectedImages = []
      for (let index = 0; index < props.selectedImages.length; index++) {
        const selectedImage = props.selectedImages[index];
        if (selectedImage === props.image) continue
        newSelectedImages.push(selectedImage)
      }
      props.setSelectedImages(newSelectedImages)
    }
  }

  const addReducedToImage = (name) => {
    let seperatedName = name.split('.')
    seperatedName.splice(-1,1)
    seperatedName[seperatedName.length - 1] = seperatedName[seperatedName.length - 1] + '_reduced.jpg'
    return seperatedName.join('.')
  }

  const getCompressedImage = (link) => {
    let seperatedLink = link.split('/')
    seperatedLink[seperatedLink.length - 1] = addReducedToImage('compressed/' + seperatedLink[seperatedLink.length - 1])
    return seperatedLink.join('/')
  }

  const getImageName = (link) => {
    let seperatedLink = link.split('/')
    return seperatedLink[seperatedLink.length - 1]
  }

  return ( 
    <div className={props.view === 'row' ? classes.imageInRowContainer : classes.imageInColumnContainer}>
      {props.view === 'row' ? (
        <>
          {previewImageUrl && (
            <img onClick={()=> props.openGallery(props.index)} alt='' src={previewImageUrl} className={props.view === 'row' ? classes.imageInRow : classes.imageInColumn} />
          )}
          {props.selectionOn && (
            <div style={{position: 'absolute', top: 8, right: 8}}>
              <Checkbox classes={{root: classes.checkbox}} checked={props.selectedImages.includes(props.image)} onChange={handleChange} />
            </div>
          )}
          <div style={{width: '100%', height: 40, fontSize: 15, background: `linear-gradient(180deg, rgba(255,255,255,0) 0%, black 100%)`, position: 'absolute', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white', bottom: 0, left: 0, borderBottomRightRadius: 8, borderBottomLeftRadius: 8}}>
            {getImageName(props.image)}
          </div>
          {props.selectionOn && (
            <div style={{width: 58, height: 58, fontSize: 15, background: `linear-gradient(45deg, rgba(255,255,255,0) 0%, white 100%)`, position: 'absolute', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white', top: 0, right: 0, borderTopRightRadius: 8}}>
            </div>
          )}
        </>
      ) : (
        <div style={{width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          <div style={{flexGrow: 1, display: 'flex', alignItems: 'center'}}>
            <div>
              {previewImageUrl && (
                <img onClick={()=> props.openGallery(props.index)} alt='' src={previewImageUrl} className={props.view === 'row' ? classes.imageInRow : classes.imageInColumn} />
              )}
            </div>
            <div style={{marginLeft: 18}}>
              Photo #{props.index} - {getImageName(props.image)}
            </div>
          </div>
          {props.selectionOn && (
            <div style={{marginRight: 8, background: 'white'}}>
              <Checkbox classes={{root: classes.checkbox}} checked={props.selectedImages.includes(props.image)} onChange={handleChange} />
            </div>
          )}
        </div>
      )}
    </div>
  );
}
 
export default Image;