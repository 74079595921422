import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import DeleteIcon from '@material-ui/icons/Delete';
import Switch from '@material-ui/core/Switch';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';

import DeleteDialog from './DeleteDialog';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
    '& .MuiTableRow-hover': {
      // backgroundColor: 'red'
    },
    '& .MuiTableRow-footer': {
      backgroundColor: 'red'
    },
    '& .MuiTableCell-root': {
      borderBottom: "none"
    }
  },
  tableCell: {
    borderBottom: "none"
  }
});

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    '&$checked': {
      transform: 'translateX(12px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.green,
        borderColor: theme.palette.primary.green,
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: 'none',
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);

export default function SimpleTable(props) {
  const classes = useStyles();
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [currentUser, setCurrentUser] = React.useState(null);

  const handleChange = (event, id) => {
    var newTableData = props.tableData
    props.tableData.forEach((row, i) => {
      if (row.id === id) {
        newTableData[i][event.target.name] = event.target.checked
        props.main.setState({tableData: newTableData})
        props.changeRelationshipStatus(id, event.target.name, event.target.checked)
      }
    });
  };

  const removeMember = () => {
    props.changeRelationshipStatus(currentUser, 'active', 'false')
    setOpenDeleteDialog(false)
  }

  return (
    <TableContainer component={Paper} elevation={0}>
      <DeleteDialog open={openDeleteDialog} setOpen={setOpenDeleteDialog} removeMember={removeMember} />
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            {/* <TableCell>Id</TableCell> */}
            <TableCell align="left">Name</TableCell>
            <TableCell align="left">Mobile</TableCell>
            <TableCell align="left">Status</TableCell>
            <TableCell align="left">Start Date</TableCell>
            <TableCell align="left">Can Watch</TableCell>
            <TableCell align="left">Can Use Order</TableCell>
            <TableCell align="left">Can Select</TableCell>
            <TableCell align="left">Accounter</TableCell>
            <TableCell align="left">Remove</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.tableData.map((row, i) => (
            <TableRow key={''} hover={true} style={{backgroundColor: i % 2 === 0 ? '#ecf1ff' : 'none'}}>
              {/* <TableCell classes={classes.tableCell} component="th" scope="row" style={{ color: '#5D2CED'}}>
                {row.Id}
              </TableCell> */}
              <TableCell classes={classes.tableCell} align="left" style={{color: '#6C65FF'}}>{row.name}</TableCell>
              <TableCell classes={classes.tableCell} align="left">{row.mobile}</TableCell>
              <TableCell classes={classes.tableCell} align="left">{row.status}</TableCell>
              <TableCell classes={classes.tableCell} align="left">{row.startAt}</TableCell>
              <TableCell classes={classes.tableCell} align="left">
                <AntSwitch checked={row.watchShootings} onChange={(e) => handleChange(e, row.id)} name="watchShootings" />
              </TableCell>
              <TableCell classes={classes.tableCell} align="left">
                <AntSwitch checked={row.useOrders} onChange={(e) => handleChange(e, row.id)} name="useOrders" />
              </TableCell>
              <TableCell classes={classes.tableCell} align="left">
                <AntSwitch checked={row.canSelect} onChange={(e) => handleChange(e, row.id)} name="canSelect" />
              </TableCell>
              <TableCell classes={classes.tableCell} align="left">
                <AntSwitch checked={row.accounter} onChange={(e) => handleChange(e, row.id)} name="accounter" />
              </TableCell>
              <TableCell classes={classes.tableCell} align="left">
                <IconButton aria-label="delete" onClick={()=> {setOpenDeleteDialog(true); setCurrentUser(row.id)}}>
                  <DeleteIcon size={30} style={{color: '#EB354B'}}/>
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
