import axios from 'axios';
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import BillingTable from './BillingTable'
import Button from '@material-ui/core/Button';
import DetailsDialog from './DetailsDialog'
import PaymentRequestsTable from './PaymentRequestsTable'
import CommentDialog from './CommentDialog'
import CircularProgress from '@material-ui/core/CircularProgress';
import DateFnsUtils from '@date-io/date-fns';
import Tooltip from '@material-ui/core/Tooltip';
import moment from '@date-io/moment';
import { Alert } from 'antd';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

const styles = theme => ({
  root: {
    flexGrow: 1,
    // width: '100vw',
    // height: '100vh',
    // backgroundColor: '#6C65FF',
    // backgroundColor: 'white'
  },
  grow: {
    flexGrow: 1,
    fontSize: '20px',
    textAlign: 'left'
  },
  margin: {
    margin: 4
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 2,
  },
});

var mobile = (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1)

function createData(name, location, product, datetime, price) {
    return { name, location, product, datetime, price};
}

function createDataForUnpaid(name, location, date, status, product, cost) {
    return { name, location, date, status ,product, cost };
}

function createDataForPaymentRequests(id, docId, requestDate, amount, vat, totalAmount, linkToInvoice, linkToReceipt, paymentDate, status, moreDetails, comment) {
    return { id, docId, requestDate, amount, vat, totalAmount, linkToInvoice, linkToReceipt, paymentDate, status, moreDetails, comment };
}

function getFormattedDate(date) {
    var dateObj = new Date(date);
    var stringToReturn = ''
    stringToReturn += dateObj.getDate() + "/"
    stringToReturn += (dateObj.getMonth() + 1) + "/"
    stringToReturn += dateObj.getFullYear() + " "
    var hour = dateObj.getHours()
    if (String(hour).length < 2) {
      hour = "0" + hour
    }
    var min = dateObj.getMinutes()
    if (String(min).length < 2) {
      min = "0" + min
    }
    stringToReturn += hour + ":"
    stringToReturn += min
    return stringToReturn
}

class MainLogin extends React.Component {

  state = {
      paymentRequests: [],
      unpaidRequests: [],
      requests: [],
      paymentsRequestTableData: null,
      openNew: false,
      openPaymentRequestDetails: false,
      detailsTableData: [],
      detailsPaymentData: null,
      currentData: 'main',
      addingCommentInProgress: false,
      commentDialogOpen: false,
      commentId: null,
      commentText: null,
      isLoading: true,
      documentType: '0',
      selectedRequests: [],
      errorAmount: '',
      errorNumber: '',
      errorComment: '',
      comment: '',
      docAmount: '',
      docNumber1: '',
      docNumber2: '',
      errorDocument: false,
      invoiceAndReciept: null,
      invoiceReciept: null,
      invoice: null,
      reciept: null,
      demandForPayment: null,
      recieptIsMissing: false,
      docDate: null,
      formatedDocDate: null,
      updatingData: false
  }

  handleDateChangeFrom = date => {
    var dateObj = new Date(date);
    var m = String(dateObj.getDate())
    var d = String(dateObj.getMonth()+1)
    var y = String(dateObj.getFullYear())
    if (m.length === 1) {
      m = "0" + m
    }
    if (d.length === 1) {
      d = "0" + d
    }
    var newDate = y + "-" + d + "-" + m
    console.log(newDate)
    console.log(new Date(date))
    this.setState({docDate: newDate, formatedDocDate: new Date(date)})
  };

  createPaymentRequest = () => {
    const contactId = localStorage.getItem("contactId")
    var requestsIds = []
    var amount = 0
    this.state.requests.forEach(request => {
      if (this.state.selectedRequests.includes(request.Name)) {
        requestsIds.push(request.Id)
        amount += request.Total_Photographer_Cost__c
      }
    });
    this.setState({amount: amount.toFixed(2), currentData: 'upload'})
  }

  getRequestFromName = (name) => {
    let req = null;
    console.log(name)
    this.state.requests.forEach(request => {
      if (request.Name === name) {
        req = request
      }
    });
    console.log(req)
    return req
  }

  onChangeFile = async (e, typeOfFile) => {
    console.log(e.target.files[0])
    console.log(typeOfFile)
    const files = Array.from(e.target.files)
    if (typeOfFile === 'Invoice') {
      this.setState({invoice: files[0]})
    } else if (typeOfFile === 'Reciept') {
      this.setState({reciept: files[0]})
    } else if (typeOfFile === 'Invoice / Reciept') {
      this.setState({invoiceReciept: files[0]})
    } else if (typeOfFile === 'Invoice + Reciept') {
      this.setState({invoiceAndReciept: files[0]})
    } else if (typeOfFile === 'DemandForPayment') {
      this.setState({demandForPayment: files[0]})
    }
  }


  addComment = () => {
    if (this.state.commentId !== null) {
      this.setState({addingCommentInProgress: true})
      var data = {requestId: this.state.commentId, data: {Photographer_Comments__c: this.state.commentText}}
      axios.post('https://wesnapp.co.il/api/payment-request/update-request',data, {headers: {'Content-Type': 'application/json'}}).then((response) => {
        this.setState({addingCommentInProgress: false, commentDialogOpen: false})
        this.commentAddedSuccessfully()
      })
      .catch( (error) => {
        //console.log(error);
      });
    }
  }

  commentAddedSuccessfully = () => {
    window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;
    this.props.enqueueSnackbar("The comment was successfully added", { variant: "success", autoHideDuration: 9999999999999999, resumeHideDuration: 99999999999999,
    action: key => (
                  <Button color="secondary" style={{color: 'white'}} size="small" onClick={() => this.props.closeSnackbar(key)}>
                      Dismiss
                  </Button>
          )}
        )
  }

  getRelatedRequests = async (contactId, accountId) => {
    const responseRequests = await axios.post('https://wesnapp.co.il' + '/api/request/get-by-photographer-contact',{id: contactId}, {headers: {'Content-Type': 'application/json'}})
    const requests = responseRequests.data
    this.setState({requests: requests})
    const responsePayments = await axios.post('https://wesnapp.co.il' + '/api/photographer/get-all-payment-requests',{id: accountId}, {headers: {'Content-Type': 'application/json'}})
    const paymentRequests = responsePayments.data
    paymentRequests.sort((a, b) => (new Date(b.Shooting_Date_Time__c).getTime()) - (new Date(a.Shooting_Date_Time__c).getTime()));
    let paymentsRequestTableData = []
    for (let i = 0; i < paymentRequests.length; i++) {
        const paymentRequestId = paymentRequests[i].Id;
        const relatedRequests = []
        const tableData = []
        for (let j = 0; j < requests.length; j++) {
            const request = requests[j];
            if (request.Photographer_Payment_Order__c === paymentRequestId) {
                relatedRequests.push(request)
                tableData.push(createData(request.Name, request.ShootingStreet__c + ', ' + request.ShootingCity__c, request.Public_Product_Name__c, getFormattedDate(request.Shooting_Date_Time__c), request.Total_Photographer_Cost__c))
            }
        }
        tableData.sort((a, b) => (new Date(b.datetime).getTime()) - (new Date(a.datetime).getTime()));
        const paymentRequestData = createDataForPaymentRequests(
            paymentRequests[i].Name,
            paymentRequests[i].Id,
            getFormattedDate(paymentRequests[i].CreatedDate),
            paymentRequests[i].Amount_Due__c,
            paymentRequests[i].VAT__c,
            paymentRequests[i].Total_Amount_Due__c,
            paymentRequests[i].Link_to_Invoice__c,
            paymentRequests[i].Link_to_Receipt__c,
            paymentRequests[i].Date_of_payment__c,
            paymentRequests[i].Status__c,
            tableData,
            {text: paymentRequests[i].Photographer_Comments__c, id: paymentRequests[i].Id}
        )
        if (paymentRequests[i].Date_of_payment__c && !paymentRequests[i].Date_of_payment__c.includes('2019')) {
          paymentsRequestTableData.push(paymentRequestData)
        } else if (!paymentRequests[i].Date_of_payment__c) {
          paymentsRequestTableData.push(paymentRequestData)
        }
    }
    paymentsRequestTableData.sort((a, b) => (new Date(b.paymentDate).getTime()) - (new Date(a.paymentDate).getTime()));
    var recieptIsMissing = false
    let missingReciepts = []
    let withReciepts = []
    let counterOfMissingReciepts = 0
    for (let index = 0; index < paymentsRequestTableData.length; index++) {
      const row = paymentsRequestTableData[index];
      if ((row.linkToReceipt != null && row.linkToReceipt.includes('http')) === false) {
        console.log(row.linkToReceipt)
        counterOfMissingReciepts += 1
        if (counterOfMissingReciepts === 2) {
          recieptIsMissing = true
        }
        missingReciepts.push(row)
      } else {
        withReciepts.push(row)
      }
    }
    console.log('missingReciepts', missingReciepts)
    let newPaymentsRequestTableData = [...withReciepts, ...missingReciepts]
    const unpaidRequests = []
    const unpaidTableData = []
    requests.sort((a, b) => (new Date(b.Shooting_Date_Time__c).getTime()) - (new Date(a.Shooting_Date_Time__c).getTime()));
    for (let j = 0; j < requests.length; j++) {
        const request = requests[j];
        if (!request.Shooting_Date_Time__c.includes('2018') && !request.Shooting_Date_Time__c.includes('2019')) {
          if (!request.Shooting_Date_Time__c.includes('2020-02') && !request.Shooting_Date_Time__c.includes('2020-03')) {
            if (request.Photographer_Payment_Order__c === null && (request.Status__c === 'Process Completed' || request.Status__c === 'Editing Complete / Waiting For Selection')) {
              unpaidRequests.push(request)
              unpaidTableData.push(createDataForUnpaid(request.Name, request.ShootingStreet__c + ', ' + request.ShootingCity__c, getFormattedDate(request.Shooting_Date_Time__c), 'Done', request.Public_Product_Name__c, request.Total_Photographer_Cost__c))      
            }
          }
        }
    }
    console.log(paymentRequests)
    console.log(newPaymentsRequestTableData)
    this.setState({recieptIsMissing: recieptIsMissing, isLoading: false, paymentsRequestTableData: newPaymentsRequestTableData, paymentRequests: paymentRequests, unpaidRequests: unpaidRequests, unpaidTableData: unpaidTableData})
    console.log(paymentRequests);
}

  componentDidMount = () => {
    var contactId = localStorage.getItem("contactId")
    var accountId = localStorage.getItem("accountId")
    if (contactId != null) {
      this.getRelatedRequests(contactId, accountId)
    }
  }

  handleChangeDocumentType = (e) => {
    this.setState({documentType: e.target.value})
  }

  clearErrors = () => {
    this.setState({
      errorForm: true
    })
  }

  getDocumentDateTime = () => {
    var date = this.state.docDate + 'T' + '12:00' + ':00.000+0200'
    return date
  }

  createPaymentRequestD = async () => {
    this.clearErrors()
    var foundError = false
    if (this.state.documentType === "0") {
      this.setState({errorDocument: true})
      foundError = true
    }
    if (this.state.documentType === "1" && this.state.invoice === null) {
      this.setState({errorDocument: true})
      foundError = true
    }
    if (this.state.documentType === "2" && this.state.reciept === null) {
      this.setState({errorDocument: true})
      foundError = true
    }
    if (this.state.documentType === "3" && this.state.invoiceReciept === null) {
      this.setState({errorDocument: true})
      foundError = true
    }
    if (this.state.documentType === "5" && this.state.demandForPayment === null) {
      this.setState({errorDocument: true})
      foundError = true
    }
    if (this.state.docAmount === '') {
      this.setState({errorDocument: true})
      foundError = true
    }
    if (this.state.docComment === '' && this.state.errorComment !== '') {
      this.setState({errorDocument: true})
      foundError = true
    }
    if (this.state.docNumber1 === '') {
      this.setState({errorDocument: true})
      foundError = true
    }
    if (this.state.docDate === null) {
      this.setState({errorDocument: true})
      foundError = true
    }
    if (this.state.documentType === "4" && (this.state.docNumber2 === '' || this.state.invoice == null || this.state.reciept == null)) {
      this.setState({errorDocument: true})
      foundError = true
    }

    if (!foundError) {
      const contactId = localStorage.getItem("accountId")
      var requestsIds = []
      var amount = 0
      this.state.requests.forEach(request => {
        if (this.state.selectedRequests.includes(request.Name)) {
          requestsIds.push(request.Id)
          amount += request.Total_Photographer_Cost__c
        }
      });
      this.setState({amount: amount.toFixed(2)})
      this.setState({creatingRequest: true})
      const newPaymentRequestData = {
        Billing_Account__c: contactId,
        Status__c: 'Input',
        Amount_Due__c: amount,
      }
      console.log('this.state.documentType', this.state.documentType)
      if (this.state.documentType === "1") {
        newPaymentRequestData['Photographer_Invoice__c'] = this.state.docNumber1
        newPaymentRequestData['Document_Type__c'] = 'Invoice'
        var link = await this.storeInvoice(this.state.invoice)
        newPaymentRequestData['Link_to_Invoice__c'] = link
        newPaymentRequestData['Photographer_Invoice_Date__c'] = this.getDocumentDateTime()
        console.log(newPaymentRequestData)
      }
      if (this.state.documentType === "2") {
        newPaymentRequestData['Photographer_Receipt__c'] = this.state.docNumber1
        newPaymentRequestData['Document_Type__c'] = 'Receipt'
        var link = await this.storeInvoice(this.state.reciept)
        newPaymentRequestData['Photographer_Receipt_Date__c'] = this.getDocumentDateTime()
        newPaymentRequestData['Link_to_Receipt__c'] = link
      }
      if (this.state.documentType === "5") {
        newPaymentRequestData['Photographer_Invoice__c'] = this.state.docNumber1
        newPaymentRequestData['Document_Type__c'] = 'Demand for Payment'
        var link = await this.storeInvoice(this.state.demandForPayment)
        newPaymentRequestData['Photographer_Invoice_Date__c'] = this.getDocumentDateTime()
        newPaymentRequestData['Link_to_Invoice__c'] = link
      }
      if (this.state.documentType === "3") {
        newPaymentRequestData['Photographer_Invoice__c'] = this.state.docNumber1
        newPaymentRequestData['Document_Type__c'] = 'Invoice / Receipt'
        var link = await this.storeInvoice(this.state.invoiceReciept)
        newPaymentRequestData['Link_to_Invoice__c'] = link
        newPaymentRequestData['Link_to_Receipt__c'] = link
        newPaymentRequestData['Photographer_Invoice_Date__c'] = this.getDocumentDateTime()
        newPaymentRequestData['Photographer_Receipt_Date__c'] = this.getDocumentDateTime()
      }
      if (this.state.documentType === "4") {
        newPaymentRequestData['Photographer_Invoice__c'] = this.state.docNumber1
        newPaymentRequestData['Photographer_Receipt__c'] = this.state.docNumber2
        newPaymentRequestData['Document_Type__c'] = 'Invoice + Reciept'
        newPaymentRequestData['Link_to_Invoice__c'] = await this.storeInvoice(this.state.invoice)
        newPaymentRequestData['Link_to_Receipt__c'] = await this.storeInvoice(this.state.reciept)
      }
      if (this.state.docComment !== '') {
        newPaymentRequestData['Photographer_Comments__c'] = this.state.docComment
      }
      axios.post('https://wesnapp.co.il/api/payment-request/create-request',{data: newPaymentRequestData, requests: requestsIds}, {headers: {'Content-Type': 'application/json'}}).then((response) => {
        this.getRelatedRequests(contactId, localStorage.getItem('accountId'))  
        this.setState({creatingRequest: false, isLoading: true, currentData: 'main'})
      })
      .catch( (error) => {
      });
    }
  }

  getSignedUrl = async (fileName, contentType) => {
    let response = await axios.post("https://wesnapp.co.il/api/storage/get-signed-url", {fileName: fileName, contentType: contentType} ,{headers: {'Content-Type': "application/json", 'Access-Control-Allow-Origin': '*'}})
    return response.data
  }

  storeInvoice = async (file) => {
    let directory = `invoices/${localStorage.getItem('contactId')}/${(new Date().getTime())}_`
    // let signedUrl = await this.getSignedUrl(directory + 'document.' + file.name.substring(file.name.lastIndexOf(".")+1), file.name.substring(file.name.lastIndexOf(".")+1) === 'pdf' ? `appliction/${file.name.substring(file.name.lastIndexOf(".")+1)}` : `image/${file.name.substring(file.name.lastIndexOf(".")+1)}`)
    let signedUrl = await this.getSignedUrl(directory + 'document.' + file.name.substring(file.name.lastIndexOf(".")+1))
    const xhr = new XMLHttpRequest();
    xhr.open("PUT", signedUrl, true);
    xhr.onload = () => {
      const status = xhr.status;
      if (status === 200) {
        console.log('uploadFinished')
      } else {
        console.log(xhr)
      }
    };
    xhr.onerror = (e) => {
      alert("שגיאה במהלך העלאת הקובץ. אנה נסה שוב או צור קשר עם צוות התמיכה", e);
    };
    xhr.setRequestHeader('Content-Type', file.type);
    xhr.send(file);
    return `https://storage.googleapis.com/wesnapp/${directory + 'document.' + file.name.substring(file.name.lastIndexOf(".")+1)}`
  }

  handleChangeText = name => event => {
    this.setState({ [name]: event.target.value });
    // Check with vat or without
    if (this.props.main.props.main.state.userData.VAT_Inclusive__c) {
      if (name === 'docAmount') {
        if (parseFloat(event.target.value).toFixed(2) === parseFloat(this.getTotalRevenue() * 1.17).toFixed(2)) {
          this.setState({errorAmount: '', errorComment: ''})
        } else {
          this.setState({errorAmount: 'Spouse to be equal to ' + String(this.getTotalRevenue() * 1.17), errorComment: 'Must fill comment'})
        }
      }
    } else {
      if (name === 'docAmount') {
        if (parseFloat(event.target.value).toFixed(2) === parseFloat(this.getTotalRevenue()).toFixed(2)) {
          this.setState({errorAmount: '', errorComment: ''})
        } else {
          this.setState({errorAmount: 'Spouse to be equal to ' + String(this.getTotalRevenue()), errorComment: 'Must fill comment'})
        }
      }
    }
  };

  getTotalRevenue = () => {
    let revenue = 0
    for (let index = 0; index < this.state.selectedRequests.length; index++) {
      const requestName = this.state.selectedRequests[index];
      let request = this.getRequestFromName(requestName)
      revenue += request.Total_Photographer_Cost__c
    }
    return revenue
  }

  changeChargingVAT = (state) => {
    this.setState({updatingData: true})
    var data = {
      id: localStorage.getItem("accountId"),
      data: {
        VAT_Inclusive__c: state
      }
     };
     axios.post('https://wesnapp.co.il/api/photographer/update-profile',data, {headers: {'Content-Type': 'application/json'}}).then(async (response) => {
      await this.props.main.props.main.getUserDetails()
      this.setState({updatingData: false})
     })
     .catch( (error) => {
     });

  }

  render() {
    const { classes, theme } = this.props;
      return (
        <div className={styles.root} style={{marginTop: 10, padding: mobile ? 10 : 0, paddingTop: mobile ? 30 : 0, paddingBottom: 150}}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
        {this.state.currentData === 'main' && (
            <div style={{fontSize: 24, color: 'rgb(40,40,53)', textAlign: 'left', fontFamily: "'Montserrat', sans-serif", marginTop: mobile ? 0 : 27, marginBottom: mobile ? 0 : 30, display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                <div><b>Billing</b></div>
                {!this.state.recieptIsMissing ? (
                  <Button onClick={() => this.setState({currentData: 'new'})} elevation={0} style={{textTransform: 'none', height: 45, paddingLeft: 15, paddingRight: 15, borderRadius: 22.5, display: 'inline-block', color: 'white', backgroundColor: '#6334FF', boxShadow: '0px 0px 11px 2px rgba(255,255,255,0.5)'}} className={classes.button}>
                    <b>+ New payment request</b>
                </Button>
                ) : (
                  <Tooltip title="Missing Reciept" placement="bottom">
                    <Button elevation={0} style={{textTransform: 'none', height: 45, paddingLeft: 15, paddingRight: 15, borderRadius: 22.5, display: 'inline-block', color: 'white', backgroundColor: '#8DA2A5', boxShadow: '0px 0px 11px 2px rgba(255,255,255,0.5)'}} className={classes.button}>
                        <b>+ New payment request</b>
                    </Button>
                  </Tooltip>
                )}
            </div>
        )}
        {this.state.currentData === 'new' && (
            <div style={{fontSize: 24, color: 'rgb(40,40,53)', textAlign: 'left', fontFamily: "'Montserrat', sans-serif", marginTop: mobile ? 0 : 27, marginBottom: mobile ? 0 : 30}}>
                <b>New Billing Request</b>
            </div>
        )}
        {this.state.currentData === 'upload' && (
            <div style={{fontSize: 24, color: 'rgb(40,40,53)', textAlign: 'left', fontFamily: "'Montserrat', sans-serif", marginTop: mobile ? 0 : 27, marginBottom: mobile ? 0 : 30}}>
                <b>Upload Reciept</b>
            </div>
      )}
        {this.state.openPaymentRequestDetails && (
          <DetailsDialog main={this}/>
        )}
        <CommentDialog main={this} />
            {!this.state.isLoading ? (
              <>
          {this.state.currentData === 'main' && (
                  <Grid container spacing={2} style={{padding: mobile ? 0 : 0, paddingTop: 0}}>
                      {this.state.recieptIsMissing && (
                        <div style={{width: '100%'}}>
                          <Alert
                            message="Warning"
                            description="Payment cannot be requested when a previous request is open"
                            type="warning"
                            showIcon
                            closable
                            style={{borderRadius: 10}}
                          />
                        </div>
                      )}
                      {this.state.paymentsRequestTableData != null && (
                          <PaymentRequestsTable main={this} tableData={this.state.paymentsRequestTableData} style={{width: '100%'}} />    
                      )} 
                  </Grid>
              )}
              {this.state.currentData === 'new' && (
                  <Grid container spacing={2} style={{padding: mobile ? 0 : 0, paddingTop: 0}}>
                      {this.state.paymentsRequestTableData != null && (
                          <BillingTable tableData={this.state.unpaidTableData} main={this} style={{width: '100%'}} />    
                      )} 
                      <Grid item xs={12} sm={12} md={12} xl={12}>
                          <Button disabled={this.state.selectedRequests.length === 0} onClick={() => this.createPaymentRequest()} elevation={0} style={{  display: 'inline-block', float: 'right', marginTop: 20, textTransform: 'none', height: 45, paddingLeft: 15, paddingRight: 15, borderRadius: 22.5, display: 'inline-block', color: 'white', backgroundColor: this.state.selectedRequests.length === 0 ? '#8DA3A6' : '#6334FF'}} className={classes.button}>
                              <b>Next</b>
                          </Button>
                          <Button onClick={() => this.setState({currentData: 'main'})} elevation={0} style={{  display: 'inline-block', float: 'left', marginTop: 20, textTransform: 'none', height: 45, paddingLeft: 15, paddingRight: 15, borderRadius: 22.5, display: 'inline-block', color: 'white', backgroundColor: '#6334FF'}} className={classes.button}>
                              <b>Back</b>
                          </Button>
                      </Grid>
                  </Grid>
              )}
              {this.state.currentData === 'upload' && (
                  <Grid container justify="center" alignItems="center" spacing={1} style={{padding: mobile ? 0 : 0, paddingTop: 0}}>
                      <Grid item xs={12} sm={7} md={7} xl={7}>
                        <div style={{textAlign: 'left', fontSize: 16, marginBottom: 8, width: '100%'}}>Selected photoshoots to get pay for:</div>
                        {this.state.selectedRequests.map((item, index) => (
                          <div style={{width: '100%', textAlign: 'left', marginTop: 24}}>
                            <div style={{marginTop: 8, fontWeight: 'bold'}}>#{index + 1} Photo shoot</div>
                            {console.log(this.getRequestFromName(item))}
                            <div style={{marginTop: 8}}>Product - {this.getRequestFromName(item).Public_Product_Name__c}</div>
                            <div style={{marginTop: 8}}>Location - {this.getRequestFromName(item).ShootingStreet__c}, {this.getRequestFromName(item).ShootingCity__c}</div>
                            <div style={{marginTop: 8}}>Revenue - {this.getRequestFromName(item).Total_Photographer_Cost__c} ILS</div>
                          </div>
                        ))}
                        {this.props.main.props.main.state.userData.VAT_Inclusive__c ? (
                          <div style={{marginTop: 12, fontWeight: 'bold'}}>Total Revenue - {this.getTotalRevenue()} ILS + VAT</div>
                        ) : (
                          <div style={{marginTop: 12, fontWeight: 'bold'}}>Total Revenue - {this.getTotalRevenue()} ILS</div>
                        )}
                        {this.props.main.props.main.state.userData.VAT_Inclusive__c ? (
                          <div style={{marginTop: 12, fontWeight: 'bold'}}>I'm charging VAT. {this.state.updatingData ? (<CircularProgress size={20}/>) : (<span style={{color: 'blue', cursor: 'pointer'}} onClick={()=> this.changeChargingVAT(false)}><u>Change</u></span>)}</div>
                        ) : (
                          <div style={{marginTop: 12, fontWeight: 'bold'}}>I'm not charging VAT. {this.state.updatingData ? (<CircularProgress size={20}/>) : (<span style={{color: 'blue', cursor: 'pointer'}} onClick={()=> this.changeChargingVAT(true)}><u>Change</u></span>)}</div>
                        )}
                        <div style={{marginTop: 12, fontWeight: 'bold'}}>My bank account details - {this.props.main.props.main.state.userData.Bank_Account_Number__c}, Branch {this.props.main.props.main.state.userData.Bank_Branch__c}, {this.props.main.props.main.state.userData.Bank_Name__c}. <span style={{color: 'blue', cursor: 'pointer'}} onClick={()=> this.props.main.props.history.push('/p/dashboard/settings')}><u>Change</u></span></div>
                      </Grid>
                      <div style={{width: '100%', height: 24}}></div>
                      <Grid item xs={12} sm={7} md={7} xl={7}>
                        <div style={{float: 'left', fontSize: 16, marginBottom: 8}}>Choose type of document</div>
                        <select value={this.state.documentType} onChange={this.handleChangeDocumentType} style={{borderRadius: 20}} className="regInput" style={{backgroundColor: '#ECF1FF', borderRadius: 20, marginTop: 0, marginLeft: 0, borderRadius: 25}}>
                          <option value="0">Document type*</option>
                          <option value="1">Invoice</option>
                          <option value="2">Reciept</option>
                          <option value="3">Invoice / Reciept</option>
                          <option value="5">Demand for Payment</option>
                          {/* <option value="4">Invoice + Reciept</option> */}
                        </select>
                      </Grid>
                      {this.state.documentType === "1" && (
                        <Grid item xs={12} sm={7} md={7} xl={7}>
                          <div style={{float: 'left', fontSize: 16, marginBottom: 8}}>Upload invoice</div>
                          <input type={"file"} className="regInput" onChange={ (e) => this.onChangeFile(e, 'Invoice')} placeholder="Email*" style={{float: 'right', paddingTop: 14}} />
                          <div style={{float: 'left', color: '#FD612B', marginTop: 3, textAlign: 'left'}}>{this.state.error_email}</div>
                        </Grid>
                      )}
                      {this.state.documentType === "2" && (
                        <Grid item xs={12} sm={7} md={7} xl={7}>
                          <div style={{float: 'left', fontSize: 16, marginBottom: 8}}>Upload receipt</div>
                          <input type={"file"} className="regInput" onChange={ (e) => this.onChangeFile(e, 'Reciept')} placeholder="Email*" style={{float: 'right', paddingTop: 14}} />
                          <div style={{float: 'left', color: '#FD612B', marginTop: 3, textAlign: 'left'}}>{this.state.error_email}</div>
                        </Grid>
                      )}
                      {this.state.documentType === "5" && (
                        <Grid item xs={12} sm={7} md={7} xl={7}>
                          <div style={{float: 'left', fontSize: 16, marginBottom: 8}}>Upload demand for payment</div>
                          <input type={"file"} className="regInput" onChange={ (e) => this.onChangeFile(e, 'DemandForPayment')} placeholder="Email*" style={{float: 'right', paddingTop: 14}} />
                          <div style={{float: 'left', color: '#FD612B', marginTop: 3, textAlign: 'left'}}>{this.state.error_email}</div>
                        </Grid>
                      )}
                      {this.state.documentType === "3" && (
                        <Grid item xs={12} sm={7} md={7} xl={7}>
                          <div style={{float: 'left', fontSize: 16, marginBottom: 8}}>Upload invoice/receipt</div>
                          <input type={"file"} className="regInput" onChange={ (e) => this.onChangeFile(e, 'Invoice / Reciept')} placeholder="Email*" style={{float: 'right', paddingTop: 14}} />
                          <div style={{float: 'left', color: '#FD612B', marginTop: 3, textAlign: 'left'}}>{this.state.error_email}</div>
                        </Grid>
                      )}
                      {this.state.documentType !== "4" && this.state.documentType !== "0" && (
                        <Grid item xs={12} sm={7} md={7} xl={7}>
                          <div style={{float: 'left', fontSize: 16, marginBottom: 8}}>Document number</div>
                          <input type={"text"} className="regInput" onChange={this.handleChangeText("docNumber1")} placeholder="Document number*" style={{float: 'right'}} />
                          <div style={{float: 'left', color: '#FD612B', marginTop: 3, textAlign: 'left'}}>{this.state.errorNumber}</div>
                        </Grid>
                      )}
                      {this.state.documentType === "4" && (
                        <Grid item xs={12} sm={7} md={7} xl={7}>
                          <div style={{float: 'left', fontSize: 16, marginBottom: 8}}>Upload invoice</div>
                          <input type={"file"} className="regInput" onChange={ (e) => this.onChangeFile(e, 'Invoice')} placeholder="Email*" style={{float: 'right', paddingTop: 14}} />
                          <div style={{float: 'left', color: '#FD612B', marginTop: 3, textAlign: 'left'}}>{this.state.error_email}</div>
                        </Grid>
                      )}
                      {this.state.documentType === "4" && (
                        <Grid item xs={12} sm={7} md={7} xl={7}>
                          <div style={{float: 'left', fontSize: 16, marginBottom: 8}}>Document number</div>
                          <input type={"text"} className="regInput" onChange={this.handleChangeText("docNumber1")} placeholder="Document number*" style={{float: 'right'}} />
                          <div style={{float: 'left', color: '#FD612B', marginTop: 3, textAlign: 'left'}}>{this.state.errorNumber}</div>
                        </Grid>
                      )}
                      {this.state.documentType === "4" && (
                        <Grid item xs={12} sm={7} md={7} xl={7}>
                          <div style={{float: 'left', fontSize: 16, marginBottom: 8}}>Upload receipt</div>
                          <input type={"file"} className="regInput" onChange={ (e) => this.onChangeFile(e, 'Reciept')} placeholder="Email*" style={{float: 'right', paddingTop: 14}} />
                          <div style={{float: 'left', color: '#FD612B', marginTop: 3, textAlign: 'left'}}>{this.state.errorE}</div>
                        </Grid>
                      )}
                      {this.state.documentType === "4" && (
                        <Grid item xs={12} sm={7} md={7} xl={7}>
                          <div style={{float: 'left', fontSize: 16, marginBottom: 8}}>Document number</div>
                          <input type={"text"} className="regInput" onChange={this.handleChangeText("docNumber2")} placeholder="Document number*" style={{float: 'right'}} />
                          <div style={{float: 'left', color: '#FD612B', marginTop: 3, textAlign: 'left'}}>{this.state.errorNumber}</div>
                        </Grid>
                      )}
                        <Grid item xs={12} sm={7} md={7} xl={7}>
                        <div style={{marginTop: 0}}>
                          <div style={{float: 'left', fontSize: 16, marginBottom: 8}}>Document date</div>
                            <KeyboardDatePicker
                              style={{width: '100%'}}
                              disableToolbar
                              variant="inline"
                              format="dd/MM/yyyy"
                              margin="normal"
                              id="date-picker-inline"
                              label=""
                              value={this.state.docDate}
                              onChange={this.handleDateChangeFrom}
                              KeyboardButtonProps={{
                                'aria-label': 'change date',
                              }}
                              fullWidth
                              InputProps={{
                                disableUnderline: true,
                               }}
                              className="regInput"
                              style={{padding: 10, display: 'inline-block', width: '100%', marginTop: 0}}
                            />
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={7} md={7} xl={7}>
                        <div style={{float: 'left', fontSize: 16, marginBottom: 8}}>Document amount</div>
                        <input type={"number"} className="regInput" onChange={this.handleChangeText("docAmount")} placeholder="Document amount*" style={{float: 'right'}} />
                        <div style={{float: 'left', color: '#FD612B', marginTop: 3, textAlign: 'left'}}>{this.state.errorAmount}</div>
                      </Grid>
                      <Grid item xs={12} sm={7} md={7} xl={7}>
                        <div style={{float: 'left', fontSize: 16, marginBottom: 8}}>Comment</div>
                        <textarea rows="4" type={"text"} className="regTextarea" onChange={this.handleChangeText("docComment")} placeholder="Enter your comment" style={{float: 'right'}} />
                        <div style={{float: 'left', color: '#FD612B', marginTop: 3, textAlign: 'left'}}>{this.state.errorComment}</div>
                      </Grid>
                      {this.state.errorDocument && (
                        <Grid item xs={12} sm={12} md={12} xl={12}>
                          <div style={{float: 'left', color: 'red', marginTop: 3, textAlign: 'left'}}>Missing fields</div>
                        </Grid>
                      )}
                      <Grid item xs={12} sm={12} md={12} xl={12}>
                        {this.state.creatingRequest ? (
                          <CircularProgress />
                        ) : (
                          <Button onClick={() => this.createPaymentRequestD()} elevation={0} style={{  display: 'inline-block', marginTop: 20, textTransform: 'none', height: 45, paddingLeft: 15, paddingRight: 15, borderRadius: 22.5, display: 'inline-block', color: 'white', backgroundColor: '#6334FF'}} className={classes.button}>
                              <b>Send Request</b>
                          </Button>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} xl={12}>
                          <Button onClick={() => this.setState({currentData: 'new'})} elevation={0} style={{  display: 'inline-block', float: 'left', marginTop: 20, textTransform: 'none', height: 45, paddingLeft: 15, paddingRight: 15, borderRadius: 22.5, display: 'inline-block', color: 'white', backgroundColor: '#6334FF'}} className={classes.button}>
                              <b>Back</b>
                          </Button>
                      </Grid>
                  </Grid>
                )}
              </>
            ) : (
              <Grid container spacing={2} style={{padding: 0, paddingTop: 0}} justifyContent="center" justify="center">
                <Grid item xs={12} sm={12} md={12} xl={12}>
                  <div style={{marginTop: 120, marginBottom: 120}}>
                    <CircularProgress />
                    <div style={{fontSize: 18, marginTop: 12}}>Loading data...</div>
                  </div>
                </Grid>
              </Grid>
            )}
        </MuiPickersUtilsProvider>
        </div>
    )
  }
}

MainLogin.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(MainLogin);
