import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Tab from '@material-ui/core/Tab';
import Grid from '@material-ui/core/Grid';
import PhoneIcon from '@material-ui/icons/Phone';
import CompanyIcon from '@material-ui/icons/Business';

import BillingIcon from '@material-ui/icons/CreditCard';
import axios from 'axios';
import { withRouter } from "react-router-dom";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import DetailsPage from './DetailsPage';
import BusinessDetails from './BusinessDetails';
import BusinessRelated from './BusinessRelated';
import { AuthContext } from '../../../../contexts/Auth';
import history from '../../../../utils/history';

import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Button } from 'antd';

import { Layout, Menu, Breadcrumb } from 'antd';
import { CameraOutlined, CreditCardOutlined, UserOutlined, LogoutOutlined } from '@ant-design/icons';
import { Hidden } from '@material-ui/core';
import { Tabs, Radio, Space } from 'antd';

import PaymentMethods from './PaymentMethods';

const { TabPane } = Tabs;

const { Header, Content, Footer, Sider } = Layout;

var mobile = (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1)

const styles = theme => ({
  list: {
    width: 250
  },
  fullList: {
    width: 'auto',
  },
  root: {
    width: '100vw',
    // height: '100vh',
    backgroundColor: '#6C65FF',
    backgroundColor: 'white'
  },
  button: {
    height: 50,
    marginTop: 15,
    float: 'left',
    borderRadius: 25,
    paddingLeft: 32,
    paddingRight: 32,
    boxShadow: 'none',
    hover: {
      backgroundColor: '#33D7A0'
    }
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
    textAlign: 'left',
    display: 'flex',
    alignItems: 'center'
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
    textAlign: 'left'
  },
});

class PhotoShoots extends React.Component {

  static contextType = AuthContext

  state = {
    isLoading: false,
    requests: [],
    currentTab: 0,
    expanded: false
  }

  componentDidMount = () => {
  }

  logout = () => {
    localStorage.removeItem('wesnapp-token');
    history.push("/login")
    window.location.reload();
  }

  render() {
    const { classes, theme } = this.props;

    return (
      <div className={styles.root} style={{padding: mobile ? 10 : 0, paddingTop: mobile ? 30 : 0, borderRadius: '35px 35px 0 0px', marginTop: 10}}>
        <div>
          <div style={{fontSize: 24, color: 'rgb(40,40,53)', textAlign: 'left', marginLeft: 0, fontFamily: "'Montserrat', sans-serif", padding: '16px 0'}}>
            <b>Settings</b>
          </div>
          <Hidden mdUp>
            <Tabs tabPosition={'top'} tabBarStyle={{textAlign: 'left'}}>
              <TabPane
                tab={
                  <span>
                    <UserOutlined />
                    General
                  </span>
                }
                key="1"
                style={{textAlign: 'left'}}
              >
                <DetailsPage />
              </TabPane>
              <TabPane
                tab={
                  <span>
                    <CreditCardOutlined />
                    Billing
                  </span>
                }
                key="2"
                style={{textAlign: 'left'}}
              >
                <BusinessDetails />
              </TabPane>
              <TabPane
                tab={
                  <span>
                    <CameraOutlined />
                    Business Related
                  </span>
                }
                key="3"
                style={{textAlign: 'left'}}
              >
                <BusinessRelated />
              </TabPane>
            </Tabs>
          </Hidden>
          <Hidden smDown>
            <Tabs tabPosition={'left'} tabBarStyle={{minWidth: 250, textAlign: 'left'}}>
              <TabPane
                tab={
                  <span>
                    <UserOutlined />
                    General Settings
                  </span>
                }
                key="1"
                style={{minWidth: 200, textAlign: 'left', paddingBottom: 24}}
              >
                <DetailsPage />
              </TabPane>
              <TabPane
                tab={
                  <span>
                    <CreditCardOutlined />
                    Billing Details
                  </span>
                }
                key="2"
                style={{minWidth: 200, textAlign: 'left', paddingBottom: 24}}
              >
                  <BusinessDetails />
              </TabPane>
              <TabPane
                tab={
                  <span>
                    <CameraOutlined />
                    Business Related
                  </span>
                }
                key="3"
                style={{minWidth: 200, textAlign: 'left', paddingBottom: 24}}
              >
                <BusinessRelated />
              </TabPane>
              <TabPane
                tab={
                  <span>
                    <CreditCardOutlined />
                    Payment Methods
                  </span>
                }
                key="4"
                style={{minWidth: 200, textAlign: 'left', paddingBottom: 24}}
              >
                  <PaymentMethods />
              </TabPane>
              <TabPane
                onClick={this.logout}
                tab={
                  <span style={{color: 'red'}} onClick={this.logout}>
                    <LogoutOutlined />
                    Logout
                  </span>
                }
                key="5"
                style={{minWidth: 200, textAlign: 'left', paddingBottom: 24}}
              >
              </TabPane>
            </Tabs>
          </Hidden>
        </div>
        <Hidden mdUp>
          <div style={{color: 'red', width: '100%', textAlign: 'left', cursor: 'pointer'}} onClick={this.logout}>Logout</div>
        </Hidden>
      </div>
    );
  }
}

PhotoShoots.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(withRouter(PhotoShoots));
