import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import AppBar from '../AppBar';
import SignUp from '../../Login/Photographer/PhotographerForm';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import { createBrowserHistory } from "history";
import { withRouter } from "react-router-dom";
import axios from 'axios'
import { Button } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ImageIcon from '@material-ui/icons/Image';
import WorkIcon from '@material-ui/icons/Work';
import BeachAccessIcon from '@material-ui/icons/BeachAccess';
import PaymentIcon from '@material-ui/icons/Payment';
import LocationIcon from '@material-ui/icons/LocationOn';
import DateIcon from '@material-ui/icons/Event';
import DurationIcon from '@material-ui/icons/Schedule';
import { Alert } from 'antd';

const history = createBrowserHistory();

const styles = theme => ({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  root: {
    // width: '100vw',
    height: '100%',
    width: '100%',
    overflowY: 'scroll',
    overflowX: 'hidden',
    backgroundColor: '#FFF',
    // background: 'linear-gradient(90deg, #6C65FF 50%, #FFF 50%)',
    position: 'fixed',
    top: 0, left: 0,
  }
});

const mobile = (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1)

class Template extends React.Component {

  state = {
    registrationStatus: ''
  }

  componentDidMount() {
  }

  render() {
    const { classes, theme } = this.props;
    return (
      <div className={classes.root}>
        <AppBar/>
        <Grid container spacing={1} style={{padding: 20}} justifyContent="center" justify="center">
            {this.state.registrationStatus === '' && (
              <Grid item xs={12} sm={12} md={5} xl={5}>
                  <div style={{width: '100%', fontSize: 24, fontWeight: 'bold', textAlign: 'left', marginTop: 0, marginBottom: 20}}>
                      Join WeSnapp today
                  </div>
                  <div style={{width: '100%', fontSize: 24, fontWeight: 'bold', textAlign: 'left', marginTop: 0, marginBottom: 20}}>
                      And Start making Money
                  </div>
                  <div><SignUp main={this} campaign={true} /></div>
              </Grid>
            )}
            {this.state.registrationStatus === 'ok' && (
              <Grid item xs={12} sm={12} md={12} xl={12} style={{textAlign: 'left'}}>
                <Alert
                  message="Success"
                  description="Thank you. We got your details and will be in touch with you soon."
                  type="success"
                  showIcon
                  style={{borderRadius: 20}}
                />
              </Grid>
            )}
            {this.state.registrationStatus === 'not-ok' && (
              <Grid item xs={12} sm={12} md={12} xl={12} style={{textAlign: 'left'}}>
                <Alert
                  message="Error"
                  description="Something went wrong. Please try again or contact us directly."
                  type="error"
                  showIcon
                  style={{borderRadius: 20}}
                />
              </Grid>
            )}
        </Grid>
      </div>
    );
  }
}

Template.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(Template);
