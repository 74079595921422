import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import Payments from '@material-ui/icons/Receipt';
import ExitToApp from '@material-ui/icons/ExitToApp';
import Settings from '@material-ui/icons/SettingsApplications';
import Notifications from '@material-ui/icons/Notifications';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withRouter } from "react-router-dom";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import BrushRoundedIcon from '@material-ui/icons/BrushRounded';

const cameraIcon = require("../../../Assets/cameraTableIcon.png");

const whiteIcon = require("../../../Assets/WhiteIcon.png");

var mobile = (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1)

const styles = theme => ({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  root: {
    height: '100%',
    width: '200px',
    backgroundColor: 'white'
  }
});

class Template extends React.Component {

  logout = () => {
    localStorage.removeItem('wesnapp-token');
    this.props.history.push("/login")
    window.location.reload();
  }

  render() {
    const { classes, theme } = this.props;
    return (
      <div style={{width: '120px', minHeight: '100vh', backgroundColor: 'clear', display: 'inline-block', verticalAlign:'top', background: 'red'}}>
          {/* <img style={{width: 50, height: 'auto', position: 'absolute', top: 45, left: 32.5}} src={whiteIcon} alt="..." /> */}
          <div className="centerbuttons">
            <Link to="/p/dashboard/photo-shoots" style={{color:'white', textDecoration: 'none'}}>
              <div style={{cursor: 'pointer', width: '120px', paddingTop: 25, paddingBottom: 25, backgroundColor: this.props.main.state.page == 'shooting' ? ("rgba(255,255,255,0.05") : ("rgba(255,255,255,0")}}>
                <div>
                  <PhotoCamera size="25" color="white" style={{color: 'white'}}/>
                </div>
                <div style={{marginTop: 4, color: 'white'}}>
                  Photo Shoots
                </div>
              </div>
            </Link>
            <Link to="/p/dashboard/auto-edit" style={{color:'white', textDecoration: 'none'}}>
              <div style={{cursor: 'pointer', width: '120px', paddingTop: 25, paddingBottom: 25, backgroundColor: this.props.main.state.page == 'shooting' ? ("rgba(255,255,255,0.05") : ("rgba(255,255,255,0")}}>
                <div>
                  <BrushRoundedIcon size="25" color="white" style={{color: 'white'}}/>
                </div>
                <div style={{marginTop: 4, color: 'white'}}>
                  Auto Edit
                </div>
              </div>
            </Link>
            <Link to="/p/dashboard/billing" style={{color:'white', textDecoration: 'none'}}>
              <div style={{cursor: 'pointer', width: '120px',  marginTop: '40%', paddingTop: 25, paddingBottom: 25, backgroundColor: this.props.main.state.page == 'order' ? ("rgba(255,255,255,0.05") : ("rgba(255,255,255,0")}}>
                <div>
                  <Payments size="25" color="white" style={{color: 'white'}}/>
                </div>
                <div style={{marginTop: 4, color: 'white'}}>
                  Billing
                </div>
              </div>
            </Link>
            <Link to="/p/dashboard/settings" style={{color:'white', textDecoration: 'none'}}>
              <div style={{cursor: 'pointer', marginTop: '40%', width: '120px', paddingTop: 25, paddingBottom: 25, backgroundColor: this.props.main.state.page == 'ss' ? ("rgba(255,255,255,0.05") : ("rgba(255,255,255,0")}}>
                <div>
                  <Settings size="25" color="white" style={{color: 'white'}}/>
                </div>
                <div style={{marginTop: 4, color: 'white'}}>
                  Settings
                </div>
              </div>
            </Link>
            <div style={{cursor: 'pointer', marginTop: '40%', width: '120px', paddingTop: 25, paddingBottom: 25, backgroundColor: this.props.main.state.page == 'ss' ? ("rgba(255,255,255,0.05") : ("rgba(255,255,255,0")}} onClick={() => this.logout()}>
              <div>
                <ExitToApp size="25" color="white" style={{color: 'white'}}/>
              </div>
              <div style={{marginTop: 4, color: 'white'}}>
                Logout
              </div>
            </div>
          </div>
      </div>
    );
  }
}

Template.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default (withStyles(styles, { withTheme: true }))(Template);
