import React, {useState, useEffect, useContext} from 'react';
import {
    Drawer,
    IconButton,
    Divider,
    Tabs,
    Tab,
    Button,
    TextField,
    CircularProgress,
    FormControlLabel,
    Radio,
    RadioGroup,
    Slider,
    Switch
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import MuiStyleFunction from './PhotoShoot.style';
import clsx from 'clsx';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ShoppingCartRoundedIcon from '@material-ui/icons/ShoppingCartRounded';
import {StyleContext} from '../../../../contexts/Style'
import VideocamRoundedIcon from '@material-ui/icons/VideocamRounded';
import history from '../../../../utils/history';
import VideoPreviewDialog from './VideoPreviewDialog';

const black_logo = require("../../../../Assets/black_logo.png");
const mobile = (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1)
const useStyles = makeStyles(MuiStyleFunction);

const PhotoShoot = (props) => {

    const classes = useStyles()

    const {isDrawerOpen, setIsDrawerOpen} = useContext(StyleContext);

    const [open, setOpen] = React.useState(true);
    const [tab, setTab] = React.useState(0);
    const [coupon, setCoupon] = React.useState(false);

    const [openPreviewDialog, setOpenPreviewDialog] = useState(false)

    useEffect(() => {
    }, [])

    const handleChange = (event) => {
        setCoupon(event.target.checked)
    };

    const handleDrawerOpen = () => {
      setOpen(true);
      setIsDrawerOpen(true)
    };
  
    const handleDrawerClose = () => {
      setOpen(false);
      setIsDrawerOpen(false)
    };

    const handleChangeTab = (event, newValue) => {
        setTab(newValue)
    };

    const getPreviewStyle = () => {
        var width = '15%'
        if (props.logo_preferences.logoSize == 66) {
          width = '20%'
        } else if (props.logo_preferences.logoSize == 100) {
          width = '25%'
        }
        if (props.logo_preferences.logoPosition == "top_left") {
          return {width: width, height: 'auto', position: 'absolute', left: 10, top: 10, opacity: props.logo_preferences.opacity/100}
        } else if (props.logo_preferences.logoPosition == "top_right") {
          return {width: width, height: 'auto', position: 'absolute', right: 10, top: 10, opacity: props.logo_preferences.opacity/100}
        } else if (props.logo_preferences.logoPosition == "bottom_left") {
          return {width: width, height: 'auto', position: 'absolute', left: 10, bottom: 10, opacity: props.logo_preferences.opacity/100}
        } else if (props.logo_preferences.logoPosition == "bottom_right") {
          return {width: width, height: 'auto', position: 'absolute', right: 10, bottom: 10, opacity: props.logo_preferences.opacity/100}
        }
    }

    return (
        <div style={{background: 'transparent', padding: 24, position: 'sticky', maxWidth: 380, marginLeft: 20, top: 84, background: 'white', boxShadow: 'rgb(99 52 255 / 5%) 0px 4px 8px 0px', borderRadius: 12}}>
            {props.requestData && (
                <VideoPreviewDialog setOpenVideoDialog={props.setOpenVideoDialog} createSnackbar={props.createSnackbar} open={openPreviewDialog} setOpen={setOpenPreviewDialog} videoLink={props.requestData.Video_Link__c} videoId={props.requestData.Video_Id__c} />
            )}
            <Tabs
                value={tab}
                indicatorColor="primary"
                textColor="primary"
                onChange={handleChangeTab}
                aria-label="disabled tabs example"
                style={{background: 'transparent'}}
            >
                <Tab label="Selection" style={{width: '50%', textTransform: 'none'}} />
                <Tab label="Logo" style={{width: '50%', textTransform: 'none'}} />
            </Tabs>
            <div style={{marginTop: 0}}>
                {tab === 0 ? (
                    <div style={{display: 'flex', flexWrap: 'wrap'}}>
                        <div className={classes.rowItem}>
                            <div className={classes.rowTitle}>Payment Status</div>
                            <div style={{display: 'flex', alignItems: 'center', marginTop: 4, gap: 12}}>
                                <div style={{width: 2, height: 32, background: '#6334FF'}}></div>
                                <div className={classes.rowValue}>{props.requestData.Paid__c ? (<span style={{color: '#33D7A0', fontWeight: 'bold'}}>Paid</span>) : (<span  style={{color: 'red', fontWeight: 'bold'}}>Not Paid</span>)}</div>
                            </div>
                        </div>
                        {props.requestData.Discount_Percentage__c !== null ? (
                            <div className={classes.rowItem}>
                                <div className={classes.rowTitle}>Shooting price</div>
                                <div style={{display: 'flex'}}>
                                    <div style={{width: 2, height: 32, background: '#6334FF'}}></div>
                                    <div className={classes.rowValue}>{props.requestData.Shooting_Cost__c}{props.requestData.CurrencyIsoCode} + {props.requestData.Discount_Percentage__c}% Discount</div>
                                </div>
                                {/* <Divider style={{color: 'rgb(226,225,240)', backgroundColor: 'rgb(226,225,240)'}}/> */}
                            </div>
                            ) : (
                            <div className={classes.rowItem}>
                                <div className={classes.rowTitle}>Shooting price</div>
                                <div style={{display: 'flex', alignItems: 'center', marginTop: 4, gap: 12}}>
                                    <div style={{width: 2, height: 32, background: '#6334FF'}}></div>
                                    <div className={classes.rowValue}>{props.requestData.Shooting_Cost__c} {props.requestData.CurrencyIsoCode}</div>
                                </div>
                            </div>
                        )}
                        {props.requestData.Extra_Cost__c !== null && (
                            <div className={classes.rowItem}>
                                <div className={classes.rowTitle}>Extra cost</div>
                                <div style={{display: 'flex', alignItems: 'center', marginTop: 4, gap: 12}}>
                                    <div style={{width: 2, height: 32, background: '#6334FF'}}></div>
                                    <div className={classes.rowValue}>{props.requestData.Extra_Cost__c}{props.requestData.CurrencyIsoCode}</div>
                                </div>
                            </div>
                        )}
                        {props.requestData.Discount_Amount__c !== null && (
                            <div className={classes.rowItem}>
                                <div className={classes.rowTitle}>Discount amount</div>
                                <div style={{display: 'flex', alignItems: 'center', marginTop: 4, gap: 12}}>
                                    <div style={{width: 2, height: 32, background: '#6334FF'}}></div>
                                    <div className={classes.rowValue}>{props.requestData.Discount_Amount__c} {props.requestData.CurrencyIsoCode}</div>
                                </div>
                            </div>
                        )}
                        {props.requestData.Free_Extra_Images__c !== null ? (
                            <div className={classes.rowItem}>
                                <div className={classes.rowTitle}>Selected images</div>
                                <div style={{display: 'flex', alignItems: 'center', marginTop: 4, gap: 12}}>
                                    <div style={{width: 2, height: 32, background: '#6334FF'}}></div>
                                    <div className={classes.rowValue}>{props.extraImagePrice != 0 && (<div>{props.selectedImages.length} / {props.requestData.Number_of_Images_for_Customer__c} + {props.requestData.Free_Extra_Images__c} FREE</div>)}</div>
                                </div>
                            </div>
                        ) : (
                            <div className={classes.rowItem}>
                                <div className={classes.rowTitle}>Selected images</div>
                                <div style={{display: 'flex', alignItems: 'center', marginTop: 4, gap: 12}}>
                                    <div style={{width: 2, height: 32, background: '#6334FF'}}></div>
                                    <div className={classes.rowValue}>{props.extraImagePrice != 0 && (<div>{props.selectedImages.length} / {props.requestData.Number_of_Images_for_Customer__c}</div>)}</div>
                                </div>
                            </div>
                        )}
                        {/* Discount in perecentages for extra images */}
                        {((props.getSelectedImagesToPayFor() > 0) && props.requestData.Extra_Product_Discount__c !== null) ? (
                            <div className={classes.rowItem} style={{width: '100%'}}>
                                <div className={classes.rowTitle}>Extra image price</div>
                                <div style={{display: 'flex', alignItems: 'center', marginTop: 4, gap: 12}}>
                                    <div style={{width: 2, height: 32, background: '#6334FF'}}></div>
                                    <div className={classes.rowValue}>{props.productData.Price_Per_Extra_Product__c} ILS + {props.requestData.Extra_Product_Discount__c}% Discount</div>
                                </div>
                            </div>
                        ) : (
                            <div>
                                {(props.getSelectedImagesToPayFor() > 0) && (
                                <div className={classes.rowItem} style={{width: '100%'}}>
                                    <div className={classes.rowTitle}>Extra image price</div>
                                    <div style={{display: 'flex', alignItems: 'center', marginTop: 4, gap: 12}}>
                                        <div style={{width: 2, height: 32, background: '#6334FF'}}></div>
                                        <div className={classes.rowValue}>{props.productData.Price_Per_Extra_Product__c}</div>
                                    </div>
                                </div>
                                )}
                            </div>
                        )}
                        {props.getSelectedImagesToPayFor() > 0 && (
                            <div className={classes.rowItem}>
                                <div className={classes.rowTitle}>Extra images</div>
                                <div style={{display: 'flex', alignItems: 'center', marginTop: 4, gap: 12}}>
                                    <div style={{width: 2, height: 32, background: '#6334FF'}}></div>
                                    <div className={classes.rowValue}>{String(props.getSelectedImagesToPayFor())} X {String(parseFloat(props.getCostForExtraProduct()).toFixed(2))} ILS</div>
                                </div>
                            </div>
                        )}
                        {props.couponIsValid && (
                            <div className={classes.rowItem}>
                                <div className={classes.rowTitle}>Coupon Discount</div>
                                <div style={{display: 'flex', alignItems: 'center', marginTop: 4, gap: 12}}>
                                    <div style={{width: 2, height: 32, background: '#6334FF'}}></div>
                                    <div className={classes.rowValue}>
                                        {props.couponType === 'cash' ? (
                                            <span>{props.couponDiscount} ILS</span>
                                        ) : (
                                            <span>{props.couponDiscount} %</span>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className={classes.rowItem}>
                            <div className={classes.rowTitle}>Total Amount</div>
                            <div style={{display: 'flex', alignItems: 'center', marginTop: 4, gap: 12}}>
                                <div style={{width: 2, height: 32, background: '#6334FF'}}></div>
                                <div className={classes.rowValue}>{props.getTotalAmountToPayFor()} {props.requestDataCurrencyIsoCode} + VAT</div>
                            </div>
                        </div>
                        <div style={{textAlign: 'left', marginTop: 12}}>
                            <FormControlLabel
                                control={<Switch checked={coupon} onChange={handleChange} name="coupon" />}
                                label="I have a coupon"
                            />
                        </div>
                        {coupon && (
                            <div style={{fontFamily: "'Montserrat', sans-serif", fontSize: 16, color: '#1F1F55', fontWeight: 'bold', textAlign: 'left', marginBottom: 0, color: 'rgb(40,40,53)', width: '100%', marginTop: 24}}>
                                <div style={{display: 'inline-block', verticalAlign: 'bottom', width: 'calc(100% - 80px'}}>
                                    <TextField id="standard-basic" label="Coupon" error={props.errorCoupon} onChange={props.handleChangeCoupon} fullWidth/>
                                </div> 
                                <div style={{display: 'inline-block', verticalAlign: 'bottom'}}>
                                    {props.checkingCoupon ? (
                                    <CircularProgress size={40} style={{marginLeft: 15}}/>
                                    ) : (
                                    <Button helper helperText="Error coupon" variant="primary" style={{marginLeft: 10, width: 70, height: 40, backgroundColor: '#6334FF', color: 'white', textTransform: 'none', fontWeight: 'bold', borderRadius: 20, opacity: props.canApply ? 1 : 0.5}} disabled={!props.canApply} onClick={()=> props.checkIfCouponIsValid()}>Apply</Button>
                                    )}
                                </div>
                            </div>
                        )}
                        {(props.errorCoupon && coupon) && (
                            <div style={{fontFamily: "'Montserrat', sans-serif", fontSize: 14, color: '#1F1F55', marginTop: 4, textAlign: 'left', marginBottom: 0, color: 'red', width: '100%'}}>
                                {props.errorCouponText}
                            </div>
                        )}
                        {(props.errorCouponText === 'Coupon is valid' && coupon) && (
                            <div style={{fontFamily: "'Montserrat', sans-serif", fontSize: 14, color: '#1F1F55', marginTop: 4, textAlign: 'left', marginBottom: 0, color: 'green', width: '100%'}}>
                                {props.errorCouponText}
                            </div>
                        )}
                        {props.confirmSelect && (
                            <Button onClick={()=> props.confirmSelection()} variant="contained" type="submit" elevation={0} style={{color: 'white', fontWeight: 'bold', marginTop: 24, marginBottom: 0, textTransform: 'none', width: '100%', height: 48, borderRadius: 24, background: "#33D7A0", boxShadow: "0px 10px 20px -10px rgba(0,0,0,0.10)"}} className = "flash-button" >
                                Confirm Selection
                            </Button>
                        )}
                    </div>
                ) : (
                    <div style={{maxWidth: '100%', overflowX: 'none'}}>
                        {mobile ? (
                                <div style={{fontWeight: 'bold', width: '100%', fontSize: 18, fontFamily: "'Montserrat', sans-serif", textAlign: 'center', marginTop: 50}}>Oops.. <br/><br/>Available only from desktop</div>
                            ) : (
                                <div>
                                    <div style={{ fontFamily: "'Montserrat', sans-serif", fontSize: 18, color: '#1F1F55', fontWeight: '500', textAlign: 'left', marginBottom: 15, marginTop: 15, color: 'rgb(40,40,53)'}}>
                                        Upload your logo
                                    </div>
                                    <form onSubmit={props.onFormSubmit}>
                                    <input type="file" name="myImage" onChange= {props.onChangeLogo} style={{display: 'inline-block', textAlign: 'left', width: '100%'}}/>
                                    {props.uploadingWatermark && (
                                        <div style={{marginTop: 0, display: 'inline-block'}}>
                                            <CircularProgress size={20} style={{marginTop: 0}}/>
                                        </div>
                                    )}
                                    </form>
                                    <div style={{ fontFamily: "'Montserrat', sans-serif", fontSize: 18, color: '#1F1F55', fontWeight: '500', textAlign: 'left', marginBottom: 15, marginTop: 24, width: '100%', color: 'rgb(40,40,53)'}}>
                                        Choose position
                                    </div>
                                    <div>
                                        <RadioGroup aria-label="gender" name="gender2" value={props.logo_preferences.logoPosition} onChange={props.handleChangeLogoPosition}>
                                            <FormControlLabel
                                                value="top_right"
                                                control={<Radio color="primary" />}
                                                label="Top Right"
                                                labelPlacement="end"
                                            />
                                            <FormControlLabel
                                                value="bottom_right"
                                                control={<Radio color="primary" />}
                                                label="Bottom Right"
                                                labelPlacement="end"
                                            />
                                            <FormControlLabel
                                                value="top_left"
                                                control={<Radio color="primary" />}
                                                label="Top Left"
                                                labelPlacement="end"
                                            />
                                            <FormControlLabel
                                                value="bottom_left"
                                                control={<Radio color="primary" />}
                                                label="Bottom Left"
                                                labelPlacement="end"
                                            />
                                        </RadioGroup>
                                    </div>
                                    <div style={{fontFamily: "'Montserrat', sans-serif", fontSize: 18, color: '#1F1F55', fontWeight: '500', textAlign: 'left', marginBottom: 15, marginTop: 15, color: 'rgb(40,40,53)'}}>
                                        Choose opacity
                                    </div>
                                    <div style={{marginTop: 25, paddingLeft: 12, paddingRight: 12}}>
                                        <Slider
                                        value={props.logo_preferences.opacity}
                                        defaultValue={props.logo_preferences.opacity}
                                        getAriaValueText={() => props.handleChangeOpacity}
                                        aria-labelledby="discrete-slider-always"
                                        onChange={props.handleChangeOpacity}
                                        step={10}
                                        marks={[]}
                                        valueLabelDisplay="auto"
                                        />
                                    </div>
                                    <div style={{fontFamily: "'Montserrat', sans-serif", fontSize: 18, color: '#1F1F55', fontWeight: '500', textAlign: 'left', marginBottom: 15, marginTop: 15, color: 'rgb(40,40,53)'}}>
                                        Choose size
                                    </div>
                                    <div style={{marginTop: 0, paddingLeft: 12, paddingRight: 12}}>
                                        <Slider
                                            value={props.logo_preferences.logoSize}
                                            defaultValue={props.logo_preferences.logoSize}
                                            aria-labelledby="discrete-slider-restrict"
                                            step={null}
                                            onChange={props.handleChangeSize}
                                            valueLabelDisplay="auto"
                                            marks={[{value: 33, label: 'small'},{value: 66, label: 'medium'},{value: 100, label: 'large'}]}
                                        />
                                    </div>
                                    <div style={{fontFamily: "'Montserrat', sans-serif", fontSize: 18, color: '#1F1F55', fontWeight: '500', textAlign: 'left', marginBottom: 20, marginTop: 25, color: 'rgb(40,40,53)'}}>
                                        Preview
                                    </div>
                                    {props.editedImages.imagesForGallery.length > 0 && (
                                        <div style={{ position: 'relative', textAlign: 'center', color: 'white'}}>
                                            <img src={props.editedImages.imagesForGallery[0].src} alt="Avatar" className="text" height="80px" style={{width: '100%', height: 'auto', borderRadius: 8}}/>
                                            {(props.logo_preferences.url != "" && props.updateInProgress == false) ? (
                                                <img src={`${props.logo_preferences.url}?${props.imageHash}`} alt="Avatar" className="text" width="80px" height="80px" style={getPreviewStyle()}/>
                                            ) : (
                                                <div>
                                                    {props.updateInProgress == true ? (<div></div>) : (
                                                        <img src={black_logo} alt="Avatar" className="text" width="80px" height="80px" style={getPreviewStyle()}/>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    )}
            </div>
        </div>
    );
}
 
export default PhotoShoot;