// import React from 'react';
// import TextField from '@material-ui/core/TextField';
// import Autocomplete from '@material-ui/lab/Autocomplete';
// import LocationOnIcon from '@material-ui/icons/LocationOn';
// import Grid from '@material-ui/core/Grid';
// import Typography from '@material-ui/core/Typography';
// import { makeStyles } from '@material-ui/core/styles';
// import parse from 'autosuggest-highlight/parse';
// import throttle from 'lodash/throttle';

// function loadScript(src, position, id) {
//   if (!position) {
//     return;
//   }

//   const script = document.createElement('script');
//   script.setAttribute('async', '');
//   script.setAttribute('id', id);
//   script.src = src;
//   position.appendChild(script);
// }

// const autocompleteService = { current: null };

// const useStyles = makeStyles((theme) => ({
//   icon: {
//     color: theme.palette.text.secondary,
//     marginRight: theme.spacing(2),
//   },
// }));

// export default function GoogleMaps(props) {
//   const classes = useStyles();
//   const [inputValue, setInputValue] = React.useState('');
//   const [options, setOptions] = React.useState([]);
//   const loaded = React.useRef(true);

//   if (typeof window !== 'undefined' && !loaded.current) {
//     if (!document.querySelector('#google-maps')) {
//       loadScript(
//         'https://maps.googleapis.com/maps/api/js?key=AIzaSyDJ7KNKLQOtQqlx871XHHU9EcZRt2K4qso&libraries=places',
//         document.querySelector('head'),
//         'google-maps',
//       );
//     }

//     loaded.current = true;
//   }

//   const handleChange = (event) => {
//     setInputValue(event.target.value);
//     props.main.setState({formattedAddress: event.target.value})
//   };

//   const fetch = React.useMemo(
//     () =>
//       throttle((request, callback) => {
//         autocompleteService.current.getPlacePredictions(request, callback);
//       }, 200),
//     [],
//   );

//   React.useEffect(() => {
//     let active = true;

//     if (!autocompleteService.current && window.google) {
//       autocompleteService.current = new window.google.maps.places.AutocompleteService();
//     }
//     if (!autocompleteService.current) {
//       return undefined;
//     }

//     if (inputValue === '') {
//       setOptions([]);
//       return undefined;
//     } else {
//       props.main.setState({formattedAddress: inputValue})
//     }

//     fetch({ input: inputValue }, (results) => {
//       if (active) {
//         setOptions(results || []);
//       }
//     });

//     return () => {
//       active = false;
//     };
//   }, [inputValue, fetch]);

//   return (
//     <Autocomplete
//       id="google-map-demo"
//       style={{ width: '100%', backgroundColor: '#ecf1fe', borderRadius: 28}}
//       getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
//       filterOptions={(x) => x}
//       options={options}
//       autoComplete
//       value={props.main.state.formattedAddress}
//       includeInputInList
//       renderInput={(params) => (
//         <TextField
//           {...params}
//           label="Choose specific address"
//           variant="outlined"
//           fullWidth
//           // error={props.main.state.errorAddress}
//           // helperText={props.main.state.errorAddress ? 'Address needs to containe a specific address' : ''}
//           // onChange={handleChange}
//         />
//       )}
//       renderOption={(option) => {
//         console.log(option)
//         const matches = option.structured_formatting.main_text_matched_substrings;
//         const parts = parse(
//           option.structured_formatting.main_text,
//           matches.map((match) => [match.offset, match.offset + match.length]),
//         );

//         return (
//           <Grid container alignItems="center">
//             <Grid item>
//               <LocationOnIcon className={classes.icon} />
//             </Grid>
//           <Grid item xs>
//               {parts.map((part, index) => (
//                 <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
//                   {part.text}
//                 </span>
//               ))}

//               <Typography variant="body2" color="textSecondary">
//                 {option.structured_formatting.secondary_text}
//               </Typography>
//             </Grid>
//           </Grid>
//         );
//       }}
//     />
//   );
// }
import React from 'react';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
 
class LocationSearchInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = { address: '' };
  }
 
  handleChange = address => {
    // console.log(address)
    this.setState({ address });
  };
 
  handleSelect = address => {
    console.log(address)
    this.setState({address: address})
    this.props.main.getCoordinates(address)
    // geocodeByAddress(address)
    //   .then(results => getLatLng(results[0]))
    //   .then(latLng => console.log('Success', latLng))
    //   .catch(error => console.error('Error', error));
  };
 
  render() {
    return (
      <PlacesAutocomplete
        value={this.state.address}
        onChange={this.handleChange}
        searchOptions={
          {
            // componentRestrictions: { country: ['il'] },
          types: ['address']
        }}
        onSelect={this.handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div style={{boxShadow: suggestions.length !== 0 ? '0 4px 10px 0 rgba(0,0,0,.25)' : '0 4px 10px 0 rgba(0,0,0,.0)', borderRadius: 30, backgroundColor: 'white', maxWidth: '100%', width: '100%', zIndex: '999999'}}>
            <input
              {...getInputProps({
                placeholder: 'Choose specific address',
                className: 'location-search-input',
              })}
              className="OrderInput"
              style={{display: 'inline-block', float: 'left', width: '100%'}}
            />
            {suggestions.length > 0 && (
              <div className="autocomplete-dropdown-container" style={{position: 'absolute', marginTop: 70, backgroundColor: 'white', boxShadow: suggestions.length !== 0 ? '0 4px 10px 0 rgba(0,0,0,.25)' : '0 4px 10px 0 rgba(0,0,0,.0)', borderRadius: 20, backgroundColor: 'white', maxWidth: '100%',zIndex: '999999', padding: 12}}>
                {loading && <div>Loading...</div>}
                {suggestions.map(suggestion => {
                  const className = suggestion.active
                    ? 'suggestion-item--active'
                    : 'suggestion-item';
                  // inline style for demonstration purpose
                  const style = suggestion.active
                    ? { backgroundColor: '#fafafa', cursor: 'pointer', borderRadius: 30, padding: 12, backgroundColor: 'none', textAlign: 'left' }
                    : { backgroundColor: '#ffffff', cursor: 'pointer', borderRadius: 30, padding: 12, backgroundColor: 'none', textAlign: 'left' };
                  return (
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style,
                      })}
                      // onClick={() => {this.handleSelect(suggestion); this.setState({address: ''})}}
                    >
                      {/* {console.log(suggestion)} */}
                      <span>{suggestion.description}</span>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        )}
      </PlacesAutocomplete>
    );
  }
}

export default LocationSearchInput
