import React from 'react';
import {Grid} from '@material-ui/core'
import {Skeleton} from '@material-ui/lab'
const PhotoShootSkeletons = () => {
    return (
        <Grid container spacing={2} dir="ltr" justify="flex-start">
            <Grid item xs={12} sm={12} md={12} xl={12}>
                <Skeleton animation="wave" variant={'rect'} width={210} height={50} style={{borderRadius: 24}} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} xl={12}>
                <Skeleton animation="wave" variant={'rect'} width={'100%'} height={200} style={{borderRadius: 24}} />
            </Grid>
            <Grid item xs={12} sm={12} md={6} xl={6}>
                <Skeleton animation="wave" variant={'rect'} width={'100%'} height={100} style={{borderRadius: 24}} />
            </Grid>
            <Grid item xs={12} sm={12} md={6} xl={6}>
                <Skeleton animation="wave" variant={'rect'} width={'100%'} height={100} style={{borderRadius: 24}} />
            </Grid>
            <Grid item xs={12} sm={12} md={6} xl={6}>
                <Skeleton animation="wave" variant={'rect'} width={'100%'} height={100} style={{borderRadius: 24}} />
            </Grid>
            <Grid item xs={12} sm={12} md={6} xl={6}>
                <Skeleton animation="wave" variant={'rect'} width={'100%'} height={100} style={{borderRadius: 24}} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} xl={12}>
                <Skeleton animation="wave" variant={'rect'} width={120} height={50} style={{borderRadius: 24}} />
            </Grid>
            <Grid item xs={12} sm={12} md={4} xl={4}>
                <Skeleton animation="wave" variant={'rect'} width={'100%'} height={280} style={{borderRadius: 24}} />
            </Grid>
            <Grid item xs={12} sm={12} md={4} xl={4}>
                <Skeleton animation="wave" variant={'rect'} width={'100%'} height={280} style={{borderRadius: 24}} />
            </Grid>
            <Grid item xs={12} sm={12} md={4} xl={4}>
                <Skeleton animation="wave" variant={'rect'} width={'100%'} height={280} style={{borderRadius: 24}} />
            </Grid>
            <Grid item xs={12} sm={12} md={4} xl={4}>
                <Skeleton animation="wave" variant={'rect'} width={'100%'} height={280} style={{borderRadius: 24}} />
            </Grid>
            <Grid item xs={12} sm={12} md={4} xl={4}>
                <Skeleton animation="wave" variant={'rect'} width={'100%'} height={280} style={{borderRadius: 24}} />
            </Grid>
            <Grid item xs={12} sm={12} md={4} xl={4}>
                <Skeleton animation="wave" variant={'rect'} width={'100%'} height={280} style={{borderRadius: 24}} />
            </Grid>
        </Grid>
    );
}
 
export default PhotoShootSkeletons;