import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  }
});

const useStyles = makeStyles({
  roundedCorners: {
    borderRadius: 24
  },
  confirmSelection: {
    paddingLeft: 18,
    paddingRight: 18,
    height: 42,
    borderRadius: 21,
    backgroundColor: '#33D7A0',
    color: 'white',
    textTransform: 'none'
  },
  dialogContent: {
    borderTop: '1px solid rgba(0,0,0,0.2)'
  }
})

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
}))(MuiDialogActions);

const BorderLinearProgress = withStyles({
  root: {
    height: 10,
    borderRadius: 20,
    backgroundColor: 'rgb(249,250,252)'
  },
  bar: {
    borderRadius: 20,
    backgroundColor: '#04e259',
  },
})(LinearProgress);

export default function CustomizedDialogs(props) {

  const classes = useStyles()

  return (
    <div>
      <Dialog maxWidth={'sm'} fullWidth={true} onClose={()=> props.setOpen(false)} aria-labelledby="customized-dialog-title" open={props.open} classes={{root: classes.roundedCorners, container: classes.roundedCorners, paper: classes.roundedCorners }}>
        <DialogTitle id="customized-dialog-title" onClose={()=> props.setOpen(false)}>
          Downloading Images...
        </DialogTitle>
        <DialogContent classes={{root: classes.dialogContent}}>
          <div style={{paddingTop: 24, paddingBottom: 24, paddingLeft: 12, paddingRight: 12, textAlign: 'center', minHeight: 120}}>
          <Typography gutterBottom>
            <b>Note</b> - Download in progress, It can take some time.
            {(props.downloadPercenteges != 100 && props.downloadPercenteges != 0) && (
              <div style={{width: '100%', marginTop: 10, textAlign: 'left', marginBottom: 10, height: 40}}>
                <div style={{marginTop: 0}}>
                  {props.prepearingImages ? (
                    <div style={{width: '100%', textAlign: 'left', color: 'black', marginBottom: 10}}>Prepearing images.. Download will start in few seconds</div>
                  ) : (
                    <div style={{width: '100%', textAlign: 'left', color: 'black', marginBottom: 10}}>Download in progress - {props.downloadPercenteges}%</div>
                  )}
                  <BorderLinearProgress variant="determinate" value={props.downloadPercenteges} />
                </div>
              </div>
            )}
          </Typography>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
